import React, {useEffect, useState} from "react";
import Dialog from "common/ui/Dialog";

import {Header} from "./Header";
import {Form} from "./Form";
import {LegalFooter} from "core/Footer/LegalFooter";

import {useHistory} from "react-router";
import {EmailAndPhone} from "./EmailAndPhone";
import {partBCalculatorService} from "./service";
import {Helmet} from "react-helmet";
import {
	SITE_DESCRIPTION,
	SITE_IMAGE_URL,
	SITE_TITLE,
	SITE_URL,
} from "./data/header-constants";
import ABTester from "common/util/abTester";

const {REACT_APP_EXPERIMENT_PART_B_CALC_APP_FLOW} = process.env;

const RESULTS_ROUTE = "/medicare-premium-calculator/results";

export function PartBCalculator() {
	const history = useHistory();

	/** Google Optimize Experiment */
	const [hideModal, setHideModal] = useState(false);

	useEffect(() => {
		getNameExperiment();
	}, []);

	async function getNameExperiment() {
		const abTesterInstance = new ABTester(
			REACT_APP_EXPERIMENT_PART_B_CALC_APP_FLOW
		);
		const variants = await abTesterInstance.getVariants();

		// Variant 0 ––> Show Modal
		// Variant 1 ––> Don't Show Modal
		if (variants[0] === 1) {
			setHideModal(true);
		}
	}

	function onComplete({phoneNumber, email, name}: any) {
		// Save the updated info on a customer
		partBCalculatorService.saveCustomerAdditionalInfo({
			phoneNumber: phoneNumber.replace(/\D/g, "") || "",
			email,
			name,
		});

		Dialog.hide();
		history.push(RESULTS_ROUTE);
	}

	function onSubmitPressed() {
		// Save before showing the modal
		partBCalculatorService.saveCustomerData();

		if (hideModal) {
			history.push(RESULTS_ROUTE);
			return;
		}

		// Show a phone / email dialog
		Dialog.show({
			children: (
				<div className="bg-white rounded-md text-base py-4 px-5">
					<div className="mx-auto max-w-xl">
						<EmailAndPhone
							onClose={() => {
								Dialog.hide();
							}}
							onComplete={onComplete}
						/>
					</div>
				</div>
			),
			canOutsideClickClose: false,
			width: 550,
		} as any);
	}

	return (
		<div className="container mx-auto px-7 lg:px-20 font-normal	text-base">
			<Helmet>
				<title>Medicare Premium Calculator</title>
				<meta property="og:url" content={SITE_URL} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={SITE_TITLE} />
				<meta property="og:description" content={SITE_DESCRIPTION} />
				<meta property="og:image" content={SITE_IMAGE_URL} />
			</Helmet>
			<Header />
			<div className="flex pt-16 pb-0 max-w-5xl mx-auto">
				<Form onSubmit={onSubmitPressed} />
			</div>
			<div className="flex py-6 max-w-5xl mx-auto">
				<LegalFooter />
			</div>
		</div>
	);
}

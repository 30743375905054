import React, {PureComponent} from "react";
import {NavLink} from "react-router-dom";
import {TouchableDivWrapper} from "./TouchableHelpers";

export default class Touchable extends PureComponent {
	clickWrapper = (e) => {
		const {onClick} = this.props;

		if (onClick) {
			e.preventDefault();
			onClick(e);
		}
	};

	render() {
		const {href, id, children, className, target, style} = this.props;

		if (href) {
			const elType = href.includes("http") ? "a" : NavLink;
			return React.createElement(
				elType,
				{
					id,
					target,
					href,
					to: href,
					className,
					onClick: this.clickWrapper,
				},
				children
			);
		} else {
			return (
				<TouchableDivWrapper
					style={style}
					id={id}
					className={className}
					children={children}
					onClick={this.clickWrapper}
				/>
			);
		}
	}
}

import React from "react";
import {BarChart} from "common/ui/Charts/BarChart";

export function Charts({calculatedResults}: {calculatedResults: any}) {
	return (
		<>
			<div className="grid md:grid-cols-2 gap-10">
				<div className="text-base">
					Monthly Premiums
					<BarChart
						className="max-w-sm"
						labels={["Current Plan", "Medicare"]}
						data={[
							calculatedResults.current.premium,
							calculatedResults.medicare.premium,
						]}
					/>
				</div>

				<div className="text-base">
					Annual Deductible
					<BarChart
						className="max-w-sm"
						labels={["Current Plan", "Medicare"]}
						data={[
							calculatedResults.current.annualDeductible,
							calculatedResults.medicare.annualDeductible,
						]}
					/>
				</div>
			</div>

			<div className="grid md:grid-cols-2 gap-10">
				<div className="text-base">
					Annual Max-Out-of-Pocket
					<BarChart
						className="max-w-sm"
						labels={["Current Plan", "Medicare"]}
						data={[
							calculatedResults.current.maxOopIn,
							calculatedResults.medicare.maxOopIn,
						]}
					/>
					<div className="text-xs">
						Annual Max-Out-of-Pocket excludes prescription costs. We can review
						the prescription costs on a Medicare plan together.
					</div>
				</div>
				<div className="text-base">
					Total Annual Exposure
					<BarChart
						className="max-w-sm"
						labels={["Current Plan", "Medicare"]}
						data={[
							calculatedResults.current.annualExposure,
							calculatedResults.medicare.annualExposure,
						]}
					/>
					<div className="text-xs">
						Total Annual Exposure excludes prescription costs. We can review the
						prescription costs on a Medicare plan together.
					</div>
				</div>
			</div>
		</>
	);
}

import clsx from "clsx";
import React from "react";

export function PlanComparison({
	plans,
	factors,
}: {
	plans: any[];
	factors: any[];
}) {
	return (
		<div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-5 lg:gap-2 lg:space-y-0">
			{plans.map((plan, i) => (
				<>
					{/** Show Feature Names every alternate row */}
					{i % 2 === 0 ? (
						<div
							key={plan.name}
							className={clsx(
								"flex flex-col rounded-lg shadow-lg overflow-hidden col-span-2 border",
								plan.recommended && "border-teal-400"
							)}
						>
							<div className="px-6 pt-5 bg-white">
								<div>
									<h1
										className="inline-flex text-underline-accent text-2xl"
										id="tier-standard"
									>
										{plan.name}
									</h1>
								</div>
							</div>
							<div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 space-y-6 sm:p-8 sm:pt-6">
								<ul className="divide-y">
									{plan.features.map((feature) => (
										<li key={feature} className="flex items-center h-14">
											<p
												className="ml-3 text-base text-left text-gray-700"
												dangerouslySetInnerHTML={{__html: feature}}
											></p>
										</li>
									))}
								</ul>
							</div>
						</div>
					) : (
						<div className="flex flex-col overflow-hidden col-span-1">
							<div className="px-6 py-7 bg-white">
								<div></div>
							</div>
							<div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8  space-y-6 sm:p-10 sm:pt-6">
								<ul className="divide-y">
									{factors.map((factor) => (
										<li className="flex flex-col items-center h-14">
											<img
												className="h-6"
												src={factor.imgSrc}
												alt={factor.name}
											/>
											<div className="text-sm font-bold">{factor.name}</div>
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
				</>
			))}
		</div>
	);
}

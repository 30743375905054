import {
	getMedsupRecommendation,
	getMaRecommendation,
} from "common/util/api/plans";
import {formatMoney} from "common/util/format";

import {partBCalculatorService} from "pages/PartBCalculator/service";
import RESULTS_DATA, {
	IResultData,
	IResultPlan,
} from "pages/PartBResults/results";
import {calculatePremium as calculatePartBPremium} from "pages/PartBResults/service";

interface IPlan {
	premium: number;
	maxOopIn: number;
	annualDeductible: number;
	annualExposure?: number;
}

export interface IFormData {
	name?: string;
	email?: string;
	zip: string;
	dob: string;

	gender: "Male" | "Female" | "preferNotToAnswer";
	hasWorkedTenYears: "yes" | "no";
	tobacco?: "true" | "false" | "preferNotToAnswer";

	optFileTaxes: "single" | "joint" | "separately";
	optIncomeRange: "1" | "2" | "3" | "4" | "5" | "6";

	currentEmployerPlan: string;
	currentInsurancePremium: string; // (number);
	currentInsuranceDeductible: string; // (number);
	numDependents: "0" | "1" | "2" | "3"; // dependentCode;
	maZipReferenceId: string;
	maxOopIn: string;

	hsaDiscount?: string;
	premiumDiscount?: string;

	optMinimizeCosts: "yes" | "no" | "maybe";
	medsupForZipCost?: number;
}

export interface IResult extends IResultData {
	current: IPlan;
	medicare: IPlan;
	recommendedPlan: IResultPlan;
	totalAnnualExposureSavings: number;
	totalAnnualExposureSavingsReadable: string;
}

/** Wraps parseInt with checks to 0 out if NaN or undefined  */
function parseIntSafe(str: string | undefined) {
	const numParsed = parseInt(str || "0", 10);
	return isNaN(numParsed) ? 0 : numParsed;
}

async function getRecommendedMaPlan(
	values: IFormData,
	partBPremium: number
): Promise<IPlan> {
	const maRecommendation =
		(await getMaRecommendation(
			values.maZipReferenceId,
			".25",
			"inNetworkOOPMax"
		).catch((e) => console.warn(e))) || {};

	return {
		premium: partBPremium,
		maxOopIn: parseIntSafe(maRecommendation.inNetworkOOPMax),
		annualDeductible: parseIntSafe(maRecommendation.deductible),
	};
}

async function getRecommendedMedSupPlan(
	values: IFormData,
	partBPremium: number
): Promise<IPlan> {
	const medSupPartial =
		(await getMedsupRecommendation().catch((e) => console.warn(e))) || {};

	const medSupRec = {...medSupPartial, premium: values.medsupForZipCost};

	return {
		premium: parseIntSafe(medSupRec.premium) + partBPremium,
		maxOopIn: parseIntSafe(medSupRec.maxOutOfPocket),
		annualDeductible: medSupRec.partBDeductible,
	};
}

function getCurrentPlanDetails(values: IFormData): IPlan {
	// offset premium by hsaDiscount or premiumDiscount
	const premiumOffsetString = values.premiumDiscount;
	const premiumOffset = parseIntSafe(premiumOffsetString);
	const premium = parseIntSafe(values.currentInsurancePremium) - premiumOffset;
	const hsaOffsetString = values.hsaDiscount;
	const hsaOffset = parseIntSafe(hsaOffsetString);

	// HSA contributions reduce OOP max, and since we get it monthly,
	// let's multiply by months/year
	const maxOopIn = parseIntSafe(values.maxOopIn) - hsaOffset * 12;

	return {
		premium,
		maxOopIn,
		annualDeductible: parseIntSafe(values.currentInsuranceDeductible),
		annualExposure: premium * 12 + maxOopIn,
	};
}

export function getDoesRecommendMA(
	optMinimizeCosts: IFormData["optMinimizeCosts"]
) {
	// MedSup is explicitly "no" here
	return optMinimizeCosts !== "no";
}

function getSwitchRecommendation(
	currentPlanAnnualExposure: number,
	recommendedPlanAnnualExposure: number
) {
	if (currentPlanAnnualExposure >= recommendedPlanAnnualExposure) {
		return {
			...RESULTS_DATA.LIKELY,
			header: "You likely want to enroll in Medicare.",
		};
	} else {
		return RESULTS_DATA.CONSIDER;
	}
}

export async function calculatePremium(values: IFormData): Promise<IResult> {
	const partBCalculations = calculatePartBPremium({
		...values,
		// Assumptions from employer calculator
		hasEmployerHealthCoverage: "yes",
		hasMoreThanTwentyEmployees: "yes",
		wantsSpouseCoverage: "false",
	} as any);

	const recommendMa = getDoesRecommendMA(values.optMinimizeCosts);
	const recommendedPlan = partBCalculations.plans
		?.slice(1)
		?.find((p) => (p.isMa && recommendMa) || (!p.isMa && !recommendMa));

	const originalMedicarePlan =
		partBCalculations.plans && partBCalculations.plans[0];
	const partBPremium = originalMedicarePlan?.priceNumber!;

	const maPlan = await getRecommendedMaPlan(values, partBPremium!);
	const medSupPlan = await getRecommendedMedSupPlan(values, partBPremium!);
	const currentPlan = getCurrentPlanDetails(values);

	const medicarePlanRecommendation = recommendMa ? maPlan : medSupPlan;

	const totalAnnualExposureMedicare =
		medicarePlanRecommendation.premium * 12 +
		medicarePlanRecommendation.maxOopIn;

	const totalAnnualExposureSavings =
		currentPlan.annualExposure! - totalAnnualExposureMedicare;

	// We want to never recommend staying on employer coverage here
	// so we modify the header here if the recommendation is stay.
	// if the calculation logic here diverges too much, we'll import the
	// relevant functions and compute here. For now, this is the only
	// divergence

	const calculations = getSwitchRecommendation(
		currentPlan.annualExposure!,
		totalAnnualExposureMedicare
	);

	return {
		...{...partBCalculations, ...calculations},
		recommendedPlan: recommendedPlan!,
		current: {...currentPlan},
		medicare: {
			...medicarePlanRecommendation,
			annualExposure: totalAnnualExposureMedicare,
		},
		totalAnnualExposureSavings:
			totalAnnualExposureSavings < 0 ? 0 : totalAnnualExposureSavings,
		totalAnnualExposureSavingsReadable: formatMoney(totalAnnualExposureSavings),
	};
}

class EmployeeCalculatorService {
	private values: IFormData = {
		currentEmployerPlan: "hsa-plan",
		zip: "",
		dob: "",

		// Selects have a default value
		optIncomeRange: "1",
		numDependents: "0",
		premiumDiscount: "0",
		optMinimizeCosts: "maybe",
	} as IFormData;

	private results: IResult = {} as IResult;

	async setResults(values: IFormData) {
		partBCalculatorService.setResults(values);
		this.results = {
			...partBCalculatorService.getResults(),
			...values,
		} as any;
	}

	async fetchCalculations() {
		// Part B Calc injects lowestMedsupInZip
		return await calculatePremium(this.getResults() as any);
	}

	getResults() {
		return this.results;
	}

	getInitialValues() {
		return this.values;
	}
}

export const employeeCalculatorService = new EmployeeCalculatorService();

import {ICustomer} from "common/util/api/service";
import {
	getIEPForBirthdate,
	getSEPForRetirementMonthAndYear,
	getGEPCurrentOrNext,
	getDateFromString,
} from "common/util/format";
import {format, isBefore, isWithinInterval} from "date-fns";

import {CURRENT_COVERAGE_VALUE_MAP, IFormData} from "./data/form";
import {
	enrollGEPSmallEmployer,
	enrollOnEmployerCoverage,
	enrollSEPEmployerCoverage,
	enrollNoSEPSmallEmployer,
	enrollGEPIEPClosed,
	enrollIEP,
	enrollIEPSmallEmployer,
	enrollIEPWithSEP,
	enrollSEPRetirementCoverage,
	enrollNowAfterBirthday,
	enrollNowPreBirthday,
	HSA_ALERT_BULLETS,
	HSA_ALERT_TITLE,
	RR_SS_DISABLED_ALERT_TITLE,
	COBRA_ALERT_BULLETS,
	COBRA_ALERT_TITLE,
	OBAMACARE_ALERT_BULLETS,
	OBAMACARE_ALERT_TITLE,
	IEnrollmentResultWindow,
	RR_SS_DISABLED_ALERT_BULLETS,
	DISABLED_ALERT,
	DISABLED_ALERT_BULLETS,
} from "./result/constants";

export interface IEnrollmentResultWindowExtended
	extends IEnrollmentResultWindow {
	calculatedStart: Date;
	calculatedEnd: Date;

	calculatedStartText: string;
	calculatedEndText: string;

	iepStartReadable: string;
	iepEndReadable: string;
	sepEndReadable: string;
	gepYear: number;
	currentInsuranceCoverageType: string;
	medicarePartsText: string;
	benefitTypeText: string;
	isRetired: boolean;
}

function replaceVariables(data: IEnrollmentResultWindowExtended) {
	const replacements = [
		{key: "{{iepStart}}", value: data.iepStartReadable},
		{key: "{{iepEnd}}", value: data.iepEndReadable},
		{key: "{{sepEnd}}", value: data.sepEndReadable},
		{key: "{{gepYear}}", value: data.gepYear?.toString()},
		{
			key: "{{currentInsuranceCoverageType}}",
			value: CURRENT_COVERAGE_VALUE_MAP[data.currentInsuranceCoverageType],
		},
		{key: "{{medicarePartsText}}", value: data.medicarePartsText},
	];

	replacements.forEach((o) => {
		data.descriptionAreas = data.descriptionAreas?.map((descriptionArea) => {
			return {
				...descriptionArea,
				lines: descriptionArea.lines.map((l) =>
					l.replace(new RegExp(o.key, "g"), o.value!)
				),
			};
		})!;
		data.nextStepLines = data.nextStepLines?.map((l) =>
			l.replace(new RegExp(o.key, "g"), o.value!)
		);
	});
	return data;
}

function getSpecialCircumstancesAlert(
	specialExceptions: IFormData["specialExceptions"]
) {
	const isHSA = specialExceptions === "hsa";
	const isDisabled = specialExceptions === "disabled";
	const isRailRoadBenefits = specialExceptions === "rr";
	const isSocialSecurity = specialExceptions === "ss";

	// Special Circumstances Alerts
	if (isHSA) {
		return {
			title: HSA_ALERT_TITLE,
			descriptionLines: HSA_ALERT_BULLETS,
		};
	} else if (isRailRoadBenefits || isSocialSecurity) {
		return {
			title: RR_SS_DISABLED_ALERT_TITLE,
			descriptionLines: RR_SS_DISABLED_ALERT_BULLETS,
		};
	} else if (isDisabled) {
		return {
			title: DISABLED_ALERT,
			descriptionLines: DISABLED_ALERT_BULLETS,
		};
	} else {
		return;
	}
}

function getCurrentInsuranceCoverageTypeAlert(
	currentInsuranceCoverageType: IFormData["currentInsuranceCoverageType"]
) {
	// Current Coverage Alerts
	if (currentInsuranceCoverageType === "cobra") {
		return {
			title: COBRA_ALERT_TITLE,
			descriptionLines: COBRA_ALERT_BULLETS,
		};
	} else if (currentInsuranceCoverageType === "obamacare") {
		return {
			title: OBAMACARE_ALERT_TITLE,
			descriptionLines: OBAMACARE_ALERT_BULLETS,
		};
	} else {
		return undefined;
	}
}

function calculateEnrollmentWindowCase(
	values: IFormData,
	isWithinIEP: boolean,
	isBeforeIEP: boolean,
	isWithinSEP: boolean | undefined
) {
	const hasEmployerCoverage =
		values.currentInsuranceCoverageType === "my-employer" ||
		values.currentInsuranceCoverageType === "spouse-employer";
	const isRetired = values.retirementStatus === "retired";
	const hasCreditableCoverage = values.hasMoreThanTwentyEmployees === "true";

	// if before iep start
	// case 8, 9, 10 depending on employers
	if (isBeforeIEP) {
		if (hasEmployerCoverage) {
			if (hasCreditableCoverage) {
				return enrollIEPWithSEP;
			} else {
				return enrollIEPSmallEmployer;
			}
		} else {
			return enrollIEP;
		}
	}
	// if we're after IEP and SEP, regardless of case, we should show
	// enrollGEP
	if (!isWithinIEP && isWithinSEP !== undefined && !isWithinSEP) {
	}
	if (hasEmployerCoverage) {
		if (!hasCreditableCoverage) {
			return isWithinIEP ? enrollNoSEPSmallEmployer : enrollGEPSmallEmployer;
		} else {
			if (!isWithinIEP && isWithinSEP) {
				return isRetired
					? enrollSEPRetirementCoverage
					: enrollSEPEmployerCoverage;
			} else {
				return enrollOnEmployerCoverage;
			}
		}
	} else {
		if (isWithinIEP) {
			// Because we're in IEP, we know we're either before or after the user's
			// birthday but still within a signup window
			const isNowBeforeUserBirthday = isBefore(
				new Date(),
				getDateFromString(values.dob).setFullYear(new Date().getFullYear())
			);
			return isNowBeforeUserBirthday
				? enrollNowPreBirthday
				: enrollNowAfterBirthday;
		} else {
			return enrollGEPIEPClosed;
		}
	}
}

export function calculateEnrollmentWindowResult(
	values: IFormData,
	medicareSignUpStatus: ICustomer["medicareSignUpStatus"]
) {
	const showJustPartB = medicareSignUpStatus === "partA";

	let isWithinSEP: boolean | undefined = undefined;
	let sepDates;

	const effectiveRetirementDate =
		values.retirementDate || values.expectedRetirementDate;

	// Calculate SEP window if the user provided a retirement date
	if (effectiveRetirementDate) {
		const {start: sepStart, end: sepEnd} = getSEPForRetirementMonthAndYear(
			effectiveRetirementDate
		);
		sepDates = {start: sepStart, end: sepEnd};
		isWithinSEP = isWithinInterval(new Date(), {
			start: sepStart,
			end: sepEnd,
		});
	}

	// Calculate IEP given user's birth date
	const {start: iepStart, end: iepEnd} = getIEPForBirthdate(values.dob);
	const iepDates = {start: iepStart, end: iepEnd};

	const exception = getSpecialCircumstancesAlert(values.specialExceptions);
	const alert = getCurrentInsuranceCoverageTypeAlert(
		values.currentInsuranceCoverageType
	);

	// Calculate whether we are currently within the user's IEP window
	const isWithinIEP = isWithinInterval(new Date(), {
		start: iepDates.start!,
		end: iepDates.end!,
	});

	// Calculate whether we are currently within the user's IEP window
	const isBeforeIEP = isBefore(new Date(), iepDates.start!);
	const enrollmentWindowResult = calculateEnrollmentWindowCase(
		values,
		isWithinIEP,
		isBeforeIEP,
		isWithinSEP
	);

	const {start: gepStart, end: gepEnd} = getGEPCurrentOrNext();

	// if either case is GEP, show GEP start date
	let calculatedStart = iepDates.start;
	let calculatedEnd = iepDates.end;
	const calculatedStartText = format(calculatedStart, "M/d/yyyy");
	let calculatedEndText = format(calculatedEnd, "M/d/yyyy");

	if (
		enrollmentWindowResult === enrollGEPSmallEmployer ||
		enrollmentWindowResult === enrollGEPIEPClosed
	) {
		calculatedStart = gepStart;
		calculatedEnd = gepEnd;
	}

	if (
		enrollmentWindowResult === enrollSEPEmployerCoverage ||
		enrollmentWindowResult === enrollSEPRetirementCoverage ||
		enrollmentWindowResult === enrollOnEmployerCoverage
	) {
		if (sepDates && sepDates.end) {
			calculatedEnd = sepDates.end; // if retirement date, show SEP end, else 8 month sosmetimes
		} else {
			calculatedEndText =
				enrollmentWindowResult === enrollSEPEmployerCoverage
					? "TBD"
					: `<span class="text-sm">8 months after current coverage ends</span>`;
		}
	}

	const enrollmentWindowDates = {
		calculatedStart,
		calculatedEnd,
		calculatedStartText,
		calculatedEndText,
		iepStartReadable:
			iepDates && iepDates.start && format(iepDates.start, "M/d/yyyy"),
		iepEndReadable:
			iepDates && iepDates.end && format(iepDates.end, "M/d/yyyy"),
		sepEndReadable:
			sepDates && sepDates.end && format(sepDates.end, "M/d/yyyy"),
		gepYear: gepStart.getFullYear(),
		currentInsuranceCoverageType: values.currentInsuranceCoverageType,
		medicarePartsText: showJustPartB ? "Part B" : "Parts A & B",
		benefitTypeText: values.specialExceptions,
		isRetired: values.retirementStatus === "retired",
	};

	const enrollmentWindowResultReplaced = replaceVariables({
		...enrollmentWindowResult,
		...enrollmentWindowDates,
	} as any);

	return {
		exception,
		alert,
		enrollmentWindowResult: enrollmentWindowResultReplaced,
	};
}

class EnrollmentWindowService {
	values = {} as IFormData;

	update(values: IFormData) {
		this.values = values;
	}
}

export const enrollmentWindowService = new EnrollmentWindowService();

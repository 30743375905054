import React from "react";
import styled from "styled-components";
import {ShadowBox} from "templates/search";
import Icon from "common/ui/Icon";
import Select from "common/ui/Select";
import CheckboxGroup from "common/ui/Checkbox";
import {capitalize} from "common/util/format";

const generateFormularyTitle = (formulary) => {
	let title = formulary.brandName
		? capitalize(`${formulary.brandName} (${formulary.compounds})`)
		: capitalize(formulary.compounds);

	if (title.length > 62) title = `${title.substring(0, 62)}...`;

	return title;
};

const FormularyShadowBox = styled(ShadowBox)`
	padding: 25px 20px;
	min-height: 380px;

	@media (min-width: 640px) {
		width: 420px;
	}

	@media (max-width: 639px) {
		width: 100%;
	}
`;

const FormularyTop = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const FormularyTitle = styled.div`
	height: 55px;
	margin-right: 10px;
`;

const checkboxOptions = [{value: "genericOk", label: "Generic OK"}];

export const Formulary = ({
	formulary,
	editFormulary,
	removeFormulary,
	didAttemptAdvance,
}) => {
	const checkboxSelected = formulary.genericOk ? ["genericOk"] : [];
	const dosageOptions = formulary.doseFormMapping[formulary.selectedDoseForm];

	let validState;
	if (formulary.selectedDoseForm) validState = "valid";
	else {
		validState = didAttemptAdvance ? "invalid" : "none";
	}

	return (
		<FormularyShadowBox>
			<FormularyTop>
				<FormularyTitle>{generateFormularyTitle(formulary)}</FormularyTitle>
				<Icon
					onClick={() => removeFormulary(formulary)}
					src="trash"
					height={27}
				/>
			</FormularyTop>
			<div>
				<Select
					value={formulary.selectedDoseForm}
					options={Object.keys(formulary.doseFormMapping)}
					onChange={(val) =>
						editFormulary(formulary.key, "selectedDoseForm", val)
					}
					placeholder="Select Form"
					large={true}
					validState={validState}
					title="Form"
				/>
				<br />
				<Select
					value={formulary.selectedRxcuid.toString()}
					options={dosageOptions}
					onChange={(val) =>
						editFormulary(formulary.key, "selectedRxcuid", val)
					}
					disabled={!dosageOptions}
					placeholder="Select Dosage"
					large={true}
					validState={formulary.selectedRxcuid ? "valid" : "none"}
					title="Dosage (optional)"
				/>
				<br />
				{formulary.brandName && (
					<CheckboxGroup
						selected={checkboxSelected}
						options={checkboxOptions}
						onChange={() =>
							editFormulary(formulary.key, "genericOk", !formulary.genericOk)
						}
						type="column"
					/>
				)}
			</div>
		</FormularyShadowBox>
	);
};

import styled from "styled-components";
import STYLES from "common/util/constants/styles";

// min-height: removes 66px for the header and 260px conditionally when the wave element is present or 70 when the footer is present
export const QuestionFlowAndNavigation = styled.div`
	background-color: ${({containerColor}) => containerColor};
	min-height: calc(100vh - 66px - ${({hasWaves}) => (hasWaves ? 260 : 70)}px);
	width: 100%;
`;

export const NavigationButtonsTop = styled.div`
	color: ${({color}) => color};
	max-width: ${STYLES.MAX_WIDTH.MAX};
	padding: 0 ${STYLES.PADDING.BODY_HORIZONTAL};
	margin: 20px auto;

	@media (min-width: 640px) {
		display: flex;
		justify-content: space-between;
	}
`;

export const NavigationButtonsBottom = styled.div`
	display: flex;
	justify-content: center;
	margin: 50px 0;
`;

export const QuestionFlowWrapper = styled.div`
	margin: 0 auto;

	.page-header {
		text-align: center;
		max-width: 600px;
	}

	@media (min-width: 640px) {
		.page-header {
			margin-bottom: 66px;
		}
	}
`;

export const TabBarContentWrapper = styled.div`
	display: flex;
	justify-content: center;
	background-color: ${({bodyColor}) => bodyColor};

	@media (max-width: 639px) {
		flex-direction: column;
	}
`;

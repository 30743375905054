import {
	parse,
	differenceInYears,
	subMonths,
	addMonths,
	addYears,
	startOfMonth,
	endOfMonth,
	isAfter,
	startOfYear,
	setMonth,
	format,
	isDate,
} from "date-fns";
import {MEDICARE_MINIMUM_AGE} from "./common";

/**
 * Capitalizes the first letter of every word in the string
 * @example capitalize("I love cake") ––> "I Love Cake"
 */
export const capitalize = (str: string): string =>
	str
		? str
				.toLowerCase()
				.split(" ")
				.map((el) => (el ? el[0].toUpperCase() + el.slice(1) : ""))
				.join(" ")
		: str;

export const capitalizeFirstOnly = (str: string): string =>
	str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Formats a string of form "08012020" to form "08/01/2020"
 * @param {string} strDate - The unformatted string date. Can be partial (e.g. "0801")
 * @returns {string} The date formatted as "MM/DD/YYYY" or partial completion
 */
export const stringToMDY = (strDate: string): string => {
	let formattedStr = "";

	for (let i = 0; i < strDate.length; i++) {
		if ([2, 4].includes(i)) formattedStr += " / ";
		formattedStr += strDate.charAt(i);
	}

	return formattedStr;
};

/**
 * Formats a string of form "6052058869" to form "(605) 205-8869"
 * @param {string} rawPhone-The unformatted string phone. Can be partial (e.g. "6052")
 * @returns {string} The date formatted as "(605) 205-8869" or partial completion
 */
export const formatPhone = (rawPhone: string): string => {
	let formattedStr = "";

	for (let i = 0; i < rawPhone.length; i++) {
		if (i === 3) formattedStr = `(${formattedStr}) `;
		if (i === 6) formattedStr = formattedStr += "-";
		formattedStr += rawPhone.charAt(i);
	}

	return formattedStr;
};

export const formatMoney = (rawCents: string | number): string => {
	const currencyFormatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	const input = typeof rawCents === "string" ? parseInt(rawCents) : rawCents;

	return currencyFormatter.format(input);
};

// Household discounts can be of form "xx%" or "$xx/m"
export const parseHouseholdDiscount = (input: string): number => {
	const match = input.match(/(\d+)/);
	if (match) return parseInt(match[0]);
	else return 0;
};

export const getAgeFromBirthdate = (birthdate: string) => {
	if (!birthdate) {
		return 0;
	}
	const birthday = getDateFromString(birthdate);
	return differenceInYears(new Date(), birthday);
};

export const getDateFromString = (
	str: string,
	format: string = "MM/dd/yyyy"
) => {
	// Default a date to not break parsing, can throw an error
	// here as well
	return parse(str || "01/01/2000", format, new Date());
};

/**
 * Use date-fns parse to parse a date given a string format safely
 */
export const parseDateString = (
	originalValue: Date | string,
	format: string
): Date => {
	return isDate(originalValue)
		? (originalValue as Date)
		: parse(originalValue as string, format, new Date());
};

/**
 * Function converts string in format of MM/YYYY to a string date
 * that represents the first of that month
 */
export const parseMonthAndYearString = (
	dateString: string
): string | undefined => {
	const date = parseDateString(dateString, "MM/yyyy");
	return format(date, "yyyy-MM-dd");
};

export const parseName = (name: string) => {
	if (!name) {
		return;
	}
	const names = name.split(" ");
	const firstName = names[0];
	const lastName = (names.length > 1 && names.pop()) || "";
	const middleName = (names.length > 2 && names.slice(1, -1).join(" ")) || "";
	return {firstName, lastName, middleName};
};

export const isValidBirthdate = (birthdate: string) => {
	const parsedVal = birthdate.replace(/\D/g, "").substring(0, 8);
	if (parsedVal) {
		if (parsedVal.length !== 8) {
			return false;
		}

		const month = parseInt(parsedVal.substring(0, 2));
		const day = parseInt(parsedVal.substring(2, 4));
		const year = parseInt(parsedVal.substring(4, 8));

		if (isNaN(month) || month < 1 || month > 12)
			throw new Error("Please enter a valid birth month");
		if (isNaN(day) || day < 1 || day > 31)
			throw new Error("Please enter a valid birth day");
		if (isNaN(year) || year < 1900 || year > 2020)
			throw new Error("Please enter a valid birth year");
	}

	return true;
};

export const getIEPForBirthdate = (birthdate: string) => {
	const birthDate = getDateFromString(birthdate);
	const medicareEligibleDate = addYears(birthDate, MEDICARE_MINIMUM_AGE);

	// If you're born on the first day of the month, you can sign up for up to 4 months in advance
	// otherwise, your enrollment window starts 3 months in advance
	// https://www.medicare.gov/sign-up-change-plans/how-do-i-get-parts-a-b/when-will-my-coverage-start
	const buffer = birthDate.getDate() === 1 ? 1 : 0;
	const enrollmentWindowMarigin = 3;
	const enrollmentMonthStart = enrollmentWindowMarigin + buffer;
	const enrollmentMonthEnd = enrollmentWindowMarigin - buffer;
	const startMonth = subMonths(medicareEligibleDate, enrollmentMonthStart);
	const endMonth = addMonths(medicareEligibleDate, enrollmentMonthEnd);

	const start = startOfMonth(startMonth);
	const end = endOfMonth(endMonth);
	const effectiveDate = subMonths(startOfMonth(medicareEligibleDate), buffer);

	return {start, end, effectiveDate};
};

/**
 *
 * OEP is 6 months from the 1st day of the month in which they turn 65
 * (or the prior month if they turn 65 on the 1st day of a month)
 */
export const getOEPForBirthdate = (birthdate: string) => {
	const birthDate = getDateFromString(birthdate);
	const medicareEligibleDate = addYears(birthDate, MEDICARE_MINIMUM_AGE);

	// If you're born on the first day of the month, you get an additional month of OEP
	const buffer = birthDate.getDate() === 1 ? -1 : 0;
	const enrollmentWindowMargin = 6;
	const enrollmentMonthStart = buffer;
	const startMonth = addMonths(medicareEligibleDate, enrollmentMonthStart);
	const endMonth = addMonths(medicareEligibleDate, enrollmentWindowMargin);

	const start = startOfMonth(startMonth);
	const end = endOfMonth(endMonth);
	const effectiveDate = addMonths(startOfMonth(medicareEligibleDate), buffer);

	return {start, end, effectiveDate};
};

export const getSEPForRetirementMonthAndYear = (
	retirementMonthAndYear: string
) => {
	// www.medicare.gov/sign-up-change-plans/how-do-i-get-parts-a-b/part-a-part-b-sign-up-periods
	// You also have an 8-month period to sign up for Part A and/or Part B that starts at one of these times (whichever happens first):
	// The month after the employment ends
	// The month after group health plan insurance based on current employment ends

	// https://www.cms.gov/Outreach-and-Education/Find-Your-Provider-Type/Employers-and-Unions/FS3-Enroll-in-Part-A-and-B.pdf
	// You have 8 months to enroll in Medicare once you stop working
	// OR your employer coverage ends (whichever happens first).
	// Current coverage will end on the last day of the month

	const retirementDate = getDateFromString(retirementMonthAndYear, "MM/yyyy");

	const sepRetirementEnrollmentMonths = 8;

	// SEP period begins the first of the month after your last day of coverage
	const start = startOfMonth(addMonths(retirementDate, 1));

	// SEP ends 7 full months after the month your coverage ends.
	const endMonth = addMonths(start, sepRetirementEnrollmentMonths - 1);
	const end = endOfMonth(endMonth);

	return {start, end};
};

export const getGEPCurrentOrNext = () => {
	// Once your Initial Enrollment Period ends, you can sign up for Part B and Part A (if you have to pay a premium for it)
	// during the General Enrollment Period between January 1–March 31 each year.
	// Your coverage will start July 1.
	// https://www.medicare.gov/sign-up-change-plans/how-do-i-get-parts-a-b/part-a-part-b-sign-up-periods
	const now = new Date();

	const MAR_MONTH = 2;
	const JUL_MONTH = 6;

	const marchThirtyOneCurrentYear = endOfMonth(
		setMonth(startOfYear(now), MAR_MONTH)
	);

	// If it is after March 31 (exclusive), use next year Jan 1 - March 31
	if (isAfter(now, marchThirtyOneCurrentYear)) {
		const nextYear = addYears(now, 1);
		const janFirstNextYear = startOfYear(nextYear);
		const marchThirtyOneNextYear = endOfMonth(setMonth(nextYear, MAR_MONTH));
		const julyFirstNextYear = startOfMonth(setMonth(nextYear, JUL_MONTH));

		return {
			start: janFirstNextYear,
			end: marchThirtyOneNextYear,
			effectiveDate: julyFirstNextYear,
		};
	} else {
		const janFirstCurrentYear = startOfYear(now);
		const julyFirstCurrentYear = startOfMonth(setMonth(now, JUL_MONTH));

		return {
			start: janFirstCurrentYear,
			end: marchThirtyOneCurrentYear,
			effectiveDate: julyFirstCurrentYear,
		};
	}
};

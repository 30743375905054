import clsx from "clsx";
import React from "react";
import {MdAccessTime} from "react-icons/md";

export function TimeLabel({
	time,
	className,
}: {
	time: string;
	className?: string;
}) {
	return (
		<div className={clsx("flex items-center", className)}>
			<MdAccessTime className="mr-1" />
			{time}
		</div>
	);
}

export const house = ({ color, height, width }) => (
  <svg
    viewBox="1.523 3.308 64.956 61.492"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.108 64.8H8.295V29.16c0-.894.743-1.62 1.658-1.62.916 0 1.659.726 1.659 1.62v32.4h14.496c.915 0 1.658.724 1.658 1.62 0 .896-.743 1.62-1.658 1.62zm33.598 0H41.893c-.917 0-1.659-.724-1.659-1.62 0-.896.742-1.62 1.659-1.62h14.496v-32.4c0-.894.741-1.62 1.658-1.62.918 0 1.659.726 1.659 1.62V64.8z"
      fill={color}
    />
    <path
      d="M43.55 64.8h-3.316V44.916c0-3.405-2.798-6.175-6.234-6.175-3.496 0-6.234 2.637-6.234 6.002V64.8h-3.317V44.743c0-5.097 4.284-9.242 9.551-9.242s9.55 4.223 9.55 9.415V64.8zM64.819 27c-.317 0-.637-.089-.92-.272L34 7.205 4.102 26.728a1.684 1.684 0 01-2.3-.447 1.597 1.597 0 01.458-2.247L34 3.308l31.742 20.724c.762.498.967 1.504.456 2.247a1.66 1.66 0 01-1.38.721z"
      fill={color}
    />
  </svg>
);

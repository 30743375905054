import {PureComponent} from "react";
import styled from "styled-components";
import Icon from "common/ui/Icon";
import Dialog from "common/ui/Dialog";
import Scheduler from "modals/Scheduler";
import STYLES from "common/util/constants/styles";

// Lots of extra padding-right to account for FreshChat Widget
const FooterStyled = styled.div`
	background-color: #c7ecee;
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const XBold = styled.span`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
	margin: 0 10px;
`;

const MobileHidden = styled.span`
	@media (max-width: 639px) {
		display: none;
	}
`;

export default class Footer extends PureComponent {
	clickBanner() {
		Dialog.show({
			children: <Scheduler showLegalText />,
			width: Scheduler.width,
			height: Scheduler.height,
			bgColor: "white",
		});
	}

	render() {
		return (
			<FooterStyled onClick={this.clickBanner}>
				<div>
					<MobileHidden>How much can a Chapter expert save you?</MobileHidden>
					<XBold>Chat with us now</XBold>
				</div>
				<Icon src="doubleArrow" width={18} color="white" />
			</FooterStyled>
		);
	}
}

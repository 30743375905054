const styles = {
	COLORS: {
		CERULEAN: "#0B39A1",
		SKY_BLUE: "#86b8f6",
		MIDNIGHT_BLUE: "#001f67",
		SALMON: "#ffa59f",
		LIGHT_SALMON: "#ffbe9f",
		NICE_BLUE: "#1456b4",
		PEACH: "#ff7165",
		LIGHT_GREY: "#f5f9fe",
		MINT: "#ecfeff",
		DARK_MINT: "#c7ecee",
		VIBRANT_MINT: "#80e7df",
		GLACIER: "#f5feff",
		WARNING_RED: "#ffdada",
		CONFIRM_GREEN: "#419b94",
		WINTERGREEN: "#ddfbfd",
		POWDER_BLUE: "#d3e7ff",
		BUBBLEGUM: "#ffa3c0",
	},
	FONT_WEIGHT: {
		REGULAR: 400,
		NORMAL: 400,
		MEDIUM: 500,
		BOLD: 700,
		XBOLD: 800,
	},
	SCREEN_SIZE: {
		MOBILE_END: "639px",
		TABLET_START: "640px",
		TABLET_END: "999px",
		DESKTOP_START: "1000px",
	},
	PADDING: {
		BODY_HORIZONTAL: "15px",
	},
	MAX_WIDTH: {
		MAX: "1200px",
		DEFAULT: "800px",
	},
};

export default styles;

import React, {memo, useEffect, useState} from "react";

import {Switch, Route, useRouteMatch} from "react-router-dom";

import {Helmet} from "react-helmet";

import {Sidebar} from "core/Sidebar/Sidebar";
import {useHistory, useLocation} from "react-router";
import {SwitcherTriage} from "./Triage";
import {SwitcherIntro} from "./Intro";
import {MedSupQuiz} from "./Form";
import {MedsupPlanComparison} from "./Form/result";
import {Page} from "../shared/Page";

import {useUser} from "common/hooks/useUser";
import switcherSelected from "../Intro/images/switcher-selected.svg";

function SidebarHeader() {
	return (
		<div>
			<img src={switcherSelected} className="mx-auto h-12 my-3" />
			Improve Medicare Coverage
		</div>
	);
}

export function SwitcherBase() {
	const match = useRouteMatch();
	const history = useHistory();
	let location = useLocation();

	const {user} = useUser();

	const [modules, setModules] = useState<any[]>([]);

	const MODULES = [
		{
			id: "1",
			name: "Plan recommendations",

			children: [
				{
					id: "1a",
					name: "Plan Finder",
					route: "switcher/recommend",
					component: () => {
						return (
							<Page>
								<MedSupQuiz
									onSubmit={() => {
										history.push("/flow/switcher/consult");
									}}
								/>
							</Page>
						);
					},
				},
			],
		},
		{
			id: "2",
			name: "Peace-of-mind consult",
			children: [
				{
					id: "2a",
					name: "Schedule Time",
					route: "switcher/consult",
					component: memo(() => {
						return (
							<Page fullWidth={true}>
								<MedsupPlanComparison />
							</Page>
						);
					}),
				},
			],
		},
	];

	useEffect(() => {
		function getModules(user) {
			if (user.currentAdditionalCoverageType === "ma") {
				return [];
			} else if (user.currentAdditionalCoverageType === "ms") {
				return MODULES;
			}
			return MODULES;
		}

		if (user) {
			setModules(getModules(user));
		}
	}, [user]);

	return (
		<>
			<Helmet>
				<title>Switcher Flow</title>
			</Helmet>

			<Switch location={location}>
				<Route path={`${match.path}/intro`}>
					<Page>
						<SwitcherIntro
							onSubmit={() => {
								// if ma just go to consult; // if medsup go to
								history.push(`/flow/switcher/recommend`);
							}}
						/>
					</Page>
				</Route>

				<Route path={`${match.path}/:module`}>
					<Sidebar Header={<SidebarHeader />} modules={modules} />
					<Switch>
						{modules?.reduce((acc, rd) => {
							if (rd && rd.children && rd.children.length) {
								acc.push(
									...rd?.children?.map((r) => (
										<Route key={r.route} path={`/flow/${r.route}`}>
											{<r.component />}
										</Route>
									))
								);
							}

							return acc;
						}, [])}
					</Switch>
				</Route>

				{/** Default Page */}
				<Route path={match.path}>
					<Page>
						<SwitcherTriage
							onSubmit={(values) => {
								// save values to service / localstorage
								history.push("/flow/switcher/intro");
							}}
						/>
					</Page>
				</Route>
			</Switch>
		</>
	);
}

import styled from "styled-components";
import CheckboxGroup from "common/ui/Checkbox";
import STYLES from "common/util/constants/styles";

export const MedicareRetireHeader = styled.div`
	text-align: center;
	margin-bottom: 30px;

	h4 {
		margin-bottom: 5px;
	}
`;

export const NormalWeight = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
`;

export const CheckboxGroupStyled = styled(CheckboxGroup)`
	label {
		margin-bottom: 50px;
	}
`;

import React from "react";
import FormGroup, {FormGroupProps} from "@material-ui/core/FormGroup";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import MaterialCheckbox from "@material-ui/core/Checkbox";

import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";

export interface ICheckboxGroupOption {
	value: string;
	label: string | JSX.Element;
}

export interface ICheckboxGroupProps extends FormGroupProps {
	labelClassName?: string;
	checkboxClassName?: string;
	rootClassName?: string;
	options: ICheckboxGroupOption[];
	name: string;
	isVertical?: boolean;
	value?: string[];
	onChange: any;
	numColumns: number;
}

export function CheckboxGroup({
	labelClassName,
	checkboxClassName,
	rootClassName,
	options,
	name,
	isVertical,
	value,
	onChange,
	numColumns = 1,
	...props
}: ICheckboxGroupProps) {
	return (
		<FormControl component="fieldset" classes={{root: "w-full"}}>
			<FormGroup row={!isVertical} aria-label={name} {...props}>
				<div
					className={clsx(
						"grid gap-4",
						numColumns === 2 ? "grid-cols-2" : "grid-cols-1"
					)}
				>
					{options.map((option) => (
						<FormControlLabel
							key={option.value}
							classes={{
								label: "font-unset " + labelClassName,
								root: "mx-0 max-w-md" + rootClassName,
							}}
							control={
								<MaterialCheckbox
									className={checkboxClassName}
									name={name}
									value={option.value}
									checked={value?.includes(option.value)}
									color="primary"
									onChange={(e) => {
										onChange &&
											(onChange as any)(
												e.target.checked
													? [...(value || []), e.target.value]
													: value?.filter((v) => v !== e.target.value) || []
											);
									}}
								/>
							}
							label={option.label}
						/>
					))}
				</div>
			</FormGroup>
		</FormControl>
	);
}

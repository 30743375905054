import React from "react";
import {AdditionalCoverageVideo} from "./Video/Video";
import {Switch, Route, Redirect} from "react-router";
import {AdditionalCoverageQuiz} from "./AdditionalCoverage";

export function Recommend() {
	return (
		<Switch>
			<Route path={"/flow/new/recommend/explainer"}>
				<Switch>
					<Route path={"/flow/new/recommend/explainer"}>
						<AdditionalCoverageVideo />
					</Route>
				</Switch>
			</Route>

			<Route path={"/flow/new/recommend/additional-coverage"}>
				<AdditionalCoverageQuiz />
			</Route>

			<Redirect to="/flow/new/recommend/explainer" />
		</Switch>
	);
}

import React, {Component} from "react";
import RadioInput from "common/ui/RadioInput";
import {LocalStorage} from "common/util/storage/";
import STORAGE_KEYS from "common/util/storage/keys";
import STYLES from "common/util/constants/styles";
import {NewExistingPlanWrapper} from "./NewExistingPlanHelpers";

export class NewExistingPlan extends Component {
	static maxWidth = 400;
	static containerColor = STYLES.COLORS.GLACIER;

	constructor(props) {
		super(props);

		const name = LocalStorage.getItem(STORAGE_KEYS.FIRST_NAME) || "";
		const selectedRadio =
			LocalStorage.getItem(STORAGE_KEYS.PAGES.INTRO_FIRST_EXISTING) || "";

		this.state = {name, selectedRadio};

		this.options = [
			{value: "newMedicarePlan", label: "Enroll in my first Medicare plan"},
			{value: "existingPlan", label: "Find better coverage"},
			{value: "somethingElse", label: "Something else"},
		];
	}

	componentDidUpdate() {
		const {selectedRadio} = this.state;

		LocalStorage.setItem(STORAGE_KEYS.PAGES.INTRO_FIRST_EXISTING, selectedRadio);
	}

	render() {
		const {name, selectedRadio} = this.state;

		return (
			<NewExistingPlanWrapper>
				<h2>Hi{name && ` ${name}`}!</h2>
				<h4>How can we help?</h4>
				<RadioInput
					value={selectedRadio}
					onChange={(selectedRadio) => this.setState({selectedRadio})}
					options={this.options}
				/>
			</NewExistingPlanWrapper>
		);
	}
}

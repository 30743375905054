import styled from "styled-components";

export const TitleLabel = styled.div`
	font-size: 16px;
	margin-bottom: 5px;
`;

export const SelectWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: white;
`;

export const CustomSelectWrapper = styled.div`
	position: relative;
	flex: 1;
`;

export const ValidStateWrapper = styled.div`
	width: 30px;
	margin-left: 10px;
`;

const selectStyles = `
	width: 100%;
	overflow-x: hidden;
	border: 2px solid var(--sky-blue);
	border-radius: 6px;
	padding: 20px;
	appearance: none;
	cursor: pointer;
	user-select: none;

	:focus {
		border-color: var(--midnight-blue);
	}
`;

export const NativeSelectStyled = styled.select`
	${selectStyles}
	background-image: url("/images/icons/down-chevron.svg");
	background-repeat: no-repeat;
	background-position: 90% center;
`;

export const CustomSelectStyled = styled.div`
	${selectStyles}
	display: flex;
	justify-content: space-between;

	svg,
	img {
		transition: 0.2s;
		transform: rotate(${({open}) => (open ? 180 : 0)}deg);
	}
`;

import React from "react";
import styled from "styled-components";
import STYLES from "common/util/constants/styles";

export const TimelineView = styled.div`
	@media (max-width: 639px) {
		width: 100%;
	}
	@media (min-width: 640px) {
		width: 330px;
	}
`;

export const TimelineWrapper = styled.div`
	margin: auto;
	border-radius: 13px;
	height: 25px;
	overflow: hidden;
	display: flex;
	background-color: ${STYLES.COLORS.WINTERGREEN};
`;

const TimelineSectionStyled = styled.div`
	transition: 0.5s;
	background-color: ${({filled}) =>
		filled ? STYLES.COLORS.VIBRANT_MINT : "inherit"};
	width: ${({$width}) => ($width ? `${$width}%` : "auto")};
`;

const Triangle = styled.div`
	width: 0;
	height: 0;
	border-top: 40px solid transparent;
	border-right: 40px solid ${STYLES.COLORS.VIBRANT_MINT};
	transform: rotate(-45deg);
	position: relative;
	top: -7px;
	left: -21px;
`;

const Chevron = styled.div`
	width: 0;
	height: 0;
	border-right: 2px solid ${STYLES.COLORS.VIBRANT_MINT};
	border-top: 2px solid ${STYLES.COLORS.VIBRANT_MINT};
	border-left: 1px solid transparent;
	border-bottom: 1px solid transparent;
	padding: 9px;
	transform: rotate(45deg);
	position: relative;
	top: 2px;
	left: -9px;
`;

export const FilledSection = ({width}) => {
	return (
		<>
			<TimelineSectionStyled $width={width} filled />
			<Triangle />
		</>
	);
};

export const UnfilledSection = ({section}) => {
	if (section !== 0) return null;

	return (
		<>
			<TimelineSectionStyled $width={23} />
			<Chevron />
		</>
	);
};

export const TimelineTextWrapper = styled.div`
	font-size: 12px;
	margin-top: 15px;
	text-align: center;
`;

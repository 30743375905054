import styled from "styled-components";

const Wrapper = styled.div`
	background-color: white;
	width: 100%;
	overflow: hidden;
	line-height: 0;

	@media (max-width: 1439px) {
		img {
			height: 260px;
		}
	}

	@media (min-width: 1440px) {
		img {
			width: 100%;
		}
	}
`;

const Waves = () => {
	return (
		<Wrapper>
			<img src="/images/backgrounds/waves.svg" alt="" />
		</Wrapper>
	);
};

export default Waves;

import results, {
	descriptionLinesEmployees20,
	descriptionLinesEmployees20IEPExpired,
	IResultData,
	IResultPlan,
	SPOUSE_DEPENDENT_WARNING,
	SOCIAL_SECURITY_SECONDARY_LINES,
	SOCIAL_SECURITY_SECONDARY_LINES_STAY,
} from "./results";
import {IFormData} from "../PartBCalculator/data/form-data";
import {
	formatMoney,
	getAgeFromBirthdate,
	getIEPForBirthdate,
	getOEPForBirthdate,
} from "common/util/format";
import {MEDICARE_CONSIDERATION_AGE} from "common/util/common";
import {format} from "date-fns";

const COSTS_2021 = [148.5, 207.9, 297.0, 386.1, 475.2, 504.9];

const MEDGAP_INCOME_RANGE = 2;

export function calculateIIRMAFromIncomeBracket(
	incomeBracket: IFormData["optIncomeRange"]
) {
	// Income brackets are normalized: 1 corresponds to the lowest, 6 to the highest.
	return COSTS_2021[parseInt(incomeBracket, 10) - 1];
}

function replaceTextWithPrice(
	result: IResultData,
	{price, currentPremium, values, recommendMA}
) {
	const recommendedPriceNumber = recommendMA ? 0 : values.medsupForZipCost || 0;
	const formattedPrice = formatMoney(price);
	const priceDifference =
		currentPremium - (price + recommendedPriceNumber) || 0;
	const priceDifferenceYearly = priceDifference * 12;
	const formattedDifference = formatMoney(priceDifferenceYearly);
	const formattedCurrentPremium = formatMoney(currentPremium * 12);

	const recommendedPrice = formatMoney(recommendedPriceNumber);
	const partBAndMedigapPriceNumber = price + recommendedPriceNumber;
	const partBAndMedigapPriceYearlyNumber = partBAndMedigapPriceNumber * 12;
	const partBAndMedigapPrice = formatMoney(partBAndMedigapPriceNumber);
	const partBAndMedigapPriceYearly = formatMoney(
		partBAndMedigapPriceYearlyNumber
	);

	const replacementVariables = [
		{key: "{price}", value: formattedPrice},
		{key: "{currentPrice}", value: formattedCurrentPremium},
		{key: "{priceDifference}", value: formattedDifference},
		{key: "{recommendedPrice}", value: recommendedPrice},
		{key: "{partBAndMedigapPrice}", value: partBAndMedigapPrice},
		{key: "{partBAndMedigapPriceYearly}", value: partBAndMedigapPriceYearly},
	];

	const newResult = {...result};
	newResult.priceDifferenceYearlyRaw = priceDifferenceYearly;

	// Catch edge case of range being the same here for now
	if (newResult.type === "CONSIDER") {
		// if the recommended is MA, the two prices are the same, so a range doesn't make sense
		if (recommendMA) {
			newResult.secondaryTextLines = [...newResult.secondaryTextLines!].map(
				(l, i) =>
					i === 1
						? `Your total Medicare premiums would be ~<span class="text-underline-accent font-bold">{price}</span>`
						: l
			);
		}
	}

	["secondaryTextLines", "descriptionLines"].forEach((key) => {
		replacementVariables.forEach((o) => {
			newResult[key] = newResult[key]?.map((l) => l.replace(o.key, o.value));
			newResult.header = newResult.header.replace(o.key, o.value);
			newResult.headerNotEligible = newResult.headerNotEligible.replace(
				o.key,
				o.value
			);
		});
	});

	return newResult;
}

function calculateReccomendation(
	values: IFormData,
	price: number,
	effectivePremium: number | undefined
) {
	// Catch Social Security Calculator Edge Case
	// if < 65 and medicare due to disability and enrolled in medsup
	if (
		values.isReceivingSocialSecurityBenefits === "yes" &&
		values.hasExistingMedicareFromDisability === "yes" &&
		values.isEnrolledInMedsup === "yes"
	) {
		return results.ALREADYENROLLED;
	}

	// If no health care coverage through employer,
	// you should sign up likely sign up
	if (values.hasEmployerHealthCoverage === "no") {
		return results.LIKELYGENERIC;
	}

	// If employer has fewer than twenty employees (small employer)
	if (
		!values.hasMoreThanTwentyEmployees ||
		values.hasMoreThanTwentyEmployees === "no"
	) {
		// if receiving SSB, return do nothing
		if (values.isReceivingSocialSecurityBenefits === "yes") {
			return results.ALREADYENROLLEDSTAY;
		}

		return results.LIKELYGENERIC;
	}

	// If neither of the optional fields are filled in
	if (!values.currentInsurancePremium) {
		return results.UNKNOWN;
	}

	if (effectivePremium) {
		if (price > effectivePremium) {
			return results.STAY;
		}
		if (
			price < effectivePremium &&
			price + (values.medsupForZipCost || 0) > effectivePremium
		) {
			return results.CONSIDER;
		}
		return results.LIKELY;
	}

	return results.UNKNOWN;
}

export function calculatePremium(values: IFormData) {
	const price = calculateIIRMAFromIncomeBracket(values.optIncomeRange);
	const incomeRange = parseInt(values.optIncomeRange, 10);

	const currentPremium = values.currentInsurancePremium
		? parseInt(values.currentInsurancePremium, 10)
		: 0;

	// Catch case with employee + spouse + children, right now, we don't
	// factor in # of children into our calculation of dependents, it's
	// just 0, 1, or 2+ which we treat as 2
	const effectiveNumDependents =
		values.numDependents === "3" ? "2" : values.numDependents;
	const numDependents = parseInt(effectiveNumDependents, 10) + 1;

	const effectivePremium = currentPremium / numDependents;

	const result = calculateReccomendation(values, price, effectivePremium);
	const formattedPrice = formatMoney(price);

	// Fill in Plans
	const plans: IResultPlan[] = [
		{
			price: formattedPrice,
			priceNumber: price,
			name: "Part B Premiums",
			secondaryText: "Covers at most 80% of medical costs",
		},
	];

	const MAPlan = {
		price: "$0",
		name: "Medicare Advantage",
		isMa: true,
	} as IResultPlan;
	plans.push(MAPlan);

	const MGPlan = {
		price:
			(values.medsupForZipCost && formatMoney(values.medsupForZipCost)) || "$0",
		name: "Medicare Supplement Plan G",
		isMa: false,
	} as IResultPlan;

	plans.push(MGPlan);

	if (values.optMinimizeCosts === "no") {
		// recommend  MA
		MAPlan.recommended = true;
		MGPlan.recommended = false;
	} else {
		// If you pay above IRMAA or if you are willing to pay
		if (
			incomeRange > MEDGAP_INCOME_RANGE ||
			values.optMinimizeCosts === "yes"
		) {
			MAPlan.recommended = false;
			MGPlan.recommended = true;
		} else {
			MAPlan.recommended = false;
			MGPlan.recommended = false;
		}
	}
	let newResult = {...result};

	const {
		start: iepStart,
		effectiveDate: iepEffectiveDate,
		end: iepEnd,
	} = getIEPForBirthdate(values.dob);
	const {start: oepStart, end: oepEnd} = getOEPForBirthdate(values.dob);

	if (values.isReceivingSocialSecurityBenefits === "yes") {
		if (newResult.type === "STAY") {
			newResult = {
				...newResult,
				secondaryTextLines: SOCIAL_SECURITY_SECONDARY_LINES_STAY,
			};
		} else if (
			newResult.type === "CONSIDER" ||
			newResult.type === "LIKELY" ||
			newResult.type === "LIKELYGENERIC" ||
			newResult.type === "UNKNOWN"
		) {
			newResult = {
				...newResult,
				secondaryTextLines: SOCIAL_SECURITY_SECONDARY_LINES,
			};
		}
	}

	// Swap out placeholders with price
	newResult = replaceTextWithPrice(newResult, {
		price,
		currentPremium: currentPremium || 0,
		values,
		recommendMA: MAPlan.recommended,
	});

	if (newResult.type === "ALREADYENROLLED") {
		newResult = {
			...newResult,
			header:
				newResult.priceDifferenceYearlyRaw! <= 0
					? newResult.headerNotEligible
					: newResult.header,
		};
	}

	if (
		!values.hasMoreThanTwentyEmployees ||
		values.hasMoreThanTwentyEmployees === "no"
	) {
		if (!values.isReceivingSocialSecurityBenefits) {
			newResult = {
				...newResult,
				secondaryTextLines:
					iepEnd > new Date()
						? descriptionLinesEmployees20
						: descriptionLinesEmployees20IEPExpired,
				descriptionLines:
					newResult.priceDifferenceYearlyRaw! > 0
						? newResult.descriptionLines
						: [],
			};
		}
	}

	// Sub out IEP date + dependents
	const replacementVariables = [
		{key: "{iepStartDate}", value: format(iepStart, "MMMM d, yyyy")},
		{key: "{iepEndDate}", value: format(iepEnd, "MMMM d, yyyy")},
		{key: "{oepStartDate}", value: format(oepStart, "MMMM d, yyyy")},
		{key: "{oepEndDate}", value: format(oepEnd, "MMMM d, yyyy")},

		{
			key: "{numDependentsText}",
			value:
				numDependents === 1
					? "yourself"
					: numDependents === 2
					? "you and your spouse"
					: "you and your family",
		},
		{key: "{zip}", value: values.zip},
	];

	["secondaryTextLines", "descriptionLines"].forEach((key) => {
		replacementVariables.forEach((o) => {
			newResult[key] = newResult[key]?.map((l) => l.replace(o.key, o.value));
		});
	});

	// Add Alert for age < 64
	const age = getAgeFromBirthdate(values.dob);

	if (age < MEDICARE_CONSIDERATION_AGE) {
		newResult.eligibilityDate = iepStart;
		newResult.effectiveDate = iepEffectiveDate;
	}

	newResult.plans = plans;
	if (values.spouseOver65 || values.disabilityCoverage || numDependents > 1) {
		newResult.alertMessage = SPOUSE_DEPENDENT_WARNING;
	}

	if (values.isReceivingSocialSecurityBenefits !== undefined) {
		newResult.isReceivingSocialSecurityBenefits =
			values.isReceivingSocialSecurityBenefits;
	}

	return newResult;
}

import React from "react";
import QuestionnaireWrapper from "./QuestionnaireTemplate";
import HoverTooltip from "common/ui/Tooltip/HoverTooltip";
import STORAGE_KEYS from "common/util/storage/keys";

const description = (
	<>
		To pay lower monthly premiums, would you be open to
		<br />
		changing your current doctor(s)?
		<br />
		<br />
		(Most people say no, but we’re asking just in case.)
	</>
);

const networkDesc = (
	<>
		A plan's network refers to the set of healthcare providers or facilities
		that you can use, or that you can use at pre-set costs.
		<br />
		Most Medicare Advantage plans have "networks" of providers, while Medigap
		plans will pay for care provided by any doctor who accepts Traditional
		Medicare.
	</>
);

const config = {
	options: [
		{value: "YES", label: "Yes"},
		{value: "MAYBE", label: "Maybe"},
		{value: "NO", label: "No"},
	],
	localStorageKey: STORAGE_KEYS.PAGES.QUESTIONNAIRE_PROVIDERS,
	questionKey: "changeProviders",
	icon: "provider",
	title: "Providers",
	description,
	drawer: {
		medigap: [
			<>
				Does not have a{" "}
				<HoverTooltip content={networkDesc}>network</HoverTooltip> of healthcare
				providers
			</>,
			"Allows you to see any doctor that accepts Traditional Medicare anywhere in the US",
			"Does not require you to receive a referral to see a specialist doctor",
		],
		medAdv: [
			<>
				Often limits the doctors that you can see to the insurance company's{" "}
				<HoverTooltip content={networkDesc}>network</HoverTooltip> of contracted
				providers
			</>,
			"Sometimes allows you to see out-of-network doctors but may require you to pay more",
			"Sometimes requires you to receive a referral before you see a specialist doctor",
		],
	},
};

export class QuestionnaireProviders extends QuestionnaireWrapper {
	componentDidMount() {
		this.setConfig(config);
	}
}

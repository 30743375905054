const keys = {
	CUSTOMER_ID: "CUSTOMER_ID",
	LOGIN_TOKEN: "LOGIN_TOKEN",
	LOGIN_TOKEN_EXPIRATION: "LOGIN_TOKEN_EXPIRATION",
	FIRST_NAME: "FIRST_NAME",
	PHONE_NUMBER: "PHONE_NUMBER",
	PARTNER: "PARTNER",
	ZIPCODE_MONTHLY_PREMIUMS: "ZIPCODE_MONTHLY_PREMIUMS",
	STATE: "STATE",
	UTM: {
		SOURCE: "UTM_SOURCE",
		MEDIUM: "UTM_MEDIUM",
		CAMPAIGN: "UTM_CAMPAIGN",
		TERM: "UTM_TERM",
		CONTENT: "UTM_CONTENT",
		AUDIENCE: "UTM_AUDIENCE",
		DRIP: "UTM_DRIP",
	},
	HEAR_DATA: {
		EMPLOYMENT: "EMPLOYMENT",
		CREDIT_SCORE: "CREDIT_SCORE",
		AGE: "AGE",
	},
	PAGES: {
		HOW_CAN_WE_HELP: "HOW_CAN_WE_HELP",
		FACTORS: "FACTORS",
		BASIC_INFO: "BASIC_INFO",
		FORMULARY_SEARCH: "FORMULARY_SEARCH",
		PROVIDER_SEARCH: "PROVIDER_SEARCH",
		QUESTIONNAIRE_COSTS: "QUESTIONNAIRE_COSTS",
		QUESTIONNAIRE_HOSPITAL_COSTS: "QUESTIONNAIRE_HOSPITAL_COSTS",
		QUESTIONNAIRE_PROVIDERS: "QUESTIONNAIRE_PROVIDERS",
		QUESTIONNAIRE_REFERRALS: "QUESTIONNAIRE_REFERRALS",
		QUESTIONNAIRE_LOCATION: "QUESTIONNAIRE_LOCATION",
		QUESTIONNAIRE_PRESCRIPTIONS: "QUESTIONNAIRE_PRESCRIPTIONS",
		QUESTIONNAIRE_ADDITIONAL_BENEFITS: "QUESTIONNAIRE_RISK",
		QUESTIONNAIRE_BENEFITS: "QUESTIONNAIRE_BENEFITS",
		INTRO_FIRST_EXISTING: "INTRO_FIRST_EXISTING",
		INTRO_TOBACCO: "INTRO_TOBACCO",
		INTRO_MEDICARE_RETIRE: "INTRO_MEDICARE_RETIRE",
		RECOMMENDATIONS: {
			MAPD: "RECOMMENDATIONS_MAPD",
			MED_SUP: "RECOMMENDATIONS_MED_SUP",
			PARTD: "RECOMMENDATIONS_PARTD",
			AUDIT: "RECOMMENDATIONS_AUDIT",
		},
		SUMMARY_V3: {
			PLAN_COUNTS: "PLAN_COUNTS",
		},
	},
};

export default keys;

import {
	MEDICARE_INTRO,
	MEDICARE_INTRO_PART_B,
	PLAN_RECOMMENDATIONS,
	CONSULT,
} from "./data/checklist";

import {EnrollmentWindow} from "../Learn/EnrollmentWindow";
import {SignUp} from "../Learn/SignUp/";
import {LearnExplainer} from "../Learn/Explainer";
import {Recommend} from "../Recommend";
import {Result} from "../Recommend/Compare";
import {ICustomer} from "common/util/api/service";

const MODULES = {
	medicareIntro: {
		id: "1",
		name: "Learn about Medicare",
		component: LearnExplainer,

		children: [
			{
				id: "1a",
				name: "Parts A & B explained",
				route: "new/learn/explainer",
				component: LearnExplainer,
			},
			{
				id: "1b",
				name: "Your enrollment dates",
				route: "new/learn/enrollment-dates/",
				component: EnrollmentWindow,
			},
			{
				id: "1c",
				name: "How to sign up through SSA",
				route: "new/learn/sign-up/",
				component: SignUp,
				fullWidth: true,
			},
		],
	},

	planRecommendations: {
		id: "2",
		name: "Plan recommendations",
		component: Recommend,

		children: [
			{
				id: "2a",
				name: "Additional coverage",
				route: "new/recommend/explainer",
				component: Recommend,
			},
			{
				id: "2b",
				name: "Coverage quiz",
				route: "new/recommend/additional-coverage",
				component: Recommend,
			},
		],
	},
	consult: {
		id: "3",
		name: "Peace-of-mind consult",
		children: [
			{
				id: "3a",
				name: "Schedule Time",
				route: "new/consult",
				component: Result,
				fullWidth: true,
			},
		],
	},
};

export function getStepsForMedicareStatus(
	medicareSignUpStatus: ICustomer["medicareSignUpStatus"]
) {
	if (medicareSignUpStatus === "partAB") {
		return [PLAN_RECOMMENDATIONS, CONSULT];
	} else if (medicareSignUpStatus === "partA") {
		return [MEDICARE_INTRO_PART_B, PLAN_RECOMMENDATIONS, CONSULT];
	} else {
		return [MEDICARE_INTRO, PLAN_RECOMMENDATIONS, CONSULT];
	}
}

export function getModulesForMedicareStatus(
	medicareSignUpStatus: ICustomer["medicareSignUpStatus"]
) {
	let modules: string[] = [];
	if (medicareSignUpStatus === "partAB") {
		modules = ["planRecommendations", "consult"];
	} else if (medicareSignUpStatus === "partA") {
		modules = ["medicareIntro", "planRecommendations", "consult"];
	} else {
		modules = ["medicareIntro", "planRecommendations", "consult"];
	}
	return [...modules.map((m) => MODULES[m])];
}

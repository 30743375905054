import {IVideoTimeline} from "common/ui/Video";

export const ADDITIONAL_COVERAGE_VID_TIMELINE: IVideoTimeline[] = [
	{
		title: "Why do I need additional coverage?",
		startTime: 0,
		durationText: "49s",
		descriptionLines: [
			`Remember, Part A and Part B together only cover approximately 80% of medical costs, at most. Which means you are responsible for that 20%, with no out-of-pocket maximum.`,
			`And with only Part A and Part B, you are responsible for the costs of prescriptions, as well as the costs of other services like most dental care, vision services, and hearing aids.`,
			`This is why nearly 70% of eligible Americans choose to enroll in additional coverage.`,
		],
	},
	{
		title: "Two types of additional coverage",
		startTime: 49,
		durationText: "35s",
		descriptionLines: [
			`First, there are Medicare Supplement plans, also known as Medigap plans. Second, there are Medicare Advantage Plans, also known as Part C plans. You can only have one or the other.`,
			`In the next step, you can take our short questionnaire to see what type of coverage is right for you and then, you can compare your plan options. No matter which one you choose, you need to be enrolled in Part A and Part B of Original Medicare.`,
		],
	},
	{
		title: "Medicare Supplement Plans",
		startTime: 84,
		durationText: "35s",
		descriptionLines: [
			`Let’s start with the first category, a Medicare Supplement Plan, also known as a Medigap plan. As the name “Medigap” suggests, these types of plans fill the gaps in costs not covered by Original Medicare. These are the 20%-plus of costs that Original Medicare doesn’t cover.`,
			`You have maximum flexibility with a Medicare Supplement plan, because you can see any doctor who accepts Original Medicare.`,
		],
	},
	{
		title: "Things to know about Medicare Supplements",
		startTime: 130,
		durationText: "2m",
		descriptionLines: [
			`First, in most states, you will have a single six-month window to choose and start a Medicare Supplement plan without undergoing medical underwriting. If you wait past this period, you may have to undergo medical underwriting and answer health questions. However, if you or your spouse are working past the age of 65 and are receiving employer-provided insurance from an organization with more than 20 employees, you will have a Special Enrollment Period. This period will start when you are leaving your employer-provided coverage. During this period, you can choose a Medicare Supplement plan without undergoing medical underwriting.`,
			`Next, people who choose a Medicare Supplement plan will typically want to choose a Standalone Prescription Drug plan too. (These are also known as Part D prescription drug plans.)`,
		],
	},
	{
		title: "Medicare Advantage Plans",
		startTime: 250,
		durationText: "34s",
		descriptionLines: [
			`The second category is called a Medicare Advantage Plan, also known as Medicare Part C. Medicare Advantage plans are administered by private insurance companies. If you choose a Medicare Advantage plan, you’ll receive your Part A and Part B benefits from your Medicare Advantage plan as well.`,
			`You can also change your Medicare Advantage plan every year, and there is no underwriting. `,
		],
	},
	{
		title: "Pros and Cons between the plans",
		startTime: 285,
		durationText: "2m 6s",
		descriptionLines: [
			`There are two advantages and two disadvantages of Medicare Advantage plans, compared to Medicare Supplement plans. The first advantage is premium costs. Medicare Advantage plans typically have much cheaper premiums than Medicare Supplement plans. The second advantage is that Medicare Advantage plans bundle a breadth of benefits and services. Most Medicare Advantage plans include prescription drug coverage, as well as dental, hearing, and vision benefits.`,
			`The first potential disadvantage of Medicare Advantage plans is network restrictions. Medicare Advantage plans typically have a network of doctors and medical facilities where you can receive care -- or receive care at the most affordable rates. Unlike Medicare Supplement plans, with a Medicare Advantage Plan you typically cannot choose to see any doctor who accepts Original Medicare, at least not at the most affordable rates.`,
			`The second potential disadvantage is what I call “back-end” costs. Medicare Advantage plans typically charge co-pays or  co-insurance when you receive medical services. While co-pays for doctors’visits tend to be very affordable, the costs of hospital visits can be significant. This is one reason that Medicare Advantage premiums tend to be so affordable. You help pay for medical costs as you receive care. However, every Medicare Advantage plan has a maximum out-of-pocket amount, which is the maximum annual amount you will pay annually for covered medical services that you receive in-network.`,
		],
	},
	{
		title: "How to choose a plan",
		startTime: 411,
		durationText: "46s",
		descriptionLines: [
			`While this is complicated, our mission -- and our commitment -- is to help you choose the best-fitting coverage for your needs.`,
			`We can help you confirm which category of coverage is right for your needs, and then we can help you choose the specific plan that fits you best.`,
			`You can start with our short questionnaire to see what type of plan is right for you, and then compare your best options. Just answer a few questions on the next screen. Our team of licensed Medicare Advocates are always ready and available to help you.`,
		],
	},
];

import React, {Component} from "react";
import Dialog from "common/ui/Dialog";
import Button from "common/ui/Button";
import Testimonial from "common/custom/Testimonial";
import {
	CHAPTER_PHONE_NUMBER_RAW,
	legalExpertText,
	CHAPTER_PHONE_NUMBER_FORMATTED,
} from "common/util/common";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import eventTracker from "common/util/eventTracker";
import FreshChat from "common/util/FreshChat";
import ExpertButton from "../Common/ExpertButton";
import Scheduler from "modals/Scheduler";
import GovernmentRules from "../../modals/GovernmentRules";
import {
	ButtonWrapper,
	LargeButtonContent,
	XBoldDiv,
	GenericTop,
	Footer,
	WhySpeak,
	Wrapper,
	ShadowBoxBottom,
	BoxBlueBottom,
	BoxWhiteBottom,
	BrokerImg,
	ButtonRow,
	LegalText,
	Underline,
	PaddingDiv,
	SchedulerWrapper,
} from "./V3Helpers";
import {VennDiagramSection} from "./VennDiagramHelpers";
import {preferenceMap} from "./VennDiagramContent";

export class SummaryV3 extends Component {
	constructor(props) {
		super(props);

		this.buttonData = [
			{
				icon: "phone",
				text: <>Call or text: {CHAPTER_PHONE_NUMBER_FORMATTED}</>,
				onClick: () => (document.location = `tel:+1${CHAPTER_PHONE_NUMBER_RAW}`),
			},
			{
				icon: "text-blurb",
				text: "Chat with us online",
				onClick: () => {
					FreshChat.open({
						name: "SummaryPage",
						replyText: "I'd like to learn more about my plan options",
					});
				},
			},
		];

		this.state = {
			numPlans: LocalStorage.getItem(STORAGE_KEYS.PAGES.SUMMARY_V3.PLAN_COUNTS),
			calendlyIsInline: true,
		};

		const factors = LocalStorage.getItem(STORAGE_KEYS.PAGES.FACTORS);

		this.vennDiagramContent = [];

		if (this.state.numPlans.intersection) {
			factors.forEach((f) => {
				const {serverName} = preferenceMap[f];

				this.vennDiagramContent.push({
					...preferenceMap[f],
					numPlans: this.state.numPlans[serverName],
					listItems: preferenceMap[f].generator(),
				});
			});
		}

		this.schedulerRef = React.createRef();
	}

	openGovernmentRulesModal = () => {
		const {calendlyIsInline} = this.state;

		const onClickSchedulerButton = calendlyIsInline
			? this.scrollToScheduler
			: null;

		Dialog.show({
			children: (
				<GovernmentRules
					{...this.props}
					onClickSchedulerButton={onClickSchedulerButton}
				/>
			),
			width: 715,
			bgColor: "white",
		});
	};

	scrollToScheduler = () => {
		Dialog.hide();

		// Safari (macOS, iPadOS, and iOS) does not support options here
		this.schedulerRef.current.scrollIntoView({
			behavior: "smooth",
			block: "center",
		});
	};

	requestArtifact = () => {
		eventTracker.log({
			name: eventTracker.EVENTS.REQUEST_ARTIFACT,
		});
	};

	render() {
		const {numPlans, calendlyIsInline} = this.state;
		const {customerId, isMobile} = this.props;

		const onClickSchedulerButton = calendlyIsInline
			? this.scrollToScheduler
			: null;

		return (
			<>
				<Wrapper>
					{this.vennDiagramContent.length === 2 ? (
						<VennDiagramSection
							content={this.vennDiagramContent}
							numPlans={numPlans}
							isMobile={isMobile}
						/>
					) : (
						<GenericTop numPlans={numPlans.genericPlans} />
					)}
					{false && <Button onClick={this.requestArtifact} text="Artifact" />}
					<PaddingDiv>
						<ShadowBoxBottom>
							<BoxWhiteBottom>
								<BrokerImg alt="" src="/images/brokers/ari.png" />
								<XBoldDiv>
									In order to see plan names and prices, have a call with a
									Chapter licensed advisor.
								</XBoldDiv>
								{calendlyIsInline ? (
									<SchedulerWrapper ref={this.schedulerRef}>
										<Scheduler />
									</SchedulerWrapper>
								) : (
									<ExpertButton
										buttonColorScheme="red"
										showLegalText={false}
										customerId={customerId}
										onClick={onClickSchedulerButton}
									/>
								)}

								<Underline onClick={this.openGovernmentRulesModal}>
									Why can’t I see my options online?
								</Underline>
							</BoxWhiteBottom>
							<BoxBlueBottom>
								<XBoldDiv>Two ways to speak with an expert:</XBoldDiv>
								<ButtonRow>
									{this.buttonData.map((btn) => (
										<ButtonWrapper
											text=<LargeButtonContent
												text={btn.text}
												icon={btn.icon}
											/>
											key={btn.icon}
											onClick={btn.onClick}
											type="large"
										/>
									))}
								</ButtonRow>
								<LegalText>{legalExpertText}</LegalText>
							</BoxBlueBottom>
						</ShadowBoxBottom>
						<WhySpeak />
						<Testimonial
							names={["cheyral", "anne", "michael", "susan", "karen"]}
						/>
					</PaddingDiv>
				</Wrapper>
				<Footer
					{...this.props}
					onClickSchedulerButton={onClickSchedulerButton}
				/>
			</>
		);
	}
}

import React, {Component} from "react";
import Chip from "@material-ui/core/Chip";
import styled from "styled-components";
import PropTypes from "prop-types";
import STYLES from "common/util/constants/styles";

const ChipWrapper = styled.div`
	.MuiChip-root {
		box-shadow: 0px 0px 5px rgba(0, 31, 103, 0.25);
		border: none;
		width: 180px;
		height: 40px;
		border-radius: 20px;
		margin: 0 10px 20px 10px;
		font-size: 16px;
		font-family: "objektiv-mk3";
		font-weight: ${STYLES.FONT_WEIGHT.REGULAR};
	}

	.MuiChip-clickableColorPrimary {
		font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
		color: inherit;
		background-color: #defbff;
		border: 1px solid #c7ecee;
	}

	.MuiChip-clickable.MuiChip-outlinedPrimary:focus {
		background-color: #defbff;
	}

	.MuiChip-clickable.MuiChip-outlinedPrimary:hover {
		background-color: #defbff;
	}
`;

export default class Chips extends Component {
	render() {
		const {options, selected, onChange, className} = this.props;

		return (
			<ChipWrapper className={className}>
				{options.map((o) => (
					<Chip
						key={o.value}
						label={o.label}
						clickable
						onClick={() => onChange(o.value)}
						variant="outlined"
						color={selected.includes(o.value) ? "primary" : "default"}
					/>
				))}
			</ChipWrapper>
		);
	}
}

Chips.propTypes = {
	selected: PropTypes.arrayOf(PropTypes.string).isRequired,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	onChange: PropTypes.func.isRequired,
};

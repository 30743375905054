import React, {Component} from "react";
import ViewPager from "react-view-pager-touch";
import {cycle} from "common/util/common";
import {ViewPagerStyled} from "./ViewPagerStyled";

export interface CarouselProps {
	items: any[];
	renderItem: (item: any) => any;
	autoAdvance?: boolean;
}

export interface CarouselState {
	currentPage: number;
	animationId: number;
}

export default class Carousel extends Component<CarouselProps, CarouselState> {
	state: CarouselState = {currentPage: 0, animationId: 0};

	componentDidMount() {
		const {autoAdvance} = this.props;

		if (autoAdvance) {
			const animationId = window.setInterval(() => {
				const {currentPage} = this.state;
				const {items} = this.props;

				this.setState({
					currentPage: cycle(currentPage, items.length, 1),
				});
			}, 5000);

			this.setState({animationId});
		}
	}

	render() {
		const {items, renderItem} = this.props;
		const {currentPage} = this.state;

		return (
			<ViewPagerStyled>
				<ViewPager
					items={items}
					renderItem={renderItem}
					currentPage={currentPage}
					onPageSelected={({position}: {position: number}) =>
						this.setState({currentPage: position})
					}
				/>
			</ViewPagerStyled>
		);
	}
}

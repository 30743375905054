import {
	CHAPTER_PHONE_NUMBER_FORMATTED,
	CHAPTER_PHONE_NUMBER_RAW,
} from "common/util/common";
import React from "react";
import {MdPhone} from "react-icons/md";

export function FooterCta() {
	return (
		<div className="flex items-center justify-center bg-teal-700 text-white py-10 px-20 text-xl w-full font-bold">
			Call or text for a free advisor consultation: &nbsp;
			<a
				className="flex items-center hover:text-white"
				href={`tel:+1${CHAPTER_PHONE_NUMBER_RAW}`}
			>
				<MdPhone className="mr-2 text-2xl" />

				{CHAPTER_PHONE_NUMBER_FORMATTED}
			</a>
		</div>
	);
}

import React, {ReactElement, useEffect, useRef} from "react";
import {useHistory} from "react-router";
import {MdChevronLeft} from "react-icons/md";

import {Button} from "common/ui/Button/Button";
import {useSidebar} from "../../../core/Sidebar/hooks/useSidebar";

export function Page({
	children,
	fullWidth,
	hideBack,
}: {
	children?: ReactElement;
	fullWidth?: boolean;
	hideBack?: boolean;
}) {
	const history = useHistory();
	const scrollRef = useRef(null);
	const {setScrollRef} = useSidebar();

	useEffect(() => {
		setScrollRef(scrollRef);
	}, [scrollRef]);

	return (
		<div className="overflow-auto max-h-full w-full" ref={scrollRef}>
			{!hideBack && (
				<button
					className="flex items-center ml-10 mt-12 absolute"
					onClick={() => {
						history.goBack();
					}}
				>
					<MdChevronLeft className="text-gray-500" />
					<div className="ml-2 ">Back</div>
				</button>
			)}
			{fullWidth ? (
				<>{children}</>
			) : (
				<>
					<div className="max-w-3xl mx-auto text-center pt-5">{children}</div>
					{false && (
						<>
							<div className="fixed bottom-0 flex items-center w-full shadow-sm border-t border-solid border-gray-200 bg-white justify-center py-5">
								<Button>Continue</Button>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}

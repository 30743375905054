import styled from "styled-components";
import STYLES from "common/util/constants/styles";

export const NoPlansStyled = styled.div`
	text-align: center;
	border-top: 20px solid ${STYLES.COLORS.WARNING_RED};
	padding: 20px;

	> * {
		margin: auto;
	}

	h4 {
		font-size: 34px;
		font-weight: ${STYLES.FONT_WEIGHT.BOLD};
	}

	a {
		font-weight: ${STYLES.FONT_WEIGHT.BOLD};
		white-space: nowrap;
	}
`;

import {Formik, FormikProps} from "formik";
import React, {useState} from "react";
import * as Yup from "yup";
import {parseDateString} from "common/util/format";

import {FORM_FIELDS, IFormData} from "./data/form-data";
import {partBCalculatorService} from "./service";
import clsx from "clsx";
import {FormCalculator} from "./FormCalculator";

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

/**
 * Set required based on the form data
 * assumes type string which should be changed
 * eventuallly
 */
const requiredFieldsSchema = FORM_FIELDS.flat()
	.filter((f) => f.required)
	.map((f) => f.name)
	.reduce((acc, name) => {
		acc[name] = Yup.string().required("Required");
		return acc;
	}, {} as {[key: string]: any});

/**
 * Add any additonal validations here, but be sure to
 * add "required" again if overwriting and is required
 */
const CalculatorSchema = Yup.object().shape({
	...requiredFieldsSchema,
	zip: Yup.string().length(5, "Please Enter a valid zip").required("Required"),
	dob: Yup.date()
		.transform(parseDateString)
		.min(new Date(1900, 1, 1), "Please Enter a valid Birthdate")
		.max(yesterday, "Please Enter a valid Birthdate")
		.required("Required")
		.typeError("Please Enter a valid Birthdate"),
});

export function Form({
	initialData,
	AdditionalQuestionsComponent,
	onSubmit,
}: {
	initialData?: IFormData;
	AdditionalQuestionsComponent?: React.FunctionComponent<any>;
	onSubmit?: (values: IFormData) => void;
}) {
	const [data] = useState(initialData || partBCalculatorService.getResults());
	return (
		<div className={clsx("px-5 lg:px-0 py-5", !initialData && "lg:px-16")}>
			<Formik
				initialValues={data || {}}
				validationSchema={CalculatorSchema}
				onSubmit={(values, {setSubmitting}) => {
					partBCalculatorService.setResults(values);
					onSubmit && onSubmit(values);
					setSubmitting(true);
				}}
			>
				{(props: FormikProps<IFormData>) => (
					<FormCalculator
						values={props.values}
						isValid={props.isValid}
						initialData={initialData}
						AdditionalQuestionsComponent={AdditionalQuestionsComponent}
					/>
				)}
			</Formik>
		</div>
	);
}

import React, {useEffect, useState} from "react";
import {Dialog} from "@material-ui/core";

import {useBoolean, useUser} from "common/hooks";
import {Button} from "common/ui/Button/Button";

import Explainer from "../shared/Explainer";
import {SpouseEmailModal} from "./SpouseEmailModal";

const DEFAULT_DESCRIPTION_LINES = [
	"I’m excited to help you with your Medicare journey.",
	"Our mission is to make finding a plan simple, and to put your needs above all else.",
	"We search <b>all</b> plans in your area and teach you about Medicare so that you can make an informed choice.",
];

export function Welcome({onSubmit}: {onSubmit: () => void}) {
	const [title, setTitle] = useState(`It's nice to meet you`);
	const {user, updateUser} = useUser();

	const [descriptionLines, setDescriptionLines] = useState<string[]>(
		DEFAULT_DESCRIPTION_LINES
	);

	const dialogOpen = useBoolean(false);

	useEffect(() => {
		setTitle(`It's nice to meet you, ${user?.name || ""}`);
		setDescriptionLines(DEFAULT_DESCRIPTION_LINES);
		if (user?.seekingCoverageFor === "meAndSpouse") {
			setTitle(`It's nice to meet you, ${user.name} and ${user.spouseName}`);
			setDescriptionLines([
				`${user.name} we're going to focus on your Medicare journey first`,
				`If you’d like to go through your Medicare options together, please send ${user.spouseName} a link to go through the flow over email.`,
				`Alternatively, you and your spouse can both speak with me (your Chapter expert) during our peace-of-mind consult.`,
			]);
		} else if (user?.seekingCoverageFor === "other") {
			setTitle(`Thanks for telling me about ${user.name}.`);
			setDescriptionLines([
				"I’m excited to help you with their Medicare journey.",
				`Our mission is to make finding a plan simple, and to put ${user.name}’s needs above all else.`,
				`We search all plans in their area, and teach you about Medicare so that you can help them make an informed choice.`,
			]);
		}
	}, [user]);

	return (
		<>
			<Dialog open={dialogOpen.state} onClose={dialogOpen.setFalse}>
				<SpouseEmailModal
					onClose={(email) => {
						updateUser({spouseEmail: email});
						dialogOpen.setFalse();
					}}
				/>
			</Dialog>
			<Explainer
				title={title}
				descriptionLines={descriptionLines}
				imgSrc="/images/illustrations/search-agent.svg"
			/>
			<div className="flex justify-center items-center space-x-4 my-7">
				<Button
					type="button"
					onClick={() => {
						onSubmit();
					}}
				>
					Continue
				</Button>
				{user?.seekingCoverageFor === "meAndSpouse" && (
					<Button
						buttonType={"secondary"}
						type="button"
						onClick={(e) => {
							dialogOpen.setTrue();
						}}
					>
						Send my spouse a link
					</Button>
				)}
			</div>
		</>
	);
}

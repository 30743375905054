import {Component} from "react";
import styled from "styled-components";
import Carousel from "common/ui/Carousel";
import STYLES from "common/util/constants/styles";

const Wrapper = styled.div`
	max-width: 400px;
	display: flex;
	align-items: center;
	font-size: 12px;
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	line-height: 17px;
	margin: auto;
	padding: 15px 10px;
	white-space: initial;
	text-align: left;
	background-color: ${STYLES.COLORS.GLACIER};

	img {
		border-radius: 50%;
		width: 60px;
		height: 60px;
		margin-right: 15px;
		flex-shrink: 0;
	}
`;

const Bold = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.BOLD};
	margin-top: 10px;
`;

const nameMap = {
	maggie: {
		name: "Maggie",
		quote:
			"Chapter helped me understand my options. They took my personal circumstances into account, which made a huge difference. I also never felt like I was being pressured.",
		location: "New York",
	},
	cheyral: {
		name: "Cheyral",
		quote:
			"I must say I am very thankful for the Chapter team...You are a true blessing",
		location: "New York",
	},
	anne: {
		name: "Anne",
		quote:
			"Ari from Chapter today helped me negotiate Medicare drug plans for my father. I now have a plan that saves money, is easy to manage, and covers all potential bases. Order out of Medicare chaos! EVEN if you think you're all set, talk to Chapter.",
		location: "New York",
	},
	michael: {
		name: "Michael",
		quote:
			"Thanks to Chapter... I'll save about $650 off my Medicare premiums and prescriptions in 2021, and still be able to use my preferred providers.",
		location: "New York",
	},
	susan: {
		name: "Susan",
		quote:
			"Thank you. We are lawyers with a fair amount of regulatory experience but honestly don't know how people navigate through this without expert help.",
		location: "New York",
	},
	karen: {
		name: "Karen",
		quote: "10 out of 10!",
		location: "Chicago",
	},
	randall: {
		name: "Randall L",
		quote:
			"Ari helped me choose the best plan for me in a very complex ocean of plans. It was great having someone to explain the benefits of each option.",
		location: "New York",
	},
	mark: {
		name: "Mark",
		quote: "Much better than anything I've used before for Medicare.",
		location: "New York",
	},
};

export default class Testimonial extends Component {
	constructor(props) {
		super(props);

		const nameNotFound = props.names.find((n) => !nameMap[n]);
		if (nameNotFound) throw new Error(`Name '${nameNotFound}' not found`);
	}

	renderTestimonial(nameKey) {
		const {quote, location, name} = nameMap[nameKey];

		return (
			<Wrapper>
				<img alt="testimonial" src={`/images/testimonials/${name}.png`} />
				<div>
					“{quote}”
					<Bold>
						– {name}, {location}
					</Bold>
				</div>
			</Wrapper>
		);
	}

	render() {
		const {names} = this.props;

		return (
			<Carousel items={names} renderItem={this.renderTestimonial} autoAdvance />
		);
	}
}

import React, {Component} from "react";
import styled from "styled-components";
import Dialog from "common/ui/Dialog";
import Button from "common/ui/Button";
import Icon from "common/ui/Icon";
import IconBackdrop from "common/ui/Icon/IconBackdrop";
import endpoints from "common/util/api/endpoints";
import axios from "common/util/api/axios";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import STYLES from "common/util/constants/styles";
import {
	Wrapper,
	Title,
	SubTitle,
	XBold,
	TextInputWrapper,
} from "../HowCanWeHelp/modals";

const ButtonWrapper = styled(Button)`
	margin: 36px auto 0 auto;
`;

const IconBackdropCentered = styled(IconBackdrop)`
	margin: 10px auto 13px auto;
`;

export class SomethingElse extends Component {
	state = {text: ""};

	submitText = () => {
		const {text} = this.state;

		const customerId = LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID);

		axios
			.post(endpoints.questionSave(), {
				customerId,
				mostImportantToYouSomethingElse: text,
			})
			.catch(console.error);

		this.setState({submitted: true});
	};

	render() {
		const {text, submitted} = this.state;

		return (
			<Wrapper>
				{submitted ? (
					<>
						<IconBackdropCentered color={STYLES.COLORS.VIBRANT_MINT} width={75}>
							<Icon src="checkMark" width={40} />
						</IconBackdropCentered>
						<Title>Thank you!</Title>
						<SubTitle>
							We can help with that.
							<br />
							Please select another factor that is important to you.
						</SubTitle>
						<br />
						<br />
						<ButtonWrapper text="Close" onClick={Dialog.hide} />
					</>
				) : (
					<>
						<Title>Is there something else you’re looking for?</Title>
						<SubTitle>
							Type something here and we’ll pass it on to an agent.
						</SubTitle>
						<XBold>I’m looking for:</XBold>
						<TextInputWrapper
							htmlEl="textarea"
							onChange={(text) => this.setState({text})}
							value={text}
							placeholder="Tell us what you're looking for. We'll have a licensed advisor take a look."
							rows={4}
						/>
						<ButtonWrapper text="Save" onClick={this.submitText} />
					</>
				)}
			</Wrapper>
		);
	}
}

import React, {ReactElement, useEffect, useState} from "react";
import {TreeView} from "common/ui/Tree";
import clsx from "clsx";
import {useHistory, useLocation} from "react-router";
import {MdArrowDropDown} from "react-icons/md";

import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import {useSidebar} from "core/Sidebar/hooks/useSidebar";

function HeaderView({item}: {item: any}) {
	return (
		<div
			className={clsx(
				"flex items-center font-bold text-black px-2 py-3 border-gray-200"
			)}
		>
			<div className="flex mr-2">
				<span className="inline-flex items-center justify-center h-4 w-4 rounded-full bg-teal-600 bg-opacity-20 overflow-hidden"></span>
			</div>

			{item.name}
			<div className="ml-auto">
				<MdArrowDropDown className="text-2xl" />
			</div>
		</div>
	);
}

function ChildView({item, isActive}: {item: any; isActive: boolean}) {
	return (
		<div
			className={clsx(
				"py-1 text-sm",
				isActive ? "text-black font-bold" : "text-gray-800",
				isActive && ""
			)}
		>
			{item.name}
		</div>
	);
}

export function Sidebar({
	modules,
	Header,
}: {
	modules?: any;
	Header?: ReactElement;
}) {
	// control from here
	// url sets page
	// data sets completed or not
	const history = useHistory();
	const [activeId, setActiveId] = useState(modules && modules[0]?.id);
	const location = useLocation();

	const {setIsSidebarOpen} = useSidebar();

	function flattenRoutes() {
		if (!modules) {
			return [];
		}
		return modules.reduce((acc, m) => {
			m.children.forEach((c) => {
				acc.push({id: c.id, route: c.route});
			});
			return acc;
		}, []);
	}

	function getPathFromRoute(route: string) {
		return `/flow/${route}`;
	}

	function getIdFromPath(path: string) {
		const module = flattenRoutes().find((m) =>
			path.replace("/flow/", "").startsWith(m.route)
		);
		return module && module.id;
	}

	useEffect(() => {
		setIsSidebarOpen(true);

		return () => {
			setIsSidebarOpen(false);
		};
	}, []);

	useEffect(() => {
		setActiveId(getIdFromPath(location.pathname));
	}, [location.pathname, modules]);

	return (
		<TreeView
			className="bg-gray-50 border-r border-gray-100 py-4 w-72 flex-shrink-0 overflow-auto max-h-screen h-full"
			activeId={activeId}
			onChange={(newSelectedIds) => {
				const newActiveId = newSelectedIds[0];
				setActiveId(newActiveId);
				const module = flattenRoutes().find((m) => m.id === newActiveId);

				if (module) {
					history.push(getPathFromRoute(module.route));
				}
			}}
			Header={Header}
			ChildView={ChildView}
			HeaderView={HeaderView}
			groups={modules || []}
		/>
	);
}

export function ResponsiveSidebar({modules}: {modules?: any}) {
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	return (
		<>
			<Hidden smUp implementation="js">
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					<Sidebar modules={modules} />
				</Drawer>
			</Hidden>
			<Hidden xsDown implementation="js">
				<Drawer variant="permanent" open>
					<Sidebar modules={modules} />
				</Drawer>
			</Hidden>
		</>
	);
}

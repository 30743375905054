import React from "react";
import {Formik, Form} from "formik";
import * as Yup from "yup";
import Dialog from "common/ui/Dialog";
import {parseMonthAndYearString} from "common/util/format";
import {useBoolean} from "common/hooks";
import {Dialog as MaterialDialog} from "@material-ui/core";
import {TypeMedicareSignUpStatus} from "common/util/api/service";
import {CurrentMedicareEnrollmentHelpModal} from "./CurrentMedicareEnrollmentHelpModal";
import Scheduler from "modals/Scheduler";
import {FormField} from "../shared/FormField";

export interface IMedicareSignUpStatusValues {
	medicareSignUpStatus: TypeMedicareSignUpStatus;
	partAEffectiveDate?: string;
	partBEffectiveDate?: string;
}

interface IMedicareSignUpStatusProps {
	onSubmit: (values: IMedicareSignUpStatusValues) => void;
}

const medicareSignUpStatusField = {
	name: "medicareSignUpStatus",
	label: "",
	type: "toggleButton",
	options: [
		{label: "Just Part A", value: "partA"},
		{label: "Both Parts A & B", value: "partAB"},
		{label: "Neither", value: "neither"},
		{
			label: "I'm not sure",
			value: "unsure",
		},
	],
	additional: (
		currentStatus: IMedicareSignUpStatusValues["medicareSignUpStatus"]
	) => {
		const myQuestions = [
			{
				name: "label",
				label: "What are your effective dates?",
				type: "label",
			},
			{
				name: "partAEffectiveDate",
				label: "Part A:",
				type: "monthyear",
				containerClassName: "flex-row",
			},
			{
				name: "partBEffectiveDate",
				label: "Part B:",
				type: "monthyear",
			},
		];

		if (currentStatus === "partAB") {
			return myQuestions;
		}
	},
};

export function MedicareSignUpStatus({onSubmit}: IMedicareSignUpStatusProps) {
	const dialogOpen = useBoolean(false);
	function showScheduler() {
		Dialog.show({
			children: <Scheduler />,
			width: Scheduler.width,
			height: Scheduler.height,
			bgColor: "white",
		});
	}

	return (
		<Formik
			initialValues={
				{
					medicareSignUpStatus: "",
					partAEffectiveDate: "",
					partBEffectiveDate: "",
				} as IMedicareSignUpStatusValues
			}
			validationSchema={Yup.object().shape({
				medicareSignUpStatus: Yup.string().required(),
			})}
			onSubmit={async (values) => {
				if (values.partAEffectiveDate) {
					values.partAEffectiveDate = parseMonthAndYearString(
						values.partAEffectiveDate
					);
				}
				if (values.partBEffectiveDate) {
					values.partBEffectiveDate = parseMonthAndYearString(
						values.partBEffectiveDate
					);
				}
				onSubmit(values);
			}}
		>
			{({values, submitForm}) => (
				<Form className="w-full">
					<MaterialDialog open={dialogOpen.state} onClose={dialogOpen.setFalse}>
						<CurrentMedicareEnrollmentHelpModal
							onClose={dialogOpen.setFalse}
							onHelp={() => {
								dialogOpen.setFalse();
								showScheduler();
							}}
						/>
					</MaterialDialog>
					<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
						Are you signed up for Medicare Parts A & B yet?
					</div>
					<div className="flex max-w-xl mx-auto my-10 mb-0 text-sm justify-center">
						<FormField
							fieldData={medicareSignUpStatusField}
							nextStep={(selected) => {
								setTimeout(() => {
									console.log(values);
									if (selected === "unsure") {
										dialogOpen.setTrue();
										return;
									}
									// if unsure is selected, let's show a modal
									submitForm();
								}, 300);
							}}
							autoAdvance={true}
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
}

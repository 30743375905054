import React from "react";

export const diamond = ({color, width, height}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 39 34"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M27.288 1.98H11.326L1.505 11.3l17.802 21.64L37.109 11.3l-9.822-9.32z"
			stroke={color}
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M22.958 1.98h-7.302l-4.494 9.32 8.145 21.64L27.45 11.3l-4.493-9.32zM1.505 11.299h35.604"
			stroke={color}
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

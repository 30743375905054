import React, {Component} from "react";
import PropTypes from "prop-types";
import {Tooltip as BPTooltip} from "@blueprintjs/core";

const classTypeMap = {
	small: "tooltip-small",
	large: "tooltip-large",
};

export default class Tooltip extends Component {
	render() {
		const {type} = this.props;

		return <BPTooltip {...this.props} popoverClassName={classTypeMap[type]} />;
	}
}

Tooltip.propTypes = {
	type: PropTypes.string,
};

Tooltip.defaultProps = {
	type: "small",
};

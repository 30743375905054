import React from "react";
import {Autocomplete} from "common/ui/Autocomplete";
import {useEffect, useState} from "react";
import {carrierService} from "./service";

const planSort = [
	"Plan K",
	"Plan L",
	"Plan G (High Deductible)",
	"Plan D",
	"Plan C",
	"Plan B",
	"Plan A",
	"Plan F",
	"Plan G",
];
export function PlanAutocomplete({props}) {
	const [options, setOptions] = useState<string[]>();

	useEffect(() => {
		async function getPlanTypes() {
			const newOptions = await carrierService.getPlanTypes().catch((err) => {
				console.log(err);
			});
			console.log(newOptions);
			if (newOptions) {
				newOptions.sort((a, b) => {
					return planSort.indexOf(b.label) - planSort.indexOf(a.label);
				});
				setOptions(newOptions);
			}
		}
		getPlanTypes();
	}, []);

	return (
		<>
			{options ? (
				<Autocomplete label="Plan Type" {...props} options={options} />
			) : (
				<div>Loading...</div>
			)}
		</>
	);
}

import React, {useEffect} from "react";
import {useHistory, useLocation} from "react-router";
import {Switch, Route, useRouteMatch, Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import {SWRConfig} from "swr";

import {ProvideUser} from "common/hooks/useUser";

import FlowFirstTimer from "../FirstTimer";
import {FlowTriage} from "./FlowTriage";
import {FlowIntro} from "./FlowIntro";
import {SwitcherBase} from "../Switcher";
// import {UnsureBase} from "../Unsure";
import {ProvideSidebar} from "../../../core/Sidebar/hooks/useSidebar";
import {Page} from "../shared/Page";
import {FlowWrapper} from "./FlowWrapper";
import {useHeader} from "core/Headers/hooks/useHeader";

export function Flow() {
	const match = useRouteMatch();
	const history = useHistory();
	const location = useLocation();
	const {setHomeUrl} = useHeader();

	useEffect(() => {
		setHomeUrl("/flow");
	}, []);

	return (
		<div className="flex flex-grow min-h-0 relative text-black text-base font-normal">
			<Helmet>
				<title>Flow</title>
			</Helmet>

			<ProvideSidebar>
				<ProvideUser>
					<SWRConfig
						value={{
							refreshInterval: 1000,
							errorRetryCount: 1,
						}}
					>
						<FlowWrapper>
							<Switch location={location}>
								<Route path={`${match.path}/select`}>
									<Page>
										<FlowTriage
											onSubmit={(path: "new" | "switcher" | "unsure") => {
												history.push(`${match.path}/${path}`);
											}}
										/>
									</Page>
								</Route>

								{/** First Timer Flow */}
								<Route path={`${match.path}/new`}>
									<FlowFirstTimer />
								</Route>

								{/** Switcher Flow */}
								<Route path={`${match.path}/switcher`}>
									<SwitcherBase />
								</Route>

								{/** Unsure Flow */}
								{/**<Route path={`${match.path}/unsure`}>
									<UnsureBase />
								</Route>**/}

								{/** Intro Screen */}
								<Route path={match.path}>
									<Page hideBack={true}>
										<FlowIntro
											onSubmit={() => {
												history.push(`${match.path}/select`);
											}}
										/>
									</Page>
								</Route>

								<Redirect to="/" />
							</Switch>
						</FlowWrapper>
					</SWRConfig>
				</ProvideUser>
			</ProvideSidebar>
		</div>
	);
}

/*

See the following wiki page for usage info:
https://github.com/getChapter/medicare/wiki/Product-Testing-(Google-Optimize)

*/
import eventTracker from "common/util/eventTracker";

class ABTester {
	_variants?: number[];
	_readyPromise?: Promise<number[]>;

	constructor(experimentId: string) {
		window.dataLayer.push({event: "optimize.activate"});

		this._readyPromise = new Promise((resolve) => {
			const intervalId = setInterval(() => {
				if (window.google_optimize !== undefined) {
					clearInterval(intervalId);

					const variantsRaw = window.google_optimize.get(experimentId);

					if (variantsRaw) {
						this._variants = variantsRaw
							.split("-")
							.map((n: string) => parseInt(n));
					} else {
						this._variants = [];
					}

					resolve(this._variants);
				}
			}, 100);
		});
	}

	/**
	 * Get the randomly assigned testing variants. Log error if Optimize returns nothing.
	 *
	 * @async
	 * @return {number[]} The array of variants. Each index corresponds to the ordered section.
	 * @example await ABTester.getVariants() ––> [0, 5] section A has variant 0, section B has variant 5
	 */
	async getVariants(): Promise<number[] | void> {
		if (this._variants) {
			if (!this._variants.length) this._logOptimizeError();
			return this._variants;
		}

		const variants = await this._readyPromise;
		if (variants) return variants;
		else this._logOptimizeError();
	}

	_logOptimizeError() {
		eventTracker.log({
			name: eventTracker.EVENTS.ERROR,
			data: {name: "NoOptimizeData"},
		});
	}
}

export default ABTester;

import styled from "styled-components";
import Select from "common/ui/Select";
import TextInput from "common/ui/TextInput";
import InlineTip from "common/ui/InlineTip";
import STYLES from "common/util/constants/styles";

export const Container = styled.div`
	max-width: 650px;
	margin: auto;
	text-align: center;

	@media (max-width: 639px) {
		padding: 0 ${STYLES.PADDING.BODY_HORIZONTAL};
	}

	input::placeholder {
		color: #b9d8f8;
	}

	button {
		margin: 40px auto 15px auto;
	}
`;

export const Title = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
	font-size: 31px;
	margin-bottom: 34px;
`;

export const SubTitle = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 25px;
	margin-bottom: 31px;
`;

export const Text = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 16px;
	margin-bottom: 38px;
`;

export const ZipcodeInput = styled(TextInput)`
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
	background-color: transparent;
	font-size: 39px;
	width: 260px;
	margin: auto;
	text-align: center;
	font-weight: ${STYLES.FONT_WEIGHT.REGULAR};
`;

export const SelectCounty = styled(Select)`
	width: 360px;
	margin: 25px auto 0 auto;
`;

export const Image = styled.img`
	margin: 10px auto;
	max-width: 100%;
`;

export const InlineTipStyled = styled(InlineTip)`
	margin: auto;
`;

import styled from "styled-components";

export const ViewPagerStyled = styled.div`
	width: 100%;

	.view-pager {
		overflow: hidden;
		position: relative;
		width: 100%;
	}
	.view-pager-canvas {
		white-space: nowrap;
		width: 100%;
		overflow: visible;
		backface-visibility: hidden;
		transform-style: flat;
	}
	.view-pager-view {
		width: 100%;
		display: inline-block;
	}
`;

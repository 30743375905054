import styled from "styled-components";
import STYLES from "common/util/constants/styles";

export const Wrapper = styled.div`
	margin: auto;
	max-width: 550px;

	@media (max-width: 639px) {
		padding: 0 ${STYLES.PADDING.BODY_HORIZONTAL};
	}
`;

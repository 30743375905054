import React, {Component} from "react";
import styled from "styled-components";
import Icon from "common/ui/Icon";
// import STYLES from "common/util/constants/styles";
import {ShadowBox} from "templates/search";

const ProviderShadowBox = styled(ShadowBox)`
	@media (min-width: 640px) {
		padding: 30px 35px 25px 64px;
		width: 480px;
	}

	@media (max-width: 639px) {
		padding: 30px 35px 25px 30px;
	}
`;

const ProviderTop = styled.div`
	display: flex;
	align-items: top;
	justify-content: space-between;
	margin-bottom: 30px;
	line-height: 23px;
`;

const IconRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 80px;
	flex-shrink: 0;
`;

// eslint-disable-next-line
const AddressRow = styled.div`
	display: flex;
	margin-bottom: 20px;

	svg {
		margin-right: 20px;
	}
`;

export class ProviderComponent extends Component {
	render() {
		const {provider, removeProvider, editProvider} = this.props;

		// eslint-disable-next-line
		const {address_details} = provider.locations.find((l) => l.selected);

		return (
			<ProviderShadowBox>
				<ProviderTop>
					<div>
						<b children={`${provider.firstName} ${provider.lastName}`} />
						<br />
						<br />
						<span children={provider.specialties[0]} />
					</div>
					<IconRow>
						<Icon
							src="pencil"
							onClick={() => editProvider(provider)}
							height={25}
						/>
						<Icon
							src="trash"
							onClick={() => removeProvider(provider.npi)}
							height={25}
						/>
					</IconRow>
				</ProviderTop>
			</ProviderShadowBox>
		);
	}
}

// Sep 27th Note - commenting this out until we find time for expanded provider view

// <AddressRow>
// 	<Icon src="pin" height={38} color={STYLES.COLORS.SALMON} />
// 	<div>
// 		<div children={address_details.street} />
// 		<div
// 			children={`${address_details.city}, ${address_details.state} ${address_details.zip}`}
// 		/>
// 	</div>
// </AddressRow>
// {provider.timesVisited && (
// 	<div>
// 		<b>{provider.timesVisited}</b> visits last year
// 	</div>
// )}

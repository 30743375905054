import React from "react";
import styled from "styled-components";
import Icon from "common/ui/Icon";
import IconBackdrop from "common/ui/Icon/IconBackdrop";
import STYLES from "common/util/constants/styles";

const Header = styled.div`
	font-size: 60px;
	font-weight: ${STYLES.FONT_WEIGHT.BOLD};
`;

const SubHeader = styled.div`
	font-size: 28px;
`;

const IconBackdropStyled = styled(IconBackdrop)`
	margin: auto;
`;

const ContainerCommon = styled.div`
	text-align: center;
	color: white;

	${IconBackdropStyled} {
		margin-bottom: 35px;
	}
`;

const HearContainer = styled(ContainerCommon)`
	${Header} {
		margin-bottom: 25px;
	}

	${SubHeader} {
		margin-bottom: 60px;
	}
`;

const StandardContainer = styled(ContainerCommon)`
	${Header} {
		margin-bottom: 65px;
	}
`;

const IconRow = styled.div`
	display: flex;
	justify-content: space-between;
	line-height: 30px;

	@media (max-width: 639px) {
		flex-flow: column;

		> div {
			margin-bottom: 25px;
		}
	}
`;

const StandardBottomText = styled.div`
	font-size: 25px;
`;

export const JourneyStartStandard = () => {
	return (
		<StandardContainer>
			<Header>Welcome to Chapter!</Header>
			<div>
				<IconBackdropStyled color={STYLES.COLORS.WINTERGREEN} width={110}>
					<Icon src="mag-glass-detailed" width={65} />
				</IconBackdropStyled>
				<StandardBottomText>
					90% of people have the wrong Medicare coverage.
					<br />
					<br />
					Let's see if you can save money or get better benefits.
				</StandardBottomText>
			</div>
		</StandardContainer>
	);
};

export const JourneyStartHear = ({firstName}) => {
	return (
		<HearContainer>
			<Header>Hi{firstName && ` ${firstName}`}!</Header>
			<SubHeader>We can help you find the right Medicare coverage.</SubHeader>
			<IconRow>
				<div>
					<IconBackdropStyled color={STYLES.COLORS.POWDER_BLUE} width={100}>
						<Icon src="pig" width={70} />
					</IconBackdropStyled>
					During this time of year, you have maximum flexibility to save.
				</div>
				<div>
					<IconBackdropStyled color={STYLES.COLORS.WARNING_RED} width={100}>
						<Icon src="ear" width={40} />
					</IconBackdropStyled>
					Many plans have $0 premiums and include hearing benefits.
				</div>
			</IconRow>
		</HearContainer>
	);
};

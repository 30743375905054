import React, {ButtonHTMLAttributes, ReactNode} from "react";
import MuiButton from "@material-ui/core/Button";
import clsx from "clsx";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	buttonType?: "primary" | "secondary";
	children: ReactNode;
}

export function Button({
	children,
	buttonType = "primary",
	...props
}: IButtonProps) {
	return (
		<MuiButton
			className={clsx(
				"inline-flex items-center px-8 py-3 text-base font-bold rounded-full shadow-sm font-unset normal-case focus:outline-none focus:ring-2 focus:ring-offset-2",
				buttonType === "primary" &&
					"text-white bg-teal-700 border border-transparent hover:bg-teal-800 focus:ring-teal-500",
				buttonType === "secondary" &&
					"bg-white text-black border border-solid border-gray-200 hover:bg-gray-100 focus:ring-gray-300"
			)}
			{...props}
			color={"primary"}
		>
			{children}
		</MuiButton>
	);
}

import React from "react";
import {Formik, Form} from "formik";
import * as Yup from "yup";
import {Dialog as MaterialDialog} from "@material-ui/core";

import {useUser} from "common/hooks/useUser";
import {useBoolean} from "common/hooks";
import Dialog from "common/ui/Dialog";
import {TypeCurrentAdditionalCoverageType} from "common/util/api/service";

import {FormField} from "../shared/FormField";
import {CurrentAdditionalCoverageTypeModal} from "../shared/CurrentAdditionalCoverageTypeModal";
import Scheduler from "modals/Scheduler";

export interface ICurrentAdditionalCoverageTypeValues {
	currentAdditionalCoverageType: TypeCurrentAdditionalCoverageType;
}

const currentAdditionalCoverageTypeField = {
	name: "currentAdditionalCoverageType",
	label: "",
	type: "toggleButton",
	options: [
		{label: "Medicare Supplement", value: "ms"},
		{label: "Medicare Advantage", value: "ma"},
		{
			label: "I'm not sure, or something else",
			value: "unsure",
		},
	],
};

export function SwitcherTriage({
	onSubmit,
}: {
	onSubmit: (values: ICurrentAdditionalCoverageTypeValues) => void;
}) {
	const {updateUser} = useUser();
	const dialogOpen = useBoolean(false);

	function showScheduler() {
		Dialog.show({
			children: <Scheduler />,
			width: Scheduler.width,
			height: Scheduler.height,
			bgColor: "white",
		});
	}

	return (
		<>
			<MaterialDialog
				maxWidth="md"
				open={dialogOpen.state}
				onClose={dialogOpen.setFalse}
			>
				<CurrentAdditionalCoverageTypeModal
					onClose={dialogOpen.setFalse}
					onHelp={() => {
						dialogOpen.setFalse();
						showScheduler();
					}}
				/>
			</MaterialDialog>
			<Formik
				initialValues={{
					currentAdditionalCoverageType: "",
				}}
				validationSchema={Yup.object().shape({
					currentAdditionalCoverageType: Yup.string().required(),
				})}
				onSubmit={(values) => {
					updateUser({
						currentAdditionalCoverageType:
							values.currentAdditionalCoverageType as TypeCurrentAdditionalCoverageType,
					});
					onSubmit(values as any);
				}}
			>
				{({submitForm}) => (
					<Form className="w-full">
						<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
							Which type of coverage do you currently have?
						</div>
						<div className="flex max-w-xl mx-auto justify-center my-10 mb-0 text-sm">
							<FormField
								fieldData={currentAdditionalCoverageTypeField}
								nextStep={(selected) => {
									if (selected === "unsure") {
										dialogOpen.setTrue();
										return;
									}
									setTimeout(() => {
										submitForm();
									}, 300);
								}}
								autoAdvance={true}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
}

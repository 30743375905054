import React, {Component} from "react";
import {DrawerWrapper} from "./DrawerHelpers";

let instance;

export default class Drawer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			hasClickedBackdrop: false,
		};

		this.isDesktop = window.innerWidth >= 640;

		instance = this;
	}

	static show = ({
		title = "",
		content,
		childProps,
		canOutsideClickClose = true,
	}) => {
		instance.setState({
			childProps,
			title,
			isOpen: true,
			ChildComponent: content,
			hasClickedBackdrop: false,
			canOutsideClickClose,
		});
	};

	static hide = () => {
		instance.setState({isOpen: false});
	};

	#onClose = () => {
		const {canOutsideClickClose, hasClickedBackdrop} = this.state;

		if (canOutsideClickClose) Drawer.hide();
		else if (!hasClickedBackdrop) this.setState({hasClickedBackdrop: true});
	};

	render() {
		const {
			ChildComponent,
			childProps,
			isOpen,
			title,
			hasClickedBackdrop,
		} = this.state;

		const BPDrawerProps = {
			isOpen,
			title,
			onClose: this.#onClose,
			size: this.isDesktop ? "640px" : "100%",
			hasBackdrop: true,
		};

		// Hide pesky Blueprint error
		if (title) BPDrawerProps.isCloseButtonShown = false;

		return (
			<DrawerWrapper {...BPDrawerProps}>
				{ChildComponent && (
					<ChildComponent
						hasClickedBackdrop={hasClickedBackdrop}
						data={childProps}
					/>
				)}
			</DrawerWrapper>
		);
	}
}

import React from "react";
import {useRouteMatch} from "react-router";
import {Switch, Route} from "react-router-dom";

import {EmployeeCalculator} from "./Calculator";
import {EmployeeCalculatorResults} from "./Results/Results";

export interface IEmployerCalculatorRouteParams {
	employerId: string;
}

export function EmployeeCalculatorBase() {
	const {path} = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}/:employerId/results`}>
				<EmployeeCalculatorResults />
			</Route>
			<Route exact path={`${path}/:employerId/`}>
				<EmployeeCalculator />
			</Route>
		</Switch>
	);
}

import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {Helmet} from "react-helmet";

import {LegalFooter} from "core/Footer/LegalFooter";
import {Header} from "./Header";

import {
	SITE_DESCRIPTION,
	SITE_IMAGE_URL,
	SITE_TITLE,
	SITE_URL,
} from "./data/constants";
import {IEmployerData} from "./data/models";

import {partBCalculatorService} from "pages/PartBCalculator/service";
import {IEmployerCalculatorRouteParams} from "./Router";
import {EMPLOYER_DATA_MAP} from "./data/employers";
import {EmployeeCalculatorFormWrapper} from "./Form/Formik";
import {employeeCalculatorService, IFormData} from "./service";
import {useHeader} from "core/Headers/hooks/useHeader";

const RESULTS_ROUTE = "results";

export function EmployeeCalculator() {
	// Get employer Id from URL, and use that to fetch employer data
	// stored in ./data/
	const {employerId} = useParams<IEmployerCalculatorRouteParams>();
	const [employerData, setEmployerData] = useState<IEmployerData>();
	const {setCoBrandImageUrl, setAltPhoneNumber} = useHeader();

	useEffect(() => {
		const newEmployerData = EMPLOYER_DATA_MAP[employerId];
		setEmployerData(newEmployerData);
		setCoBrandImageUrl(newEmployerData?.imageUrl);
		setAltPhoneNumber(newEmployerData?.phoneNumberRaw);
	}, [employerId, setCoBrandImageUrl]);

	const history = useHistory();

	function onSubmitPressed(values: IFormData) {
		// Save before showing the modal
		employeeCalculatorService.setResults(values);

		partBCalculatorService.saveCustomerData();

		history.push(`/calculators/employees/${employerId}/${RESULTS_ROUTE}`);
	}

	return (
		<div className="container mx-auto px-7 xl:px-20 font-normal text-base text-black">
			<Helmet>
				<title>
					{(employerData && employerData.name) || ""} Employee Premium
					Calculator
				</title>
				<meta property="og:url" content={SITE_URL} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={SITE_TITLE} />
				<meta property="og:description" content={SITE_DESCRIPTION} />
				<meta property="og:image" content={SITE_IMAGE_URL} />
			</Helmet>
			<div className="mb-10">
				<Header employerData={employerData} />
			</div>

			<div className="flex w-full">
				<div className="flex justify-center w-full bg-white">
					<EmployeeCalculatorFormWrapper
						employerData={employerData!}
						onSubmit={onSubmitPressed}
					/>
				</div>
			</div>
			<div className="flex py-6 max-w-5xl mx-auto">
				<LegalFooter />
			</div>
		</div>
	);
}

import React, {Component} from "react";
import styled from "styled-components";
import * as FullStory from "@fullstory/browser";
import TextInput from "common/ui/TextInput";
import {capitalize} from "common/util/format";
import {LocalStorage} from "common/util/storage/";
import STORAGE_KEYS from "common/util/storage/keys";
import STYLES from "common/util/constants/styles";

const Container = styled.div`
	max-width: 450px;
	margin: auto;

	@media (min-width: 640px) {
		margin-top: 100px;
	}

	@media (max-width: 639px) {
		padding: 0 ${STYLES.PADDING.BODY_HORIZONTAL};
	}

	h4,
	input {
		text-align: center;
	}

	input::placeholder {
		color: #b9d8f8;
	}
`;

const NameInput = styled(TextInput)`
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
	background-color: transparent;
	font-size: 39px;
`;

export class FirstName extends Component {
	static bodyColor = "white";
	static hasWaves = true;

	constructor(props) {
		super(props);

		const name = LocalStorage.getItem(STORAGE_KEYS.FIRST_NAME) || "";
		this.state = {name};
	}

	componentWillUnmount() {
		const {name} = this.state;

		if (name) {
			FullStory.setUserVars({
				displayName: name,
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {name} = this.state;

		LocalStorage.setItem(STORAGE_KEYS.FIRST_NAME, name);
	}

	onKeyDownWrapper = (key) => {
		const {advancePage} = this.props;
		const {name} = this.state;

		if (key === "Enter" && name.length >= 3) advancePage();
	};

	render() {
		const {name} = this.state;

		return (
			<Container>
				<h4>What is your first name?</h4>
				<NameInput
					value={name}
					onChange={(name) => this.setState({name: capitalize(name)})}
					placeholder="Type Here"
					onKeyDown={this.onKeyDownWrapper}
				/>
			</Container>
		);
	}
}

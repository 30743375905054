import React from "react";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import QuestionnaireWrapper from "./QuestionnaireTemplate";

const config = {
	options: [
		{value: "true", label: "Yes"},
		{value: "false", label: "No"},
	],
	localStorageKey: STORAGE_KEYS.PAGES.QUESTIONNAIRE_COSTS,
	questionKey: "premiumAffordability",
	icon: "pig",
	title: "Premiums",
	isRequired: true,
	drawer: {
		medigap: [
			"Has higher monthly premiums in exchange for low (or no) out-of-pocket expenses if you visit your doctor or go to the hospital",
		],
		medAdv: [
			"Has lower premiums, sometimes as low as $0 per month",
			"Has higher out-of-pocket expenses if you get sick or injured",
		],
	},
};

export class QuestionnaireCosts extends QuestionnaireWrapper {
	componentDidMount() {
		// This data pulled from server and stored in BasicInfo.js
		const premiumCost = LocalStorage.getItem(STORAGE_KEYS.ZIPCODE_MONTHLY_PREMIUMS) || 90;

		config.drawer.topText = `If you are willing and able to pay at least $${premiumCost} per month in premiums, we will search for options that minimize or nearly eliminate your potential out-of-pocket expenses.`;

		config.description = (
			<>
				Some plans minimize out of pocket expenses but have higher premiums.
				Would you be willing to spend ${premiumCost} per month for this type of plan?
			</>
		);

		this.setConfig(config);
	}
}

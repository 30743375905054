import React from "react";
import {Button} from "common/ui/Button/Button";
import {CHAPTER_PHONE_NUMBER_FORMATTED} from "common/util/common";
import medSupIdCardIdentify from "./images/medsup-id-card-identify.png";
import maIdCardIdentify from "./images/ma-id-card-identify.png";

export function CurrentAdditionalCoverageTypeModal({
	onClose,
	onHelp,
}: {
	onClose: () => void;
	onHelp: () => void;
}) {
	return (
		<div className="flex h-full px-7 overflow-auto max-h-full font-normal text-base">
			<div className="flex flex-col h-full py-6 px-4 space-y-5">
				<div className="text-center font-bold text-3xl">
					Find out what plan type you have
				</div>
				<div className="text-center max-w-md mx-auto">
					You can also text a photo of your card to{" "}
					{CHAPTER_PHONE_NUMBER_FORMATTED} if you’d prefer.
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
					<div className="space-y-4">
						<img className="" src={medSupIdCardIdentify} />
						<div>
							If you card has a Medicare plan letter, you have a
							<b> Medicare Supplement</b> plan.
						</div>
					</div>
					<div className="space-y-4">
						<img className="" src={maIdCardIdentify} />
						<div>
							If you card has numbers starting with “CMS” and then either H, R,
							or C (on the front or back), you have a <b>Medicare Advantage</b>{" "}
							plan. You may also see co-pay information.
						</div>
					</div>
				</div>

				<div className="mx-auto flex items-center space-x-4">
					<Button onClick={onClose}>Go Back</Button>

					<Button
						onClick={onHelp}
						className="inline-flex items-center px-8 py-3 text-base font-bold rounded-full shadow-sm border-gray-200 border border-solid font-unset normal-case"
					>
						I need help
					</Button>
				</div>
			</div>
		</div>
	);
}

import React, {useEffect} from "react";
import {IFormData} from "../../PartBCalculator/data/form-data";
import Dialog from "common/ui/Dialog";
import ExpertButton from "pages/QuestionFlow/views/Summary/Results/Common/ExpertButton";
import {CHAPTER_PHONE_NUMBER_FORMATTED} from "common/util/common";

export function FormMedsupAlert({values}: {values: IFormData}) {
	const {isEnrolledInMedsup} = values;

	// also age < 65

	useEffect(() => {
		if (isEnrolledInMedsup === "yes" || isEnrolledInMedsup === "no") {
			Dialog.show({
				children: (
					<AlertModal
						isEnrolledInMedsup={isEnrolledInMedsup}
						onClose={() => {
							Dialog.hide();
						}}
					/>
				),
				width: 550,
				bgColor: "white",
			} as any);
		}
	}, [isEnrolledInMedsup]);
	return <></>;
}

function AlertModal({
	isEnrolledInMedsup,
}: {
	isEnrolledInMedsup: string;
	onClose: () => void;
}) {
	return (
		<div className="flex h-full px-7 overflow-auto max-h-full font-normal">
			<div className="flex flex-col h-full py-6 px-4">
				<div className="text-center font-bold text-xl mb-5">
					You'll have a new Open Enrollment Period when you turn 65
				</div>
				<div className="flex flex-col h-full items-center">
					<div className="text-base text-black space-y-3">
						<p>
							In most states, you will have{" "}
							{isEnrolledInMedsup === "no" ? "a new" : "an"} Open Enrollment
							window to choose a Medicare Supplement Plan when you turn 65.
						</p>
						<p>
							During this six-month period, you can choose a new Medicare
							Supplement plan without underwriting. This means you cannot be
							charged a higher premium.
						</p>
						<p>
							Our licensed advisors can help explain the Open Enrollment
							Process. You can call us at {CHAPTER_PHONE_NUMBER_FORMATTED} or
							make an appointment for a free, peace of mind consultation.
						</p>
					</div>

					<div className="mt-auto pt-5 text-center">
						<ExpertButton className="mx-auto" />
					</div>
				</div>
			</div>
		</div>
	);
}

import {Component} from "react";
import PropTypes from "prop-types";
import Icon from "common/ui/Icon";
import STYLES from "common/util/constants/styles";
import {
	DefaultButton,
	OutlineRoundButton,
	OutlineBoxButton,
	LargeButton,
	MinimalButton,
} from "./ButtonHelpers";
import "./index.css";

const typeComponentMap = {
	large: LargeButton,
	default: DefaultButton,
	minimal: MinimalButton,
	outlineBox: OutlineBoxButton,
	outlineRound: OutlineRoundButton,
};

const colorMap = {
	red: {
		normal: "#FF7165",
		hover: "#CD3939",
	},
	blue: {
		normal: STYLES.COLORS.CERULEAN,
		hover: STYLES.COLORS.MIDNIGHT_BLUE,
	},
	white: {
		normal: "white",
		hover: "white",
		textColor: STYLES.COLORS.MIDNIGHT_BLUE,
		shadow: true,
	},
};

export default class Button extends Component {
	render() {
		const {
			text,
			className,
			icon,
			onClick,
			type,
			active,
			iconHeight,
			iconWidth,
			iconColor,
			iconPosition,
			disabled,
			colorScheme,
			visible,
		} = this.props;

		const classes = [className];
		if (active) classes.push("active");

		const ButtonComponent = typeComponentMap[type];
		const iconComp = icon && (
			<Icon
				src={icon}
				height={iconHeight}
				width={iconWidth}
				color={iconColor}
			/>
		);

		return (
			<ButtonComponent
				onClick={onClick}
				className={classes}
				icon={icon}
				iconPosition={iconPosition}
				disabled={disabled}
				$color={colorMap[colorScheme || "blue"]}
				$visible={visible}
			>
				{iconPosition === "left" && iconComp}
				{text}
				{iconPosition === "right" && iconComp}
			</ButtonComponent>
		);
	}
}

Button.propTypes = {
	text: PropTypes.node.isRequired,
	onClick: PropTypes.func,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	type: PropTypes.oneOf(Object.keys(typeComponentMap)),
	visible: PropTypes.bool,
	active: PropTypes.bool,
	icon: PropTypes.string,
	iconHeight: PropTypes.number,
	iconWidth: PropTypes.number,
	iconColor: PropTypes.string,
	iconPosition: PropTypes.oneOf(["left", "right"]),
	colorScheme: PropTypes.oneOf(["red", "blue", "white"]),
};

Button.defaultProps = {
	type: "default",
	iconHeight: 20,
	iconWidth: 20,
	iconColor: STYLES.COLORS.MIDNIGHT_BLUE,
	iconPosition: "left",
	visible: true,
};

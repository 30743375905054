import styled from "styled-components";
import Checkbox from "common/ui/Checkbox";
import Button from "common/ui/Button";
import InlineTip from "common/ui/InlineTip";
import Icon from "common/ui/Icon";
import STYLES from "common/util/constants/styles";

export const Wrapper = styled.div`
	padding: 10px ${STYLES.PADDING.BODY_HORIZONTAL};
`;

export const Title = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
	font-size: 31px;
	margin-bottom: 30px;
	text-align: center;
`;

export const Subtext = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 25px;
	margin-bottom: 30px;
	text-align: center;
`;

export const FactorInputCheckboxStyled = styled(Checkbox)`
	width: 350px;
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 16px;
	line-height: 22px;
	margin: auto;

	@media (min-width: 640px) {
		label {
			margin-bottom: 10px;
		}
	}
`;

export const FactorsConfirmationCheckboxStyled = styled(Checkbox)`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 16px;
	line-height: 22px;
	margin: auto;
	justify-content: space-evenly;
	text-align: center;

	label {
		margin: 0 0 35px 0;
	}

	@media (min-width: 640px) {
		max-width: 640px;
		label {
			width: 180px;
		}
	}

	@media (max-width: 639px) {
		max-width: 320px;
		label {
			width: 160px;
			padding: 0 10px;
		}
	}
`;

export const ButtonContainer = styled.div`
	margin: 15px auto;

	@media (min-width: 640px) {
		display: flex;
		justify-content: space-between;
		width: 515px;
	}
	@media (max-width: 639px) {
		button {
			margin: 10px auto;
		}
	}
`;

export const ButtonContinue = styled(Button)`
	width: 250px;
`;

const SomethingElseWrapper = styled.div`
	display: flex;
`;

const SomethingElseSmallText = styled.div`
	font-size: 12px;
`;

const SomethingElseText = styled.div`
	margin-left: 10px;
`;

export const SomethingElseContent = () => {
	return (
		<SomethingElseWrapper>
			<Icon src="pencil" width={16} />
			<SomethingElseText>
				<div>Something Else?</div>
				<SomethingElseSmallText>
					Add a note for an expert
				</SomethingElseSmallText>
			</SomethingElseText>
		</SomethingElseWrapper>
	);
};

export const HelperText = styled.div`
	font-size: 12px;
	max-width: 350px;
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	margin: 0 auto 25px auto;
	text-align: center;
`;

export const TipStyled = styled(InlineTip)`
	margin: 0 auto 15px auto;
`;

export const BottomText = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
	font-size: 16px;
	text-align: center;
`;

export const checkboxOptions = [
	{
		value: "minimizePremiums",
		label: "Minimizing monthly premiums",
		template: "QuestionnaireCosts",
	},
	{
		value: "minimizeCareCosts",
		label: "Minimizing costs when I receive care",
		template: "QuestionnaireHospitalCosts",
	},
	{
		value: "doctors",
		label: "Seeing the doctors I want",
		template: "ProviderSearch",
	},
	{
		value: "prescriptions",
		label: "Saving money on prescriptions",
		template: "FormularySearch",
	},
	{
		value: "additionalBenefits",
		label: "Coverage for specific benefits",
		template: "QuestionnaireAdditionalCoverage",
	},
];

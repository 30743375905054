import React from "react";
import STYLES from "common/util/constants/styles";
import {
	Triangle,
	InlineTipStyled,
	InlineTipContainer,
	ClosingX,
} from "./InlineTipStyles";

export default function InlineTip({
	visible,
	color,
	children,
	onClose = null,
	width,
	className = "",
}) {
	return (
		<InlineTipContainer $width={width} $visible={visible} className={className}>
			<Triangle $color={color} />
			<InlineTipStyled $color={color}>
				<div children={children} />
				{onClose && (
					<ClosingX
						src="x"
						onClick={onClose}
						width={20}
						height={20}
						color={STYLES.COLORS.SKY_BLUE}
					/>
				)}
			</InlineTipStyled>
		</InlineTipContainer>
	);
}

import {useUser} from "common/hooks/useUser";
import React from "react";
import {EmailField} from "./EmailField";
import {TimeLabel} from "./TimeLabel";

// Component gathers a user's email
export function GuideSignUp({onSubmit}: {onSubmit?: (email: string) => void}) {
	const {updateUser} = useUser();

	return (
		<div className="space-y-2 pb-5">
			<div className="h5">Get your personalized guide for easy sign-up</div>
			<div className="flex">
				<TimeLabel
					className="mx-auto text-gray-600 text-sm"
					time="5-7 minutes"
				/>
			</div>

			<div className="text-sm max-w-md">
				You’ll never miss a step with our expert-created guide. Get our free
				guide with your email below:
			</div>
			{/** Email Input */}
			<EmailField
				submitText="Get the guide"
				onSubmit={(email) => {
					console.log("email", email);
					updateUser({email});
					onSubmit && onSubmit(email);
				}}
			/>
		</div>
	);
}

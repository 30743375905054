import React from "react";
import {Button} from "common/ui/Button/Button";

export function PlanQuestion({onSubmit, premiumsRange}) {
	const tiers = [
		{
			name: "Plan 1",
			id: "plan1",
			features: [
				"See <b>any doctor</b> who takes Original Medicare",
				`Premiums: <b>${premiumsRange} Minimal</b> out-of-pocket costs`,
				"Pay <b>out-of-pocket</b> for standalone benefits",
			],
		},
		{
			icon1: "",
			features: [""],
		},
		{
			name: "Plan 2",
			id: "plan2",
			features: [
				"See doctors covered by <b>plan network</b>",
				"Premiums: <b>$0 - $25 Higher</b> out-of-pocket costs",
				"Benefits like dental, hearing and vision can be <b>bundled</b>",
			],
		},
	];

	return (
		<div>
			<div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-5 lg:gap-2 lg:space-y-0">
				{tiers.map((tier, i) => (
					<>
						{i !== 1 ? (
							<div
								key={tier.name}
								className="flex flex-col rounded-lg shadow-lg overflow-hidden col-span-2 border"
							>
								<div className="px-6 py-8 bg-white sm:p-8 sm:pb-6">
									<div>
										<h1
											className="inline-flex text-underline-accent text-2xl"
											id="tier-standard"
										>
											{tier.name}
										</h1>
									</div>
								</div>
								<div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8  space-y-6 sm:p-8 sm:pt-6">
									<ul className="space-y-4">
										{tier.features.map((feature) => (
											<li
												key={feature}
												className="flex items-start border-b border-gray-100 pb-3"
											>
												<p
													className="ml-3 text-base text-left text-gray-700"
													dangerouslySetInnerHTML={{__html: feature}}
												></p>
											</li>
										))}
									</ul>
									<Button
										onClick={() => {
											onSubmit(tier.id);
										}}
									>
										Choose Plan
									</Button>
								</div>
							</div>
						) : (
							<div className="flex flex-col overflow-hidden col-span-1">
								<div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
									<div></div>
								</div>
								<div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8  space-y-6 sm:p-10 sm:pt-6">
									<ul className="">
										<li className="flex flex-col items-center border-b border-gray-100 py-3">
											<img src="/images/icons/doctors.svg" alt="Doctors" />
											<div className="text-sm mt-1 font-bold">Doctors</div>
										</li>
										<li className="flex flex-col items-center border-b border-gray-100 py-5">
											<img src="/images/icons/budget.svg" alt="Budget" />
											<div className="text-sm mt-1 font-bold">Budget</div>
										</li>
										<li className="flex flex-col items-center py-6">
											<img src="/images/icons/hands.svg" alt="Benefits" />
											<div className="text-sm mt-1 font-bold">Benefits</div>
										</li>
									</ul>
								</div>
							</div>
						)}
					</>
				))}
			</div>
		</div>
	);
}

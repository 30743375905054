import {IField} from "common/ui/Form/FormRow";

export const SOCIAL_SECURITY_QUESTIONS = {
	name: "isReceivingSocialSecurityBenefits",
	label:
		"Did you — or will you — start receiving Social Security Benefits at least 4 months before your 65th birthday?",
	tooltip:
		"There are specific steps you may need to take if you began withdrawing Social Security Benefits before 65.",
	type: "",
	fields: [
		{
			name: "isReceivingSocialSecurityBenefits",
			label: "",
			type: "radio",
			options: [
				{label: "Yes", value: "yes"},
				{label: "No", value: "no"},
			],
		},
	],

	additionalFields: (
		isReceivingSocialSecurityBenefits: "yes" | "no",
		hasExistingMedicareFromDisability?: "yes" | "no"
	) => {
		const optionalQuestions: IField[] = [];
		if (isReceivingSocialSecurityBenefits === "yes") {
			optionalQuestions.push({
				name: "hasExistingMedicareFromDisability",
				label: "Do you already have Medicare due to a disability?",
				tooltip:
					"If you have Medicare because you are disabled, you might have more enrollment options for certain types of Medicare coverage.",
				type: "radio",
				options: [
					{label: "Yes", value: "yes"},
					{label: "No", value: "no"},
				],
			});
			if (hasExistingMedicareFromDisability === "yes") {
				optionalQuestions.push({
					name: "isEnrolledInMedsup",
					label:
						"Are you currently enrolled in a Medicare Supplement (Medigap) Plan?",
					tooltip:
						"Medicare Supplements help to minimize out-of-pocket costs and give you strong peace of mind.",
					type: "radio",
					options: [
						{label: "Yes", value: "yes"},
						{label: "No", value: "no"},
					],
				});
			}
		}

		return optionalQuestions;
	},
};

import React, {Component} from "react";
import sectionPageArray from "pages/QuestionFlow/pageStructure";

export class PageList extends Component {
	constructor(props) {
		super(props);

		this.pages = [];

		sectionPageArray.forEach((section, sectionIndex) => {
			section.pages.forEach((page, pageIndex) => {
				this.pages.push(page.name);
			});
		});
	}
	render() {
		return (
			<ol>
				{this.pages.map((page) => (
					<li key={page}>{page}</li>
				))}
			</ol>
		);
	}
}

import React from "react";

export const pin = ({color, height, width}) => (
	<svg
		width={width}
		height={height}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 26 39"
	>
		<path
			d="M1.975 6.742C5.685.579 13.63-1.372 19.719 2.386c6.09 3.754 8.016 11.795 4.305 17.958L13 38.65 1.975 20.344a13.2 13.2 0 010-13.602zm18.243 6.801c0-4.036-3.232-7.305-7.219-7.305S5.78 9.508 5.78 13.543c0 4.034 3.232 7.305 7.22 7.305 3.986 0 7.218-3.271 7.218-7.305z"
			fill={color}
		/>
	</svg>
);

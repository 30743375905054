import React from "react";
import {CALCULATOR_DESCRIPTION, CALCULATOR_TITLE} from "./data/constants";

export function Header() {
	return (
		<div className="flex flex-col max-w-4xl mx-auto space-y-7">
			<h1 className="text-center font-extrabold text-gray-900 text-3xl md:text-4xl mt-10">
				{CALCULATOR_TITLE}
			</h1>

			<div className="max-w-3xl mx-auto text-lg text-center leading-relaxed font-light text-accent">
				{CALCULATOR_DESCRIPTION}
			</div>
		</div>
	);
}

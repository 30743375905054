import React from "react";

export const checkMark = ({color, height, width}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 29 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2 11.133L9.682 21 27 2"
			stroke={color}
			strokeWidth="4"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

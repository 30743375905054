import React, {FC, memo, useState} from "react";

import {Checklist} from "common/ui/Checkbox/Checklist";
import {Button} from "common/ui/Button/Button";
import {SOCIAL_SECURITY_CHECKLIST_CONFIG} from "./socialSecurityChecklistConfig";
import {TimeLabel} from "../shared/TimeLabel";
import {useUser} from "common/hooks/useUser";

export interface ISocialSecurityChecklistProps {
	onSubmit: (values: string[]) => void;
}

export const SocialSecurityChecklist: FC<ISocialSecurityChecklistProps> = memo(
	({onSubmit}) => {
		const [selected, setSelected] = useState([]);
		const {user} = useUser();
		return (
			<div>
				<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
					Here's your expert sign-up checklist
				</div>
				<div className="font-bold text-base text-gray-600 mb-10 mt-3 leading-relaxed px-4">
					{user && user.email && (
						<>
							We've also sent a copy to <b>{user.email}</b> for safe-keeping.
						</>
					)}
					<div className="font-normal text-sm mt-1">
						<TimeLabel className="justify-center" time="About 5-7 mins" />
					</div>
				</div>

				<Checklist
					value={selected}
					rows={SOCIAL_SECURITY_CHECKLIST_CONFIG}
					name="social-security"
					numColumns={1}
					onChange={setSelected}
				/>

				<div className="my-10">
					<Button
						type="submit"
						onClick={() => {
							onSubmit(selected);
						}}
					>
						I've applied
					</Button>
				</div>
			</div>
		);
	}
);

const pageStructure = [
	{
		name: "Intro",
		pages: [{name: "ZipCode"}, {name: "BetterForLess"}, {name: "HowCanWeHelp"}],
	},
	// EmailAndPhone conditionally goes here. It's added by Questionflow if the ABTest assigns that variant
	{
		name: "Factors",
		pages: [{name: "Factors"}, {name: "FactorsConfirmation"}],
	},
	{
		pages: [{name: "SummaryLoading"}],
	},
	// EmailAndPhone conditionally goes here. It's added by EmailAndPhone itself if the user didn't input info previously
	{
		pages: [{name: "SummaryParent", hideHeader: true}],
	},
];

export default pageStructure;

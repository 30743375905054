import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";

const generatePremiums = () => {
	const answer = LocalStorage.getItem(STORAGE_KEYS.PAGES.QUESTIONNAIRE_COSTS);
	return answer === true
		? ["Minimize out-of-pocket exposure"]
		: ["Low or $0 monthly premiums"];
};

const generateCareCosts = () => {
	const answer = LocalStorage.getItem(STORAGE_KEYS.PAGES.QUESTIONNAIRE_HOSPITAL_COSTS);
	return answer === true ? ["Low co-pays"] : ["Low hospitalization costs"];
};

const generateProviders = () => {
	const providers = LocalStorage.getItem(STORAGE_KEYS.PAGES.PROVIDER_SEARCH);
	return providers.map((p) => `${p.firstName} ${p.lastName}`);
};

export const preferenceMap = {
	minimizePremiums: {
		title: "Premiums",
		generator: generatePremiums,
		listItems: [],
		description: "premium preference",
		icon: "pig",
		serverName: "premiums",
	},
	minimizeCareCosts: {
		title: "Care Costs",
		generator: generateCareCosts,
		listItems: [],
		description: "care costs preference",
		icon: "dollarBill",
		serverName: "careCosts",
	},
	doctors: {
		title: "Doctors",
		generator: generateProviders,
		listItems: [],
		description: "doctors",
		icon: "provider",
		serverName: "providers",
	},
};

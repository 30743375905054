import React from "react";
import {Button} from "common/ui/Button/Button";
import {CHAPTER_PHONE_NUMBER_FORMATTED} from "common/util/common";
import medsupIdCard from "./images/medsup-id-card.svg";

export function MedSupIdCardModal({
	onClose,
	onHelp,
}: {
	onClose: () => void;
	onHelp: () => void;
}) {
	return (
		<div className="flex h-full px-7 overflow-auto max-h-full font-normal text-base">
			<div className="flex flex-col h-full py-6 px-4 space-y-5">
				<div className="text-center font-bold text-xl">
					How to find your plan information
				</div>
				<div className="text-center max-w-md mx-auto">
					You can also text a photo of your card to{" "}
					{CHAPTER_PHONE_NUMBER_FORMATTED} if you’d prefer.
				</div>
				<div className="flex text-sm space-x-1">
					<div className="flex flex-col max-h-52 text-left">
						<p className="mt-auto">This is where your insurance carrier is </p>
						<p className="mt-auto">This is where your plan letter will be</p>
					</div>
					<img className="w-3/4" src={medsupIdCard} />
				</div>
				<div className="mx-auto flex items-center space-x-4">
					<Button onClick={onClose}>Go Back</Button>

					<Button
						onClick={onHelp}
						className="inline-flex items-center px-8 py-3 text-base font-bold rounded-full shadow-sm border-gray-200 border border-solid font-unset normal-case"
					>
						I need help
					</Button>
				</div>
			</div>
		</div>
	);
}

import React from "react";

export const exclamation = ({color, height, width}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 6 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.27 13.45L.82.25h4.95l-.45 13.2H1.27zm2.024 6.7c-.766 0-1.383-.225-1.85-.675-.466-.45-.7-1.025-.7-1.725 0-.7.234-1.267.7-1.7.467-.45 1.084-.675 1.85-.675.75 0 1.359.225 1.825.675.484.433.725 1 .725 1.7s-.241 1.275-.725 1.725c-.466.45-1.075.675-1.825.675z"
			fill={color}
		/>
	</svg>
);

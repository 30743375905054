import React, {Component} from "react";
import {LocalStorage} from "common/util/storage/";
import STORAGE_KEYS from "common/util/storage/keys";
import endpoints from "common/util/api/endpoints";
import {
	NewExistingPlanWrapper,
	RadioInputCentered,
} from "./NewExistingPlanHelpers";

export class Tobacco extends Component {
	constructor(props) {
		super(props);

		props.setBottomNextButtonText("Next");

		const selectedRadio =
			LocalStorage.getItem(STORAGE_KEYS.PAGES.INTRO_TOBACCO) || "";

		this.state = {selectedRadio};

		this.options = [
			{value: "true", label: "Yes"},
			{value: "false", label: "No"},
		];

		this.setParentPersistence();
	}

	componentDidUpdate() {
		const {selectedRadio} = this.state;

		this.setParentPersistence();

		LocalStorage.setItem(STORAGE_KEYS.PAGES.INTRO_TOBACCO, selectedRadio);
	}

	setParentPersistence = () => {
		const {setPersistence} = this.props;
		const {selectedRadio} = this.state;

		setPersistence({
			url: endpoints.questionSave(),
			data: {tobacco: selectedRadio},
		});
	};

	render() {
		const {selectedRadio} = this.state;

		return (
			<NewExistingPlanWrapper>
				<h4>Do you use tobacco?</h4>
				<RadioInputCentered
					value={selectedRadio}
					onChange={(selectedRadio) => this.setState({selectedRadio})}
					options={this.options}
				/>
			</NewExistingPlanWrapper>
		);
	}
}

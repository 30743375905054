import React, {Component} from "react";
import Carousel from "common/ui/Carousel";
import {
	RecentSavingsOuterWrapper,
	RecentSavingsCardWrapper,
	RecentSavingsCard,
	OuterTitle,
} from "./RecentSavingsStyled";
import {RecentSavingsType} from "./RecentSavingsTypes";

interface RecentSavingsProps {
	isMobile: boolean;
}

const people: RecentSavingsType[] = [
	{
		pctSaved: "49%",
		startPrice: "$107",
		subtitle: "in Medigap premiums",
		img: "Michael",
		personalInfo: "A.C. from IL",
	},
	{
		pctSaved: "64%",
		startPrice: "$64",
		subtitle: "in Part D drug premiums",
		img: "Maggie",
		personalInfo: "S.T. from NY",
	},
	{
		pctSaved: "74%",
		startPrice: "$780",
		subtitle: "using Chapter to sign up",
		img: "Karen",
		personalInfo: "C.M. from CA",
	},
];

export default class RecentSavings extends Component<RecentSavingsProps> {
	renderRecentSavingsCard(p: RecentSavingsType) {
		return <RecentSavingsCard key={p.img} person={p} />;
	}

	render() {
		const {isMobile} = this.props;

		return (
			<RecentSavingsOuterWrapper>
				<OuterTitle>Recent savings with Chapter</OuterTitle>
				<RecentSavingsCardWrapper>
					{isMobile ? (
						<Carousel
							items={people}
							renderItem={this.renderRecentSavingsCard}
							autoAdvance={true}
						/>
					) : (
						people.map(this.renderRecentSavingsCard)
					)}
				</RecentSavingsCardWrapper>
			</RecentSavingsOuterWrapper>
		);
	}
}

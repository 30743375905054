/*

	There are two versions of the Select element - one for mobile that's built atop HTML's <select> and one for desktop that is entirely custom.
	
	This file contains functionality common to the two components.
*/
import React, {Component} from "react";
import PropTypes from "prop-types";
import Icon from "common/ui/Icon";
import IconBackdrop from "common/ui/Icon/IconBackdrop";
import {isMobile} from "common/util/common";
import STYLES from "common/util/constants/styles";
import {SelectWrapper, ValidStateWrapper, TitleLabel} from "./SelectHelpers";
import {NativeSelect} from "./NativeSelect";
import {CustomSelect} from "./CustomSelect";

export default class Select extends Component {
	render() {
		const {validState, title, className} = this.props;

		const validComponent = validState === "valid" && (
			<IconBackdrop color={STYLES.COLORS.CONFIRM_GREEN} width={30}>
				<Icon src="checkMark" width={15} color="white" />
			</IconBackdrop>
		);

		const invalidComponent = validState === "invalid" && (
			<IconBackdrop color={STYLES.COLORS.PEACH} width={30}>
				<Icon src="exclamation" width={6} color="white" />
			</IconBackdrop>
		);

		const validStateUI = validState && (
			<ValidStateWrapper children={validComponent || invalidComponent} />
		);

		const SelectComponent = isMobile ? NativeSelect : CustomSelect;
		return (
			<div className={className}>
				{title && <TitleLabel>{title}</TitleLabel>}
				<SelectWrapper>
					<SelectComponent {...this.props} />
					{validStateUI}
				</SelectWrapper>
			</div>
		);
	}
}

Select.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	validState: PropTypes.oneOf(["none", "valid", "invalid"]),
	title: PropTypes.string,
	className: PropTypes.string,
};

Select.defaultProps = {
	options: [],
};

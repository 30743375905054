import React from "react";
import {Button} from "common/ui/Button/Button";
import medicareCard from "./images/medicare-card.svg";

export function CurrentMedicareEnrollmentHelpModal({
	onClose,
	onHelp,
}: {
	onClose: () => void;
	onHelp: () => void;
}) {
	return (
		<div className="flex h-full px-7 overflow-auto max-h-full font-normal text-base">
			<div className="flex flex-col h-full py-6 px-4 space-y-5">
				<div className="text-center font-bold text-xl">
					How to see if you have Parts A & B
				</div>
				<div className="text-center">
					Check your Medicare card (“Red White and Blue” card)
				</div>
				<div className="flex text-center text-sm space-x-3">
					<p className="mt-auto w-1/4">
						If you are signed up for Parts A & B, they will be shown here.
					</p>
					<img
						className="w-3/4"
						src={medicareCard}
						alt="Medicare Card Example"
					/>
				</div>
				<div className="mx-auto flex items-center space-x-4">
					<Button onClick={onClose}>Go Back</Button>

					<Button
						onClick={onHelp}
						className="inline-flex items-center px-8 py-3 text-base font-bold rounded-full shadow-sm border-gray-200 border border-solid font-unset normal-case"
					>
						I need help
					</Button>
				</div>
			</div>
		</div>
	);
}

import React, {useEffect, useState} from "react";
import {Form as FormikForm, useField} from "formik";
import clsx from "clsx";

import {IField} from "common/ui/Form/FormRow";

import {
	birthdayField,
	genderField,
	tobaccoField,
	hasWorkedTenYearsField,
	optFileTaxes,
} from "pages/PartBCalculator/data/form-data";

import {
	FormRow,
	IncomeRange,
	OptionalHigherPremiums,
} from "pages/PartBCalculator/FormRow";
import {FormAlertAge} from "pages/PartBCalculator/alerts/Age";

import {IEmployerData} from "../data/models";
import {getPlanDetails} from "../data/life-south";
import {IFormData} from "../service";

import {
	DEPENDENT_CODE_MAP,
	calculateAdditionalQuestions,
	optMinimizeCosts,
	zipCountyField,
	nameField,
	emailField,
} from "./fields";

const SPACING_BETWEEN_ROWS = "mb-2";

interface IFieldRowsProps {
	fields: IField[][];
	values: any;
	initialData?: any;
	hasPressedSubmit?: boolean;
}

interface IFormCalculatorProps {
	values: IFormData;
	initialData?: any;
	isValid: boolean;
	employerData: IEmployerData;
}

function FieldRows({
	fields,
	initialData,
	hasPressedSubmit,
	values,
}: IFieldRowsProps) {
	return (
		<>
			{fields.map((row, i) => (
				<div className={clsx("flex space-x-3", SPACING_BETWEEN_ROWS)} key={i}>
					{row.map((field) => (
						<div className="flex-grow pr-2" key={field.name}>
							<FormRow
								isResults={!!initialData}
								fieldData={field}
								hasPressedSubmit={hasPressedSubmit}
								values={values}
							/>
						</div>
					))}
				</div>
			))}
		</>
	);
}

export function EmployerCalculatorForm({
	values,
	isValid,
	initialData,
	employerData,
}: IFormCalculatorProps) {
	const [hasPressedSubmit, setHasPressedSubmit] = useState(false);

	const [employerPlanOptions, setEmployerPlanOptions] = useState<
		IEmployerData["plans"]
	>([]);

	useEffect(() => {
		employerData && setEmployerPlanOptions(employerData.plans);
	}, [employerData]);

	const FORM_FIELDS: IField[][] = [
		[nameField, emailField],
		[zipCountyField, birthdayField],
		[genderField],
		[tobaccoField],
		[
			{
				name: "currentEmployerPlan",
				label: "What is your current insurance plan?",
				tooltip: ``,
				type: "select",
				options: employerPlanOptions,
				required: true,
				additional: (value: any, values: IFormData) => {
					return calculateAdditionalQuestions(employerData, values);
				},
			},
		],
		[hasWorkedTenYearsField],
		[optFileTaxes],
	];

	const FORM_FIELDS_REMAINING: IField[][] = [[optMinimizeCosts]];

	const [, , {setValue: setCurrentInsurancePremium}] = useField(
		"currentInsurancePremium"
	);
	const [, , {setValue: setMaxOopIn}] = useField("maxOopIn");
	const [, , {setValue: setCurrentInsuranceDeductible}] = useField(
		"currentInsuranceDeductible"
	);

	const [oldPlan, setOldPlan] = useState<any>();

	/** Long use-effect here to set the user's current insurance information
	 *  based on their tobacco use, dependents, and plan selection
	 */
	useEffect(() => {
		// get current plan and fill in data
		const newCurrentPlanDetails = getPlanDetails(
			values.currentEmployerPlan,
			DEPENDENT_CODE_MAP[values.numDependents],
			values.tobacco === "true"
		);

		// Essential for not creating an infinite loop
		if (newCurrentPlanDetails === oldPlan) {
			return;
		}

		setOldPlan(newCurrentPlanDetails);

		// make sure to only trigger when things actually changed
		setCurrentInsurancePremium(newCurrentPlanDetails.premium);
		setMaxOopIn(newCurrentPlanDetails.maxOopIn);
		setCurrentInsuranceDeductible(newCurrentPlanDetails.annualDeductible);
	}, [
		values,
		values.tobacco,
		values.numDependents,
		values.currentEmployerPlan,

		setCurrentInsurancePremium,
		setMaxOopIn,
		setCurrentInsuranceDeductible,
		oldPlan,
	]);

	const fieldRowsProps = {
		isResults: !!initialData,
		hasPressedSubmit,
		values,
	};

	return (
		<FormikForm>
			<div className="flex flex-col justify-center w-full">
				<div className="max-w-2xl">
					<FieldRows fields={FORM_FIELDS} {...fieldRowsProps} />

					<IncomeRange
						optFileTaxes={values?.optFileTaxes}
						isResults={!!initialData}
						hasPressedSubmit={hasPressedSubmit}
					/>

					<FieldRows fields={FORM_FIELDS_REMAINING} {...fieldRowsProps} />

					{/** This is a pretty bad hack to inject lowestMedSupPrice into our new form
					 * TODO: Darsh 6/10 refactor
					 */}
					<div className={clsx("invisible h-0", SPACING_BETWEEN_ROWS)}>
						<OptionalHigherPremiums
							values={values as any}
							isResults={!!initialData}
						/>
					</div>

					{hasPressedSubmit && !isValid && (
						<div className={clsx("text-red-400", SPACING_BETWEEN_ROWS)}>
							Please fill in all the values above!
						</div>
					)}

					<div className="text-center my-8">
						<button
							className="bg-teal-700 font-bold hover:bg-teal-900 transition-colors rounded-full text-white px-20 py-4 text-lg disabled:bg-gray-300"
							type="submit"
							onClick={() => {
								setHasPressedSubmit(true);
							}}
						>
							{!!initialData ? "Resubmit" : "See Results"}
						</button>
					</div>
				</div>
			</div>
			{!initialData && <FormAlertAge values={values as any} />}
		</FormikForm>
	);
}

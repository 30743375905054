import styled from "styled-components";
import STYLES from "common/util/constants/styles";

export const DropDownStyled = styled.div`
	width: 100%;
	position: relative;

	input {
		box-shadow: 0px 0px 5px rgba(0, 31, 103, 0.25);
	}
`;

export const DropDownResultsStyled = styled.div`
	width: 100%;
	position: absolute;
	margin-top: -5px;
	border: 2px solid ${STYLES.COLORS.SKY_BLUE};
	border-top: 3px solid ${STYLES.COLORS.NICE_BLUE};
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	background-color: white;
	cursor: pointer;
	z-index: 1;
	text-align: left;
	box-shadow: 0px 0px 5px rgba(0, 31, 103, 0.25);
	max-height: 400px;
	overflow: scroll;
`;

export const DropDownRowStyled = styled.div`
	box-shadow: 0px 24px 2px -24px #0000006b;

	@media (max-width: 639px) {
		font-size: 16px;
		padding: 20px 35px;
	}

	@media (min-width: 640px) {
		padding: 20px;
	}

	&:last-child {
		border: none;
	}

	&.active,
	&:hover {
		background-color: ${STYLES.COLORS.MIDNIGHT_BLUE};
		color: white;
	}
`;

import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import {CheckboxProps} from "@material-ui/core";

interface ICheckboxProps extends CheckboxProps {
	label: string;
}
export function Checkbox({label, ...props}: ICheckboxProps) {
	const [checked, setChecked] = React.useState(true);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	return (
		<FormControlLabel
			control={
				<MaterialCheckbox
					color="primary"
					checked={checked}
					onChange={handleChange}
					{...props}
				/>
			}
			label={label}
		/>
	);
}

import React, {Component} from "react";
import {withRouter} from "react-router";
import {Switch, Route} from "react-router-dom";
import {urlMappings} from "pages/urlMappings";
import HeaderMain from "core/Headers/HeaderMain";
import {ProvideHeader} from "core/Headers/hooks/useHeader";
import Drawer from "common/ui/Drawer";
import Dialog from "common/ui/Dialog";
import {urlParse} from "common/util/urlParse";
import STORAGE_KEYS from "common/util/storage/keys";
import {LocalStorage} from "common/util/storage";
import {AppWrapper} from "./AppHelpers";
import {ThemeProvider, createMuiTheme} from "@material-ui/core/styles";

const theme = createMuiTheme({
	typography: {
		fontFamily: "objektiv-mk3, Helvetica Neue",
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				"@font-face": ["objektiv-mk3"],
			},
		},
	},
	palette: {
		primary: {
			main: "#2A837C",
		},
	},
});

// Map URL parameters to local storage keys
const paramLSMapping = {
	hcom_n: STORAGE_KEYS.FIRST_NAME,
	hcom_e: STORAGE_KEYS.HEAR_DATA.EMPLOYMENT,
	hcom_c: STORAGE_KEYS.HEAR_DATA.CREDIT_SCORE,
	hcom_a: STORAGE_KEYS.HEAR_DATA.AGE,
	utm_source: STORAGE_KEYS.UTM.SOURCE,
	utm_medium: STORAGE_KEYS.UTM.MEDIUM,
	utm_campaign: STORAGE_KEYS.UTM.CAMPAIGN,
	utm_term: STORAGE_KEYS.UTM.TERM,
	utm_content: STORAGE_KEYS.UTM.CONTENT,
	utm_audience: STORAGE_KEYS.UTM.AUDIENCE,
	utm_drip: STORAGE_KEYS.UTM.DRIP,
};

class App extends Component {
	constructor(props) {
		super(props);

		this.urlParsed = urlParse(document.location);
		this.parseQueryParams();
		this.state = {keyboardAccessability: false};
	}

	parseQueryParams = () => {
		let partner = null;

		for (const key in paramLSMapping) {
			if (this.urlParsed[key]) {
				// Make utmSource lowercase to standardize it / remove human error
				if (key === "utm_source")
					this.urlParsed[key] = this.urlParsed[key].toLowerCase();

				LocalStorage.setItem(paramLSMapping[key], this.urlParsed[key]);

				if (key.includes("hear")) partner = "hear.com";
			}
		}

		// Zipcode parsed separately because it's stored differently in LS
		if (this.urlParsed.hcom_z) {
			const basicInfo =
				LocalStorage.getItem(STORAGE_KEYS.PAGES.BASIC_INFO) || {};
			basicInfo.zipcode = this.urlParsed.hcom_z;
			LocalStorage.setItem(STORAGE_KEYS.PAGES.BASIC_INFO, basicInfo);
			partner = "hear.com";
		}

		// Set hear.com as UTM source
		if (partner === "hear.com") {
			LocalStorage.setItem(STORAGE_KEYS.UTM.SOURCE, partner);
		}

		LocalStorage.setItem(STORAGE_KEYS.PARTNER, partner);
	};

	componentDidMount() {
		window.addEventListener("keydown", this.onKeyDown);
	}

	componentWillUnmount() {
		this.removeOnKeyDown();
	}

	removeOnKeyDown() {
		window.removeEventListener("keydown", this.onKeyDown);
	}

	onKeyDown = (e) => {
		if (e.key !== "Tab") return;

		this.removeOnKeyDown();
		this.setState({keyboardAccessability: true});
	};

	renderRoute = (route) => {
		return (
			<Route
				path={route.path}
				key={route.path}
				exact={route.isExact === undefined ? true : route.isExact}
				component={route.component}
			/>
		);
	};

	render() {
		const {keyboardAccessability} = this.state;

		return (
			<AppWrapper keyboardAccessability={keyboardAccessability}>
				<ThemeProvider theme={theme}>
					<ProvideHeader>
						<HeaderMain />
						<Drawer />
						<Dialog />
						<Switch>{urlMappings.map(this.renderRoute)}</Switch>
					</ProvideHeader>
				</ThemeProvider>
			</AppWrapper>
		);
	}
}

export default withRouter(App);

import {PartBRecommendation} from "common/util/api/service";
import {
	CHAPTER_PHONE_NUMBER_FORMATTED,
	CHAPTER_PHONE_NUMBER_RAW,
	MEDICARE_ENROLLMENT_PENALTY_SITE,
} from "common/util/common";
/**
 * Calculator Results Language
 */
export interface IResultData {
	type: PartBRecommendation;
	header: string;
	headerNotEligible: string;
	secondaryTextLines?: string[];
	descriptionLines: string[];
	experimentDescriptionLines?: string[];
	ctas: {text: string; link: "SCHEDULE" | "CALL_NOW" | "APP" | string}[];
	image: string;
	plans?: IResultPlan[];
	alertMessage?: string;
	eligibilityDate?: Date;
	effectiveDate?: Date;
	priceDifferenceYearlyRaw?: number;
	isReceivingSocialSecurityBenefits?: "yes" | "no";
}

export interface IResultPlan {
	price: string;
	priceNumber?: number;
	name: string;
	header?: string;
	recommended?: boolean;
	secondaryText?: string;
	isMa?: boolean;
}

export const PART_B_RECOMMENDATION_READABLE_MAP = {
	STAY: "Stay on employer coverage",
	CONSIDER: "Consider enrolling in Medicare",
	LIKELY: "Likely enroll in Medicare",
	LIKELYGENERIC:
		"Likely enroll in Medicare (not enough data to show calculations)",
	UNKNOWN: "Unknown",
	ALREADYENROLLED:
		"Taking Social Security Benefits with disability and existing Medsup",
	ALREADYENROLLEDSTAY:
		"Taking Social Security Benefits with under 20 employees",
	ERROR: "Not Yet Eligible For Medicare",
};

const STANDARD_CTA = [
	{text: "Call us now", link: `tel:${CHAPTER_PHONE_NUMBER_RAW}`},
	{
		text: "Book an appointment",
		link: "APP",
	},
];
/**
 * TODO this should be cleaned up
 */
const zipHTML = `<span class="text-underline-accent font-bold">{zip}</span>`;
const priceHTML = `<span class="text-underline-accent font-bold">{price}</span>`;
const priceDifference = `<span class="text-underline-accent font-bold">{priceDifference}</span>`;
const currentPrice = `<span class="text-underline-accent font-bold">{currentPrice}</span>`;
const recommendedPrice = `<span class="text-underline-accent font-bold">{recommendedPrice}</span>`;
const partBAndMedigapPrice = `<span class="text-underline-accent font-bold">{partBAndMedigapPrice}</span>`;
const partBAndMedigapPriceYearly = `<span class="text-underline-accent font-bold">{partBAndMedigapPriceYearly}</span>`;
const numDependentsTextHTML = `<span class="text-underline-accent font-bold">{numDependentsText}</span>`;
const iepStartDateHTML = `<span class="text-underline-accent font-bold">{iepStartDate}</span>`;
const iepEndDateHTML = `<span class="text-underline-accent font-bold">{iepEndDate}</span>`;
const oepStartDateHTML = `<span class="text-underline-accent font-bold">{oepStartDate}</span>`;
const oepEndDateHTML = `<span class="text-underline-accent font-bold">{oepEndDate}</span>`;
const lifetimePenaltiesLink = `<a href=${MEDICARE_ENROLLMENT_PENALTY_SITE} target="_blank" class="underline">life-time penalties</a>`;
const planFinderLink = `<a href="/" class="text-underline-accent">plan finder</a>`;
const socialSecurityPhoneLink = `<a href="tel:+18007721213" class="text-underline-accent">1-800-772-1213</a>`;
const socialSecurityTTYPhoneLink = `<a href="tel:+18003250778" class="text-underline-accent">(TTY 1-800-325-0778)</a>`;

export const SOCIAL_SECURITY_SECONDARY_LINES = [
	`You will likely be automatically enrolled in Medicare Parts A and B effective on ${iepStartDateHTML}, and you will receive your Medicare card in the mail beforehand.`,
	`The Social Security Administration will automatically deduct your Part B premiums of ${priceHTML} from your Social Security benefit payments.`,
	`You don’t need to take any action to sign up for Parts A and B, but this covers only up to 80% of your medical costs. Most people in your zip code ${zipHTML} choose additional coverage.`,
	"See below for more information on what you’ll likely spend on Medicare costs and to chat with an expert.",
];

export const SOCIAL_SECURITY_SECONDARY_LINES_STAY = [
	`You should likely remain on employer coverage.`,
	`Because you take Social Security benefits, you will be automatically enrolled in Medicare Parts A and B starting on ${iepStartDateHTML}.`,
	"If you want to actively delay your Medicare coverage, there are two ways to do so:",
	"1. Follow the instructions that come with the your Medicare card and send the card back. If you keep the card, you are keeping Part B and will pay Part B premiums.",
	`2. Contact Social Security at ${socialSecurityPhoneLink} ${socialSecurityTTYPhoneLink}.`,
];

const RESULTS_DATA: {[key: string]: IResultData} = {
	STAY: {
		type: "STAY",
		header: `We recommend most people in your situation wait to sign up for Medicare Part B and remain on employer coverage.`,
		headerNotEligible: `When you are eligible, we recommend that you consider waiting to sign up for Part B`,
		secondaryTextLines: [
			`Based on your responses, your 2021 Part B premiums will likely be ${priceHTML}/month. This is more than what you currently pay, though there may be coverage differences.`,
		],
		descriptionLines: [
			"Some people in your situation will still choose to enroll in Medicare but when they do so it is usually because of deductible related reasons.",
			"Most Medicare plans have $0 deductibles. So even if premiums for Medicare are more expensive, most people find that Medicare typically provides more robust coverage with lower deductibles than their current coverage.",
			`If you have questions or would like to explore the quality of Medicare coverage, you can call one of our advocates at ${CHAPTER_PHONE_NUMBER_FORMATTED}.`,
		],
		experimentDescriptionLines: [
			`You can use our ${planFinderLink} to discover which Medicare plan type would be the best fit for you.`,
			"Most Medicare plans have $0 deductibles. So even if premiums for Medicare are more expensive, most people find that Medicare typically provides more robust coverage with lower deductibles than their current coverage.",
			`If you have questions or would like to explore the quality of Medicare coverage, you can call one of our advocates at ${CHAPTER_PHONE_NUMBER_FORMATTED}.`,
		],
		ctas: [{text: "Call us now", link: `tel:${CHAPTER_PHONE_NUMBER_RAW}`}],
		image: "person",
	},
	CONSIDER: {
		type: "CONSIDER",
		header:
			"You may want to enroll in Medicare depending on your coverage needs.",
		headerNotEligible: `When you are eligible, you may want to enroll in Medicare depending on your coverage needs.`,
		secondaryTextLines: [
			`You're within 35% of people in ${zipHTML} who might save by switching to Medicare.`,
			`Your total Medicare premiums are likely to range from ${priceHTML} to ${partBAndMedigapPrice}, depending on whether you enroll in a Medicare Advantage plan or a Medicare Supplement plan.`,
		],
		descriptionLines: [
			"Original Medicare typically covers at most 80% of your medical costs. That's why people typically choose an additional type of coverage, like a Medicare Advantage Plan or a Medicare Supplement (Medigap) Plan. For people in your situation, the type of coverage you choose will influence whether Medicare is more affordable than your current coverage. Even if Medicare is more expensive, most people find that it typically provides more robust coverage with lower deductibles than their current coverage.",
		],
		ctas: [...STANDARD_CTA],
		image: "person",
	},
	LIKELY: {
		type: "LIKELY",
		header: `Enrolling in Medicare could save you ${priceDifference} per year, compared to your current coverage.`,
		headerNotEligible:
			"When you are eligible, you likely will want to enroll in Medicare.",
		secondaryTextLines: [
			`You're within 35% of people in ${zipHTML} who likely will save by switching to Medicare.`,
			`Enrolling in Medicare could save you ${priceDifference} in premiums per year, compared to your current coverage.`,
		],
		descriptionLines: [
			`You currently pay ${currentPrice} for ${numDependentsTextHTML} per year in premiums.`,
			`Your total expected premiums when switching to Medicare would be ${partBAndMedigapPriceYearly}, saving you ${priceDifference}/year in premiums.`,
			"The premiums for the plans above represent the most affordable rates for each plan type.",
			`Original Medicare typically covers at most 80% of your medical costs. That’s why most people choose an additional type of coverage, like a Medicare Advantage Plan or a Medicare Supplement (Medigap) Plan. For people in your situation, either plan type would be more affordable than your current employer coverage. Chapter's ${planFinderLink} will ask you a series of questions that will allow us to match you to the best additional coverage options for you.`,
		],
		ctas: [...STANDARD_CTA],
		image: "trophy",
	},
	LIKELYGENERIC: {
		type: "LIKELYGENERIC",
		header: "You likely want to enroll in Medicare.",
		headerNotEligible:
			"When you are eligible, you likely will want to enroll in Medicare.",
		secondaryTextLines: [
			`If you do not have health insurance or have individual coverage (e.g., Obamacare plan),  and you are preparing to turn 65, you should consider transitioning to Medicare to maximize your coverage options, save on premiums, and avoid the risk of late sign-up penalties.`,
		],
		descriptionLines: [
			`Your total expected premiums after signing up for Original Medicare (Parts A & B) and a supplemental plan are expected to be ${partBAndMedigapPrice} per month.`,
		],
		ctas: [...STANDARD_CTA],
		image: "person",
	},
	UNKNOWN: {
		type: "UNKNOWN",
		header: `You could save by switching to Medicare if you pay more than ${partBAndMedigapPrice} per month for your employer based coverage`,
		headerNotEligible: `When you turn 65, you should consider switching to Medicare if you pay more than ${partBAndMedigapPrice} per month for your employer coverage`,
		descriptionLines: [
			"Note: this amount is based on what you would you pay for yourself, not including a spouse/dependents. This amount is calculated by:",
			`Your Medicare Part B Premium ${priceHTML} + your supplemental plan premium ${recommendedPrice}.`,
			"You should consider signing up for Medicare if you currently pay more than this amount each month.",
			"Original Medicare typically covers at most 80% of your medical costs. That’s why most people choose an additional type of coverage, like a Medicare Advantage Plan or a Medicare Supplement (Medigap) Plan. Even if Medicare is more expensive, most people find that it typically provides more robust coverage with lower deductibles than their current coverage.",
		],
		ctas: [...STANDARD_CTA],
		image: "person",
	},
	ALREADYENROLLED: {
		type: "ALREADYENROLLED",
		header: `You could save ${priceDifference} per year by switching to the strongest available Medicare Supplement plan.`,
		headerNotEligible:
			"Comparing new Medicare Supplement plans is likely to help you save substantially, get stronger coverage, or both.",
		secondaryTextLines: [
			`An Open Enrollment Period is a 6-month window beginning ${oepStartDateHTML} during which you can choose or change a Medicare Supplement Plan without underwriting.`,
			`Because you cannot be charged a higher rate because of your health, many people save money during this period.`,
		],
		descriptionLines: [],
		ctas: [...STANDARD_CTA],
		image: "person",
	},
	ALREADYENROLLEDSTAY: {
		type: "ALREADYENROLLEDSTAY",
		header: `You will automatically be enrolled in Medicare when you turn 65`,
		headerNotEligible:
			"You will automatically be enrolled in Medicare when you turn 65",
		secondaryTextLines: [
			`Because you work at a company with <20 employees and you began withdrawing Social Security Benefits before your 65th birthday, you should be automatically signed up for Medicare when you turn 65.`,
			`It is important that you confirm you will be signed up for Medicare, because you may be subject to life time penalties if you do not sign up by ${oepEndDateHTML}.`,
			`Even if you are given the option to opt-out of Medicare, you will likely want to keep Medicare. Medicare is required to be primary insurance for people employed at organizations with fewer than 20 employees.`,
			`Your Medicare Part B premiums of ${priceHTML} should be automatically deducted from your Social Security payments.`,
		],
		descriptionLines: [],
		ctas: [...STANDARD_CTA],
		image: "person",
	},
};

export default RESULTS_DATA;

export const SPOUSE_DEPENDENT_WARNING =
	"If your spouse/dependents are currently covered by your health coverage, they may not be covered by your Medicare plan.";

export const descriptionLinesEmployees20 = [
	`Because you work at a company with <20 employees, Medicare is considered "primary" coverage.`,
	`As such, you may be subject to ${lifetimePenaltiesLink} if you sign up for Medicare later than your Initial Enrollment Period, which ends on ${iepEndDateHTML}, even though you still have employer-sponsored insurance.`,
	"Our licensed advocates can help answer questions about your particular situation, if you would like.",
];

export const descriptionLinesEmployees20IEPExpired = [
	`Because you work at a company with <20 employees, Medicare is considered "primary" coverage.`,
	`As such, you may be subject to ${lifetimePenaltiesLink} if you sign up for Medicare later than your Initial Enrollment Period, which ends on ${iepEndDateHTML}, even though you still have employer-sponsored insurance.`,
	"While your Initial Enrollment Period has ended, you may be able to minimize potential penalties by signing up sooner instead of later.",
	`If we can help with any questions you have, you can speak with one of our licensed advocates, free of charge at ${CHAPTER_PHONE_NUMBER_FORMATTED}`,
];

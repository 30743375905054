import React from "react";

import {Button} from "common/ui/Button/Button";
import Explainer from "../shared/Explainer";
import {MA_DATA, MS_DATA} from "./constants";
import {Reminders} from "../shared/Reminders";
import {useUser} from "common/hooks/useUser";
import {CHAPTER_PHONE_NUMBER_RAW} from "common/util/common";

export function SwitcherIntro({onSubmit}: {onSubmit: () => void}) {
	const {user, updateUser} = useUser();
	const data =
		user && (user.currentAdditionalCoverageType === "ms" ? MS_DATA : MA_DATA);

	return (
		<>
			<Explainer
				title={data?.title}
				descriptionLines={data?.description || []}
				imgSrc="/images/illustrations/confirmed-with-woman.svg"
			/>

			{data?.bullets && (
				<div className="prose text-left ml-52">
					<ul>
						{data.bullets.map((bullet) => (
							<li key={bullet} dangerouslySetInnerHTML={{__html: bullet}}></li>
						))}
					</ul>
				</div>
			)}

			{user?.currentAdditionalCoverageType === "ms" ? (
				<Button
					onClick={() => {
						onSubmit();
					}}
				>
					Continue
				</Button>
			) : (
				<>
					<div className="my-4">
						<Button
							buttonType={"secondary"}
							onClick={() => {
								window.open(`tel:+${CHAPTER_PHONE_NUMBER_RAW}`);
							}}
						>
							Questions? Call us
						</Button>
					</div>

					<div className="my-10">
						<Reminders
							actionText="Send me a reminder"
							onSubmit={(email) => {
								updateUser({email});
							}}
						/>
					</div>
				</>
			)}
		</>
	);
}

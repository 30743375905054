import React from "react";
import Scheduler from "modals/Scheduler";

const EXPERT_GUIDANCE_GENERIC_LINES = [
	"Confirm your current doctors take Original Medicare (most do!)",
	"Decide if you want drug coverage, and benefits like hearing, dental and vision.",
];

export function ScheduleCard({
	expertGuidanceText,
}: {
	expertGuidanceText?: string[];
}) {
	return (
		<div className="flex items-start">
			<div className="flex flex-col items-start w-1/2 text-left my-5">
				<div className="flex text-lg text-underline-accent my-4 items-start">
					What we'll discuss
				</div>
				<div className="space-y-5 divide divide-gray-200">
					<div>
						<b>Review your plan options</b> and answer any questions you may
						have.
					</div>
					<div className="space-y-3">
						<b>Expert Guidance</b>
						{(expertGuidanceText || EXPERT_GUIDANCE_GENERIC_LINES).map(
							(line) => (
								<div key={line}>{line}</div>
							)
						)}
					</div>
					<div>
						<b>Streamlined enrollment:</b> We can help you sign up for your plan
						choice.
					</div>
				</div>
			</div>
			<div className="h-110 w-1/2">
				<Scheduler />
			</div>
		</div>
	);
}

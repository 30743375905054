import React, {Component} from "react";
import PropTypes from "prop-types";
import {DropDownResultsStyled, DropDownRowStyled} from "./DropDownHelpers";

const DropDownRow = ({row, isActive, renderRow, onSelect}) => {
	return (
		<DropDownRowStyled
			className={isActive && "active"}
			children={renderRow(row)}
			onClick={() => onSelect(row)}
		/>
	);
};

export default class DropDownList extends Component {
	render() {
		const {
			elements,
			highlightedRowIndex,
			renderRow,
			onSelect,
			showNoResults,
		} = this.props;

		if (!elements.length && !showNoResults) return null;

		return (
			<DropDownResultsStyled>
				{elements.map((row, rowIndex) => (
					<DropDownRow
						isActive={highlightedRowIndex === rowIndex}
						row={row}
						key={row.key || row.value || row}
						renderRow={renderRow}
						onSelect={onSelect}
					/>
				))}
				{!elements.length && showNoResults && (
					<DropDownRowStyled children="No results" />
				)}
			</DropDownResultsStyled>
		);
	}
}

DropDownList.propTypes = {
	elements: PropTypes.array.isRequired,
	onSelect: PropTypes.func.isRequired,
	renderRow: PropTypes.func.isRequired,
	highlightedRowIndex: PropTypes.number,
	className: PropTypes.string,
	showNoResults: PropTypes.bool,
};

DropDownList.defaultProps = {
	className: "",
};

/*
	Event Tracker sends behavioral data to:
	* FullStory (when active, given environmental settings)
	* GoogleTagManager
	* Our /customer/event endpoint ––> CustomerIO
*/

import * as FullStory from "@fullstory/browser";
import axios from "common/util/api/axios";
import endpoints from "common/util/api/endpoints";
import {LocalStorage, SessionStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";

export default class eventTracker {
	static EVENTS = {
		PAGE_VIEW: "PAGE_VIEW",
		MODAL_VIEW: "MODAL_VIEW",
		CLICK_BUTTON: "CLICK_BUTTON",
		ERROR: "ERROR",
		RECORD_EMAIL: "RECORD_EMAIL",
		RECORD_ID: "RECORD_ID",
		SAW_VENN_DIAGRAM: "SAW_VENN_DIAGRAM",
		BOOK_APPOINTMENT: "BOOK_APPOINTMENT",
		REQUEST_ARTIFACT: "REQUEST_ARTIFACT",
		CLICK_SOCIAL: "CLICK_SOCIAL",
	};

	static async log({name, data = null}) {
		if (process.env.REACT_APP_FULLSTORY_DEV_MODE === "false")
			FullStory.event(name, data);

		if (window.dataLayer) window.dataLayer.push({event: name, ...data});

		const id =
			LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID) ||
			SessionStorage.getItem(STORAGE_KEYS.CUSTOMER_ID);

		axios
			.post(endpoints.customerEvent(), {
				id,
				eventName: name,
				eventData: data,
			})
			.catch(console.error);
	}
}

import clsx from "clsx";
import {format} from "date-fns";
import React, {useEffect, useState} from "react";

import {Loader} from "common/ui/Loader/Loader";
import {SpouseAndDependents} from "pages/PartBResults/SpouseAndDependents";
import Alert from "common/ui/Alert";
import {
	CHAPTER_PHONE_NUMBER_FORMATTED,
	CHAPTER_PHONE_NUMBER_RAW,
} from "common/util/common";

import {employeeCalculatorService} from "../service";

import {Charts} from "./Charts";
import {Plans} from "./Plans";
import {Actions} from "./Actions";
import {useParams} from "react-router";
import {useHeader} from "core/Headers/hooks/useHeader";
import {IEmployerCalculatorRouteParams} from "../Router";
import {EMPLOYER_DATA_MAP} from "../data/employers";

export function EmployeeCalculatorResults() {
	// Fetch results from calculator service and use that to setState. Note,
	// Realtime changes in the service will not be captured here as we're only
	// setting state once. Calculations can be manually updated with
	// 'updateCalculations' below
	const [calculatedResults, setCalculatedResults] = useState<any>(
		employeeCalculatorService.getResults() || {}
	);

	useEffect(() => {
		async function fetchCalcs() {
			const results = await employeeCalculatorService.fetchCalculations();
			setCalculatedResults(results);
		}
		fetchCalcs();
	}, []);

	// Fetch employer and update header logo and phone number
	const {employerId} = useParams<IEmployerCalculatorRouteParams>();
	const {setCoBrandImageUrl, setAltPhoneNumber} = useHeader();
	useEffect(() => {
		const newEmployerData = EMPLOYER_DATA_MAP[employerId];
		setCoBrandImageUrl(newEmployerData?.imageUrl);
		setAltPhoneNumber(newEmployerData?.phoneNumberRaw);
	}, [employerId, setCoBrandImageUrl]);

	const {header, headerNotEligible, image, alertMessage, ctas} =
		calculatedResults;

	const isNotEligible = !!calculatedResults.eligibilityDate;

	return (
		<div className="text-center space-y-5 text-black">
			{/** Header */}
			<div
				className="text-4xl py-3 leading-normal font-light max-w-3xl mx-auto"
				dangerouslySetInnerHTML={{
					__html: isNotEligible ? headerNotEligible : header,
				}}
			/>
			{/** Image */}
			<div className="flex justify-center py-4">
				<img
					src={`/images/part-b/${image || "person"}.svg`}
					alt="We've calculated your results!"
					height={260}
					width={image === "trophy" ? 200 : 175}
				/>
			</div>

			{(calculatedResults && calculatedResults.ctas && (
				<>
					<div
						className={clsx(
							"font-light text-2xl max-w-2xl text-center mx-auto  leading-relaxed",
							(isNotEligible || calculatedResults.secondaryTextLines?.length) &&
								"pb-2"
						)}
					>
						{(calculatedResults.totalAnnualExposureSavings && (
							<>
								Your annual savings potential is{" "}
								<span className="text-underline-accent font-bold">
									{calculatedResults.totalAnnualExposureSavingsReadable}
								</span>
								<br />
								<span className="text-sm">
									This incorporates premiums and maximum out-of-pocket exposure.
								</span>
							</>
						)) || <></>}
						{/** Adjust content based on whether or not the user is medicare-eligible */}
						{(isNotEligible && calculatedResults.eligibilityDate && (
							<div className="pb-2">
								You can first sign up for Medicare starting in{" "}
								<span className="text-underline-accent font-bold">
									{format(calculatedResults.eligibilityDate!, "MMMM yyyy")}
								</span>{" "}
								, and your coverage can go into effect on{" "}
								<span className="text-underline-accent font-bold">
									{format(calculatedResults.effectiveDate!, "MMMM d, yyyy")}
								</span>
								.{" "}
							</div>
						)) || <></>}
					</div>

					{/** CTAs */}
					<div className="mb-2 pb-1 md:space-x-5 text-center px-5 md:px-0 space-y-2 md:space-y-0">
						<Actions ctas={ctas} />
					</div>

					<div className="max-w-2xl mx-auto">
						{/** Plans Overview */}

						<Plans
							plans={calculatedResults.plans}
							recommendedPlan={calculatedResults.recommendedPlan}
						/>

						{/** total charts see how your plan compares */}
						<div className="text-2xl my-10 ">
							{" "}
							See how Medicare compares to your current plan
						</div>

						<div className="space-y-5">
							<Charts calculatedResults={calculatedResults} />
						</div>

						<div className="flex flex-col md:flex-row space-x-3"></div>

						<div className="space-y-5 mt-5 text-lg text-left leading-relaxed ">
							{alertMessage && (
								<div className="space-y-5">
									<SpouseAndDependents />
									<Alert
										className="text-sm px-4 py-3 shadow border border-gray-100"
										type="info"
									>
										<div className="w-full">
											<b>Please note:</b> {alertMessage} Call or text{" "}
											<a href={`tel:${CHAPTER_PHONE_NUMBER_RAW}`}>
												<b>{CHAPTER_PHONE_NUMBER_FORMATTED}</b>
											</a>{" "}
											for help.
										</div>
									</Alert>
								</div>
							)}
						</div>
					</div>
				</>
			)) || (
				<div className="mx-auto w-full">
					<Loader className="w-10 mx-auto" />
				</div>
			)}
		</div>
	);
}

/**
 * Enrollment Date Form Field Data
 */

import {IPage} from "common/ui/Form/FormRow";

export interface IFormData {
	premiumAffordability: "true" | "false" | "maybe";
}

export const premiumAffordabilityField = {
	autoAdvance: true,
	fields: [
		{
			name: "premiumAffordability",
			type: "toggleButton",
			options: [
				{label: "Yes", value: "true"},
				{label: "No", value: "false"},
				{label: "Maybe", value: "maybe"},
			],
			required: true,
		},
	],
};

export const FORM_FIELDS: IPage[] = [
	{} as any,
	premiumAffordabilityField,
	{} as any,
];

import React from "react";
import TextField from "@material-ui/core/TextField";
import MaterialAutocomplete from "@material-ui/lab/Autocomplete";

interface IOption {
	value: string;
	label: string;
}

/**
 *
 * Simple Wrapper around Material UI Autocomplete
 * Needs to be better at letting you pick from all
 * options after you enter one in.
 */
export function Autocomplete({
	options,
	label,
	...props
}: {
	options: IOption[];
	label: string;
	value?: string;
	onChange?: (e: React.ChangeEvent<{}>) => void;
	name?: string;
	error?: string;
}) {
	return (
		<MaterialAutocomplete
			options={options}
			classes={{}}
			autoHighlight
			blurOnSelect
			getOptionLabel={(option) => option.label}
			filterSelectedOptions
			renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					variant="outlined"
					inputProps={{
						...params.inputProps,
						autoComplete: "new-password", // disable autocomplete and autofill
					}}
				/>
			)}
			{...props}
			value={options.find((o) => o.label === props.value) || null}
			onChange={(e, newValue) => {
				props.onChange &&
					props.onChange({
						...e,
						target: {
							...e.target,
							value: (newValue && newValue.label) || newValue,
							name: props.name!,
						},
					} as any);
			}}
		/>
	);
}

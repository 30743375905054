import React from "react";
import QuestionnaireWrapper from "./QuestionnaireTemplate";
import STORAGE_KEYS from "common/util/storage/keys";

const description = (
	<>
		Some Medicare plans require you to obtain a referral from your
		<br />
		primary care doctor before seeing a specialist. <br />
		<br />
		Do you mind needing a referral to see a specialist?
	</>
);

const config = {
	options: [
		{value: "true", label: "I'm fine getting a referral"},
		{value: "false", label: "I want to avoid referrals"},
	],
	localStorageKey: STORAGE_KEYS.PAGES.QUESTIONNAIRE_REFERRALS,
	questionKey: "referrals",
	icon: "hospital",
	title: "Referrals",
	description,
	drawer: {
		topText: (
			<>
				Some plans that require referrals have less expensive premiums compared
				to other plans.
				<br />
				<br />
				Your answer to this question will help us to narrow the list of coverage
				options for you. We will prioritize the most cost-effective options for
				you based on the preferences you share.
			</>
		),
	},
};

export class QuestionnaireReferrals extends QuestionnaireWrapper {
	componentDidMount() {
		this.setConfig(config);
	}
}

/**
 * Enrollment Date Form Field Data
 */

import {CHAPTER_PHONE_NUMBER_FORMATTED} from "common/util/common";
import {
	TypeSpecialExceptions,
	TypeCurrentInsuranceCoverageType,
	TypeRetirementStatus,
} from "common/util/api/service";

export interface IFormData {
	dob: string;
	retirementStatus: TypeRetirementStatus;
	currentInsuranceCoverageType: TypeCurrentInsuranceCoverageType;
	specialExceptions: TypeSpecialExceptions;
	retirementDate: string;
	expectedRetirementDate: string;
	hasMoreThanTwentyEmployees: "true" | "false";
}

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export const birthdayField = {
	autoAdvance: false,
	fields: [
		{
			name: "dob",
			label: "When is your birthday?",
			type: "birthdate",
			placeholder: "MM/DD/YYYY",
			required: true,
		},
	],
};

export const retirementStatusField = {
	autoAdvance: true,
	fields: [
		{
			name: "retirementStatus",
			label: "Have you retired recently, or are you planning to retire soon?",
			type: "toggleButton",
			options: [
				{label: "I recently retired", value: "retired"},
				{label: "I will retire soon", value: "will-retire"},
				{label: "No", value: "no"},
			],
			required: true,
			additional: (retirementStatus: TypeRetirementStatus) => {
				const retirementDate = {
					name: "retirementDate",
					label: "When did you retire?",
					type: "monthyear",
					containerClassName: "flex-col",
				};
				const expectedRetirementDate = {
					name: "expectedRetirementDate",
					label: "When do you plan on retiring?",
					type: "monthyear",
					containerClassName: "flex-col",
				};

				// add another question here for Did you have health coverage through an employer:

				if (
					retirementStatus === "retired" ||
					retirementStatus === "will-retire"
				) {
					const retirementDateField =
						retirementStatus === "retired"
							? retirementDate
							: expectedRetirementDate;

					return [retirementDateField];
				}
			},
		},
	],
};

export const CURRENT_COVERAGE_VALUE_MAP = {
	"my-employer": "My employer",
	"spouse-employer": "My spouse's employer",
	obamacare: "Obamacare Marketplace",
	cobra: "COBRA",
	medicaid: "Medicaid",
	tricare: "Tricare",
	union: "A Union",
	"no-coverage": "I don't have coverage",
};

export const currentInsuranceCoverageTypeField = {
	autoAdvance: true,
	fields: [
		{
			name: "currentInsuranceCoverageType",
			labelFcn: (values: IFormData) => {
				if (values.retirementStatus === "retired") {
					return "I had coverage through:";
				}
				return "I currently have coverage through:";
			},
			type: "toggleButton",
			options: Object.keys(CURRENT_COVERAGE_VALUE_MAP).map((key) => ({
				label: CURRENT_COVERAGE_VALUE_MAP[key],
				value: key,
			})),
			additional: (
				currentInsuranceCoverageType: TypeCurrentInsuranceCoverageType
			) => {
				if (
					currentInsuranceCoverageType === "my-employer" ||
					currentInsuranceCoverageType === "spouse-employer"
				) {
					return [
						{
							name: "hasMoreThanTwentyEmployees",
							label: `Does ${
								currentInsuranceCoverageType === "my-employer"
									? "your"
									: "your spouse's"
							} employer have more than 20 employees?`,
							type: "radio",
							options: [
								{label: "Yes", value: "true"},
								{label: "No", value: "false"},
							],
						},
					];
				}
				if (currentInsuranceCoverageType === "tricare") {
					return [
						{
							name: "label",
							label: `If you have Tricare, please call us at ${CHAPTER_PHONE_NUMBER_FORMATTED} to discuss your coverage options.`,
							type: "label",
						},
						{
							name: "call-us",
							label: "Call Us",
							type: "submit",
							action: "CALL_US",
						},
					];
				}
			},
			required: true,
		},
	],
};

export const exceptionsField = {
	autoAdvance: true,
	fields: [
		{
			name: "specialExceptions",
			label: "Do any of these apply to you?",
			tooltip:
				"Most people have Medicare payroll taxes automatically deducted from each paycheck.",
			type: "toggleButton",
			options: [
				{label: "I'm disabled", value: "disabled"},
				{label: "I have an HSA", value: "hsa"},
				{label: "I'm receiving Social Security", value: "ss"},
				{label: "I'm receiving Railroad Retirement", value: "rr"},
				{label: "None", value: "none"},
			],
			// Pushes different screens depending on answer
			required: true,
		},
	],
};

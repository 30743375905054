import React, {useState, useEffect, useContext, createContext} from "react";
import {ICustomer, ICustomerQuestion, apiService} from "../util/api/service";

import {LocalStorage} from "../util/storage";
import STORAGE_KEYS from "../util/storage/keys";
import {parseName} from "../util/format";

/**
 * The user context returned from the {@link useUser} hook.
 * @category Client
 */
export interface IUserContext {
	user: ICustomer | null;
	error?: Error;
	updateUser: (updates: Partial<ICustomer>) => Promise<void>;
	saveUser: (updates: Partial<ICustomer>) => Promise<ICustomer["id"] | void>;
}

function transformDob(dob: string) {
	const birthdate = dob;
	const birthmonth = birthdate.substring(0, 2);
	const birthyear = birthdate.slice(-4);
	const transformedDob =
		birthdate && `${birthyear}-${birthmonth}-${birthdate.substring(3, 5)}`;

	return {
		birthdate,
		birthmonth,
		birthyear,
		dob: transformedDob,
	};
}

function parseAllName(name: string) {
	const parsedName = name && parseName(name);

	const firstName =
		(parsedName && parsedName.firstName) ||
		LocalStorage.getItem(STORAGE_KEYS.FIRST_NAME) ||
		"";

	const nameFields = {firstName} as any;
	if (parsedName) {
		if (parsedName.lastName) {
			nameFields.lastName = parsedName.lastName;
		}
		if (parsedName.middleName) {
			nameFields.middleName = parsedName.middleName;
		}
	}
	return nameFields;
}

export function convertUserForServer(results: any) {
	let updatedCustomer: any = {...results};
	// catch age
	if (results.dob) {
		const data = transformDob;
		updatedCustomer = {...updatedCustomer, ...data};
		const birthdayFields = transformDob(results.dob);
		updatedCustomer = {...updatedCustomer, ...birthdayFields};
	}

	// Are they willing to pay extra for additional coverage
	// Note: this assumes maybe's are yes's
	if (results.premiumAffordability) {
		updatedCustomer.premiumAffordability =
			results.premiumAffordability !== "no";
	}

	// Parse out name
	if (results.name) {
		const nameFields = parseAllName(results.name);
		updatedCustomer = {...updatedCustomer, ...nameFields};
	}

	if (results.zip) {
		updatedCustomer.zip = results.zip;
	}

	if (results.gender) {
		updatedCustomer.gender =
			results.gender === "preferNotToAnswer" ? null : results.gender;
	}

	if (results.tobacco) {
		updatedCustomer.tobacco = results.tobacco === "true";
	}

	const customerQuestion: Partial<ICustomerQuestion> = {
		premiumAffordability: results.premiumAffordability,
	};

	if (results.currentInsurancePremium) {
		updatedCustomer.currentInsurancePremium = parseInt(
			results.currentInsurancePremium,
			10
		);
	}
	if (results.currentInsuranceDeductible) {
		updatedCustomer.currentInsuranceDeductible = parseInt(
			results.currentInsuranceDeductible,
			10
		);
	}
	if (results.hasMoreThanTwentyEmployees) {
		updatedCustomer.hasMoreThanTwentyEmployees =
			results.hasMoreThanTwentyEmployees !== "false";
	}

	if (results.lowestPlanN) {
	}

	if (results.email) {
		updatedCustomer.email = results.email;
	}

	const optionalBooleanFields = [
		"isReceivingSocialSecurityBenefits",
		"hasExistingMedicareFromDisability",
		"isEnrolledInMedsup",
	];

	optionalBooleanFields.forEach((field) => {
		// Check if undefined or null
		if (results[field]) {
			updatedCustomer[field] = results[field] === "yes";
		}
	});

	return {customer: updatedCustomer, customerQuestion};
}

const missingUserProvider = "You forgot to wrap the app in <UserProvider>";

const UserContext = createContext<IUserContext>({
	get user(): never {
		throw new Error(missingUserProvider);
	},
	get error(): never {
		throw new Error(missingUserProvider);
	},
	updateUser: (): never => {
		throw new Error(missingUserProvider);
	},
	saveUser: (): never => {
		throw new Error(missingUserProvider);
	},
});

export type UseUser = () => IUserContext;

export const useUser: UseUser = () => useContext<IUserContext>(UserContext);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideUser({children}) {
	const user = useProvideUser();
	return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

function fetchUser(): Partial<ICustomer> {
	const id = LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID);

	const userString = localStorage.getItem("currentUser");
	try {
		if (!userString) {
			throw new Error("No user in storage");
		}
		console.log("found user", userString);
		return JSON.parse(userString);
	} catch {
		return {id};
	}
}

function storeUserInLocalStorage(user: Partial<ICustomer>) {
	localStorage.setItem("currentUser", JSON.stringify({user}));
}

// Provider hook that creates auth object and handles state
export function useProvideUser() {
	const [user, setUser] = useState<ICustomer | null>(null);

	const updateUser = (updates: Partial<ICustomer>): Promise<void> => {
		return new Promise((resolve, reject) => {
			try {
				const updatedUser = {...user, ...updates};
				console.log("updateduser", updatedUser);
				setUser(updatedUser as ICustomer);

				storeUserInLocalStorage(updatedUser);
				saveUser(updates);
				resolve();
			} catch (err) {
				reject(err);
			}
		});
	};

	const saveUser = (
		updates: Partial<ICustomer>
	): Promise<ICustomer["id"] | void> => {
		if (user) {
			const {customer, customerQuestion} = convertUserForServer(updates);
			return apiService.updateCustomerAndCustomerQuestion(
				customer,
				customerQuestion
			);
		}
		return Promise.resolve();
	};

	// Subscribe to user on mount
	useEffect(() => {
		setUser(fetchUser() as ICustomer);
		return () => {
			// future users shouldn't forget to unmount
		};
	}, []);

	// Return the user object and update methods
	return {
		user,
		updateUser,
		saveUser,
	};
}

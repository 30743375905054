import React from "react";
import clsx from "clsx";

import {BasicMedicare, SupplementalPlan} from "pages/PartBResults/Plans";
import {IResultPlan} from "pages/PartBResults/results";

export function Plans({
	plans,
	recommendedPlan,
}: {
	plans: IResultPlan[];
	recommendedPlan: IResultPlan;
}) {
	return (
		<div className={clsx("sm:grid")}>
			<div className="flex flex-col space-y-3">
				<div className="shadow rounded py-5 w-full md:w-3/4 mx-auto">
					<BasicMedicare plan={plans[0]} />
				</div>
				<div className="text-accent text-2xl font-bold leading-none">+</div>
				<div className="shadow rounded py-5 w-full md:w-3/4 mx-auto">
					<SupplementalPlan plan={recommendedPlan || plans[1]} />
				</div>
			</div>
		</div>
	);
}

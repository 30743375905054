import styled from "styled-components";

export const FormStyled = styled.form`
	margin: auto;

	@media (min-width: 640px) {
		.form-row {
			display: flex;
			justify-content: space-evenly;
			margin-bottom: 30px;

			> * {
				flex: 1;
				margin: 0 15px;
			}
		}

		label {
			width: 100%;
		}
	}

	@media (max-width: 639px) {
		.form-row {
			> * {
				margin: 10px 0;
			}
		}

		label {
			margin-bottom: 25px;
		}
	}
`;

import styled from "styled-components";
import STYLES from "common/util/constants/styles";

const disabledStyles = `
	cursor: not-allowed;
	opacity: 0.5;
`;

const CommonButtonStyles = styled.button`
	height: 60px;
	display: flex;
	font-family: inherit;
	font-size: 20px;
	color: inherit;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: none;
	user-select: none;
	transition: 0.3s;
	font-weight: ${STYLES.FONT_WEIGHT.MEDIUM};

	${({disabled}) => disabled && disabledStyles}
	${({$visible}) => !$visible && "opacity: 0;"}
	
	svg,
	img {
		a:1;
		${({icon, iconPosition}) => {
			if (icon)
				return iconPosition === "left"
					? "margin-right: 15px;"
					: "margin-left: 15px;";
		}}

`;

export const DefaultButton = styled(CommonButtonStyles)`
	background-color: ${({$color}) => $color.normal};
	display: flex;
	padding: 0 30px;
	border-radius: 100px;
	min-width: 175px;
	color: white;

	${({$color}) => $color.textColor && `color: ${$color.textColor};`}
	${({$color}) =>
		$color.shadow && "box-shadow: 0px 0px 5px rgba(0, 31, 103, 0.25);"}

	:hover {
		background-color: ${({$color}) => $color.hover};
	}
`;

export const OutlineRoundButton = styled(CommonButtonStyles)`
	background-color: white;
	color: ${STYLES.COLORS.NICE_BLUE};
	border: 2px solid ${STYLES.COLORS.NICE_BLUE};
	display: flex;
	padding: 0 30px;
	border-radius: 100px;
	min-width: 175px;

	:hover {
		background-color: ${STYLES.COLORS.NICE_BLUE};
		color: white;
	}
`;

export const OutlineBoxButton = styled(CommonButtonStyles)`
	display: flex;
	padding: 0 10px;
	border: 2px solid ${STYLES.COLORS.SKY_BLUE};
	border-radius: 6px;
	min-width: 175px;
	background-color: white;

	:hover {
		background-color: ${STYLES.COLORS.MIDNIGHT_BLUE};
		border: none;
		color: white;
	}

	&:hover svg > .colorable {
		stroke: white;
	}
`;

export const LargeButton = styled(CommonButtonStyles)`
	border: 1px solid ${STYLES.COLORS.SKY_BLUE};
	width: 266px;
	height: 140px;
	border-radius: 6px;
	background-color: white;

	&.active {
		background-color: ${STYLES.COLORS.MIDNIGHT_BLUE};
		font-weight: bold;
		color: white;
	}

	:hover {
		background-color: ${STYLES.COLORS.LIGHT_GREY};
		color: ${STYLES.COLORS.MIDNIGHT_BLUE};
	}

	:hover.active {
		background-color: ${STYLES.COLORS.MIDNIGHT_BLUE};
		opacity: 0.8;
		color: white;
	}

	:active {
		background-color: ${STYLES.COLORS.MIDNIGHT_BLUE};
		font-weight: bold;
		color: white;
	}
`;

export const MinimalButton = styled(CommonButtonStyles)`
	background-color: transparent;
	border-radius: 30px;
	padding: 0 30px;
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 16px;

	:hover {
		background-color: #dffbff;
		color: ${STYLES.COLORS.MIDNIGHT_BLUE};
	}

	:active {
		background-color: #d0f9ff;
	}

	&:active svg > .colorable {
		stroke: ${STYLES.COLORS.MIDNIGHT_BLUE};
	}

	&.prev,
	&.next {
		font-size: 20px;
	}
`;

import {CHAPTER_PHONE_NUMBER_FORMATTED} from "common/util/common";
export interface IAction {
	title: string;
	type:
		| "REMINDERS_GEP"
		| "REMINDERS_IEP"
		| "QUESTIONS"
		| "SIGN_UP"
		| "CALCULATOR"
		| "SAVE_DATES";
}

export interface IEnrollmentResultWindow {
	titleLines: string[];
	descriptionAreas: {title: string; lines: string[]}[];
	nextStepLines: string[];
	nextStepActions: IAction[];
}

const REMINDERS_GEP_ACTION: IAction = {
	title: "Send Reminders",
	type: "REMINDERS_GEP",
};
const REMINDERS_IEP_ACTION: IAction = {
	title: "Send Reminders",
	type: "REMINDERS_IEP",
};
const QUESTIONS_ACTION: IAction = {
	title: "I have questions",
	type: "QUESTIONS",
};
const SIGN_UP_ACTION: IAction = {title: "Continue", type: "SIGN_UP"};
const CALCULATE_ACTION: IAction = {title: "Continue", type: "CALCULATOR"};
const SAVE_DATES_ACTION: IAction = {
	title: "Save these dates",
	type: "SAVE_DATES",
};

// Case 1
export const enrollGEPSmallEmployer: IEnrollmentResultWindow = {
	titleLines: [
		"Thanks for your answers!",
		"You’ll want to enroll in Medicare ASAP.",
	],
	descriptionAreas: [
		{
			title: "Do I have a Special Enrollment Period?",
			lines: [
				"No. Since the employer you have health coverage through does not have more than 20 employees, you don’t quality for a Special Enrollment Period.",
			],
		},
		{
			title: "When can I sign up for Medicare?",
			lines: [
				"In order to avoid additional Part B penalties for late sign-up, we recommend you sign up for Medicare during the General Enrollment Period, between Jan 1 – Mar 31 of {{gepYear}}. Your coverage will go into effect on July 1, of {{gepYear}}.",
			],
		},
	],
	nextStepLines: [
		"Next step: Sign up to get reminders for the General Enrollment Period.",
	],
	nextStepActions: [REMINDERS_GEP_ACTION, QUESTIONS_ACTION],
};

// Case 2
export const enrollSEPEmployerCoverage: IEnrollmentResultWindow = {
	titleLines: [
		"Thanks for your answers!",
		"Here is your Medicare sign-up window.",
	],
	descriptionAreas: [
		{
			title: "You’re in a Special Enrollment Period.",
			lines: [
				"Your initial sign up period has ended, and you are in a Special Enrollment Period because of your employer coverage.",
			],
		},
		{
			title: "When should I sign up for Medicare?",
			lines: [
				"You have 8 months after your current coverage ends (such as if you retire) to defer Part B without penalties. But, you may be able to find better coverage by switching to Medicare, such as lower premiums and deductibles.",
			],
		},
	],
	nextStepLines: [
		"Next step: Use our calculator to see if you should sign up for Medicare now, or stay on your employer coverage.",
	],
	nextStepActions: [CALCULATE_ACTION, SAVE_DATES_ACTION],
};

// Case 3
export const enrollSEPRetirementCoverage: IEnrollmentResultWindow = {
	titleLines: [
		"Thanks for your answers!",
		"Here is your Medicare sign-up window.",
	],
	descriptionAreas: [
		{
			title: "You’re in a Special Enrollment Period.",
			lines: [
				"Your initial sign up period has ended, and you are in a Special Enrollment Period because of your retirement. You have until {{sepEnd}} to defer Part B without penalties. We recommend enrolling in Medicare to avoid any gaps in your coverage and potential penalties.",
			],
		},
		{
			title: "When should I sign up for Medicare?",
			lines: [
				"You have 8 months after your current coverage ends (such as if you retire) to defer Part B without penalties. But, you may be able to find better coverage by switching to Medicare, such as lower premiums and deductibles.",
			],
		},
	],
	nextStepLines: [
		"Now, we’ll help you sign up for Medicare {{medicarePartsText}}.",
	],
	nextStepActions: [SIGN_UP_ACTION, SAVE_DATES_ACTION],
};

// Case 4
export const enrollOnEmployerCoverage: IEnrollmentResultWindow = {
	titleLines: [
		"Thanks for your answers!",
		"Here is your Medicare sign-up window.",
	],
	descriptionAreas: [
		{
			title: "You’ll have a Special Enrollment Period.",
			lines: [
				"Since the employer you have health coverage through has more than 20 employees, you have the option of deferring Part B without penalties up to 8 months after your current coverage ends (such as when you retire).",
			],
		},
		{
			title: "When should I sign up for Medicare?",
			lines: [
				"You have 8 months after your current coverage ends (such as if you retire) to defer Part B without penalties. But, you may be able to find better coverage by switching to Medicare, such as <b>lower premiums and deductibles.</b>",
			],
		},
	],
	nextStepLines: [
		"Next step: Use our calculator to see if you should sign up for Medicare now, or stay on your employer coverage.",
	],
	nextStepActions: [CALCULATE_ACTION, SAVE_DATES_ACTION],
};

// Case 5
export const enrollNoSEPSmallEmployer: IEnrollmentResultWindow = {
	titleLines: ["Thanks for your answers!", "Here are your enrollment dates."],
	descriptionAreas: [
		{
			title: "You won’t have a Special Enrollment Period.",
			lines: [
				"Since the employer you have health coverage through does not have more than 20 employees, you want to sign up for Part B by {{iepEnd}} to avoid lifetime penalties.",
			],
		},
	],
	nextStepLines: [
		"Now, we’ll help you sign up for Medicare {{medicarePartsText}}.",
	],
	nextStepActions: [SIGN_UP_ACTION, SAVE_DATES_ACTION],
};

// Case 6a
export const enrollNowPreBirthday: IEnrollmentResultWindow = {
	titleLines: [
		"Thanks for your answers!",
		"Here is your Medicare sign-up window.",
	],
	descriptionAreas: [
		{
			title: "Your sign-up window just started.",
			lines: [
				"Since you have coverage through {{currentInsuranceCoverageType}}, you’ll need to sign up by {{iepEnd}}. We recommend signing up now (rather than waiting) to avoid gaps in your coverage and potential penalties.",
			],
		},
	],
	nextStepLines: [
		"Now, we’ll help you sign up for Medicare {{medicarePartsText}}.",
	],
	nextStepActions: [SIGN_UP_ACTION, SAVE_DATES_ACTION],
};

// Case 6b
export const enrollNowAfterBirthday: IEnrollmentResultWindow = {
	titleLines: [
		"Thanks for your answers!",
		"Here is your Medicare sign-up window.",
	],
	descriptionAreas: [
		{
			title: "You’re in your sign-up window.",
			lines: [
				"We recommend signing up now because you have coverage through {{currentInsuranceCoverageType}}, and will need to enroll within your window to avoid lifetime penalties.",
			],
		},
	],
	nextStepLines: [
		"Now, we’ll help you sign up for Medicare {{medicarePartsText}}.",
	],
	nextStepActions: [SIGN_UP_ACTION, SAVE_DATES_ACTION],
};

// Case 7
export const enrollGEPIEPClosed: IEnrollmentResultWindow = {
	titleLines: [
		"Thanks for your answers!",
		"Here is your Medicare sign-up window.",
	],
	descriptionAreas: [
		{
			title: "Your sign-up window has ended.",
			lines: [
				"Your initial enrollment period closed on {{iepEnd}}. In order to avoid additional Part B penalties for late sign-up, we recommend you sign up for Medicare during the General Enrollment Period, between Jan 1 – Mar 31 of {{gepYear}}. Your coverage will go into effect on July 1, of {{gepYear}}.",
			],
		},
		{
			title: "Get reminders, and avoid scams.",
			lines: [
				"We can send you reminders to sign-up, so that there are no gaps in your coverage. Additionally, we’re here to help you avoid Medicare scams that you may receive in the mail, or in your inbox.",
			],
		},
	],
	nextStepLines: [
		"Next step: Sign up to get reminders for the General Enrollment Period.",
	],
	nextStepActions: [REMINDERS_GEP_ACTION, QUESTIONS_ACTION],
};

// Case 8
export const enrollIEP: IEnrollmentResultWindow = {
	titleLines: [
		"Thanks for your answers!",
		"You’re almost ready to sign up for Medicare.",
	],
	descriptionAreas: [
		{
			title: "Your sign-up window hasn’t started yet.",
			lines: [
				"Your can sign up for medicare starting on {{iepStart}}, the beginning of your Initial Enrollment Period.",
			],
		},
		{
			title: "Get reminders, and avoid scams.",
			lines: [
				"We can send you reminders to sign-up, so that there are no gaps in your coverage. Additionally, we’re here to help you avoid Medicare scams that you may receive in the mail, or in your inbox.",
			],
		},
	],
	nextStepLines: [
		"Next step: Sign up to get reminders and avoid Medicare scams.",
	],
	nextStepActions: [REMINDERS_IEP_ACTION, QUESTIONS_ACTION],
};

// Case 9
export const enrollIEPWithSEP: IEnrollmentResultWindow = {
	titleLines: [
		"Thanks for your answers!",
		"You’re almost ready to sign up for Medicare.",
	],
	descriptionAreas: [
		{
			title: "Your sign-up window hasn’t started yet.",
			lines: [
				"Your can sign up for medicare starting on {{iepStart}}, the beginning of your Initial Enrollment Period. Since you have coverage through an employer, you will have a Special Enrollment Period if you choose to stop working after the end of your Initial Enrollment Period. This allows you to delay signing up for Medicare up to 8 months after your employer coverage ends (such as if you retire). We’ll help you decide whether to enroll once you are closer to your start date.",
			],
		},
		{
			title: "Get reminders, and avoid scams.",
			lines: [
				"We can send you reminders to sign-up, so that there are no gaps in your coverage. Additionally, we’re here to help you avoid Medicare scams that you may receive in the mail, or in your inbox.",
			],
		},
	],
	nextStepLines: [
		"Next step: Sign up to get reminders and avoid Medicare scams.",
	],
	nextStepActions: [REMINDERS_IEP_ACTION, QUESTIONS_ACTION],
};

// Case 10
export const enrollIEPSmallEmployer: IEnrollmentResultWindow = {
	titleLines: [
		"Thanks for your answers!",
		"You’re almost ready to sign up for Medicare.",
	],
	descriptionAreas: [
		{
			title: "Your sign-up window hasn’t started yet.",
			lines: [
				"Your can sign up for medicare starting on {{iepStart}}, the beginning of your Initial Enrollment Period (IEP). Please note that since you have coverage through an employer with less than 20 employees, you will not have a Special Enrollment Period. You will want to sign up for Medicare during your IEP.",
			],
		},
		{
			title: "Get reminders, and avoid scams.",
			lines: [
				"We can send you reminders to sign-up, so that there are no gaps in your coverage. Additionally, we’re here to help you avoid Medicare scams that you may receive in the mail, or in your inbox.",
			],
		},
	],
	nextStepLines: [
		"Next step: Sign up to get reminders and avoid Medicare scams.",
	],
	nextStepActions: [REMINDERS_IEP_ACTION, QUESTIONS_ACTION],
};

// Exceptions
export const HSA_ALERT_TITLE =
	"If you have an HSA, there are a few things you need to know.";

export const HSA_ALERT_BULLETS = [
	"If you enroll in Medicare, you can no longer contribute to your HSA as of your effective dates for either Part A or Part B.",
	"Note that you can continue to use balances from your HSA to pay for certain Medicare premiums, including Part B premiums, Part D drug plan premiums, and any Medicare Advantage (Part C) premiums.",
	"However, some people want to continue contributing to an HSA after turning 65 and defer enrolling in Medicare. This is an option for those who have employer-based health coverage from an organization with more than 20 employees.",
	"In that circumstance, It's important to consider whether continuing to contribute to an HSA while staying on your current coverage outweighs the potential benefits of Medicare options, which often provide more robust coverage at more affordable premiums.",
	`If you have any questions, you can call us at ${CHAPTER_PHONE_NUMBER_FORMATTED}.`,
];

export const RR_SS_DISABLED_ALERT_TITLE =
	"Social Security or Railroad Retirement Board benefits";

export const RR_SS_DISABLED_ALERT_BULLETS = [
	"If you are disabled and are receiving Social Security or Railroad Retirement Board benefits, you may be automatically (or already enrolled) in Parts A & B.",
];

export const DISABLED_ALERT = "Disability benefits";

export const DISABLED_ALERT_BULLETS = [
	`If you have collected Social Security for a disability for at least 24 months, you will likely be automatically enrolled in Medicare Parts A & B. If you have any questions, please call us at ${CHAPTER_PHONE_NUMBER_FORMATTED}.`,
];

export const OBAMACARE_ALERT_TITLE = "Obamacare Alert";

export const OBAMACARE_ALERT_BULLETS = [
	"Most people on an ACA plan will save money, find better benefits, or both by signing up for Medicare.",
];

export const COBRA_ALERT_TITLE = "COBRA Alert";

export const COBRA_ALERT_BULLETS = [
	"COBRA is not considered creditable coverage. For Medicare, this means that enrollment in a COBRA plan does not allow you to delay Medicare enrollment without potential penalties. If you are on a COBRA plan, you should consider enrolling in Medicare during your enrollment window.",
];

import axios from "common/util/api/axios";
import endpoints from "common/util/api/endpoints";
import {
	ICustomerAndCustomerQuestion,
	ICustomer,
	ICustomerQuestion,
	apiService,
} from "common/util/api/service";
import {getAgeFromBirthdate, parseName} from "common/util/format";
import {IFormData} from "./data/form-data";

import eventTracker from "common/util/eventTracker";
import * as FullStory from "@fullstory/browser";
import {
	marriedButSeparateIncomeBrackets,
	marriedIncomeBrackets,
	singleIncomeBrackets,
} from "./data/income-brackets";
import {calculatePremium} from "pages/PartBResults/service";
import {PART_B_RECOMMENDATION_READABLE_MAP} from "pages/PartBResults/results";
import {MEDICARE_MINIMUM_AGE} from "common/util/common";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";

const ROUND_ZIP_PREMIUM_NUMBER = 5;

class PartBCalculatorService {
	private results: IFormData = {
		zip: "",
		dob: "",
		// Selects have a default value
		wantsSpouseCoverage: "false",
		optIncomeRange: "1",
		numDependents: "0",
		/*
		gender: "Male",
		tobacco: "false",
		hasEmployerHealthCoverage: "yes",
		hasWorkedTenYears: "no",
		hasMoreThanTwentyEmployees: "yes",
		optFileTaxes: "single",
		optMinimizeCosts: "no",
		*/
	} as IFormData;

	private zipCache = {};

	setResults(results) {
		const cacheKey = `${results.zip}-${results.dob}-${results.tobacco}-${results.gender}`;
		this.results = {...results, medsupForZipCost: this.zipCache[cacheKey]};
	}

	getResults() {
		return this.results;
	}

	calculateIncomeMinAndMax(
		optIncomeRange: IFormData["optIncomeRange"],
		optFileTaxes: IFormData["optFileTaxes"]
	) {
		const brackets = {
			single: singleIncomeBrackets,
			joint: marriedIncomeBrackets,
			separately: marriedButSeparateIncomeBrackets,
		};
		const incomeRanges = brackets[optFileTaxes];
		const incomeRange = incomeRanges.find((r) => r.value === optIncomeRange);
		if (!incomeRange) {
			return {
				incomeMinimum: 0,
				incomeMaximum: 0,
			};
		}
		return {incomeMinimum: incomeRange.min, incomeMaximum: incomeRange.max};
	}

	convertResults(results: IFormData): ICustomerAndCustomerQuestion {
		const birthdate = results.dob;
		const birthmonth = birthdate.substring(0, 2);
		const birthyear = birthdate.slice(-4);
		const dob =
			birthdate && `${birthyear}-${birthmonth}-${birthdate.substring(3, 5)}`;

		const numDependents = parseInt(results.numDependents, 10);
		const incomeMinAndMax = this.calculateIncomeMinAndMax(
			results.optIncomeRange,
			results.optFileTaxes
		);

		const partBRecommendation = calculatePremium(results);
		const calculatedPartBPremiumPlan =
			partBRecommendation.plans && partBRecommendation.plans[0];
		const calculatedPartBPremium = calculatedPartBPremiumPlan?.priceNumber;

		// Are they willing to pay extra for additional coverage
		// Note: this assumes maybe's are yes's
		const premiumAffordability = results.optMinimizeCosts !== "no";

		// What is their total premium?
		const calculatedTotalPremium =
			calculatedPartBPremium ||
			0 +
				(premiumAffordability && results.medsupForZipCost
					? results.medsupForZipCost
					: 0);

		// Parse out name
		const parsedName = results.name && parseName(results.name);

		const firstName =
			(parsedName && parsedName.firstName) ||
			LocalStorage.getItem(STORAGE_KEYS.FIRST_NAME) ||
			"";

		const customer: Partial<ICustomer> = {
			zip: results.zip,
			dob,
			gender: results.gender === "preferNotToAnswer" ? null : results.gender,
			tobacco: results.tobacco === "true",
			wantsSpouseCoverage: results.wantsSpouseCoverage === "true",
			hasMoreThanTwentyEmployees: results.hasMoreThanTwentyEmployees === "yes",
			numDependents,
			...incomeMinAndMax,
			partBRecommendation:
				PART_B_RECOMMENDATION_READABLE_MAP[partBRecommendation.type],
			calculatedPartBPremium,
			birthmonth,
			birthyear,
			firstName,
			calculatedTotalPremium,
		};

		const customerQuestion: Partial<ICustomerQuestion> = {premiumAffordability};

		if (parsedName) {
			if (parsedName.lastName) {
				customer.lastName = parsedName.lastName;
			}
			if (parsedName.middleName) {
				customer.middleName = parsedName.middleName;
			}
		}
		if (results.currentInsurancePremium) {
			customer.currentInsurancePremium = parseInt(
				results.currentInsurancePremium,
				10
			);
		}
		if (results.currentInsuranceDeductible) {
			customer.currentInsuranceDeductible = parseInt(
				results.currentInsuranceDeductible,
				10
			);
		}

		if (results.email) {
			customer.email = results.email;
		}

		const optionalBooleanFields = [
			"isReceivingSocialSecurityBenefits",
			"hasExistingMedicareFromDisability",
			"isEnrolledInMedsup",
		];
		optionalBooleanFields.forEach((field) => {
			// Check if undefined or null
			if (results[field]) {
				customer[field] = results[field] === "yes";
			}
		});

		return {customer, customerQuestion};
	}

	saveCustomerData() {
		const res = this.convertResults(this.getResults());

		return apiService.updateCustomerAndCustomerQuestion(
			res.customer,
			res.customerQuestion
		);
	}

	saveCustomerAdditionalInfo({phoneNumber, email, name}) {
		const nameValues = parseName(name);

		apiService
			.updateCustomer({
				email,
				phoneNumber,
				...nameValues,
			})
			.catch(console.error);

		if (window.dataLayer && email) {
			// Send email to Google Tag Manager
			window.dataLayer.push({email});

			eventTracker.log({
				name: eventTracker.EVENTS.RECORD_EMAIL,
			});
		}

		// HIPAA compliance - email domain and area code aren't PII
		if (email) {
			FullStory.setUserVars({
				email: email.split("@")[1],
				phoneAreaCode: phoneNumber.substring(0, 3),
			});
		}
	}

	async fetchMedSupZipCodePremium(
		zip: string,
		dob: string = "",
		tobacco: string,
		gender: IFormData["gender"]
	) {
		const cacheKey = `${zip}-${dob}-${tobacco}-${gender}`;

		if (this.zipCache[cacheKey] !== undefined) {
			return this.zipCache[cacheKey];
		}

		const age = getAgeFromBirthdate(dob);

		const queryParams: any = {
			zip,
			// Default gender to female if not answered
			gender: gender === "preferNotToAnswer" ? "Female" : gender,
			tobacco: tobacco === "true",
		};
		// Calculate price at AGE or 65 (default value) if younger than 65
		if (age && age >= MEDICARE_MINIMUM_AGE) {
			queryParams.age = age;
		}

		return await axios
			.get(endpoints.medsupLowestPremium(queryParams))
			.then((response) => {
				const rawPrice = response.data;
				// Round up premium to nearest 5
				const roundedPrice =
					Math.ceil(rawPrice / ROUND_ZIP_PREMIUM_NUMBER) *
					ROUND_ZIP_PREMIUM_NUMBER;
				this.zipCache[cacheKey] = roundedPrice;
				return roundedPrice;
			})
			.catch((err) => {
				console.warn("Retrieving Zip Premium Error", err);
			});
	}
}

export const partBCalculatorService = new PartBCalculatorService();

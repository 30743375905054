import React, {ReactChild, ReactChildren, useState} from "react";
import {Collapse as MaterialCollapse} from "@material-ui/core";
import clsx from "clsx";
import Icon from "../Icon";

/**
 *
 * Collapse component adds a button and collapsible element
 * the button content and classnames can be configured via props
 * and optionally Collapse can be modified to take in a React Component
 *
 * It manages open and closed states
 */
export function Collapse({
	triggerContent,
	triggerClassName,
	children,
	triggerOnClick,
}: {
	triggerContent: string | ReactChild;
	triggerClassName?: string;
	children: ReactChild | ReactChildren;
	triggerOnClick?: () => void;
}) {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	return (
		<>
			<button
				className={triggerClassName}
				onClick={() => {
					setIsOpen(!isOpen);
					triggerOnClick && triggerOnClick();
				}}
			>
				<div className="flex items-center">
					{triggerContent}
					<div
						className={clsx(
							"transform ml-1",
							isOpen ? "rotate-90" : "-rotate-90"
						)}
					>
						<Icon
							src="leftChevron"
							height={14}
							width={14}
							alt={"Go Back"}
							color="#bdbdbd"
						/>
					</div>
				</div>
			</button>
			<MaterialCollapse in={isOpen}>{children}</MaterialCollapse>
		</>
	);
}

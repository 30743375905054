import clsx from "clsx";
import React, {ReactElement, useEffect, useState} from "react";

import {useScroll} from "common/hooks/useScroll";
import {useSidebar} from "../../../core/Sidebar/hooks/useSidebar";

export function FlowWrapper({children}: {children: ReactElement}) {
	const {isSidebarOpen, scrollRef} = useSidebar();

	const [isTop, setIsTop] = useState(true);
	const scroll = useScroll({
		wait: 100,
		element: scrollRef,
	});

	// We want to scroll the broker image back down if you're within
	// 20px of the top
	const scrollTopBuffer = 20;
	useEffect(() => {
		if (scroll.y! <= scrollTopBuffer) {
			setIsTop(true);
		} else if (scroll.direction === "down") {
			setIsTop(false);
		}
	}, [scroll]);

	return (
		<div className="flex-grow text-center">
			<div className="flex items-center flex-col max-h-full flex-grow h-full">
				<div className="absolute z-30">
					<img
						className={clsx(
							"inline-block shadow z-40 object-cover -mt-8 h-14 w-14 rounded-full",
							"transform transition-all",
							isSidebarOpen && "ml-72",
							// Show Broker Image
							isTop ? "-mt-8" : "-mt-20 scale-75"
						)}
						src="/images/brokers/ari-circle.png"
						alt="Ari Parker, Licensed Senior Medicare Advisor"
					/>
				</div>
				<div className="w-full relative flex min-h-0 max-h-full h-screen">
					{children}
				</div>
			</div>
		</div>
	);
}

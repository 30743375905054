import React, {useEffect, useState} from "react";

import {RadioGroup} from "common/ui/RadioGroup/RadioGroup";
import Select from "common/ui/Select";
import GenericFormRow, {IField, IOptionLabel} from "common/ui/Form/FormRow";
import InputText, {
	InputTextCurrency,
	InputTextBirthday,
	InputTextZip,
	InputTextEmail,
} from "common/ui/InputText";
import {InputZipCounty} from "common/ui/Fields/InputZipCounty";

import {partBCalculatorService} from "./service";

import {
	CurrentCost,
	CURRENT_PREMIUM_TOOLTIP_LINES,
	IFormData,
	IncomeBracketData,
	OptMinimizeCosts,
} from "./data/form-data";
import Tooltip from "common/ui/Tooltip";
import clsx from "clsx";

function FormLabel({name}) {
	return <div className="mx-2 font-light">{name}</div>;
}

function NumberTextInput(props) {
	return (
		<div className="w-28">
			<InputTextCurrency {...props} />
		</div>
	);
}

function TextInput(props) {
	return (
		<div className="w-50">
			<InputText {...props} label={""} />
		</div>
	);
}

function FormRadio(props) {
	return <RadioGroup labelClassName={"mr-10"} {...props} />;
}

function FormSelect(props) {
	return (
		<div className="max-w-md pt-1">
			<Select
				selectClassName="w-full bg-white shadow border-0 focus:border-blue-300 text-base py-4"
				{...props}
			/>
		</div>
	);
}

const COMPONENT_MAP = {
	inputNumber: NumberTextInput,
	input: TextInput,
	zip: InputTextZip,
	email: InputTextEmail,
	zipCounty: InputZipCounty,
	birthdate: InputTextBirthday,
	radio: FormRadio,
	select: FormSelect,
	label: FormLabel,
};

export function FormRow({
	values,
	...props
}: {
	className?: string;
	hideError?: boolean;
	isResults?: boolean;
	fieldData: IField;
	hasPressedSubmit?: boolean;
	values?: any;
}) {
	return (
		<div className="font-light">
			<GenericFormRow
				componentMap={COMPONENT_MAP}
				componentClassName={
					"border-gray-100 focus:border-blue-300 transition-colors text-lg w-full max-w-xl"
				}
				{...props}
			/>
			{props.fieldData.additional && (
				<div className="ml-10 border-l px-5 border-green-300 space-y-4">
					{props.fieldData.additional(undefined, values!).map((field) => (
						<FormRow fieldData={{...field}} key={field.name} />
					))}
				</div>
			)}
		</div>
	);
}

export function OptionalHigherPremiums({
	values,
	isResults,
}: {
	values: IFormData;
	isResults?: boolean;
}) {
	const field = OptMinimizeCosts;
	const [label, setLabel] = useState<string>("?");

	useEffect(() => {
		// Only run the query if we have a valid zip, or if the DOB is
		// filled in entirely, or not filled in
		if (!values || !values.zip || values.zip.length !== 5) {
			return;
		}
		if (values.dob && values.dob.length !== 10) {
			return;
		}
		// Record zipcode's average premium cost for a later page to reference
		partBCalculatorService
			.fetchMedSupZipCodePremium(
				values.zip,
				values.dob,
				values.tobacco!,
				values.gender
			)
			.then((premium) => {
				setLabel(`$${premium || 0}`);
			});
	}, [values, values.zip, values.dob, values.tobacco, values.gender]);

	const render = () => {
		return (
			<div className="my-4 font-semibold leading-relaxed text-lg">
				Would you be willing to pay{" "}
				<div className="inline-block border px-2 py-2 rounded-md mx-2">
					<Tooltip
						content={
							<div className="text-sm space-y-2">
								{CURRENT_PREMIUM_TOOLTIP_LINES.map((line) => (
									<div key={line}>{line}</div>
								))}
							</div>
						}
					>
						<div className="px-2 bg-white text-gray-600 text-underline-accent">
							{label}
						</div>
					</Tooltip>
				</div>
				/month for this type of plan?
			</div>
		);
	};

	return <FormRow fieldData={{...field, render}} isResults={isResults} />;
}

export function OptionalCurrentPay({
	values,
	isResults,
}: {
	values: IFormData;
	isResults?: boolean;
}) {
	const field = CurrentCost;
	const [additionalFields, setAdditionalFields] = useState<IField[]>([]);
	useEffect(() => {
		const additional = field.additionalFields(
			values.numDependents,
			values.currentInsurancePremium
		);
		setAdditionalFields(additional);
	}, [field, values]);

	const render = () => {
		return (
			<div>
				<div className="items-center py-2 grid grid-cols-3 md:flex">
					{field &&
						field.fields.map((f, i) => (
							<div
								key={f.name}
								className={clsx(
									"col-span-3",
									(i === field.fields.length - 1 &&
										"col-span-3 md:col-span-1 flex-grow") ||
										""
								)}
							>
								{f.type === "label" ? (
									<FormLabel name={f.text} />
								) : (
									<FormRow
										fieldData={{...f}}
										className={f.className}
										hideError={true}
									/>
								)}
							</div>
						))}
				</div>
				<div className="ml-20 border-l px-5 border-green-300 space-y-4">
					{additionalFields.map((field) => (
						<FormRow fieldData={{...field}} key={field.name} />
					))}
				</div>
			</div>
		);
	};

	return <FormRow fieldData={{...field, render}} isResults={isResults} />;
}

export function IncomeRange({
	optFileTaxes,
	isResults,
	hasPressedSubmit,
}: {
	optFileTaxes: IFormData["optFileTaxes"];
	isResults?: boolean;
	hasPressedSubmit?: boolean;
}) {
	const [options, setOptions] = useState<IOptionLabel[]>();

	useEffect(() => {
		const opts = IncomeBracketData.options(optFileTaxes);
		setOptions(opts);
	}, [optFileTaxes]);

	return (
		<FormRow
			fieldData={{...IncomeBracketData, options}}
			isResults={isResults}
			hasPressedSubmit={hasPressedSubmit}
		/>
	);
}

import React, {Component} from "react";
import {LocalStorage} from "common/util/storage/";
import STORAGE_KEYS from "common/util/storage/keys";
import endpoints from "common/util/api/endpoints";
import STYLES from "common/util/constants/styles";
import {
	MedicareRetireHeader,
	NormalWeight,
	CheckboxGroupStyled,
} from "./MedicareRetireHelpers";

export class MedicareRetire extends Component {
	static maxWidth = 600;
	static containerColor = STYLES.COLORS.GLACIER;

	constructor(props) {
		super(props);

		const selectedBoxes = LocalStorage.getItem(
			STORAGE_KEYS.PAGES.INTRO_MEDICARE_RETIRE
		);

		if (selectedBoxes) {
			this.state = {selectedBoxes};
			this.setParentPersistence();
		} else {
			this.state = {selectedBoxes: []};
		}

		props.setBottomNextButtonText("Next");

		this.options = [
			{
				value: "signedUpForPartB",
				label:
					"I already signed up for Medicare Part B and I signed up in the last 6 months.",
			},
			{
				value: "willSignUpForPartB",
				label:
					"I will be signing up for Medicare Part B within the next 3 months.",
			},
			{
				value: "stoppedWork",
				label: "I have stopped full-time work within the past 6 months.",
			},
			{
				value: "willStopWork",
				label: "I plan to stop full-time work within the next 3 months.",
			},
		];
	}

	componentDidUpdate(prevProps, prevState) {
		const {setBottomNextButtonText} = this.props;
		const {selectedBoxes} = this.state;

		const oldLength = prevState.selectedBoxes.length;

		if (oldLength === selectedBoxes.length) return;

		setBottomNextButtonText("Next");

		this.setParentPersistence();

		LocalStorage.setItem(STORAGE_KEYS.PAGES.INTRO_MEDICARE_RETIRE, selectedBoxes);
	}

	setParentPersistence = () => {
		const {setPersistence} = this.props;
		const {selectedBoxes} = this.state;

		const data = {};
		selectedBoxes.forEach((box) => (data[box] = true));

		// Send preference from question saved prior to customerId
		const lookingFor = LocalStorage.getItem(STORAGE_KEYS.PAGES.INTRO_FIRST_EXISTING);
		data[lookingFor] = true;

		setPersistence({
			url: endpoints.questionSave(),
			data,
		});
	};

	onChangeWrapper = (val) => {
		const {selectedBoxes} = this.state;

		if (selectedBoxes.includes(val))
			this.setState({selectedBoxes: selectedBoxes.filter((f) => f !== val)});
		else {
			const newSelectedBoxes = [...selectedBoxes, val];
			this.setState({selectedBoxes: newSelectedBoxes});
		}
	};

	render() {
		const {selectedBoxes} = this.state;
		return (
			<div>
				<MedicareRetireHeader>
					<h4>Do any of these apply to you?</h4>
					<NormalWeight>(Check any that apply)</NormalWeight>
				</MedicareRetireHeader>
				<CheckboxGroupStyled
					selected={selectedBoxes}
					options={this.options}
					onChange={this.onChangeWrapper}
					type="column"
				/>
			</div>
		);
	}
}

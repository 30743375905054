import React, {useEffect, useState} from "react";
import {Switch, Route, useRouteMatch} from "react-router-dom";

import {Helmet} from "react-helmet";

import {Sidebar} from "core/Sidebar/Sidebar";
import {MedicareSignUpStatus} from "./MedicareSignUpStatus";
import {useHistory, useLocation} from "react-router";
import Checklist from "./Checklist";
import {FirstTimerIntro} from "./FirstTimerIntro";
import {Page} from "../shared/Page";

import {About} from "../Intro/FlowAbout";
import {Welcome} from "../Intro/FlowWelcome";
import {useUser} from "common/hooks/useUser";
import {getModulesForMedicareStatus} from "./utils";
import firstTimerSelected from "../Intro/images/first-timer-selected.svg";

const HEADER = "First Medicare Plan";

function SidebarHeader() {
	return (
		<div>
			<img
				src={firstTimerSelected}
				alt={HEADER}
				className="mx-auto h-12 my-3"
			/>
			{HEADER}
		</div>
	);
}

export default function FlowFirstTimer() {
	const match = useRouteMatch();
	const history = useHistory();
	let location = useLocation();

	const {user, updateUser} = useUser();

	const [routeData, setRouteData] = useState<any[]>();

	useEffect(() => {
		if (user) {
			setRouteData(getModulesForMedicareStatus(user.medicareSignUpStatus));
		}
	}, [user]);

	return (
		<>
			<Helmet>
				<title>First Timer Flow</title>
			</Helmet>

			<Switch location={location}>
				<Route path={`${match.path}/overview`}>
					<Page>
						<Welcome
							onSubmit={() => {
								// go to name page
								history.push("/flow/new/triage");
							}}
						/>
					</Page>
				</Route>

				<Route path={`${match.path}/triage`}>
					<Page>
						<MedicareSignUpStatus
							onSubmit={(values) => {
								updateUser(values);
								// save values to service / localstorage
								history.push("/flow/new/intro");
							}}
						/>
					</Page>
				</Route>

				<Route path={`${match.path}/intro`}>
					<Page>
						<FirstTimerIntro
							onSubmit={() => {
								history.push("checklist");
							}}
						/>
					</Page>
				</Route>

				<Route path={`${match.path}/checklist`}>
					<Page>
						<Checklist
							onSubmit={() => {
								if (user?.medicareSignUpStatus === "partAB") {
									history.push(`${match.path}/recommend/explainer`);
								} else {
									history.push(`${match.path}/learn/explainer`);
								}
							}}
						/>
					</Page>
				</Route>

				<Route path={`${match.path}/:module`}>
					<Sidebar Header={<SidebarHeader />} modules={routeData} />
					{/** Back Button */}
					<Switch>
						{routeData?.reduce((acc, rd) => {
							if (rd && rd.children && rd.children.length) {
								acc.push(
									...rd?.children?.map((r) => (
										<Route key={r.route} path={`/flow/${r.route}`}>
											<Page fullWidth={r.fullWidth}>{<r.component />}</Page>
										</Route>
									))
								);
							}

							return acc;
						}, [])}
					</Switch>
				</Route>

				{/** Default Page */}
				<Route path={match.path}>
					<About
						onSubmit={() => {
							// go to name page
							history.push("/flow/new/overview");
						}}
					/>
				</Route>
			</Switch>
		</>
	);
}

import React from "react";

export function EnrollmentDates({
	calculatedStartText,
	calculatedEndText,
}: {
	calculatedStartText: string;
	calculatedEndText?: string;
}) {
	return (
		<div className="flex flex-col ml-auto space-y-4 my-5 text-lg">
			<div className="text-xl mb-5">Your sign-up window</div>
			<div className="flex space-x-5 items-center">
				<div className="flex flex-col">
					<span className="uppercase text-xs mb-2">Start</span>
					<div className="text-underline-accent font-bold">
						{calculatedStartText}
					</div>
				</div>
				<div className="text-teal-600 text-3xl mt-2">-</div>
				<div className="flex flex-col">
					<span className="uppercase text-xs mb-2">End</span>
					<div
						className="text-underline-accent font-bold leading-none"
						dangerouslySetInnerHTML={{__html: calculatedEndText!}}
					></div>
				</div>
			</div>
		</div>
	);
}

import axios from "./axios";
import endpoints from "./endpoints";

export async function getMaRecommendation(
	maZipReferenceId: string,
	percentile: string,
	field: string
) {
	const {data} = await axios.get(
		endpoints.maReduce({maZipReferenceId, percentile, field})
	);
	return data;
}

export async function getPartDRecommendation(
	maZipReferenceId: string,
	percentile: string,
	field: string
) {
	const {data} = await axios.get(
		endpoints.partDReduce({maZipReferenceId, percentile, field})
	);
	return data;
}

export async function getMedsupRecommendation() {
	const {
		data: [data],
	} = await axios.get(endpoints.medsupPlanDetails());

	return {
		...data,
		maxOutOfPocket: data.maxOutOfPocket === null ? 0 : data.maxOutOfPocket,
		// We divide by 100 here because API sends us data in the format of cents
		// ex: 20300, which is actually 203.00
		partBDeductible: parseInt(data.partBDeductible) / 100,
	};
}

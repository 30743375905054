import React from "react";
import styled from "styled-components";
import Icon from "common/ui/Icon";
import IconBackdrop from "common/ui/Icon/IconBackdrop";
import STYLES from "common/util/constants/styles";

const DrawerHeaderWrapper = styled.div`
	height: ${({$height}) => $height}px;
	background-color: ${STYLES.COLORS.LIGHT_GREY};
	font-size: 31px;
`;

const DrawerHeaderStyled = styled.div`
	padding: 0 65px;
	text-align: center;
	height: 100%;
	display: flex;
`;

const BackTitleInline = styled.div`
	display: flex;
	align-items: center;
	width: 100%;

	span {
		margin: 0 auto;
	}
`;

const BackdropCentered = styled.div`
	display: flex;
	justify-content: center;
	margin-top: -${({$offset}) => $offset}px;
`;

export const DrawerHeader = (props) => {
	return (
		<DrawerHeaderWrapper $height={props.height}>
			<DrawerHeaderStyled>
				<BackTitleInline>
					<Icon src="leftChevron" onClick={props.onClickBack} height={30} />
					{props.title && <span>{props.title}</span>}
				</BackTitleInline>
			</DrawerHeaderStyled>
			<BackdropCentered $offset={props.iconBackdropWidth / 2}>
				<IconBackdrop
					width={props.iconBackdropWidth}
					color={STYLES.COLORS.SALMON}
				>
					<Icon
						src={props.iconSrc}
						color={props.iconColor}
						width={props.iconWidth}
					/>
				</IconBackdrop>
			</BackdropCentered>
		</DrawerHeaderWrapper>
	);
};

export const doubleArrow = ({color, width, height}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 20 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1 17l9-8-9-8M10 17l9-8-9-8"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

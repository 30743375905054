import React, {AnchorHTMLAttributes, ReactNode} from "react";
import clsx from "clsx";

interface ILinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	linkType?: "buttonPrimary" | "buttonSecondary" | "anchor";
	children: ReactNode;
}

export function Link({children, linkType = "anchor", ...props}: ILinkProps) {
	return (
		<a
			className={clsx(
				"inline-flex items-center px-8 py-3 text-base font-bold rounded-full shadow-sm font-unset normal-case focus:outline-none focus:ring-2 focus:ring-offset-2",
				"hover:no-underline",
				linkType === "buttonPrimary" &&
					"text-white bg-teal-700 border border-transparent hover:bg-teal-800 focus:ring-teal-500",
				linkType === "buttonSecondary" &&
					"bg-white text-black border border-solid border-gray-200 hover:bg-gray-100 focus:ring-gray-300"
			)}
			{...props}
			rel="noreferrer"
		>
			{children}
		</a>
	);
}

import * as FullStory from "@fullstory/browser";
import axios from "./axios";
import endpoints from "./endpoints";
import {LocalStorage} from "../storage";
import STORAGE_KEYS from "../storage/keys";
import eventTracker from "../eventTracker";

export type TypeCurrentAdditionalCoverageType = "ma" | "ms" | "unsure";
export type TypeRetirementStatus = "retired" | "will-retire" | "no";
export type TypeCurrentInsuranceCoverageType =
	| "my-employer"
	| "spouse-employer"
	| "obamacare"
	| "cobra"
	| "medicaid"
	| "tricare"
	| "union"
	| "no-coverage";
export type TypeSpecialExceptions = "disabled" | "hsa" | "ss" | "rr" | "none";
export type TypeMedicareSignUpStatus = "partA" | "partAB" | "neither" | "";

// Maps Customer model field names to the localStorage keys where they are stored
interface StringStringMap {
	[key: string]: string;
}
const localStorageFieldMap: StringStringMap = {
	employment: STORAGE_KEYS.HEAR_DATA.EMPLOYMENT,
	credit: STORAGE_KEYS.HEAR_DATA.CREDIT_SCORE,
	age: STORAGE_KEYS.HEAR_DATA.AGE,
	partner: STORAGE_KEYS.PARTNER,
	utmSource: STORAGE_KEYS.UTM.SOURCE,
	utmMedium: STORAGE_KEYS.UTM.MEDIUM,
	utmCampaign: STORAGE_KEYS.UTM.CAMPAIGN,
	utmContent: STORAGE_KEYS.UTM.CONTENT,
	utmTerm: STORAGE_KEYS.UTM.TERM,
	utmAudience: STORAGE_KEYS.UTM.AUDIENCE,
	utmDrip: STORAGE_KEYS.UTM.DRIP,
};

export type PartBRecommendation =
	| "STAY"
	| "CONSIDER"
	| "LIKELY"
	| "LIKELYGENERIC"
	| "UNKNOWN"
	| "ERROR"
	| "ALREADYENROLLED"
	| "ALREADYENROLLEDSTAY";

export interface ICustomer {
	id: string;
	email: string;
	birthmonth: string;
	birthyear: string;
	zip: string;
	maZipReferenceId: string;

	firstName: string;
	lastName: string;
	middleName: string;
	dob: string; // YYYY-MM-DD
	phoneNumber: string;
	gender: "Male" | "Female" | null;
	incomeMinimum: number;
	incomeMaximum: number;
	numDependents: number;
	wantsSpouseCoverage: boolean;
	tobacco: boolean;

	// Part B Recommendation fields
	partBRecommendation: string;
	calculatedPartBPremium: number;
	currentInsurancePremium: number;
	currentInsuranceDeductible?: number;
	hasMoreThanTwentyEmployees: boolean;
	calculatedTotalPremium?: number;

	// Social Security Calculator Fields
	isReceivingSocialSecurityBenefits: boolean;
	hasExistingMedicareFromDisability: boolean;
	isEnrolledInMedsup: boolean;

	// Flow 2.0 Fields
	currentInsurancePlanType: string;
	currentInsuranceCarrierShort: string;
	seekingCoverageFor?: "me" | "meAndSpouse" | "other";
	spouseName?: string;
	spouseEmail?: string;
	currentAdditionalCoverageType?: TypeCurrentAdditionalCoverageType;
	medicareSignUpStatus?: TypeMedicareSignUpStatus;
	currentInsuranceCoverageType: TypeCurrentInsuranceCoverageType;
	specialExceptions: TypeSpecialExceptions;

	retirementDate: string;
	retirementStatus: TypeRetirementStatus;
	expectedRetirementDate?: string; // YYYY-MM-DD;

	enrollmentWindowStart: string;
	enrollmentWindowEnd: string;

	partAEffectiveDate: Date | string;
	partBEffectiveDate: Date | string;

	premiumAffordability: boolean;
	zeroPremiumPreference: boolean;

	// Tracking Fields
	utmSource: string;
	utmMedium: string;
	utmCampaign: string;
	utmContent: string;
	utmTerm: string;
	utmAudience: string;
	utmDrip: string;
	// Warning! This partner field refers to which company sent us this customer
	// It has nothing to do with their spouse
	partner: string;

	// Hear.com Fields
	employment: string;
	credit: string;
	age: string;

	// Temporary fields
	name?: string;

	lowestPlanN: number;
	lowestPremium: number;
	lowestComparablePremium: number;
}

export interface ICustomerQuestion {
	customerId: string;
	howCanWeHelpYou: string;
	howCanWeHelpYouSomethingElse: string;
	mostImportantToYou: string;
	mostImportantToYouSomethingElse: string;
	signedUpForPartB: boolean;
	willSignUpForPartB: boolean;
	stoppedWork: boolean;
	willStopWork: boolean;
	tobacco: boolean;
	changeProviders: string;
	prescriptionCoverage: boolean;
	premiumAffordability: boolean;
	zeroPremiumPreference: boolean;
	coverageInMultipleLocations: boolean;
	referrals: boolean;
	vision: boolean;
	hearing: boolean;
	dental: boolean;
	additionalBenefits: string;
	acceptedScopeOfAppointment: boolean;
	talkAboutMedicareAdvantage: boolean;
	talkAboutMedigap: boolean;
	talkAboutPartD: boolean;
}

export interface ICustomerAndCustomerQuestion {
	customer: Partial<ICustomer>;
	customerQuestion: Partial<ICustomerQuestion>;
}

/**
 * Wrapper service around API calls. As the codebase grows
 * it may be wise to split this up per model (customer.api.service, etc)
 */
class ApiService {
	/** Updates or creates the customer by the LocalStorage id with the provided data */
	updateCustomer(data: Partial<ICustomer>) {
		const saveData = {
			id: this.getCustomerId(),
			...data,
		};

		this.setDataFromLocalStorage(saveData);

		return axios
			.post(endpoints.customerSave(), saveData)
			.then((response) => {
				const customerId = response.data;

				// If server sends new customerId, set it after the save
				if (customerId !== saveData.id) {
					this.storeCustomerId(customerId);
					this.onUpdateCustomerId(customerId);
				}

				return response;
			})
			.catch(console.error);
	}

	updateCustomerQuestion(data: Partial<ICustomerQuestion>) {
		const saveData = {
			customerId: this.getCustomerId(),
			...data,
		};

		return axios.post(endpoints.questionSave(), saveData);
	}

	async updateCustomerAndCustomerQuestion(
		customer: Partial<ICustomer>,
		customerQuestion: Partial<ICustomerQuestion>
	) {
		// Due to poor API design, this.updateCustomer must be run first & serially because it generates the customer ID
		await this.updateCustomer(customer);

		// this.updateCustomerQuestion can be run once we have the customerID
		this.updateCustomerQuestion(customerQuestion);
	}

	fetchCustomer(token: string): any {
		return axios.get(endpoints.customerGet({token})).then((response) => {
			this.storeCustomerId(response.data.id);
			return response;
		});
	}

	private setDataFromLocalStorage(customer: Partial<ICustomer>): void {
		for (const field in localStorageFieldMap) {
			const lsKey = localStorageFieldMap[field];
			customer[field as keyof ICustomer] = LocalStorage.getItem(lsKey) || "";
		}
	}

	private getCustomerId() {
		return LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID);
	}

	private storeCustomerId(customerId: string) {
		LocalStorage.setItem(STORAGE_KEYS.CUSTOMER_ID, customerId);
	}

	private onUpdateCustomerId(customerID: string) {
		FullStory.identify(customerID, {
			clientVersion: process.env.REACT_APP_CLIENT_VERSION,
		});

		if (window.dataLayer) {
			window.dataLayer.push({customerID});

			eventTracker.log({
				name: eventTracker.EVENTS.RECORD_ID,
			});
		}
	}
}

export const apiService = new ApiService();

import Radio from "@material-ui/core/Radio";
import MaterialRadioGroup, {
	RadioGroupProps,
} from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import React, {ReactNode} from "react";
import clsx from "clsx";

export interface IRadioGroupProps extends RadioGroupProps {
	labelClassName?: string;
	radioClassName?: string;
	options: any;
	children?: ReactNode;
	name: string;
	isVertical?: boolean;
	labelElement?: any;
	additional?: any;
}

function FormLabel({
	labelClassName,
	radioClassName,
	className,
	labelElement,
	option,
	...props
}: any) {
	return (
		<FormControlLabel
			className={clsx(className)}
			key={option.value}
			classes={{
				label: "font-unset " + labelClassName,
				root: "mx-0 mx-auto " + className,
			}}
			value={option.value}
			control={<Radio className={radioClassName} color="primary" />}
			label={
				(labelElement &&
					labelElement({
						...option,
						selected: option.value === props.value,
					})) ||
				option.label
			}
		/>
	);
}

export function RadioGroup({
	options,
	name,
	isVertical,
	children,
	additional,
	...props
}: IRadioGroupProps) {
	const rowLength =
		isVertical && options.length > 5
			? Math.ceil(options && options.length / 2)
			: options.length;

	return (
		<FormControl component="fieldset" classes={{root: "w-full"}}>
			<MaterialRadioGroup
				row={!isVertical}
				aria-label={name}
				name={name}
				{...props}
			>
				{options.map((option, i) => (
					<div key={option.value}>
						<FormLabel
							option={option}
							name={name}
							{...props}
							additional={additional}
						/>
						<div className="col-span-2">
							{option &&
								props.value === option.value &&
								additional &&
								additional(props.value)}
						</div>
					</div>
				))}
			</MaterialRadioGroup>
		</FormControl>
	);
}

import React from "react";
import styled from "styled-components";
import CheckboxGroup from "common/ui/Checkbox";
import Icon from "common/ui/Icon";
import IconBackdrop from "common/ui/Icon/IconBackdrop";
import InlineTip from "common/ui/InlineTip";
import STYLES from "common/util/constants/styles";

export const IndentedCheckboxGroup = styled(CheckboxGroup)`
	padding-left: 50px;
`;

export const ConfirmationWrapper = styled.div`
	padding: 10px;
	text-align: center;

	h4 {
		font-size: 42px;
		font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
	}

	button {
		margin: 65px auto 0 auto;
	}
`;

export const InlineTipStyled = styled(InlineTip)`
	max-width: 350px;
	margin: auto;
`;

const TooltipContentStyled = styled.div`
	display: flex;
	align-items: center;

	> :first-child {
		flex-shrink: 0;
		margin-right: 25px;
	}
`;

export const TooltipContent = () => {
	return (
		<TooltipContentStyled>
			<IconBackdrop color={STYLES.COLORS.PEACH} width={33}>
				<Icon src="exclamation" width={6} color="white" />
			</IconBackdrop>
			<div>Please select at least one box</div>
		</TooltipContentStyled>
	);
};

export const CheckboxGroupWrapper = styled.div`
	margin-top 30px;

	form {
		width: fit-content;
		margin: auto;
	}

	label {
		padding: 5px;
	}
`;

export const IconBackdropCentered = styled(IconBackdrop)`
	margin: auto;
`;

export const BoldedText = styled.div`
	font-size: 25px;
	font-weight: ${STYLES.FONT_WEIGHT.BOLD};
	margin-top: 10px;
	line-height: 36px;
`;

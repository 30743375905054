import React from "react";
import {ToggleButtonGroup as BaseToggleButtonGroup} from "common/ui/Button/ToggleButtonGroup";
import {RadioGroup} from "common/ui/RadioGroup/RadioGroup";
import {
	InputTextBirthday,
	InputTextMonthYear,
	InputTextZip,
	InputTextAge,
} from "common/ui/InputText";
import clsx from "clsx";

export function Label({label, labelClassName}) {
	return <div className={labelClassName || "text-lg font-bold"}>{label}</div>;
}

export function ToggleButtonGroup({
	containerClassName,
	labelClassName,
	helperText,
	...props
}: any) {
	return (
		<>
			<div
				className={labelClassName}
				dangerouslySetInnerHTML={{__html: props.label}}
			></div>
			<BaseToggleButtonGroup {...props} />
		</>
	);
}

export function Radio({containerClassName, labelClassName, ...props}: any) {
	return (
		<>
			<div className={labelClassName}>{props.label}</div>
			<RadioGroup
				isVertical={false}
				labelClassName={"mr-6 text-center mx-auto w-full"}
				{...props}
			/>
		</>
	);
}

export function MonthYear({
	labelClassName,
	containerClassName,
	additional,
	...props
}) {
	return (
		<div
			className={clsx(
				"flex  mx-auto items-center space-y-3",
				containerClassName
			)}
		>
			<div className={labelClassName}>{props.label}</div>
			<InputTextMonthYear placeholder={"MM/YYYY"} {...props} />
		</div>
	);
}

export function Birthday({labelClassName, additional, ...props}) {
	return (
		<div className="flex flex-col mx-auto items-center space-y-3">
			<div className={labelClassName}>{props.label}</div>
			<InputTextBirthday {...props} />
		</div>
	);
}

export function AgeTextInput({labelClassName, ...props}) {
	return (
		<div className="w-32">
			<div className={labelClassName}>{props.label}</div>
			<InputTextAge className="-px-2" {...props} />
		</div>
	);
}

export function ZipTextInput({labelClassName, ...props}) {
	return (
		<div className="w-32">
			<div className={labelClassName}>{props.label}</div>
			<InputTextZip {...props} />
		</div>
	);
}

import React from "react";
import useSWR from "swr";
import {
	calculateEnrollmentWindowResult,
	enrollmentWindowService,
} from "../service";

import {Actions} from "./Actions";
import {EnrollmentDateDetails} from "./EnrollmentDateDetails";
import {EnrollmentDates} from "./EnrollmentDates";
import {Alert} from "pages/Flow/shared/Alert";
import {useUser} from "common/hooks/useUser";

export function Result() {
	const {data: values} = useSWR(
		"/api/user/enrollment-window/result",
		(url) => enrollmentWindowService.values
	);
	const {user} = useUser();

	const {
		exception,
		alert,
		enrollmentWindowResult: data,
	} = calculateEnrollmentWindowResult(
		values || ({} as any),
		user?.medicareSignUpStatus
	);

	// Redirect to page 1 if no data
	return (
		<>
			{data && (
				<div className="flex flex-col">
					<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
						{/** Page Header */}
						{data.titleLines?.map((title) => (
							<p key={title}>{title}</p>
						))}
					</div>
					{exception && (
						<Alert
							title={exception.title}
							descriptionLines={exception.descriptionLines}
							collapse={true}
						/>
					)}
					<div className="flex items-center my-10 max-w-xl w-full mx-auto shadow border rounded px-10 py-5">
						<img
							className="mr-10 h-32"
							src={"/images/illustrations/calendar-with-woman.svg"}
						/>
						{data.calculatedStartText && (
							<EnrollmentDates
								calculatedStartText={data.calculatedStartText}
								calculatedEndText={data.calculatedEndText}
							/>
						)}
					</div>
					<div className="w-full">
						{alert && (
							<Alert
								title={alert.title}
								descriptionLines={alert.descriptionLines}
							/>
						)}

						<EnrollmentDateDetails data={data} />
						{data.nextStepActions && <Actions actions={data.nextStepActions} />}
					</div>
				</div>
			)}
		</>
	);
}

import {dependentCode, IEmployerData, IEmployerPlanDetails} from "./models";

/**
 *
 * This file is generated based off of LifeSouth's benefit guide
 * It contains plan information, and information about the company
 * itself, for use in the employer-specific calculator
 */

const PLANS: IEmployerData["plans"] = [
	{
		value: "hsa-plan",
		label: "LifeSouth HSA Plan",
		isHsa: true,
	},
	{
		value: "co-pay-plan",
		label: "LifeSouth Copay Plan",
		isCoPay: true,
	},
];
const hsaEoPlanDetails = {
	annualDeductible: 2000,
	coInsurance: 2000,
	maxOopIn: 4000,
	maxOopOn: 9000,
};

const hsaEcPlanDetails = {
	annualDeductible: 2800,
	coInsurance: 3200,
	maxOopIn: 6000,
	maxOopOn: 18000,
};

const hsaEsPlanDetails = {
	annualDeductible: 4000,
	coInsurance: 4000,
	maxOopIn: 8000,
	maxOopOn: 18000,
};

const hsaEfPlanDetails = {
	annualDeductible: 4000,
	coInsurance: 4000,
	maxOopIn: 8000,
	maxOopOn: 18000,
};

const copayEoPlanDetails = {
	annualDeductible: 5000,
	coInsurance: 1350,
	maxOopIn: 6350,
	maxOopOn: 20000,
};

const copayEcPlanDetails = {
	annualDeductible: 7500,
	coInsurance: 2025,
	maxOopIn: 9525,
	maxOopOn: 30000,
};

const copayEsPlanDetails = {
	annualDeductible: 10000,
	coInsurance: 2700,
	maxOopIn: 12700,
	maxOopOn: 40000,
};

const copayEfPlanDetails = {
	annualDeductible: 10000,
	coInsurance: 2700,
	maxOopIn: 12700,
	maxOopOn: 40000,
};

// T: Tobacco
// NT: Non-tobacco
// EO: Employee only
// EC: Employee & child(ren)
// ES: Employee & spouse
// EF: Employee & family

const PLAN_DATA = {
	"hsa-plan": {
		"EO-NT": {
			...hsaEoPlanDetails,
			premium: 94,
		},
		"EC-NT": {
			...hsaEcPlanDetails,
			premium: 200,
		},
		"ES-NT": {
			...hsaEsPlanDetails,
			premium: 564,
		},
		"EF-NT": {
			...hsaEfPlanDetails,
			premium: 656,
		},
		"EO-T": {
			...hsaEoPlanDetails,
			premium: 164,
		},
		"EC-T": {
			...hsaEcPlanDetails,
			premium: 270,
		},
		"ES-T": {
			...hsaEsPlanDetails,
			premium: 634,
		},
		"EF-T": {
			...hsaEfPlanDetails,
			premium: 726,
		},
	},
	"co-pay-plan": {
		"EO-NT": {
			...copayEoPlanDetails,
			premium: 84,
		},
		"EC-NT": {
			...copayEcPlanDetails,
			premium: 180,
		},
		"ES-NT": {
			...copayEsPlanDetails,
			premium: 532,
		},
		"EF-NT": {
			...copayEfPlanDetails,
			premium: 618,
		},
		"EO-T": {
			...copayEoPlanDetails,
			premium: 154,
		},
		"EC-T": {
			...copayEcPlanDetails,
			premium: 250,
		},
		"ES-T": {
			...copayEsPlanDetails,
			premium: 602,
		},
		"EF-T": {
			...copayEfPlanDetails,
			premium: 688,
		},
	},
};

export function getPlanDetails(
	planId: string,
	dependentCode: dependentCode = "EO",
	tobacco: boolean
): IEmployerPlanDetails {
	return PLAN_DATA[planId][`${dependentCode}-${tobacco ? "T" : "NT"}`];
}

export const LifeSouthEmployerData: IEmployerData = {
	id: "life-south",
	name: "LifeSouth",
	imageUrl: "/images/employers/life-south-logo.png",
	email: "LifeSouth@getchapter.com",
	nameLong: " LifeSouth Community Blood Centers",
	location: "Northeast Florida",
	phoneNumberFormatted: "(352) 254-3609",
	phoneNumberRaw: "3522543609",
	plans: PLANS,
	hsaLimit: 30,
	premiumDiscountOptions: [
		{value: "0", label: "$0"},
		{
			value: "10",
			label: "$10",
		},
	],
};

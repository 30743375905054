import React from "react";
import ExpandableImage from "common/ui/ExpandableImage";
import newAccountImage from "./images/new-account.png";

const descriptionLines = [
	"You’ll need to create an account to sign up for Medicare – don’t worry, this will NOT enroll you in Social Security benefits.",
	"Read and agree to the terms of service",
	"<b>Fill out your basic information:</b> You’ll need your Social Security number and a valid email address.",
	"You’ll create your password on the next screen.",
];

export function SocialSecurityAccountCallout() {
	return (
		<div className="shadow rounded border px-10 py-7">
			<div className="text-xl mb-5">
				Don't have a "my Social Security" account yet?
			</div>
			<div className="flex space-x-3">
				<div className="ml-3 prose w-1/2">
					<ul className="prose space-y-4">
						{descriptionLines.map((line) => (
							<li
								key={line}
								className="font-medium text-gray-700"
								dangerouslySetInnerHTML={{__html: line}}
							/>
						))}
					</ul>
				</div>
				<div className="w-1/2">
					<ExpandableImage
						src={newAccountImage}
						alt="Social Security Website Account"
						className="object-contain ml-auto"
					/>
				</div>
			</div>
		</div>
	);
}

import styled from "styled-components";

const defaultStyles = `
	input:focus,
	textarea:focus,
	div:focus,
	button:focus {
		outline: none;
	}
	height: 100%;
	display: flex;
	flex-direction: column
`;

export const AppWrapper = styled.div`
	${({keyboardAccessability}) => !keyboardAccessability && defaultStyles}
`;

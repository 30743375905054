import React, {useEffect} from "react";
import {Formik, Form, useField} from "formik";
import clsx from "clsx";
import {FORM_FIELDS, ISwitcherFormFields} from "./data";
import axios from "common/util/api/axios";
import {Button} from "common/ui/Button/Button";
import {FormField} from "pages/Flow/shared/FormField";
import {useBoolean} from "common/hooks";
import {Dialog} from "@material-ui/core";
import {MedSupIdCardModal} from "pages/Flow/shared/MedSupIdCardModal";
import {useUser} from "common/hooks/useUser";

function CurrentInsurancePremium({values}: {values?: ISwitcherFormFields}) {
	const [, , {setValue}] = useField("currentInsurancePremium");

	async function fetchPremium() {
		if (!values) {
			return;
		}
		if (
			values.zip &&
			values.age &&
			values.gender &&
			values.tobacco &&
			values.currentInsurancePlanType &&
			values.currentInsuranceCarrierShort
		) {
			await axios
				.get(
					`/medsup/lowest_premium?zipcode=${values.zip}&age=${
						values.age
					}&gender=${
						values.gender
					}&partner=${"false"}&tobacco=${!!values.tobacco}&planType=${
						values.currentInsurancePlanType
					}&carrier=${values.currentInsuranceCarrierShort}`
				)
				.then((response) => {
					const rawPrice = response.data;
					setValue(rawPrice);
				})
				.catch((err) => {
					console.warn("Retrieving Zip Premium Error", err);
				});
		}
	}

	useEffect(() => {
		fetchPremium();
	}, [values, values?.age, values?.gender, values?.currentInsurancePlanType]);

	return (
		<div className="flex flex-col">
			<div className="text-lg mr-5 mb-5">
				How much do you pay in premiums per month?
			</div>
			<div className="flex items-center">
				I pay
				<FormField
					fieldData={{
						type: "currency",
						name: "currentInsurancePremium",
						label: "I Pay",
					}}
				/>
				per month
			</div>
		</div>
	);
}

export function MedSupQuiz({
	onSubmit,
}: {
	onSubmit: (values: ISwitcherFormFields) => void;
}) {
	const {updateUser} = useUser();

	async function handleSubmit(values) {
		console.log("values", values);

		await axios
			.get(
				`/medsup/lowest_premium?zipcode=${values.zip}&age=${
					values.age
				}&gender=${
					values.gender
				}&partner=${"false"}&tobacco=${!!values.tobacco}&planType=${
					values.currentInsurancePlanType
				}`
			)
			.then((response) => {
				const rawPrice = response.data;
				onSubmit(values);
				updateUser({
					lowestComparablePremium: rawPrice,
					...values,
				});
			})
			.catch((err) => {
				console.warn("Retrieving Zip Premium Error", err);
			});
	}

	const dialogOpen = useBoolean(false);

	return (
		<>
			<Dialog open={dialogOpen.state} onClose={dialogOpen.setFalse}>
				<MedSupIdCardModal onHelp={() => {}} onClose={dialogOpen.setFalse} />
			</Dialog>
			<div className="flex flex-col items-start max-w-2xl">
				<div className="font-bold text-2xl mt-6 leading-relaxed px-4 mb-5">
					Let’s find your current plan, so that we can compare it to other
					options.
				</div>

				<button
					className="mx-auto text-underline-accent mb-5"
					onClick={() => {
						dialogOpen.setTrue();
					}}
				>
					Where can I find this information?
				</button>

				<div className="w-full">
					<Formik
						initialValues={
							{
								zip: "",
								age: "",
								currentInsurancePlanType: "G",
								currentInsuranceCarrierShort: "",
								gender: "Female",
								tobacco: "false",
							} as ISwitcherFormFields
						}
						onSubmit={handleSubmit}
					>
						{({values}) => (
							<Form className="w-full">
								<>
									<div className="space-y-5 text-left">
										{FORM_FIELDS.map((row, i) => (
											<div className={clsx("flex ")} key={i}>
												{row.map((field) => (
													<div className="flex-grow pr-2" key={field.name}>
														<FormField fieldData={field} />
													</div>
												))}
											</div>
										))}
										<CurrentInsurancePremium values={values} />
									</div>
									<div className="my-5">
										<Button type="submit">Find my savings</Button>
									</div>
								</>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</>
	);
}

export const INTRO_DATA = {
	neither: {
		title:
			"Understood. I can show you how to sign up for <br/> Parts A & B and review the basics of Medicare.",
		descriptionLines: [
			"Since you’re <b> new to Medicare </b>, there are a few steps that you’ll need to complete to start your coverage.",
			"To make things easy, I’ve created a list of steps that we’ll go through together on the next page.",
		],
	},
	partAB: {
		title:
			"Got it. Since you’ve already signed up for Parts A & B, let’s talk about additional coverage.",
		descriptionLines: [
			"Remember, <b>Parts A & B cover at most 80%</b> of your medical expenses. We’ll review some options to help you cover that missing 20%.",
			"To make things easy, I’ve created a list of steps that we’ll go through together on the next page.",
		],
	},
	partA: {
		title:
			"Got it. I can share details on Part B and we can review the basics of Medicare.",
		descriptionLines: [
			"Since you might want to <b>sign up for Part B</b>, there are a few steps you should know about.",
			"To make things easy, I’ve created a list of steps that we’ll go through together on the next page.",
		],
	},
};

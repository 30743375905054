export const hospital = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 62 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55 57a1 1 0 001-1V32a1 1 0 00-1-1H31a1 1 0 00-1 1v24a1 1 0 001 1h24zM32 33h22v8h-2v-5a1 1 0 00-1-1h-8a1 1 0 00-1 1v5h-2v-5a1 1 0 00-1-1h-4a1 1 0 00-1 1v5h-2v-8zm18 8v-4h-2v4h2zm-4-4v4h-2v-4h2zm-8 4v-4h-2v4h2zm16 10h-2v-5a1 1 0 00-1-1h-6a1 1 0 00-1 1v5h-2v-5a1 1 0 00-1-1h-6a1 1 0 00-1 1v5h-2v-8h22v8zm-4 0v-4h-4v4h4zm-10-4v4h-4v-4h4zm14 8v-2H32v2h22z"
      fill={color}
    />
    <path fill={color} d="M20 47h2v4h-2zM12 47h2v4h-2z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 18v-3h-3a1 1 0 01-1-1v-4a1 1 0 011-1h3V6a1 1 0 011-1h4a1 1 0 011 1v3h3a1 1 0 011 1v4a1 1 0 01-1 1h-3v3a1 1 0 01-1 1h-4a1 1 0 01-1-1zm1-7h-3v2h3a1 1 0 011 1v3h2v-3a1 1 0 011-1h3v-2h-3a1 1 0 01-1-1V7h-2v3a1 1 0 01-1 1z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.949 11H61a1 1 0 011 1v14a1 1 0 01-1 1h-1v35a1 1 0 01-1 1H3a1 1 0 01-1-1V27H1a1 1 0 01-1-1V12a1 1 0 011-1h19.049a10.994 10.994 0 0121.9 0zM40 12a9 9 0 00-9-9 9.011 9.011 0 00-9 9 9 9 0 0018 0zM16 61H8V37h8v24zm2-24v24h8V37h-8zm40 24H28V36a1 1 0 00-1-1H7a1 1 0 00-1 1v25H4V27h54v34zM2 25h58V13H41.951a10.994 10.994 0 01-21.9 0H2v12z"
      fill={color}
    />
  </svg>
);

import React, {Component} from "react";
import styled from "styled-components";
import Card from "common/ui/Card";
import IconList from "common/ui/IconList";
import HoverTooltip from "common/ui/Tooltip/HoverTooltip";
import STYLES from "common/util/constants/styles";

const Wrapper = styled.div`
	text-align: center;
	max-width: 1000px;
	margin: auto;
	padding: 0 20px;
`;

const BoxRow = styled.div`
	@media (min-width: 640px) {
		display: flex;
		justify-content: space-between;
		margin-top: 50px;

		> div {
			flex: 1;
			max-width: 450px;
		}

		> :first-child {
			margin-right: 20px;
		}
	}

	@media (max-width: 639px) {
		> div {
			margin-top: 50px;
		}
	}
`;

const description =
	"The Medicare Part D benefit provides coverage for prescription drugs and sometimes has its own premium required, depending on the plan";

const partDDescription = (
	<>
		The Medicare Part D benefit provides coverage for prescription drugs and
		sometimes has its own premium required, depending on the plan
	</>
);

const content = [
	{
		header: (
			<>
				Supplemental Medicare
				<br />
				(Medigap)
			</>
		),
		color: STYLES.COLORS.SKY_BLUE,
		items: [
			"Are add-on plans that help cover the 20%+ of costs that are not paid for by Traditional  Medicare",
			'Fills "gaps" in Traditional Medicare – that\'s why many people refer to these plans as "Medigap" plans',
			"Sits on top of Traditional Medicare coverage so you still receive appropriate coverage via Part A and Part B",
			<>
				Does not include drug coverage so is frequently combined with a{" "}
				<HoverTooltip content={description}>Medicare Part D</HoverTooltip> drug
				plan.
			</>,
		],
	},
	{
		header: (
			<>
				Medicare Advantage
				<br />
				(Part C)
			</>
		),
		color: STYLES.COLORS.SALMON,
		items: [
			"Are substitutes for Traditional Medicare that are provided by private insurance companies and largely paid for by the government",
			"Must cover at least the same benefits as Traditional  Medicare (Part A & Part B)",
			"Often include additional benefits like coverage for prescription drugs, dental, vision, or hearing services",
			<>
				Often includes{" "}
				<HoverTooltip content={partDDescription}>Medicare Part D</HoverTooltip>{" "}
				prescription drug benefits
			</>,
		],
	},
];

const CardWrapper = ({header, color, items}) => {
	return (
		<Card color={color}>
			<h4>{header}</h4>
			<IconList items={items} color={color} />
		</Card>
	);
};

export class MedSupVsMedAdv extends Component {
	static containerColor = STYLES.COLORS.GLACIER;

	render() {
		return (
			<Wrapper>
				<h2>Two types of additional Medicare coverage</h2>
				<BoxRow>
					{content.map((c, index) => (
						<CardWrapper key={index} {...c} />
					))}
				</BoxRow>
			</Wrapper>
		);
	}
}

import {Component} from "react";
import PropTypes from "prop-types";

export default class CounterAnimated extends Component {
	constructor(props) {
		super(props);
		const numIterations = props.durationMs / 50;

		this.state = {
			current: 0,
			increment: Math.ceil(props.endNumber / numIterations),
		};

		window.setTimeout(this.initializeInterval, props.delayMs);
	}

	initializeInterval = () => {
		this.intervalId = window.setInterval(this.iterate, 50);
	};

	iterate = () => {
		const {current, increment} = this.state;
		const {endNumber} = this.props;

		this.setState({current: Math.min(endNumber, current + increment)});
	};

	render() {
		return this.state.current;
	}
}

CounterAnimated.propTypes = {
	endNumber: PropTypes.number.isRequired,
	durationMs: PropTypes.number,
	delayMs: PropTypes.number,
};

CounterAnimated.defaultProps = {
	durationMs: 1000,
	delayMs: 0,
};

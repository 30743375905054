import React, {useEffect, useState} from "react";
import Icon from "common/ui/Icon";
import {Form} from "../PartBCalculator/Form";
import {Result} from "../PartBResults/Result";
import {partBCalculatorService} from "../PartBCalculator/service";
import {calculatePremium} from "../PartBResults/service";
import {useHistory} from "react-router";
import {Helmet} from "react-helmet";
import {
	SITE_DESCRIPTION,
	SITE_IMAGE_URL,
	SITE_TITLE,
	SITE_URL,
} from "pages/PartBCalculator/data/header-constants";
import {Share} from "common/ui/Share";
import {SocialSecurityQuestions} from "pages/SocialSecurityCalculator/Questions";

function ShareCard({...props}: any) {
	return (
		<Share
			shareLink={SITE_URL}
			title={SITE_TITLE}
			description={`I used this free calculator to understand how Social Security and Medicare interact and to estimate my premiums. I wanted to share it with you too: ${SITE_URL}`}
			shareText={"Share to help your friends save, too"}
			channels={["FACEBOOK", "EMAIL", "NEXTDOOR"]}
			{...props}
		/>
	);
}

export default function SocialSecurityCalculatorResults() {
	// Fetch results from calculator service and use that to setState. Note,
	// Realtime changes in the service will not be captured here as we're only
	// setting state once. Calculations can be manually updated with
	// 'updateCalculations' below
	const [results, setResults] = useState(partBCalculatorService.getResults());
	const [calculatedResults, setCalculatedResults] = useState(
		calculatePremium(partBCalculatorService.getResults())
	);

	function scrollToTop() {
		window?.scrollTo(0, 0);
	}

	// Update Calculations
	function updateCalculations() {
		const res = partBCalculatorService.getResults();
		setResults(res);
		setCalculatedResults(calculatePremium(res));

		// Save to server on resubmit
		partBCalculatorService.saveCustomerData();

		// Scroll to top to ensure user recognizes new results
		scrollToTop();
	}

	useEffect(() => {
		// Given we're in a single page app, we scroll to the top on first render
		// to ensure after navigation we're at the top of the page
		scrollToTop();
	}, []);

	let history = useHistory();

	return (
		<div className="container relative mx-auto px-7 md:px-20 text-black text-base font-normal">
			<Helmet>
				<title>Social Security and Medicare Premium Calculator</title>
				<meta property="og:url" content={SITE_URL} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={SITE_TITLE} />
				<meta property="og:description" content={SITE_DESCRIPTION} />
				<meta property="og:image" content={SITE_IMAGE_URL} />
			</Helmet>
			<div className="flex">
				<div className="flex flex-col mx-auto">
					<div className="flex flex-col mt-5 lg:mt-2 lg:absolute lg:left-0">
						<button
							className="flex items-center"
							onClick={() => {
								history.goBack();
							}}
						>
							<Icon
								src="leftChevron"
								height={20}
								width={20}
								alt={"Go Back"}
								color="#BDBDBD"
							/>
							<div className="ml-2 text-sm">Back</div>
						</button>
					</div>
					<div className="my-3">
						{calculatedResults && <Result result={calculatedResults} />}
					</div>
					<div className="my-5 xl:hidden">
						<ShareCard className="max-w-2xl mx-auto" row={true} />
					</div>
					<div className="mx-auto max-w-4xl my-16">
						<div className="font-medium text-xl my-4 text-accent">
							Your responses
						</div>
						<Form
							initialData={results}
							onSubmit={updateCalculations}
							AdditionalQuestionsComponent={SocialSecurityQuestions}
						/>
					</div>
				</div>
				<div className="absolute right-0 mt-9 xl:mr-10 hidden lg:block">
					<ShareCard className="w-36 pl-4 xl:pl-0" />
				</div>
			</div>
		</div>
	);
}

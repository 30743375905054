import React from "react";

export const formulary = ({color, height, width}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 54 69"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g mask="url(#a)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M48.877 64.371a2.435 2.435 0 01-1.792.245 2.418 2.418 0 01-1.459-1.15l-2-3.495 4.158-2.471 1.968 3.45a2.495 2.495 0 01-.875 3.421zm-8.998 1.754H4.875a1.449 1.449 0 01-1.458-1.438v-5.75h7.292c.805 0 1.459-.643 1.459-1.437 0-.795-.654-1.438-1.459-1.438H3.417V30.189h5.28c.804 0 1.458-.644 1.458-1.438 0-.795-.654-1.438-1.459-1.438h-5.28v-1.437c0-3.175 2.613-5.75 5.835-5.75h26.252c3.222 0 5.834 2.575 5.834 5.75v1.438H16.543c-.805 0-1.458.642-1.458 1.437 0 .794.653 1.438 1.458 1.438h24.794v17.35a5.396 5.396 0 00-1.72.618c-2.533 1.511-3.396 4.725-1.954 7.273l.365.633H16.543c-.805 0-1.458.643-1.458 1.438 0 .794.653 1.438 1.458 1.438h23.131l1.663 2.874v2.876c0 .793-.652 1.437-1.458 1.437zM19.46 17.25h5.834v-2.875H19.46v2.875zm-7.292-5.75h20.418V2.875H12.168V11.5zm34.188 43.528L42.183 57.5l-1.968-3.451a2.49 2.49 0 01.874-3.392c.36-.204.767-.311 1.181-.317.203-.02.409-.02.613 0a2.43 2.43 0 011.459 1.151l2.014 3.537zm5.949 4.542l-5.411-9.444a5.22 5.22 0 00-2.64-2.286V25.875c0-4.764-3.917-8.625-8.75-8.625H28.21v-2.875h4.536c1.522 0 2.756-1.216 2.756-2.717V2.717c0-1.5-1.234-2.717-2.756-2.717h-20.74c-1.522 0-2.756 1.216-2.756 2.717v8.941c0 1.5 1.234 2.717 2.756 2.717h4.536v2.875H9.251C4.419 17.25.5 21.111.5 25.875v38.813C.5 67.069 2.458 69 4.875 69H39.88c1.855.001 3.509-1.151 4.127-2.875a5.404 5.404 0 002.32 1.335c.448.115.908.174 1.37.174a5.29 5.29 0 002.654-.718c2.575-1.514 3.444-4.783 1.955-7.346z"
				fill={color}
			/>
		</g>
		<g mask="url(#b)" fillRule="evenodd" clipRule="evenodd" fill={color}>
			<path d="M21.5 11h2V5h-2v6zM15.5 11h2V5h-2v6zM27.5 11h2V5h-2v6zM21.162 45.338v4.55a.126.126 0 00.126.112h4.424a.126.126 0 00.126-.112v-4.55h4.55a.126.126 0 00.112-.126v-4.424a.126.126 0 00-.112-.126h-4.55v-4.55a.126.126 0 00-.126-.112h-4.424a.126.126 0 00-.126.112v4.55h-4.55a.125.125 0 00-.112.126v4.424a.125.125 0 00.112.126h4.55z" />
		</g>
	</svg>
);

import React, {useState, useContext, createContext} from "react";

/**
 * The user context returned from the {@link useSidebar} hook.
 * @category Client
 */
export interface ISidebarContext {
	isSidebarOpen: boolean;
	// TODO add in types for ref
	scrollRef: any;
	setScrollRef: any;
	setIsSidebarOpen: (value: boolean) => void;
}

const missingSidebarProvider =
	"You forgot to wrap the app in <SidebarProvider>";

const SidebarContext = createContext<ISidebarContext>({
	get isSidebarOpen(): never {
		throw new Error(missingSidebarProvider);
	},

	get scrollRef(): never {
		throw new Error(missingSidebarProvider);
	},

	get setScrollRef(): never {
		throw new Error(missingSidebarProvider);
	},

	setIsSidebarOpen(): never {
		throw new Error(missingSidebarProvider);
	},
});

export type UseSidebar = () => ISidebarContext;

export const useSidebar: UseSidebar = () =>
	useContext<ISidebarContext>(SidebarContext);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useSidebar().
export function ProvideSidebar({children}) {
	const user = useProvideSidebar();
	return (
		<SidebarContext.Provider value={user as any}>
			{children}
		</SidebarContext.Provider>
	);
}

// Provider hook that creates sidebar object and handles state
export function useProvideSidebar() {
	const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

	const [scrollRef, setScrollRef] = useState(null);
	// Return the sidebar props
	return {
		isSidebarOpen,
		scrollRef,
		setScrollRef,
		setIsSidebarOpen,
	};
}

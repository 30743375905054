import React, {Component} from "react";
import * as FullStory from "@fullstory/browser";
import Button from "common/ui/Button";
import axios from "common/util/api/axios";
import endpoints from "common/util/api/endpoints";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import STYLES from "common/util/constants/styles";
import eventTracker from "common/util/eventTracker";
import {
	Container,
	Title,
	SubTitle,
	Text,
	ZipcodeInput,
	SelectCounty,
	Image,
	InlineTipStyled,
} from "./ZipCodeHelpers";

export class ZipCode extends Component {
	static showRecentSavings = true;

	constructor(props) {
		super(props);

		const basicInfo = LocalStorage.getItem(STORAGE_KEYS.PAGES.BASIC_INFO);
		this.state = {
			zipcode: basicInfo?.zipcode || "",
			county: "",
			countyOptions: [],
			errorText: "",
		};

		this.setParentPersistence();
		props.setOnAttemptAdvance(this.onAttemptAdvance);
		props.setBottomNextButtonText(null);
	}

	componentDidUpdate(oldProps, oldState) {
		const {zipcode, county} = this.state;

		this.setParentPersistence();

		LocalStorage.setItem(STORAGE_KEYS.PAGES.BASIC_INFO, {zipcode});

		const inputsChanged =
			oldState.zipcode !== zipcode || oldState.county !== county;
		if (inputsChanged) {
			const errorText = this.generateErrorText();
			// Only update the error text if it's to clear the error tip
			// Otherwise, it pesters users while they're typing
			if (!errorText) this.setState({errorText});
		}
	}

	componentDidMount() {
		const {zipcode} = this.state;

		if (zipcode.length === 5) this.fetchCountyOptions(zipcode);
	}

	onAttemptAdvance = () => {
		const errorText = this.generateErrorText(true);
		this.setState({errorText});
		return !errorText;
	};

	generateErrorText = (attemptedAdvance) => {
		const {zipcode, county, countyOptions} = this.state;

		let errorText = "";

		try {
			this.isValidZipcode(zipcode);
			this.isValidCounty(county, countyOptions);
		} catch (e) {
			// Only show the error text if the user attempted to advance to
			// the next page. This prevents the error popup from showing
			// while the user is actively typing
			// if (attemptedAdvance)
			errorText = e.message;
		}

		return errorText;
	};

	isValidZipcode = (zipcode) => {
		if (zipcode.length !== 5) throw new Error("Please enter a valid zipcode");

		return true;
	};

	isValidCounty = (county, countyOptions) => {
		if (countyOptions.length >= 2 && !county)
			throw new Error("Please select a county");

		return true;
	};

	setParentPersistence = () => {
		const {setPersistence} = this.props;
		const {zipcode, county} = this.state;

		const employment =
			LocalStorage.getItem(STORAGE_KEYS.HEAR_DATA.EMPLOYMENT) || "";
		const credit =
			LocalStorage.getItem(STORAGE_KEYS.HEAR_DATA.CREDIT_SCORE) || "";
		const age = LocalStorage.getItem(STORAGE_KEYS.HEAR_DATA.AGE) || "";
		const partner = LocalStorage.getItem(STORAGE_KEYS.PARTNER) || "";
		const utmSource = LocalStorage.getItem(STORAGE_KEYS.UTM.SOURCE) || "";
		const utmMedium = LocalStorage.getItem(STORAGE_KEYS.UTM.MEDIUM) || "";
		const utmCampaign = LocalStorage.getItem(STORAGE_KEYS.UTM.CAMPAIGN) || "";
		const utmContent = LocalStorage.getItem(STORAGE_KEYS.UTM.CONTENT) || "";
		const utmTerm = LocalStorage.getItem(STORAGE_KEYS.UTM.TERM) || "";
		const utmAudience = LocalStorage.getItem(STORAGE_KEYS.UTM.AUDIENCE) || "";
		const utmDrip = LocalStorage.getItem(STORAGE_KEYS.UTM.DRIP) || "";

		const firstName = LocalStorage.getItem(STORAGE_KEYS.FIRST_NAME);

		setPersistence({
			url: endpoints.customerSave(),
			data: {
				zip: zipcode,
				partner,
				employment,
				credit,
				utmSource,
				utmMedium,
				utmCampaign,
				utmContent,
				utmTerm,
				utmAudience,
				utmDrip,
				firstName,
				age,
				maZipReferenceId: county,
			},
			onResponse: this.onResponse,
		});
	};

	onChangeZipcode = (val) => {
		const zipcode = val.replace(/\D/g, "").substring(0, 5);

		const newState = {zipcode};

		if (zipcode.length === 5) {
			this.fetchCountyOptions(zipcode);
		} else {
			newState.countyOptions = [];
			newState.county = "";
		}

		this.setState(newState);
	};

	fetchCountyOptions = async (zipcode) => {
		const {data} = await axios.get(endpoints.zipToCounty({zip: zipcode}));

		const countyOptions = data.map((c) => ({
			value: c.id.toString(),
			label: c.county,
		}));
		const newState = {countyOptions, county: ""};

		// Auto-select if there's only one option
		if (newState.countyOptions.length === 1) {
			newState.county = newState.countyOptions[0].value;
		}
		this.setState(newState);
	};

	onResponse = (customerID) => {
		const {zipcode} = this.state;

		LocalStorage.setItem(STORAGE_KEYS.CUSTOMER_ID, customerID);

		FullStory.identify(customerID, {
			clientVersion: process.env.REACT_APP_CLIENT_VERSION,
		});

		if (window.dataLayer) {
			window.dataLayer.push({customerID});

			eventTracker.log({
				name: eventTracker.EVENTS.RECORD_ID,
			});
		}

		// Record zipcode's average premium cost for a later page to reference
		axios
			.get(endpoints.zipcodePremiumSearch({zip: zipcode}))
			.then((response) => {
				LocalStorage.setItem(
					STORAGE_KEYS.ZIPCODE_MONTHLY_PREMIUMS,
					response.data.premium
				);
			});
	};

	onKeyDownWrapper = (key) => {
		const {advancePage} = this.props;

		if (key === "Enter" && this.onAttemptAdvance()) advancePage();
	};

	render() {
		const {zipcode, countyOptions, county, errorText} = this.state;
		const {advancePage} = this.props;

		const showCountySelect = countyOptions.length >= 2;

		return (
			<Container>
				<Title>Find the Medicare plan that fits you best.</Title>
				<SubTitle>Enter your zip code</SubTitle>
				<Text>(Plan options vary by location)</Text>
				<ZipcodeInput
					value={zipcode}
					onChange={this.onChangeZipcode}
					name="zipcode"
					className="fs-exclude"
					placeholder="Type Here"
					onKeyDown={this.onKeyDownWrapper}
				/>
				{showCountySelect && (
					<SelectCounty
						value={county}
						onChange={(county) => this.setState({county})}
						options={countyOptions}
						placeholder="County"
					/>
				)}
				<InlineTipStyled
					color={STYLES.COLORS.WARNING_RED}
					visible={!!errorText}
					children={errorText}
					width={250}
				/>
				<Button colorScheme="red" text="Continue" onClick={advancePage} />
				<Image src="/images/misc/building-community.svg" />
			</Container>
		);
	}
}

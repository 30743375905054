import {useUser} from "common/hooks/useUser";
import {Button} from "common/ui/Button/Button";
import {NumberOfPlans} from "pages/Flow/Recommend/Compare";
import {FooterCta} from "pages/Flow/shared/FooterCta";
import {PlanComparison} from "pages/Flow/shared/PlanComparison";
import React, {useEffect, useState} from "react";
import {ScheduleCard} from "../../shared/ScheduleCard";

const EXPERT_GUIDANCE_REMAIN_LINES = [
	"We can help you find drug coverage, and benefits like hearing, dental and vision.",
	"Receive updates about premium changes with your plan.",
];

export function MedsupPlanComparison() {
	const {user} = useUser();
	const [savings, setSavings] = useState(0);
	useEffect(() => {
		if (user) {
			const calculatedSavings =
				user.currentInsurancePremium! - user?.lowestComparablePremium!;
			if (calculatedSavings >= 0) {
				setSavings(calculatedSavings);
			}
		}
	}, [user]);

	// Learn more and schedule a call
	return (
		<div className="w-full flex flex-col h-full">
			{user &&
				user.currentInsurancePremium !== undefined &&
				user.lowestComparablePremium !== 0 && (
					<>
						<div className="w-full bg-teal-100 bg-opacity-40 py-10">
							<div className="flex mx-auto max-w-3xl mt-4">
								<div className="text-left space-y-4 text-xl">
									{savings > 0 ? (
										<>
											<div>
												We found another Plan{" "}
												{user && user.currentInsurancePlanType} that saves you
											</div>
											<div className="text-5xl font-bold text-teal-900">
												${savings} per year
											</div>
											<div>in premiums, with the same coverage.</div>
										</>
									) : (
										<>
											<div className="text-5xl font-bold text-teal-900">
												Good news!
											</div>
											<div>
												You’re already on the lowest premium Plan{" "}
												{user && user.currentInsurancePlanType} in your area.
											</div>
										</>
									)}
									<Button>Learn more and schedule a call</Button>
								</div>
								<div className="ml-auto my-auto">
									<img
										className="h-52"
										src="/images/illustrations/man-pointing.svg"
									/>
								</div>
							</div>
						</div>
						<div className="my-10 max-w-3xl mx-auto">
							<PlanComparison
								factors={[
									{imgSrc: "/images/icons/hands.svg", name: "Savings"},
									{imgSrc: "/images/icons/doctors.svg", name: "Coverage"},
								]}
								plans={[
									{
										name: "Current plan",
										features: [
											`Premiums: $${user && user.currentInsurancePremium}/year`,
											"Identical Coverage",
										],
									},
									{name: "grid", features: []},

									{
										name: "Recommended plan",
										features: [
											`Premiums: $${
												user && user.lowestComparablePremium
											}/year <br /> <span class="text-xs font-bold">You save $${savings} per year</span>`,
											"Identical Coverage",
										],
										recommended: true,
									},
								]}
							/>
						</div>

						<div className="text-2xl my-10">
							We also found {(user && <NumberOfPlans zip={user.zip} />) || "?"}{" "}
							other options in your area for you to compare.
						</div>
					</>
				)}
			<div className="mx-auto max-w-3xl">
				<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
					Schedule your peace-of-mind consult
				</div>
				<ScheduleCard expertGuidanceText={EXPERT_GUIDANCE_REMAIN_LINES} />
			</div>
			<div className="mt-auto">
				<FooterCta />
			</div>
		</div>
	);
}

import React, {ReactChild, useState} from "react";
import Icon from "../Icon";
import {Popup} from "../Tooltip/Popup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

export function Information({
	height,
	width,
	title,
	children,
	className,
}: {
	height?: number;
	width?: number;
	children: ReactChild;
	title?: string;
	className?: string;
}) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	/*
	// Darsh 3/21
	// Code below is to enable appearance on hover
	// For now, it's not the ux we want, so I'm commenting
	// it out but we may re-use it later

	onMouseEnter={handleMouseEnter}
	const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
		if (anchorEl === event.currentTarget) {
			return;
		}
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};*/

	return (
		<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
			<div className={className}>
				<button type="button" onClick={handleClick} className="">
					<Icon src="help" height={height || 20} width={width || 20} />
				</button>
				<Popup isOpen={Boolean(anchorEl)} anchorEl={anchorEl}>
					<div className="bg-white px-5 py-3 border shadow-xl rounded-md">
						{title && (
							<div className="text-base font-semibold mb-1">{title}</div>
						)}
						{children}
					</div>
				</Popup>
			</div>
		</ClickAwayListener>
	);
}

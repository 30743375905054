import React, {Component} from "react";
import sectionPageArray from "pages/QuestionFlow/pageStructure";
import {TabBarWrapper, Tab} from "./TabBarHelpers";

const sections = sectionPageArray.map((s) => {
	s.component = s.pages[0].name;
	return s;
});

export default class TabBar extends Component {
	onSelectWrapper = (selectedTab) => {
		// const {skipToComponent} = this.props;
		// skipToComponent(componentLookup[selectedTab.component]);
	};

	render() {
		const {sectionPageIndeces} = this.props;

		const [sectionIndex] = sectionPageIndeces;

		return (
			<TabBarWrapper>
				{sections.map((s, index) => (
					<Tab
						tabInfo={s}
						key={index}
						onSelect={this.onSelectWrapper}
						isActive={index === sectionIndex}
					/>
				))}
			</TabBarWrapper>
		);
	}
}

import clsx from "clsx";
import * as React from "react";
import {Dialog} from "@material-ui/core";
import Icon from "../Icon";
import {useBoolean} from "../../hooks";

export interface IExpandableImageProps
	extends React.ImgHTMLAttributes<HTMLImageElement> {
	/** The class name applied to the image's container */
	containerClassName?: string;
	/** The class name applied to the image itself */
	className?: string;
}

export const ExpandableImage: React.FC<IExpandableImageProps> = React.memo(
	(props) => {
		const {className, containerClassName, ...rest} = props;
		const dialogOpen = useBoolean(false);

		return (
			<>
				<div
					className={clsx("relative inline-block h-full", containerClassName)}
				>
					<img
						className={className}
						{...rest}
						onClick={dialogOpen.setTrue}
						alt=""
					/>
					<div
						className={clsx(
							"absolute bottom-2 right-2 rounded-full flex items-center justify-center w-8 h-8 bg-white cursor-pointer",
							"transition-shadow shadow hover:shadow-lg"
						)}
						onClick={dialogOpen.setTrue}
					>
						<Icon src="magGlass" color="black" width={24} height={24} />
					</div>
				</div>
				<Dialog
					open={dialogOpen.state}
					onClose={dialogOpen.setFalse}
					fullWidth={true}
					maxWidth={"md"}
				>
					<div className="p-2">
						<img {...rest} />
					</div>
				</Dialog>
			</>
		);
	}
);

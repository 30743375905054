import React from "react";
import {
	TimelineView,
	TimelineWrapper,
	TimelineTextWrapper,
	FilledSection,
	UnfilledSection,
} from "./TimelineHelpers";

const names = ["PRIORITIES", "QUESTIONS", "SCHEDULE"];
const sectionWidthMap = [30, 60, 90];

export default function Timeline({section}) {
	return (
		<TimelineView>
			<TimelineWrapper>
				<FilledSection width={sectionWidthMap[section]} />
				<UnfilledSection section={section} />
			</TimelineWrapper>
			<TimelineTextWrapper>
				Part {section + 1}: {names[section]}
			</TimelineTextWrapper>
		</TimelineView>
	);
}

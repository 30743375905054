import {Formik, FormikProps} from "formik";
import React, {useState} from "react";
import * as Yup from "yup";

import {parseDateString} from "common/util/format";
import {EmployerCalculatorForm} from "./Form";
import {IEmployerData} from "../data/models";
import {IFormData, employeeCalculatorService} from "../service";

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

/**
 * Add any additonal validations here, but be sure to
 * add "required" again if overwriting and is required
 */
const CalculatorSchema = Yup.object().shape({
	zip: Yup.string().length(5, "Please Enter a valid zip").required("Required"),
	dob: Yup.date()
		.transform(parseDateString)
		.min(new Date(1900, 1, 1), "Please Enter a valid Birthdate")
		.max(yesterday, "Please Enter a valid Birthdate")
		.required("Required")
		.typeError("Please Enter a valid Birthdate"),
	gender: Yup.string().required("Required"),
	tobacco: Yup.string().required("Required"),
	hasWorkedTenYears: Yup.string().required("Required"),
	optFileTaxes: Yup.string().required("Required"),
});

export function EmployeeCalculatorFormWrapper({
	initialData,
	employerData,
	onSubmit,
}: {
	initialData?: IFormData;
	employerData: IEmployerData;
	onSubmit?: (values: IFormData) => void;
}) {
	const [data] = useState(
		initialData || employeeCalculatorService.getInitialValues()
	);

	return (
		<Formik
			initialValues={data || {}}
			validationSchema={CalculatorSchema}
			onSubmit={(values, {setSubmitting}) => {
				onSubmit && onSubmit(values);
				setSubmitting(true);
			}}
		>
			{(props: FormikProps<any>) => (
				<EmployerCalculatorForm
					values={props.values}
					isValid={props.isValid}
					initialData={initialData}
					employerData={employerData}
				/>
			)}
		</Formik>
	);
}

import React, {Component} from "react";
import PropTypes from "prop-types";
import {throttle} from "throttle-debounce";
import axios from "common/util/api/axios";
import TextInput from "common/ui/TextInput";
import DropDownList from "./DropDownList";
import {cycle} from "common/util/common";
import {DropDownStyled} from "./DropDownHelpers";

export default class DropDown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			textSearchValue: "",
			dropdownVisible: false,
			dropdownResults: [],
			highlightedRowIndex: -1,
			serverHasResponded: false,
		};

		this.dropDownRef = React.createRef();
	}

	requestDataThrottled = throttle(500, (url) => {
		axios
			.get(url)
			.then((results) => {
				const resultsWithKey = results.data.map(this.props.formatData);
				this.setState({
					dropdownResults: resultsWithKey,
					serverHasResponded: true,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	});

	componentDidMount() {
		window.addEventListener("click", this.handleWindowClick);
	}

	componentWillUnmount() {
		window.removeEventListener("click", this.handleWindowClick);
	}

	handleWindowClick = (e) => {
		const {dropdownVisible} = this.state;

		const clickedInput = e.target === this.dropDownRef.current;

		if (dropdownVisible && !clickedInput) {
			this.setState({dropdownVisible: false});
		} else if (!dropdownVisible && clickedInput) {
			this.setState({dropdownVisible: true});
		}
	};

	componentDidUpdate(prevProps, prevState) {
		const {url, cleanUserInput} = this.props;
		const {textSearchValue} = this.state;

		if (textSearchValue === prevState.textSearchValue) return;

		const cleanedValue = cleanUserInput(textSearchValue);

		if (!textSearchValue) {
			this.setState({dropdownVisible: false});
		} else if (cleanedValue.length >= 3) {
			this.requestDataThrottled(`${url}${cleanedValue}`);
		}
	}

	onChangeHandler = (newTextSearchValue) => {
		const {dropdownVisible} = this.state;

		const newState = {textSearchValue: newTextSearchValue};
		if (!dropdownVisible) newState.dropdownVisible = true;

		this.setState(newState);
	};

	onKeyDownHandler = (key) => {
		if (["ArrowUp", "ArrowDown"].includes(key))
			return this.onNavigationArrowKey(key);
		if ("Enter" === key) return this.onEnter();
	};

	onNavigationArrowKey = (key) => {
		const {highlightedRowIndex, dropdownResults} = this.state;

		const increment = key === "ArrowUp" ? -1 : 1;
		this.setState({
			highlightedRowIndex: cycle(
				highlightedRowIndex,
				dropdownResults.length,
				increment
			),
		});
	};

	onEnter = () => {
		const {highlightedRowIndex, dropdownResults} = this.state;

		if (highlightedRowIndex >= 0)
			this.onSelectRow(dropdownResults[highlightedRowIndex]);
	};

	onSelectRow = (row) => {
		const {onSelect} = this.props;

		this.setState({
			textSearchValue: "",
			dropdownVisible: false,
			dropdownResults: [],
			highlightedRowIndex: -1,
		});

		onSelect(row);
	};

	render() {
		const {
			textSearchValue,
			dropdownVisible,
			dropdownResults,
			highlightedRowIndex,
			serverHasResponded,
		} = this.state;
		const {placeholder, className, autoFocus, renderRow} = this.props;

		return (
			<DropDownStyled className={className}>
				<TextInput
					placeholder={placeholder}
					value={textSearchValue}
					onChange={this.onChangeHandler}
					onKeyDown={this.onKeyDownHandler}
					icon="mag-glass"
					iconPaddingLeft={100}
					iconPositionLeft={32}
					autoFocus={autoFocus}
					childRef={this.dropDownRef}
				/>
				{dropdownVisible && (
					<DropDownList
						elements={dropdownResults}
						onSelect={this.onSelectRow}
						renderRow={renderRow}
						highlightedRowIndex={highlightedRowIndex}
						showNoResults={serverHasResponded}
					/>
				)}
			</DropDownStyled>
		);
	}
}

DropDown.propTypes = {
	url: PropTypes.string.isRequired,
	onSelect: PropTypes.func.isRequired,
	placeholder: PropTypes.string.isRequired,
	renderRow: PropTypes.func.isRequired,
	autoFocus: PropTypes.bool,
	className: PropTypes.string,
	formatData: PropTypes.func,
	cleanUserInput: PropTypes.func,
};

DropDown.defaultProps = {
	formatData: (a) => a.key,
	cleanUserInput: (a) => a,
	className: "",
	autoFocus: false,
};

import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";

import {Button} from "common/ui/Button/Button";

import {FormField} from "../shared/FormField";
import {useUser} from "common/hooks/useUser";

const nameField = {
	name: "name",
	label: "My name is:",
	type: "name",
	placeholder: "Your Name",
	required: true,
};

const spouseNameField = {
	name: "spouseName",
	label: "My spouse's name is:",
	type: "name",
	placeholder: "Spouse's Name",
	required: true,
};

const theirNameField = {
	name: "theirName",
	label: "Their name is:",
	type: "name",
	placeholder: "Their Name",
	required: true,
};

export function About({onSubmit}: any) {
	const {user, updateUser} = useUser();

	const [title, setTitle] = useState("Tell me a bit about yourself.");
	const [fields, setFields] = useState([nameField]);

	useEffect(() => {
		if (user?.seekingCoverageFor === "me") {
			setTitle("Tell me a bit about yourself.");
			setFields([nameField]);
		} else if (user?.seekingCoverageFor === "meAndSpouse") {
			setTitle("Tell me a bit about you and your spouse.");
			setFields([nameField, spouseNameField]);
		} else if (user?.seekingCoverageFor === "other") {
			setTitle("Tell me a bit about your loved one.");
			setFields([theirNameField]);
		}
	}, [user]);

	return (
		<div className="flex flex-col mt-10 mx-auto">
			<div className="text-2xl">{title}</div>
			<Formik
				initialValues={{
					name: user?.name,
					spouseName: user?.spouseName,
					theirName: user?.name,
				}}
				onSubmit={(values) => {
					updateUser({
						name: values.name || values.theirName,
						spouseName: values.spouseName,
					});

					setTimeout(() => {
						onSubmit(values as any);
					}, 100);
				}}
			>
				{({submitForm}) => (
					<Form className="w-full mx-auto text-center">
						<div className="flex justify-center mx-auto my-10 mb-0 text-sm">
							{fields.map((field) => (
								<FormField
									key={field.name}
									fieldData={field}
									nextStep={() => {}}
									autoAdvance={false}
								/>
							))}
						</div>
						<div className="my-7">
							<Button
								type="button"
								onClick={() => {
									submitForm();
								}}
							>
								Continue
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
}

import styled from "styled-components";
import Icon from "common/ui/Icon";
import Button from "common/ui/Button";
import STYLES from "common/util/constants/styles";
import {isMobile} from "common/util/common";
import ExpertButton from "../Common/ExpertButton";

const Bold = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.BOLD};
`;

const XBoldDiv = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
	line-height: 25px;
`;

const XBoldSpan = styled.span`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
`;

export const Underline = styled.div`
	border-bottom: 1px solid ${STYLES.COLORS.DARK_MINT};
	padding-bottom: 4px;
	width: fit-content;
	margin: 20px auto;
	cursor: pointer;
	font-size: 16px;
`;

export const Wrapper = styled.div`
	max-width: 900px;
	margin: auto;
	text-align: center;
	padding-bottom: 50px;

	@media (max-width: 639px) {
		font-size: 16px;
	}
`;

export const PaddingDiv = styled.div`
	padding: 0 ${STYLES.PADDING.BODY_HORIZONTAL};
`;

const ShadowBox = styled.div`
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	margin-bottom: 55px;
`;

const ShadowBoxTop = styled(ShadowBox)`
	@media (min-width: 640px) {
		padding: 60px 25px 40px 25px;
		margin-left: ${STYLES.PADDING.BODY_HORIZONTAL};
		margin-right: ${STYLES.PADDING.BODY_HORIZONTAL};
	}

	@media (max-width: 639px) {
		box-shadow: none;
	}
`;

export const ShadowBoxBottom = styled(ShadowBox)`
	padding-top: 30px;
	margin-bottom: 0;

	${XBoldDiv} {
		margin-bottom: 30px;
	}
`;

const IconStyled = styled(Icon)`
	@media (min-width: 640px) {
		margin: 35px auto;
	}

	@media (max-width: 639px) {
		margin: 20px auto 30px auto;
	}
`;

const TopBoxTitle = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	max-width: 660px;
	margin: auto;

	@media (max-width: 639px) {
		font-size: 22px;
	}

	@media (min-width: 640px) {
		font-size: 31px;
	}
`;

const GenericWrapper = styled.div`
	@media (max-width: 639px) {
		max-width: 270px;
		margin: auto;
	}
`;

export const GenericTop = ({numPlans}) => {
	return (
		<GenericWrapper>
			<ShadowBoxTop>
				<TopBoxTitle>
					There are <XBoldSpan>{numPlans}</XBoldSpan> plans in your area that
					fit one or both of your needs.
				</TopBoxTitle>
				<IconStyled src="map" width={isMobile ? 75 : 130} />
				<Bold>90% of people are on the wrong Medicare plan.</Bold>
			</ShadowBoxTop>
		</GenericWrapper>
	);
};

export const BoxBlueBottom = styled.div`
	background-color: #eefdff;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;

	@media (max-width: 639px) {
		padding: 20px 10px;
	}

	@media (min-width: 640px) {
		padding: 30px 10px 20px 10px;
	}
`;

export const BoxWhiteBottom = styled.div`
	padding: 0 15px;

	@media (max-width: 639px) {
		${XBoldDiv} {
			max-width: 260px;
			margin-left: auto;
			margin-right: auto;
		}
	}
`;

export const ButtonWrapper = styled(Button)`
	display: block;
	border: none;
	box-shadow: 0px 0px 5px rgba(0, 31, 103, 0.25);
	font-size: 12px;

	@media (max-width: 639px) {
		margin-right: 10px;

		&:last-child {
			margin: 0;
		}
	}

	@media (min-width: 640px) {
		width: 200px;
		height: 125px;
	}

	img {
		margin: auto;
	}
`;

export const ButtonRow = styled.div`
	display: flex;
	justify-content: space-evenly;
	margin: 10px 0;
`;

export const LegalText = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 12px;
	line-height: 23px;
`;

export const BrokerImg = styled.img`
	border-radius: 50%;
	width: 75px;
	margin: 0 auto 10px auto;
`;

const FooterWrapper = styled.div`
	background-color: ${STYLES.COLORS.MIDNIGHT_BLUE};
	color: white;
	text-align: center;
	padding: 50px 0 10px 0;

	button {
		margin: auto;
	}

	${XBoldDiv} {
		margin-bottom: 30px;
	}

	@media (max-width: 639px) {
		padding: 15px ${STYLES.PADDING.BODY_HORIZONTAL};
	}
`;

export const Footer = (props) => {
	const {onClickSchedulerButton} = props;
	return (
		<FooterWrapper>
			<XBoldDiv>
				Speaking to an expert is free, and there’s no obligation to enroll.
			</XBoldDiv>
			{onClickSchedulerButton ? (
				<Button
					text="Speak with an expert"
					onClick={onClickSchedulerButton}
					colorScheme="red"
				/>
			) : (
				<ExpertButton buttonColorScheme="red" {...props} />
			)}
		</FooterWrapper>
	);
};

const WhySpeakWrapper = styled.div`
	text-align: left;
	margin-top: 55px;

	@media (max-width: 639px) {
		margin-bottom: 15px;
	}

	@media (min-width: 640px) {
		margin-bottom: 50px;
	}
`;

const WhySpeakTitle = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 31px;
`;

const WhySpeakBody = styled.div`
	display: flex;

	@media (max-width: 639px) {
		flex-flow: column-reverse;

		img {
			max-width: 210px;
			margin: auto;
		}
	}
`;

const WhySpeakSection = styled.div`
	padding: 15px 0;
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 16px;

	${Bold} {
		font-size: 20px;
		margin-bottom: 10px;
	}
`;

export const WhySpeak = () => {
	return (
		<WhySpeakWrapper>
			<WhySpeakTitle>Why should I speak with an expert?</WhySpeakTitle>
			<WhySpeakBody>
				<div>
					<WhySpeakSection>
						<Bold>Independent guidance</Bold>
						Most advisors search only a limited set of plan options. We search
						every available insurance carrier to find the right option for you
						at the most affordable rate.
					</WhySpeakSection>
					<WhySpeakSection>
						<Bold>Completely free, and no pressure</Bold>
						There is absolutely no cost associated with using Chapter, and we
						will never pressure you to enroll in a specific plan or even to
						enroll with us.
					</WhySpeakSection>
					<WhySpeakSection>
						<Bold>Outstanding, personalized service</Bold>
						Our licensed Medicare experts take the time to speak with every
						client. We answer your questions and help you understand your
						coverage options. We also provide ongoing support as questions arise
						throughout the year.
					</WhySpeakSection>
				</div>
				<img alt="" src="/images/misc/signpost.svg" />
			</WhySpeakBody>
		</WhySpeakWrapper>
	);
};

export const LargeButtonContent = ({icon, text}) => {
	return (
		<>
			<Icon src={icon} />
			<br />
			{text}
		</>
	);
};

export const SchedulerWrapper = styled.div`
	@media (max-width: 639px) {
		height: 450px;
	}
	@media (min-width: 640px) {
		height: 750px;
	}
`;

export {Bold, XBoldDiv, XBoldSpan, ShadowBoxTop, TopBoxTitle};

import React from "react";
import styled from "styled-components";
import STYLES from "common/util/constants/styles";
import {RecentSavingsType} from "./RecentSavingsTypes";

export const RecentSavingsOuterWrapper = styled.div`
	background-color: ${STYLES.COLORS.GLACIER};

	@media (min-width: 640px) {
		padding: 15px ${STYLES.PADDING.BODY_HORIZONTAL} 50px
			${STYLES.PADDING.BODY_HORIZONTAL};
	}

	@media (max-width: 639px) {
		padding: 15px ${STYLES.PADDING.BODY_HORIZONTAL};
	}
`;

export const OuterTitle = styled.div`
	font-size: 25px;
	margin-bottom: 20px;
	text-align: center;
`;

export const RecentSavingsCardWrapper = styled.div`
	display: flex;
	justify-content: center;

	@media (max-width: 639px) {
		flex-direction: column;
		align-items: center;
	}
`;

const RecentSavingsWrapper = styled.div`
	box-shadow: 0px 0px 5px rgba(0, 31, 103, 0.25);
	border-radius: 10px;
	width: 224px;
	text-align: center;

	@media (min-width: 640px) {
		margin-right: 30px;

		:last-of-type {
			margin: 0;
		}
	}

	@media (max-width: 639px) {
		margin: 15px auto;
	}
`;

const Header = styled.div`
	border-radius: 10px 10px 0 0;
	background-color: #ddfbfd;
	padding: 5px 0;
	font-size: 12px;
`;

const Body = styled.div`
	padding: 10px 10px 20px 10px;
	background-color: white;
	border-radius: 0 0 10px 10px;
`;

const StartPrice = styled.div`
	font-size: 18px;
	margin-bottom: 5px;
`;

const XBold = styled.span`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
`;

const Subtitle = styled.div`
	font-size: 16px;
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
`;

const Icon = styled.img`
	border-radius: 50%;
	width: 68px;
	margin: 10px auto;
`;

const PersonalInfo = styled.div`
	font-size: 12px;
`;

interface RecentSavingsCardProps {
	person: RecentSavingsType;
}

export const RecentSavingsCard = (props: RecentSavingsCardProps) => {
	const {person} = props;

	return (
		<RecentSavingsWrapper>
			<Header>{person.pctSaved} saved</Header>
			<Body>
				<StartPrice>
					<XBold>{person.startPrice}</XBold> per month saved
				</StartPrice>
				<Subtitle>{person.subtitle}</Subtitle>
				<Icon src={`/images/testimonials/${person.img}.png`} />
				<PersonalInfo>{person.personalInfo}</PersonalInfo>
			</Body>
		</RecentSavingsWrapper>
	);
};

import React from "react";
import {SignUpVideo} from "./Video";
import {Switch, Route, Redirect, useHistory} from "react-router";
import {
	SocialSecurityChecklist,
	SocialSecurityChecklistPartB,
} from "../../SocialSecurityChecklist";

import {Completed} from "./Completed";
import {SocialSecurityTip} from "./SocialSecurityTip";
import {useUser} from "common/hooks/useUser";

export function SignUp() {
	const history = useHistory();
	const {user} = useUser();

	return (
		<Switch>
			<Route path={"/flow/new/learn/sign-up/video"}>
				<div className="max-w-3xl mx-auto">
					<SignUpVideo
						onSubmit={() => {
							const checklistPath =
								user?.medicareSignUpStatus === "partA"
									? "part-b"
									: "part-a-and-b";
							history.push(
								`/flow/new/learn/sign-up/checklist/${checklistPath}`
							);
						}}
					/>
				</div>
			</Route>

			<Route path={"/flow/new/learn/sign-up/checklist/skip"}>
				<div className="max-w-3xl mx-auto">
					<SocialSecurityTip
						onSubmit={() => {
							history.push("/flow/new/recommend/explainer");
						}}
					/>
				</div>
			</Route>

			<Route path={"/flow/new/learn/sign-up/checklist/part-a-and-b"}>
				<div className="max-w-4xl mx-auto">
					<SocialSecurityChecklist
						onSubmit={() => {
							history.push("/flow/new/learn/sign-up/completed");
						}}
					/>
				</div>
			</Route>

			<Route path={"/flow/new/learn/sign-up/checklist/part-b"}>
				<div className="max-w-4xl mx-auto">
					<SocialSecurityChecklistPartB
						onSubmit={() => {
							history.push("/flow/new/learn/sign-up/completed");
						}}
					/>
				</div>
			</Route>

			<Route path={"/flow/new/learn/sign-up/completed"}>
				<div className="max-w-3xl mx-auto">
					<Completed
						onSubmit={() => {
							history.push("/flow/new/recommend/explainer");
						}}
					/>
				</div>
			</Route>
			<Redirect to="/flow/new/learn/sign-up/video" />
		</Switch>
	);
}

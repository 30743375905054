import React, {useState, useRef, useEffect} from "react";
import TextField, {TextFieldProps} from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputMask from "react-input-mask";
import styled from "styled-components";
import clsx from "clsx";
import Icon from "../Icon";
import {CHAPTER_PHONE_NUMBER_FORMATTED} from "common/util/common";

const Triangle = styled.div`
	width: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid #fde2e1;
	margin: auto;
`;

interface ITextInput {
	validator?: (value: string) => boolean;
	error?: boolean;
	hidePopup?: boolean;
	labelClassName?: string;
	inputClassName?: string;
	additional?: any;
}

type ITextInputProps = ITextInput & TextFieldProps;

/**
 *
 * The core input component is a wrapper around Material UI's Input
 * It supports start and end "adornments", error states and more.
 *
 * It currently surfaces errors in the form of a popup that only
 * disappears when the input becomes valid
 *
 * OnBlur and OnChange logic are handled before passing up to
 * props to ensure
 *
 * Input masks from react-input-masks are used for formatting
 * like phone number, zip code, etc
 */
export default function InputText({
	InputProps,
	validator,
	hidePopup,
	error,
	labelClassName,
	inputClassName,
	...props
}: ITextInputProps) {
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [errorText, setErrorText] = useState<any>(null);

	const textRef = useRef<any>();

	function setIsValid(err?: string) {
		if (!err) {
			setAnchorEl(null);
			setErrorText("");
		} else {
			setErrorText(err);
			setAnchorEl(textRef.current);
		}
	}

	/**
	 * On Change, run the validations but only to hide any
	 * potential errors
	 */
	function onChange(e: any) {
		const val = e.target.value;
		if (validator) {
			try {
				const isValid = validator(val);
				if (isValid) {
					setIsValid();
				}
			} catch (err) {
				setIsValid(err.message);
			}
		}
		props.onChange && props.onChange(e);
	}

	/**
	 * On Blur, run the validations, but only to show any
	 * potential errors
	 */
	function onBlur(e: any) {
		const val = e.target.value;
		if (validator) {
			try {
				validator(val);
			} catch (err) {
				setIsValid();
			}
		}
		props.onBlur && props.onBlur(e as any);
	}

	/**
	 * Use the parent's error state to trigger showing or hiding
	 * the popup as well
	 */
	useEffect(() => {
		setIsValid(error as any);
	}, [error]);

	return (
		<>
			<TextField
				ref={textRef}
				variant={"outlined" as any}
				classes={{
					root: clsx("font-unset font-medium", inputClassName),
				}}
				InputProps={{
					classes: {
						root: "bg-white",
						notchedOutline:
							"border border-gray-300 rounded-lg focused:border-blue-400",
					} as any,
					...InputProps,
				}}
				error={!!error}
				{...props}
				onChange={onChange}
				onBlur={onBlur}
			/>
		</>
	);
}

function AgeFormatCustom(props: any) {
	// https://github.com/mui-org/material-ui/pull/23174
	// inputRef is passed to input element by material or mask
	const {inputRef, ...others} = props;
	return <InputMask {...others} mask="99" maskChar={null} />;
}

export function InputTextAge(props: ITextInputProps) {
	return (
		<InputText
			InputProps={{
				inputComponent: AgeFormatCustom as any,
			}}
			{...props}
			label={""}
		/>
	);
}

function CurrencyFormatCustom(props: any) {
	// https://github.com/mui-org/material-ui/pull/23174
	// inputRef is passed to input element by material or mask
	const {inputRef, ...others} = props;
	return <InputMask {...others} mask="999" maskChar={null} />;
}

export function InputTextCurrency(props: ITextInputProps) {
	return (
		<InputText
			InputProps={{
				inputComponent: CurrencyFormatCustom as any,
				startAdornment: <InputAdornment position="start">$</InputAdornment>,
			}}
			{...props}
			label={""}
		/>
	);
}

function BirthdayFormatCustom(props: any) {
	// https://github.com/mui-org/material-ui/pull/23174
	// inputRef is passed to input element by material or mask
	const {inputRef, ...others} = props;
	return <InputMask {...others} mask="99/99/9999" maskChar={null} />;
}

export function InputTextBirthday(props: ITextInputProps) {
	// TODO use YUP schema here

	return (
		<InputText
			InputProps={{
				inputComponent: BirthdayFormatCustom as any,
			}}
			{...props}
			label={""}
		/>
	);
}

function ZipFormatCustom(props: any) {
	// https://github.com/mui-org/material-ui/pull/23174
	// inputRef is passed to input element by material or mask
	const {inputRef, ...others} = props;
	return <InputMask {...others} mask="99999" maskChar={null} />;
}

export function InputTextZip(props: ITextInputProps) {
	const isValidZipcode = (zipcode: string) => {
		if (zipcode.length !== 5) {
			throw new Error("Please enter a valid zipcode");
		}
		return true;
	};

	return (
		<InputText
			InputProps={{
				inputComponent: ZipFormatCustom as any,
			}}
			{...props}
			label={""}
			validator={isValidZipcode}
		/>
	);
}

function YearFormatCustom(props: any) {
	// https://github.com/mui-org/material-ui/pull/23174
	// inputRef is passed to input element by material or mask
	const {inputRef, ...others} = props;
	return <InputMask {...others} mask="99/9999" maskChar={null} />;
}

export function InputTextMonthYear(props: ITextInputProps) {
	return (
		<InputText
			InputProps={{
				inputComponent: YearFormatCustom as any,
			}}
			{...props}
			label={""}
		/>
	);
}

function PhoneFormatCustom(props: any) {
	// https://github.com/mui-org/material-ui/pull/23174
	// inputRef is passed to input element by material or mask
	const {inputRef, ...others} = props;
	return <InputMask {...others} mask="(999) 999-9999" maskChar={null} />;
}

export function InputTextPhone({className, ...props}: ITextInputProps) {
	const isValidPhone = (phone: string) => {
		const phoneMatchPattern = phone.replace(/\D/g, "").match(/^\d{10}$/);

		if (!phoneMatchPattern) {
			throw new Error("Please enter a valid phone number to continue");
		}

		return true;
	};

	return (
		<InputText
			InputProps={{
				inputComponent: PhoneFormatCustom as any,
				startAdornment: (
					<InputAdornment position="start">
						<Icon src="cell-phone" />
					</InputAdornment>
				),
			}}
			type="tel"
			placeholder={CHAPTER_PHONE_NUMBER_FORMATTED}
			className={clsx("fs-exclude", className)}
			{...props}
			label={""}
			validator={isValidPhone}
		/>
	);
}

export function InputTextEmail({className, ...props}: ITextInputProps) {
	const isValidEmail = (email: string) => {
		const emailMatchPattern = email.match(/.*@.*\..*/);

		if (!emailMatchPattern) {
			throw new Error("Please enter a valid email address");
		}

		return true;
	};

	return (
		<InputText
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<Icon src="email" />
					</InputAdornment>
				),
			}}
			type="email"
			placeholder="email@mail.com"
			className={clsx("fs-exclude", className)}
			{...props}
			label={""}
			validator={isValidEmail}
		/>
	);
}

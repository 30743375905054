import React, {Component} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as SVGs from "./svgs";
import STYLES from "common/util/constants/styles";

const IconStyled = styled.img`
	cursor: ${({onClick}) => (onClick ? "pointer" : "inherit")};
	height: ${({$height}) => ($height ? `${$height}px` : "auto")};
	width: ${({$width}) => ($width ? `${$width}px` : "auto")};
	object-fit: contain;
`;

export default class Icon extends Component {
	render() {
		const {src, onClick, alt, height, color, width, className} = this.props;

		if (SVGs[src]) {
			const SVGReact = SVGs[src];
			return (
				<SVGReact
					color={color}
					height={height}
					width={width}
					onClick={onClick}
					className={className}
				/>
			);
		}

		return (
			<IconStyled
				src={`/images/icons/${src}.svg`}
				onClick={onClick}
				alt={alt}
				$height={height}
				$width={width}
				className={className}
			/>
		);
	}
}

Icon.propTypes = {
	src: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	alt: PropTypes.string,
	height: PropTypes.number,
	width: PropTypes.number,
	color: PropTypes.string,
};

Icon.defaultProps = {
	alt: "",
	color: STYLES.COLORS.MIDNIGHT_BLUE,
};

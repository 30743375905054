import React, {Component} from "react";
import {DateTime} from "luxon";
import Icon from "common/ui/Icon";
import Button from "common/ui/Button";
import CheckboxGroup from "common/ui/Checkbox";
import {urlParse} from "common/util/urlParse";
import STORAGE_KEYS from "common/util/storage/keys";
import {LocalStorage} from "common/util/storage";
import STYLES from "common/util/constants/styles";
import axios from "common/util/api/axios";
import endpoints from "common/util/api/endpoints";
import eventTracker from "common/util/eventTracker";
import {
	IndentedCheckboxGroup,
	ConfirmationWrapper,
	InlineTipStyled,
	TooltipContent,
	CheckboxGroupWrapper,
	BoldedText,
} from "./BookingConfirmationHelpers";
import ThankYou from "./ThankYou";

export class BookingConfirmation extends Component {
	constructor(props) {
		super(props);

		const searchParamsMap = urlParse(document.location);
		const startDateTime = DateTime.fromISO(searchParamsMap.event_start_time);

		// lastName is the last string in the name field
		// firstName is everything else (eg might include middle name)
		const names = searchParamsMap.invitee_full_name.split(" ");
		const lastName = names.pop();
		const firstName = names.join(" ");

		this.info = {
			dateString: startDateTime.toFormat("ccc LLL d 'at' t ZZZZ"),
			dateSeconds: startDateTime.toSeconds(),
			phone: searchParamsMap.answer_1,
			email: searchParamsMap.invitee_email,
			assignedTo: searchParamsMap.assigned_to,
			firstName,
			lastName,
		};

		this.sendDataToServer();

		this.state = {
			discussAll: true,
			showError: false,
			showThankYou: false,
			plansSelected: this.planOptions.map((p) => p.value),
			firstName: LocalStorage.getItem(STORAGE_KEYS.FIRST_NAME),
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const {discussAll, plansSelected, showError} = this.state;

		if (
			prevState.discussAll === discussAll &&
			prevState.plansSelected.length === plansSelected.length &&
			prevState.showError === showError
		)
			return;

		if (!discussAll && !plansSelected.length) this.setState({showError: true});
	}

	toggleDiscussAll = () => {
		const {discussAll} = this.state;
		this.setState({discussAll: !discussAll});
	};

	planOptions = [
		{
			value: "medigap",
			label: "Medigap",
		},
		{value: "medadv", label: "Medicare Advantage"},
		{
			value: "partd",
			label: "Stand-alone Part D",
		},
	];

	togglePlan = (value) => {
		const {plansSelected} = this.state;

		let newState;
		if (plansSelected.includes(value)) {
			newState = plansSelected.filter((s) => s !== value);
		} else {
			newState = plansSelected.concat(value);
		}

		this.setState({plansSelected: newState});
	};

	sendDataToServer = () => {
		const id = LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID);

		const saveData = {
			id,
			scheduled: true,
			email: this.info.email,
			firstName: this.info.firstName,
			lastName: this.info.lastName,
			phoneNumber: this.info.phone,
		};

		axios.post(endpoints.customerSave(), saveData).catch(console.error);

		eventTracker.log({
			name: eventTracker.EVENTS.BOOK_APPOINTMENT,
			data: {
				start_date: this.info.dateSeconds,
				advisor: this.info.assignedTo,
			},
		});
	};

	confirm = () => {
		const {discussAll, plansSelected} = this.state;

		if (!plansSelected.length && !discussAll)
			return this.setState({showError: true});

		this.setState({showThankYou: true});

		const data = {
			talkAboutMedicareAdvantage: plansSelected.includes("medadv"),
			talkAboutMedigap: plansSelected.includes("medigap"),
			talkAboutPartD: plansSelected.includes("partd"),
			customerId: LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID),
		};

		axios.post(endpoints.questionSave(), data).catch(console.error);
	};

	discussAll = [
		{
			value: "discussAll",
			label: "I want to discuss all plan types",
		},
	];

	tipOnClose = () => {
		this.setState({showError: false});
	};

	render() {
		const {
			discussAll,
			plansSelected,
			showError,
			showThankYou,
			firstName,
		} = this.state;

		if (showThankYou)
			return (
				<ThankYou firstName={firstName} brokerName={this.info.assignedTo} />
			);

		const selected = [];
		if (discussAll) selected.push("discussAll");

		return (
			<ConfirmationWrapper>
				<h4>Please confirm your appointment</h4>
				<Icon src="calendar" />
				<BoldedText>
					{this.info.dateString}
					<br />
					with {this.info.assignedTo}
				</BoldedText>
				<CheckboxGroupWrapper>
					<CheckboxGroup
						type="column"
						selected={selected}
						options={this.discussAll}
						onChange={this.toggleDiscussAll}
					/>
					{!discussAll && (
						<IndentedCheckboxGroup
							type="column"
							selected={plansSelected}
							options={this.planOptions}
							onChange={this.togglePlan}
						/>
					)}
				</CheckboxGroupWrapper>
				<InlineTipStyled
					visible={showError}
					color={STYLES.COLORS.WARNING_RED}
					onClose={this.tipOnClose}
					children=<TooltipContent />
				/>
				<Button onClick={this.confirm} text="Confirm" />
			</ConfirmationWrapper>
		);
	}
}

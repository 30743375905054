import React, {Component} from "react";
import {SearchWrapper, SearchTop, ResultsRow} from "templates/search";
import endpoints from "common/util/api/endpoints";
import {LocalStorage} from "common/util/storage/";
import STORAGE_KEYS from "common/util/storage/keys";
import {Formulary} from "./FormularySearchHelpers";
import formatFormulary from "./FormatFormulary";
import "./index.css";

const baseTipText =
	"If easier, you can share your presciptions over the phone with one of our experts. Just hit skip and finish the remaining questions.";

export class FormularySearch extends Component {
	static maxWidth = 900;

	constructor(props) {
		super(props);

		const selectedFormularies =
			LocalStorage.getItem(STORAGE_KEYS.PAGES.FORMULARY_SEARCH) || [];

		this.state = {
			selectedFormularies,
			tipText: baseTipText,
		};
		this.state.showTip = !this.state.selectedFormularies.length;

		this.setBottomText();

		props.setOnAttemptAdvance(this.onAttemptAdvance);
	}

	componentDidUpdate(prevProps, prevState) {
		const {setPersistence} = this.props;
		const {selectedFormularies} = this.state;

		const prescriptionInfos = selectedFormularies.map((formulary) => {
			// Auto-select a rxcuid - helps the server reduce API calls
			// Also not sure why server has param 'rxcui'. I know it's confusing.
			// Param should be 'rxcuid'
			let rxcui = formulary.selectedRxcuid;
			let systemSelected = false;
			if (!rxcui && formulary.selectedDoseForm) {
				rxcui = formulary.doseFormMapping[formulary.selectedDoseForm][0].value;
				systemSelected = true;
			}

			return {
				genericPreferred: formulary.genericOk,
				generic: formulary.compounds,
				rxcui,
				systemSelected,
			};
		});

		setPersistence({
			url: endpoints.formularySave(),
			data: {prescriptionInfos},
		});

		LocalStorage.setItem(STORAGE_KEYS.PAGES.FORMULARY_SEARCH, selectedFormularies);

		if (selectedFormularies.length !== prevState.selectedFormularies.length)
			this.setBottomText();
	}

	setBottomText = () => {
		const {
			setBottomNextButtonText,
			setBottomNextButtonColorScheme,
		} = this.props;
		const {selectedFormularies} = this.state;

		const hasContent = selectedFormularies.length > 0;

		setBottomNextButtonText(hasContent ? "Next" : "Skip");
		setBottomNextButtonColorScheme(hasContent ? "blue" : "white");
	};

	onAttemptAdvance = () => {
		const {selectedFormularies} = this.state;

		const hasEmptyForms = selectedFormularies.find((f) => !f.selectedDoseForm);

		this.setState({didAttemptAdvance: true});

		return !hasEmptyForms;
	};

	renderDropDownRow = (formulary) => {
		if (formulary.brandName)
			return (
				<div>
					{formulary.brandName}
					<br />
					{`Generic: ${formulary.compounds}`}
				</div>
			);
		else return <div children={formulary.compounds} />;
	};

	openSecondTooltip = () => {
		this.setState({
			showTip: true,
			tipText: "You can also search for another prescription",
		});
	};

	addFormulary = (formulary) => {
		const {selectedFormularies} = this.state;

		// Make sure newly added formulary shows up first
		const newState = {
			selectedFormularies: [formulary].concat(selectedFormularies),
			showTip: false,
		};

		// Show an additional tooltip on a delay so it catches user's eye
		if (newState.selectedFormularies.length === 1) {
			window.setTimeout(this.openSecondTooltip, 1000);
		}

		this.setState(newState);
	};

	removeFormulary = (formulary) => {
		const {selectedFormularies} = this.state;

		const newFormularies = [...selectedFormularies].filter(
			(f) => formulary.key !== f.key
		);
		this.setState({selectedFormularies: newFormularies});
	};

	editFormulary = (formularyKey, field, value) => {
		const {selectedFormularies} = this.state;

		selectedFormularies.forEach((f) => {
			if (f.key !== formularyKey) return;

			// Update Dosage if user selects a new Form
			if (field === "selectedDoseForm" && f.selectedDoseForm !== value)
				f.selectedRxcuid = "";

			f[field] = value;
		});

		this.setState({selectedFormularies});
	};

	render() {
		const {
			selectedFormularies,
			showTip,
			didAttemptAdvance,
			tipText,
		} = this.state;

		return (
			<SearchWrapper>
				<SearchTop
					iconSrc="formulary"
					iconWidth={42}
					header="What is your most expensive prescription?"
					dropDownUrl={endpoints.formularySearch({})}
					dropDownOnSelect={this.addFormulary}
					dropDownPlaceholder="Search for a drug"
					formatData={formatFormulary}
					renderRow={this.renderDropDownRow}
					tipIsVisible={showTip}
					tipOnClose={() => this.setState({showTip: false})}
					tipText={tipText}
				/>
				<ResultsRow
					children={selectedFormularies.map((f) => (
						<Formulary
							formulary={f}
							removeFormulary={this.removeFormulary}
							editFormulary={this.editFormulary}
							key={f.key}
							didAttemptAdvance={didAttemptAdvance}
						/>
					))}
				/>
			</SearchWrapper>
		);
	}
}

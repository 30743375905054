import {IField} from "common/ui/Form/FormRow";
export interface ISwitcherFormFields {
	zip: string;
	age: string;
	currentInsurancePlanType: string;
	currentInsuranceCarrierShort: string;
	gender: "Male" | "Female" | "preferNotToAnswer";
	tobacco: "true" | "false";
}
export const FORM_FIELDS: Array<IField[]> = [
	[
		{
			name: "zip",
			label: "Zip Code",
			type: "zip",
			placeholder: "12345",
			required: true,
		},
		{
			name: "age",
			label: "Age",
			type: "ageNumber",
			placeholder: "65",
			required: true,
		},
	],
	[
		{
			name: "currentInsurancePlanType",
			label: "Plan Type",
			type: "autocompletePlans",
			placeholder: "N",
			required: true,
		},
		{
			name: "currentInsuranceCarrierShort",
			label: "Carrier",
			type: "autocompleteCarriers",
			placeholder: "United",
			required: true,
		},
	],
	[
		{
			name: "gender",
			label: "Gender",
			type: "radio",
			options: [
				{label: "Male", value: "Male"},
				{label: "Female", value: "Female"},
				{label: "Prefer not to answer", value: "preferNotToAnswer"},
			],
			required: true,
		},
	],
	[
		{
			name: "tobacco",
			label: "Do you use tobacco?",
			tooltip:
				"In certain circumstances, some Medicare Supplement plans will ask for this information.",
			type: "radio",
			options: [
				{label: "Yes", value: "true"},
				{label: "No", value: "false"},
			],
			required: true,
		},
	],
];

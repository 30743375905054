import React from "react";

interface IExplainerProps {
	title?: string;
	imgSrc: string;
	descriptionLines: string[];
}

export default function Explainer({
	title,
	imgSrc,
	descriptionLines,
}: IExplainerProps) {
	return (
		<>
			<div
				className="font-bold text-2xl max-w-2xl mx-auto mt-6 leading-relaxed px-4"
				dangerouslySetInnerHTML={{__html: title || ""}}
			/>
			<div className="flex items-center space-x-16 my-10">
				<img className="max-h-50" alt="E" src={imgSrc} />
				<div className="text-left space-y-5 leading-relaxed">
					{descriptionLines.map((line) => (
						<p key={line} dangerouslySetInnerHTML={{__html: line}}></p>
					))}
				</div>
			</div>
		</>
	);
}

import React, {useState, useEffect} from "react";
import {useFetch} from "common/hooks";
import {getMedsupLowestPremium} from "common/util/api/medsup";

import {Button} from "common/ui/Button/Button";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Redirect, useHistory, useRouteMatch} from "react-router";
import {FORM_FIELDS, premiumAffordabilityField} from "./data/form";

import {PlanQuestion} from "./PlanQuestion";
import Explainer from "pages/Flow/shared/Explainer";
import {FormField} from "pages/Flow/shared/FormField";
import {Formik, FormikProps} from "formik";
import endpoints from "common/util/api/endpoints";
import {useUser} from "common/hooks/useUser";
import * as Yup from "yup";

const zipField = {
	name: "zip",
	label: "Zip Code",
	type: "zip",
	placeholder: "Enter your zip code",
	required: true,
};

interface IAdditionalCoverageQuiz {
	premiumAffordability: boolean;
	zip: string;
	zeroPremiumPreference?: boolean;
}

interface IPageProps {
	values: IAdditionalCoverageQuiz;
	isValid?: boolean;
	handleSubmit: () => void;
}
export function AdditionalCoverageQuiz() {
	const {user} = useUser();

	return (
		<BaseForm
			initialValues={
				user && {
					premiumAffordability: user.premiumAffordability,
					zip: user.zip,
				}
			}
		/>
	);
}

const Page1 = ({values, isValid}: IPageProps) => {
	const history = useHistory();
	const {updateUser} = useUser();

	return (
		<>
			<div className="max-w-2xl text-center">
				<Explainer
					title="Tell us what matters to you, and we’ll pair you with the right plans in your area"
					descriptionLines={[
						`We have a database of <b>over 20,000 plans</b>, and all doctors in the US. Our technology can pair you with plans that fit your preferences.`,
						`<b>The difference?</b> Other advisors only search a few plans that pay them a commission – not the full set of options to find the best-fitting coverage for you.`,
					]}
					imgSrc="/images/illustrations/filling-out-form.svg"
				/>
				<div className="flex justify-center mx-auto my-10 mb-0 text-sm">
					<FormField
						fieldData={zipField}
						nextStep={() => {
							history.push("/flow/new/recommend/additional-coverage/2");
						}}
						autoAdvance={false}
					/>
				</div>
				<div className="my-7">
					<Button
						type="button"
						onClick={() => {
							if (isValid) {
								updateUser({zip: values.zip});
								history.push("/flow/new/recommend/additional-coverage/2");
							}
						}}
					>
						Continue
					</Button>
				</div>
			</div>
		</>
	);
};

const Page2 = ({values, handleSubmit}: IPageProps) => {
	const history = useHistory();

	const [query, setQuery] = useState("");
	useEffect(() => {
		if (values.zip) {
			setQuery(
				endpoints.medsupLowestPremium({
					zip: values.zip,
					tobacco: false,
					planType: "N",
				})
			);
		}
	}, [values, values.zip]);
	const {data: lowestPremium} = useFetch(query, getMedsupLowestPremium);

	const {updateUser} = useUser();

	return (
		<>
			<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
				{/** Page Header */}
				Coverage Type Questionaire
			</div>
			<div className="flex flex-col items-center">
				<div className="text-sm text-gray-600 mb-5 mt-3 leading-relaxed px-4">
					Question 2/2
				</div>
				<FormField
					fieldData={{
						...premiumAffordabilityField.fields[0],
						label: `<b>Your budget:</b> Would you be willing to pay <span class="text-underline-accent">$${
							lowestPremium || "?"
						}</span> per month for a plan  minimizes potential out-of-pocket costs more than other type of plans?`,
					}}
					nextStep={(value) => {
						setTimeout(() => {
							updateUser({
								lowestPlanN: lowestPremium,
								premiumAffordability: value !== "no",
							});
							if (value === "no") {
								handleSubmit();
							} else {
								history.push("/flow/new/recommend/additional-coverage/3");
							}
						}, 0);
					}}
					autoAdvance={FORM_FIELDS[1].autoAdvance}
				/>
			</div>
		</>
	);
};

const Page3 = ({values, handleSubmit}: IPageProps) => {
	const [query, setQuery] = useState("");

	useEffect(() => {
		if (values.zip) {
			setQuery(
				endpoints.medsupLowestPremium({
					zip: values.zip,
					tobacco: false,
					planType: "G",
				})
			);
		}
	}, [values, values.zip]);

	const {data: lowestPremium} = useFetch(query, getMedsupLowestPremium);

	const {user, updateUser} = useUser();

	return (
		<>
			<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
				{/** Page Header */}
				Coverage Type Questionaire
			</div>
			<div className="flex flex-col items-center">
				<div className="text-sm text-gray-600 mb-5 mt-3 leading-relaxed px-4">
					Question 3/3
				</div>
				<PlanQuestion
					premiumsRange={`$${
						(user && user.lowestPlanN) || 0
					} - $${lowestPremium}`}
					onSubmit={(tierId) => {
						updateUser({
							zeroPremiumPreference: tierId === "plan2",
							lowestPremium: lowestPremium,
						});
						setTimeout(() => {
							handleSubmit();
						});
					}}
				/>
			</div>
		</>
	);
};

const FormSchema = Yup.object().shape({
	zip: Yup.string().length(5, "Please Enter a valid zip").required("Required"),
});

const BaseForm = ({initialValues}: {initialValues: any}) => {
	const match = useRouteMatch();
	const {updateUser} = useUser();
	const history = useHistory();

	return (
		<Router>
			<Formik
				initialValues={initialValues}
				onSubmit={(values) => {
					updateUser(values);
					setTimeout(() => {
						history.push("/flow/new/consult");
					}, 300);
				}}
				validationSchema={FormSchema}
			>
				{({values, handleSubmit, isValid}: FormikProps<any>) => (
					<form onSubmit={handleSubmit}>
						<Switch>
							<Route
								path={`${match.path}/1`}
								render={(routeProps) => (
									<Page1 {...routeProps} values={values} isValid={isValid} />
								)}
							/>
							<Route
								path={`${match.path}/2`}
								render={(routeProps) => (
									<Page2
										{...routeProps}
										values={values}
										handleSubmit={handleSubmit}
									/>
								)}
							/>
							<Route
								path={`${match.path}/3`}
								render={(routeProps) => (
									<Page3
										{...routeProps}
										values={values}
										handleSubmit={handleSubmit}
									/>
								)}
							/>
							<Redirect to={`${match.path}/1`} />
						</Switch>
					</form>
				)}
			</Formik>
		</Router>
	);
};

import React, {useState} from "react";
import {useField} from "formik";

import axios from "../../util/api/axios";
import endpoints from "../../util/api/endpoints";

import {InputTextZip} from "../InputText";
import Select from "../Select";

const ZIP_LEN = 5;

interface IOption {
	value: string;
	label: string;
}

export function InputZipCounty({...props}) {
	const [countyOptions, setCountyOptions] = useState<IOption[]>([]);

	async function fetchCountyOptions(zip: string) {
		const {data} = await axios.get(endpoints.zipToCounty({zip}));

		const countyOptions = data.map((c: any) => ({
			value: c.id.toString(),
			label: c.county,
		}));

		setCountyOptions(countyOptions);
		// Default to first county
		countyOptions && setMaRefId(countyOptions[0]?.value);
	}

	function onChangeZipcode(e: any) {
		const zip = e.target.value.replace(/\D/g, "").substring(0, ZIP_LEN);

		if (zip.length === ZIP_LEN) {
			fetchCountyOptions(zip);
		} else {
			setCountyOptions([]);
			setMaRefId("");
		}
	}

	// This line of code assumes we're a part of a Formik Form
	// we should refactor or deconstruct more safely
	const [, , helpers] = useField("maZipReferenceId");
	const {setValue: setMaRefId} = helpers || {};

	return (
		<div className="flex items-center space-x-2">
			<InputTextZip
				{...props}
				onChange={(e) => {
					onChangeZipcode(e);
					props.onChange && props.onChange(e);
				}}
			/>
			{(countyOptions && countyOptions.length > 1 && (
				<div className="w-full flex-grow">
					<Select
						selectClassName="w-full flex bg-white shadow border-0 focus:border-blue-300 text-base py-4"
						options={countyOptions}
						value={countyOptions[0].value}
						onChange={() => {
							setMaRefId(countyOptions[0].value);
						}}
					/>
				</div>
			)) || <></>}
		</div>
	);
}

import React, {Component} from "react";
import styled from "styled-components";
import STORAGE_KEYS from "common/util/storage/keys";
import {LocalStorage} from "common/util/storage";
import STYLES from "common/util/constants/styles";

const Wrapper = styled.div`
	text-align: center;
	height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export class NiceToMeet extends Component {
	static containerColor = STYLES.COLORS.GLACIER;

	constructor(props) {
		super(props);

		this.state = {name: LocalStorage.getItem(STORAGE_KEYS.FIRST_NAME)};
		this.timeoutID = window.setTimeout(props.advancePage, 5000);
	}

	componentWillUnmount() {
		window.clearTimeout(this.timeoutID);
	}

	render() {
		const {name} = this.state;

		return (
			<Wrapper>
				<h2>It's nice to meet you{name && `, ${name}`}!</h2>
			</Wrapper>
		);
	}
}

import React, {Component} from "react";

export default class ProviderDropDownRow extends Component {
	render() {
		const {provider} = this.props;

		return (
			<div>
				<b children={`${provider.firstName} ${provider.lastName}`} />
				<div children={provider.specialties[0]} />
			</div>
		);
	}
}

import React from "react";
import {Field, getIn, FieldProps} from "formik";

import {IconButtonGroup} from "common/ui/Button/IconButtonGroup";
import {CarrierAutocomplete} from "pages/Flow/shared/carriers/Autocomplete";
import {PlanAutocomplete} from "pages/Flow/shared/carriers/PlanType";
import InputText, {InputTextCurrency} from "common/ui/InputText";
import {
	ToggleButtonGroup,
	Radio,
	Birthday,
	MonthYear,
	AgeTextInput,
	ZipTextInput,
	Label,
} from "./Fields";
import {Button} from "common/ui/Button/Button";

import {CHAPTER_PHONE_NUMBER_RAW} from "common/util/common";

const FIELD_COMPONENT_MAP = {
	name: InputText,
	toggleButton: ToggleButtonGroup,
	radio: Radio,
	iconButton: IconButtonGroup,
	birthdate: Birthday,
	monthyear: MonthYear,
	ageNumber: AgeTextInput,
	label: Label,
	zip: ZipTextInput,
	autocompleteCarriers: CarrierAutocomplete,
	autocompletePlans: PlanAutocomplete,
	currency: InputTextCurrency,
};

export function FieldAdditional({fields, nextStep}: {fields: any; nextStep}) {
	// return wrapper div with additional fuction fields and formField

	const customSubmit = fields.find((f) => f.type === "submit");

	return (
		<>
			<div className="mx-auto max-w-lg relative flex flex-col items-center text-center bg-gray-100 rounded-lg py-5 px-5 my-3">
				<div className="absolute top-0 -mt-3 arrow-up text-gray-100 text-lg"></div>
				<div className="space-y-3">
					{fields
						.filter((f) => f.type !== "submit")
						.map((fieldData) => (
							<FormField
								key={fieldData.name}
								labelClassName="text-base mb-0"
								fieldData={fieldData}
							/>
						))}

					{customSubmit ? (
						<a
							type="button"
							className="inline-flex items-center px-8 py-3 border border-transparent text-base font-bold rounded-full shadow-sm text-white bg-teal-700 hover:bg-teal-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 font-unset normal-case"
							href={
								customSubmit.action === "CALL_US"
									? `tel:${CHAPTER_PHONE_NUMBER_RAW}`
									: customSubmit.action
							}
						>
							{customSubmit.label}
						</a>
					) : (
						<Button
							type="button"
							onClick={() => {
								setTimeout(() => {
									nextStep();
								}, 300);
							}}
						>
							Continue
						</Button>
					)}
				</div>
			</div>
		</>
	);
}

export function FormField({
	fieldData,
	nextStep,
	autoAdvance,
	labelClassName,
}: {
	fieldData: any;
	nextStep?: (value?: any) => void;
	autoAdvance?: boolean;
	labelClassName?: string;
}) {
	const FieldComponent = FIELD_COMPONENT_MAP[fieldData.type];

	function handleNext(value: any, values: any) {
		const fields = fieldData.additional && fieldData.additional(value, values);
		setTimeout(() => {
			autoAdvance && !fields && nextStep && nextStep(value);
		}, 500);
	}

	return (
		<>
			<Field name={fieldData.name}>
				{({
					field: {onBlur: fieldOnBlur, ...field},
					form: {touched, errors, values},
				}: FieldProps) => {
					const fieldError = getIn(errors, fieldData.name);
					const showError = getIn(touched, fieldData.name) && !!fieldError;
					return (
						<FieldComponent
							{...fieldData}
							labelClassName={labelClassName || "text-lg mb-3"}
							value={field.value || ""}
							onChange={(e: any) => {
								if (!e) {
									return;
								}
								field.onChange(e);
								handleNext(e.target.value, values);
							}}
							onBlur={(e) => {
								fieldOnBlur(e ?? field.name);
							}}
							error={showError}
							helperText={showError && fieldError}
							additional={(value) => {
								const fields =
									fieldData.additional && fieldData.additional(value, values);
								if (fields) {
									return (
										<FieldAdditional fields={fields} nextStep={nextStep} />
									);
								}
							}}
						/>
					);
				}}
			</Field>
		</>
	);
}

import React from "react";
import styled from "styled-components";
import DropDown from "common/ui/DropDown";
import Icon from "common/ui/Icon";
import IconBackdrop from "common/ui/Icon/IconBackdrop";
import InlineTip from "common/ui/InlineTip";
import STYLES from "common/util/constants/styles";

const StyledSearchDropDown = styled(DropDown)`
	@media (max-width: 639px) {
		margin-bottom: 25px;
	}

	@media (min-width: 640px) {
		margin-bottom: 15px;
	}
`;

const SearchTopStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 550px;
	margin: auto;
	padding-bottom: 62px;
	text-align: center;

	span {
		font-weight: ${STYLES.FONT_WEIGHT.REGULAR};
		margin: 35px 0 30px 0;
	}
`;

export const SearchWrapper = styled.div`
	max-width: 1000px;
	margin: auto;

	@media (max-width: 639px) {
		padding: 0 ${STYLES.PADDING.BODY_HORIZONTAL};
	}
`;

export const SearchTop = (props) => {
	return (
		<SearchTopStyled>
			<IconBackdrop color={STYLES.COLORS.SALMON} width={70}>
				<Icon src={props.iconSrc} width={props.iconWidth} />
			</IconBackdrop>
			<span>{props.header}</span>
			<StyledSearchDropDown
				url={props.dropDownUrl}
				onSelect={props.dropDownOnSelect}
				placeholder={props.dropDownPlaceholder}
				formatData={props.formatData}
				renderRow={props.renderRow}
				autoFocus={document.body.clientWidth >= 640}
				cleanUserInput={props.cleanUserInput}
			/>
			<InlineTip
				visible={props.tipIsVisible}
				color={STYLES.COLORS.DARK_MINT}
				onClose={props.tipOnClose}
			>
				<b>FYI:</b> {props.tipText}
			</InlineTip>
		</SearchTopStyled>
	);
};

export const ResultsRow = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media (min-width: 640px) {
		justify-content: space-evenly;
		align-items: flex-start;
	}

	@media (max-width: 639px) {
		justify-content: flex-start;
	}
`;

export const ShadowBox = styled.div`
	border-radius: 4px;
	margin-bottom: 25px;
	box-shadow: 0px 0px 4px 2px rgba(11, 33, 117, 0.15);
`;

import React, {useEffect} from "react";
import {IFormData} from "../data/form-data";
import Dialog from "common/ui/Dialog";

import {getAgeFromBirthdate, isValidBirthdate} from "common/util/format";
import {MEDICARE_CONSIDERATION_AGE} from "common/util/common";

export function FormAlertAge({values}: {values: IFormData}) {
	const {dob} = values;

	function showDialog() {
		// Set Timeout so it doesn't happen as soon as the last key
		// on the birthdate field is pressed
		setTimeout(() => {
			Dialog.show({
				children: (
					<AlertModal
						onClose={() => {
							Dialog.hide();
						}}
					/>
				),
				width: 550,
				bgColor: "white",
			} as any);
		}, 300);
	}

	useEffect(() => {
		if (dob) {
			try {
				// Only show if there is a valid DOB and the age is < 65
				// Do show it every time the value is updated.
				const isValid = isValidBirthdate(dob);
				if (isValid) {
					const age = getAgeFromBirthdate(dob);
					if (age < MEDICARE_CONSIDERATION_AGE) {
						showDialog();
					}
				}
			} catch {}
		}
	}, [dob]);

	return <></>;
}

function AlertModal({onClose}: {onClose: () => void}) {
	return (
		<div className="flex h-full px-7 overflow-auto max-h-full font-normal">
			<div className="flex flex-col h-full py-6 px-4">
				<div className="text-center font-bold text-xl mb-5">
					It looks like you’re not yet eligible for Medicare.
				</div>
				<div className="flex flex-col h-full items-center">
					<div className="text-center text-base text-black space-y-3">
						<p>
							Unless you have a disability, you’re outside of your sign-up
							window (which starts 3 months before your 65th birthday)
						</p>
						<p>
							However, you can still fill out this form if you’d like a preview
							of your Part B premium and reminders for your sign-up deadline.
						</p>
					</div>
					<br />

					<button
						className="bg-orange font-bold hover:bg-red-500 transition-colors rounded-full text-white px-16 py-4 text-lg disabled:bg-gray-300"
						onClick={onClose}
					>
						Got It
					</button>
				</div>
			</div>
		</div>
	);
}

import React, {Component} from "react";
import Drawer from "common/ui/Drawer";
import STYLES from "common/util/constants/styles";
import InlineTip from "common/ui/InlineTip";
import {DrawerHeader} from "templates/drawer";
import {
	ProviderAddDetailsDots,
	ProviderAddDetailsBody,
	LocationButton,
	FrequencyButtonRow,
	FrequencyButton,
} from "./ProviderAddDetailsHelpers";

export class ProviderAddDetailsDrawer extends Component {
	frequencyOptions = [
		{value: "0-1", label: "0-1 visits"},
		{value: "2-4", label: "2-4 visits"},
		{value: "5-9", label: "5-9 visits"},
		{value: "10+", label: "10+ visits"},
		{value: null, label: "Prefer not to answer", fullWidth: true},
	];

	static getDerivedStateFromProps(props, state) {
		if (props.hasClickedBackdrop) state.showWarning = true;

		return state;
	}

	state = {
		selectedAddress: null,
		showWarning: false,
	};

	onClickChevron = () => {
		const {showWarning, selectedAddress} = this.state;

		if (selectedAddress) this.setState({selectedAddress: null});
		else if (showWarning) Drawer.hide();
		else this.setState({showWarning: true});
	};

	onSelectAddress = (location) => {
		this.setState({selectedAddress: location});
	};

	onSelectFrequency = (timesVisited) => {
		const {selectedAddress} = this.state;
		const {data} = this.props;

		data.setProviderInfo({
			provider: data.provider,
			selectedAddressUuid: selectedAddress.uuid,
			timesVisited,
		});
		Drawer.hide();
	};

	render() {
		const {selectedAddress, showWarning} = this.state;
		const {data} = this.props;

		return (
			<div>
				<DrawerHeader
					onClickBack={this.onClickChevron}
					title={`${data.provider.firstName} ${data.provider.lastName}`}
					iconSrc="pin"
					iconColor="white"
					iconWidth={25}
					iconBackdropWidth={60}
					height={200}
				/>
				<ProviderAddDetailsBody>
					{!selectedAddress ? (
						<>
							<b>At which location do you visit this doctor?</b>
							<br />
							<InlineTip
								visible={showWarning}
								color={STYLES.COLORS.WARNING_RED}
							>
								<b>FYI:</b> We need this information to find you the most
								accurate options.
							</InlineTip>
							{data.provider.locations.map((l) => (
								<LocationButton
									location={l}
									key={l.uuid}
									onSelectAddress={this.onSelectAddress}
									selectedAddress={selectedAddress}
								/>
							))}
						</>
					) : (
						<>
							<b>
								Optional: About how many times did you see Dr.{" "}
								{data.provider.lastName} last year? (In-person or virtual)
							</b>
							<FrequencyButtonRow>
								{this.frequencyOptions.map((f, index) => (
									<FrequencyButton
										frequency={f}
										key={index}
										onSelectFrequency={this.onSelectFrequency}
									/>
								))}
							</FrequencyButtonRow>
						</>
					)}
				</ProviderAddDetailsBody>
				<ProviderAddDetailsDots
					numTotal={2}
					numFilled={selectedAddress ? 2 : 1}
				/>
			</div>
		);
	}
}

/**
 * Calculator Header Language
 */

export const SITE_URL =
	"https://medicare.getchapter.com/medicare-premium-calculator";
export const SITE_TITLE = "Medicare Premium Calculator";
export const SITE_DESCRIPTION =
	"This free calculator helps you decide whether you should switch to Medicare";
export const SITE_IMAGE_URL =
	"https://medicare.getchapter.com/part-b/social.jpg";

import React from "react";
import styled from "styled-components";

const CardStyled = styled.div`
	border-top: ${({borderHeight}) => borderHeight}px solid;
	border-radius: 4px;
	background-color: white;
	border-color: ${({$color}) => $color};
	box-shadow: 0px 0px 4px 2px rgba(11, 33, 117, 0.15);

	h4 {
		margin: 0 0 36px 0;
		font-weight: bold;
	}

	@media (min-width: 640px) {
		padding: 15px 50px;
	}

	@media (max-width: 639px) {
		padding: 15px 25px;
	}
`;

export default function Card({
	borderHeight = 18,
	color,
	children,
	className = "",
}) {
	return (
		<CardStyled
			$color={color}
			borderHeight={borderHeight}
			className={className}
		>
			{children}
		</CardStyled>
	);
}

import React, {useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Redirect, useHistory} from "react-router";
import {Formik, FormikProps} from "formik";
import * as Yup from "yup";

import {Button} from "common/ui/Button/Button";
import {useUser} from "common/hooks/useUser";
import {IPage} from "common/ui/Form/FormRow";
import {ICustomer} from "common/util/api/service";
import {parseDateString, parseMonthAndYearString} from "common/util/format";

import {FormField} from "pages/Flow/shared/FormField";
import {
	birthdayField,
	currentInsuranceCoverageTypeField,
	exceptionsField,
	IFormData,
	retirementStatusField,
} from "./data/form";
import {Result} from "./result/result";
import {
	calculateEnrollmentWindowResult,
	enrollmentWindowService,
} from "./service";

function parseMonthYearString(value, originalValue) {
	return parseDateString(originalValue, "MM/yyyy");
}
function parseMonthYearDayString(value, originalValue) {
	return parseDateString(originalValue, "MM/dd/yyyy");
}

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

function FormPage({
	page,
	values,
	i,
	handleSubmit,
}: {
	page: IPage;
	values: IFormData;
	i: number;
	handleSubmit: () => void;
}) {
	const history = useHistory();
	function onNext() {
		const isLastStep = i === 3;
		if (isLastStep) {
			handleSubmit();
		} else {
			// TODO: we should save here
			history.push(`/flow/new/learn/enrollment-dates/${i + 1 + 1}`);
		}
	}

	return (
		<>
			{page.fields.map((fieldData) => (
				<div key={fieldData.name}>
					{fieldData.labelFcn && (
						<div className={fieldData.labelClassName}>
							{fieldData.labelFcn(values)}
						</div>
					)}

					<FormField
						fieldData={fieldData}
						nextStep={() => {
							onNext();
						}}
						autoAdvance={page.autoAdvance}
					/>
				</div>
			))}
			{!page.autoAdvance && (
				<div className="my-7">
					<Button
						type="button"
						onClick={() => {
							onNext();
						}}
					>
						Continue
					</Button>
				</div>
			)}
		</>
	);
}

/**
 * Add any additonal validations here, but be sure to
 * add "required" again if overwriting and is required
 */
const FormSchema = Yup.object().shape({
	dob: Yup.date()
		.transform(parseMonthYearDayString)
		.min(new Date(1900, 1, 1), "Please enter a valid birthdate")
		.max(yesterday, "Please enter a valid birthdate")
		.required("Required")
		.typeError("Please enter a valid birthdate"),
	retirementDate: Yup.date()
		.transform(parseMonthYearString)
		.min(new Date(1900, 1, 1), "Please enter a valid date")
		.max(yesterday, "Please Enter a valid retirement date")
		.typeError("Please enter a valid date"),
	expectedRetirementDate: Yup.date()
		.transform(parseMonthYearString)
		.min(yesterday, "Please enter a date in the future")
		.typeError("Please enter a valid date"),
});

function EnrollmentWindowForm({initialValues}: {initialValues: IFormData}) {
	const FORM_FIELDS: IPage[] = [
		birthdayField,
		retirementStatusField,
		currentInsuranceCoverageTypeField,
		exceptionsField,
	];
	const [formFields] = useState(FORM_FIELDS);

	const {user, updateUser} = useUser();
	const history = useHistory();

	return (
		<>
			<Router>
				<Formik
					initialValues={initialValues}
					onSubmit={(values) => {
						enrollmentWindowService.update(values);
						const userFields = convertEnrollmentWindowDataToCustomer(values);
						if (userFields.retirementDate) {
							userFields.retirementDate = parseMonthAndYearString(
								userFields.retirementDate
							);
						}
						if (userFields.expectedRetirementDate) {
							userFields.expectedRetirementDate = parseMonthAndYearString(
								userFields.expectedRetirementDate
							);
						}
						const results = calculateEnrollmentWindowResult(
							values,
							user?.medicareSignUpStatus
						);
						const userResultFields = {
							enrollmentWindowStartDate:
								results.enrollmentWindowResult.calculatedStart,
							enrollmentWindowEndDate:
								results.enrollmentWindowResult.calculatedEnd,
						};
						updateUser({...userFields, ...userResultFields});
						// setIsSubmitted(true);
						setTimeout(() => {
							history.push("/flow/new/learn/enrollment-dates/result");
						}, 300);
					}}
					validationSchema={FormSchema}
				>
					{({values, handleSubmit}: FormikProps<IFormData>) => (
						<form onSubmit={handleSubmit}>
							<Switch>
								{formFields.map((page, i) => (
									<Route
										key={i}
										path={`/flow/new/learn/enrollment-dates/${i + 1}`}
										render={(routeProps) => (
											<>
												<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
													{/** Page Header */}
													{user && user.medicareSignUpStatus === "partA"
														? "Let’s find out when you are eligible to sign up for Part B"
														: "Let's find out when you can sign up for Medicare"}
												</div>
												<div className="flex flex-col items-center">
													<div className="text-sm text-gray-600 mb-5 mt-3 leading-relaxed px-4">
														Question {i + 1}/{FORM_FIELDS.length}
													</div>
													<FormPage
														{...routeProps}
														user={user}
														handleSubmit={handleSubmit}
														page={page}
														values={values}
														i={i}
													/>
												</div>
											</>
										)}
									/>
								))}

								<Redirect to="/flow/new/learn/enrollment-dates/1" />
							</Switch>
						</form>
					)}
				</Formik>
			</Router>
		</>
	);
}

function getEnrollmentWindowData(user: ICustomer): Partial<IFormData> {
	return {
		dob: user.dob,
		retirementStatus: user.retirementStatus,
		currentInsuranceCoverageType: user.currentInsuranceCoverageType,
		specialExceptions: user.specialExceptions,
		retirementDate: user.retirementDate,
		expectedRetirementDate: user.expectedRetirementDate,
		hasMoreThanTwentyEmployees:
			user.hasMoreThanTwentyEmployees === false ? "false" : "true",
	};
}

function convertEnrollmentWindowDataToCustomer(
	enrollmentData: IFormData
): Partial<ICustomer> {
	console.log(enrollmentData);
	return {
		...enrollmentData,
		hasMoreThanTwentyEmployees:
			enrollmentData.hasMoreThanTwentyEmployees !== "false",
	};
}

export function EnrollmentWindow() {
	const {user} = useUser();
	return (
		<Switch>
			<Route path={"/flow/new/learn/enrollment-dates/result"}>
				<Result />
			</Route>

			<EnrollmentWindowForm
				initialValues={user && (getEnrollmentWindowData(user) as any)}
			/>
		</Switch>
	);
}

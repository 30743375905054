import * as React from "react";
import Icon from "common/ui/Icon";
import { Dialog } from "@material-ui/core";

export const MapdComparison: React.FC = () => {
    return (
        <Dialog open={true} fullWidth={true} maxWidth="xl">
            <div className="h-screen p-5 flex flex-col">
                <div className="mb-5">
                    <button className="flex items-center">
                        <Icon
                            src="leftChevron"
                            height={30}
                            width={30}
                            alt={"Go back"}
                            color="#BDBDBD"
                        />
                        <div className="ml-2 text-sm">Back</div>
                    </button>
                </div>
                <iframe
                    className="flex-1"
                    src="https://www.sunfirematrix.com/app/consumer/chap/#/plandetails/benefits/33010/12086/MAPD/212411|210045"
                />
            </div>
        </Dialog>
    );
};

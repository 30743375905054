import React from "react";
import styled from "styled-components";
import Touchable from "common/ui/Touchable";
import STYLES from "common/util/constants/styles";
import {
	CHAPTER_PHONE_NUMBER_RAW,
	CHAPTER_PHONE_NUMBER_FORMATTED,
	TTY_PHONE_NUMBER_RAW,
	TTY_PHONE_NUMBER_FORMATTED,
} from "common/util/common";
import {formatPhone} from "common/util/format";
import {LANDING_URL} from "common/util/common";
import {useHeader} from "./hooks/useHeader";

// position: relative and z-index added to ensure Header shadow apepars over sibling elements
const HeaderFullWidth = styled.div`
	background-color: white;
	box-shadow: 0px 0px 5px rgba(0, 31, 103, 0.25);
	position: relative;
	z-index: 1;

	a {
		font-size: 0;
	}

	@media (max-width: 639px) {
		position: sticky;
		top: 0;
		z-index: 1;
	}
`;

const HeaderWrapper = styled.div`
	margin: auto;
	padding: 10px;
	max-width: 1300px;
	display: flex;

	@media (max-width: 639px) {
		flex-wrap: wrap;
	}

	justify-content: space-between;
	align-items: center;
`;

const Logo = styled.img`
	width: 140px;
`;

const CallElement = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;

	img {
		margin-right: 10px;
	}

	@media (max-width: 639px) {
		font-size: 14px;
	}
`;

const MobileHidden = styled.span`
	white-space: pre;

	@media (max-width: 639px) {
		display: none;
	}
`;

const TTYSpan = styled.span`
	white-space: pre;
	margin-left: 10px;
	font-weight: ${STYLES.FONT_WEIGHT.REGULAR};
`;

export default function Header() {
	const {coBrandImageUrl, altPhoneNumber, homeUrl} = useHeader();

	function clickPhoneNumber() {
		document.location = `tel:+1${altPhoneNumber || CHAPTER_PHONE_NUMBER_RAW}`;
	}

	function clickTTYNumber() {
		document.location = `tel:+1${altPhoneNumber || TTY_PHONE_NUMBER_RAW}`;
	}

	return (
		<HeaderFullWidth>
			<HeaderWrapper>
				<div className="flex items-center text-black text-base w-full">
					<Touchable href={homeUrl || LANDING_URL}>
						<div className="flex flex-shrink-0 items-center hover:no-underline">
							{coBrandImageUrl && (
								<>
									<img
										className="w-32"
										alt="Partnership"
										src={coBrandImageUrl}
									/>
									<span className="text-lg mr-2">+</span>
								</>
							)}
							<Logo alt="site-logo" src="/images/logo.svg" />
						</div>
					</Touchable>
					<div className="ml-auto">
						<CallElement onClick={clickPhoneNumber}>
							<MobileHidden>Questions? </MobileHidden>Call us: &nbsp;
							<b>
								{formatPhone(altPhoneNumber) || CHAPTER_PHONE_NUMBER_FORMATTED}
							</b>
							<span className="hidden lg:inline-flex">
								<TTYSpan onClick={clickTTYNumber}>
									TTY: {TTY_PHONE_NUMBER_FORMATTED}
								</TTYSpan>
							</span>
						</CallElement>
					</div>
				</div>
			</HeaderWrapper>
		</HeaderFullWidth>
	);
}

interface IncomeBracket {
	label: string;
	value: string;
	min: number;
	max?: number;
}

export const singleIncomeBrackets: IncomeBracket[] = [
	{label: "0 - $88,000", value: "1", min: 0, max: 88000},
	{label: "$88,001 - $111,000", value: "2", min: 88001, max: 111000},
	{label: "$111,001 - $138,000", value: "3", min: 111001, max: 13800},
	{label: "$138,001 - $165,000", value: "4", min: 138001, max: 165000},
	{label: "$165,001 - $499,999", value: "5", min: 165001, max: 499999},
	{label: "$500,000+", value: "6", min: 500000},
];

export const marriedIncomeBrackets: IncomeBracket[] = [
	{label: "0 - $176,000", value: "1", min: 0, max: 176000},
	{label: "$176,001 - $222,000", value: "2", min: 176001, max: 222000},
	{label: "$222,001 - $276,000", value: "3", min: 222001, max: 276000},
	{label: "$276,001 - $330,000", value: "4", min: 276001, max: 330000},
	{label: "$330,001 - $749,999", value: "5", min: 330001, max: 749999},
	{label: "$750,000+", value: "6", min: 750000},
];

export const marriedButSeparateIncomeBrackets: IncomeBracket[] = [
	{label: "0 - $88,000", value: "1", min: 0, max: 88000},
	{label: "$88,001 - $411,999", value: "5", min: 88001, max: 411999},
	{label: "$412,000+", value: "6", min: 412000},
];

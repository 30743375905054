import React from "react";
import Icon from "common/ui/Icon";
import IconBackdrop from "common/ui/Icon/IconBackdrop";
import STYLES from "common/util/constants/styles";
import {CHAPTER_PHONE_NUMBER_RAW} from "common/util/common";
import {formatPhone} from "common/util/format";
import {NoPlansStyled} from "./NoPlansHelpers";

export default function NoPlans() {
	return (
		<NoPlansStyled>
			<h4>Speak with an expert to find your option.</h4>
			<IconBackdrop width={150} color={STYLES.COLORS.WARNING_RED}>
				<Icon src="brokenComputer" />
			</IconBackdrop>
			<br />
			<b>Computers are good at many things, but sometimes you need a human.</b>
			<br />
			<br />
			<div>
				Please give us a call at{" "}
				<a href={`tel:+1${CHAPTER_PHONE_NUMBER_RAW}`}>
					{formatPhone(CHAPTER_PHONE_NUMBER_RAW)}
				</a>
			</div>
		</NoPlansStyled>
	);
}

import React, {useEffect, useState} from "react";
import Scheduler from "modals/Scheduler";
import Dialog from "common/ui/Dialog";
import clsx from "clsx";
import {IResultData} from "pages/PartBResults/results";
import {useParams} from "react-router";
import {IEmployerCalculatorRouteParams} from "../Router";
import {EMPLOYER_DATA_MAP} from "../data/employers";
import {IEmployerData} from "../data/models";

export function Actions({ctas}: {ctas: IResultData["ctas"]}) {
	const {employerId} = useParams<IEmployerCalculatorRouteParams>();
	const [employerData, setEmployerData] = useState<IEmployerData>();

	useEffect(() => {
		setEmployerData(EMPLOYER_DATA_MAP[employerId]);
	}, [employerId]);

	function ctaClickWrapper(cta) {
		if (cta.link === "SCHEDULE" || cta.link === "APP") {
			return showScheduler();
		}

		window.open(cta.link);
	}

	function showScheduler() {
		Dialog.show({
			children: <Scheduler />,
			width: Scheduler.width,
			height: Scheduler.height,
			bgColor: "white",
		});
	}

	return (
		<>
			{ctas.map((cta, i) => (
				<button
					key={cta.link}
					className={clsx(
						"py-4 px-6 font-bold transition-colors rounded-full w-full md:w-auto text-sm sm:text-base",
						i === 0
							? "bg-teal-700 text-white hover:bg-teal-800"
							: "border border-gray-300  text-gray-700 hover:bg-gray-50"
					)}
					onClick={() => ctaClickWrapper(cta)}
				>
					{cta.text}
				</button>
			))}
			<div className="py-4 text-gray-600 text-sm leading-relaxed">
				Working with Chapter is entirely free. <br /> You can also email us at{" "}
				<a
					className="text-underline-accent"
					href={`mailto:${employerData?.email}}`}
				>
					{employerData?.email}
				</a>{" "}
				<br />
				with any questions
			</div>
		</>
	);
}

// FreshChat is initialized via Google Tag Manager. This module offers some basic functionality as a wrapper.

class FreshChat {
	open({name, replyText}) {
		name = name.toLowerCase();

		if (window.fcWidget) {
			window.fcWidget.track(name);
			window.fcWidget.open({
				name,
				replyText,
			});
		} else {
			this._warnNoFreshChat();
		}
	}
	_warnNoFreshChat() {
		alert("FreshChat not enabled");
	}
}

export default new FreshChat();

import {IChecklistItem} from "common/ui/Checkbox/Checklist";
import womanForm from "./images/woman-form.svg";
import partBEnrollment from "./images/part-b-enrollment.png";
import partBEnrollmentArrow from "./images/part-b-enrollment-yellow-arrow.png";
import partBStartDate from "./images/part-b-start-date.png";
import partBEmploymentVerification from "./images/part-b-employment-verification.png";

export const SOCIAL_SECURITY_CHECKLIST_PART_B_CONFIG: IChecklistItem[] = [
	{
		value: "send-forms",
		label:
			"1: Send your employer’s HR department two forms  (CMS-40B and CMS-L564)",
		descriptionLines: [],
		imageUrl: womanForm,
		imageExpandable: true,
		actions: [
			{
				title: "CMS-40B",
				link: "https://www.cms.gov/cms40b-application-enrollment-part-b",
			},
			{
				title: "CMS-L564",
				link: "https://www.cms.gov/cms-l564-request-employment-information",
			},
		],
	},
	{
		value: "start-application",
		label: "2: Start your Part B application",
		descriptionLines: ["Read and agree to the terms of service"],
		imageUrl: partBEnrollment,
		imageExpandable: true,
		actions: [
			{
				title: "Go to application",
				link: "https://secure.ssa.gov/mpboa/medicare-part-b-online-application/",
			},
		],
	},
	{
		value: "sign-in",
		label:
			"3: Click the yellow arrow on the left side to start your application",
		descriptionLines: [
			"Begin filling out the online form with your basic information, your employer’s information, and your current health coverage.",
		],
		imageUrl: partBEnrollmentArrow,
		imageExpandable: true,
	},
	{
		value: "fill-out-application",
		label:
			"4: In Step 7 “Remark”, enter the date you’d like your coverage to start.",
		descriptionLines: [
			"Enter the date that you would like your Part B Medicare coverage to start.",
		],
		imageUrl: partBStartDate,
		imageExpandable: true,
	},
	{
		value: "upload-forms",
		label: "5: Upload your two forms to the document (CMS-40B and CMS-L564)",
		descriptionLines: [
			"From your computer, upload the two documents that your employer’s HR department filled out for you.",
		],
		imageUrl: partBEmploymentVerification,
		imageExpandable: true,
	},
	{
		value: "review-application",
		label: "6: Review your application and confirm your e-signature",
		descriptionLines: [
			`Review your information, and submit your application for review. You’ll receive a <b>re-entry number</b>, please record it in Step 6 below.`,
			`<b>IMPORTANT:</b> You will receive an email from “Echo Sign” to confirm your e-signature. <b>You click the confirmation in this email for your application to be complete.</b>`,
		],
		imageUrl: "/images/illustrations/email.svg",
		imageExpandable: false,
	},
];

import React, {ReactElement, useEffect, useState} from "react";
import MaterialTreeView from "@material-ui/lab/TreeView";
import MaterialTreeItem from "@material-ui/lab/TreeItem";

interface IGroup {
	id: string;
	name: string;
	children?: Array<{name: string; id: string}>;
}

interface ITreeViewProps {
	Header?: ReactElement;
	groups: IGroup[];
	activeId?: string;
	className?: string;
	footer?: ReactElement;
	ChildView: React.FC<{item: IGroup; isActive: boolean}>;
	HeaderView: React.FC<{item: IGroup; isActive: boolean}>;
	onChange: (selectedIds: string[]) => void;
}

export function TreeView({
	Header,
	groups,
	activeId,
	className,
	ChildView,
	HeaderView,
	onChange,
}: ITreeViewProps) {
	const [expanded, setExpanded] = useState<string[]>([]);
	const [selected, setSelected] = useState<string[]>(
		activeId ? [activeId] : []
	);

	const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
		const newExpanded = Array.isArray(nodeIds) ? nodeIds : [nodeIds];
		setExpanded(newExpanded);
		onChange(newExpanded);
	};

	const handleSelect = (
		event: React.ChangeEvent<{}>,
		nodeIds: string[] | string
	) => {
		if (isParentId(nodeIds)) {
			return;
		}
		const newSelected = Array.isArray(nodeIds) ? nodeIds : [nodeIds];
		setSelected(newSelected);
		onChange(newSelected);
	};

	function isParentId(id: string | string[]) {
		return id.length === 1;
	}

	function expandParentForId(id: string) {
		// make sure activeId's parent is expanded
		const parentId = activeId?.split("")[0]!;
		setExpanded(Array.from(new Set([...expanded, parentId])));
	}

	useEffect(() => {
		// update selected and expanded
	}, [groups]);

	useEffect(() => {
		if (!activeId) {
			return;
		}
		expandParentForId(activeId);
		setSelected([activeId]);
	}, [activeId]);

	return (
		<nav className={className}>
			<div className="text-lg text-center mb-5">{Header}</div>
			{groups && (
				<MaterialTreeView
					expanded={expanded}
					selected={selected}
					onNodeToggle={handleToggle}
					onNodeSelect={handleSelect}
				>
					{groups.map((group) => (
						<div className="mb-3 border-b" key={group.id}>
							<MaterialTreeItem
								nodeId={group.id}
								label={
									(HeaderView && (
										<HeaderView
											item={group}
											isActive={expanded.includes(group.id)}
										/>
									)) ||
									group.name
								}
								classes={{
									label: "rounded tracking-normal px-0",
									iconContainer: "w-0 hidden",
								}}
							>
								{group.children?.map((child) => (
									<div className="my-1" key={child.id}>
										<MaterialTreeItem
											nodeId={child.id}
											classes={{
												root: "rounded cursor-pointer",
												label:
													"rounded tracking-normal px-3 py-1 font-unset px-0 flex",
												iconContainer: "w-0",
											}}
											label={
												(ChildView && (
													<ChildView
														item={child}
														isActive={selected.includes(child.id)}
													/>
												)) ||
												child.name
											}
										/>
									</div>
								))}
							</MaterialTreeItem>
						</div>
					))}
				</MaterialTreeView>
			)}
		</nav>
	);
}

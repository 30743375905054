import {CHAPTER_PHONE_NUMBER_FORMATTED} from "common/util/common";
import React from "react";

export function SpouseAndDependents() {
	return (
		<div className="space-y-4">
			<p>
				While Medicare may provide more affordable and higher quality coverage
				for you, you should also evaluate how your spouse / dependents will
				receive coverage if you enroll in Medicare.
			</p>
			<p>
				In some situations, even if you enroll in Medicare, you may be able to
				keep your spouse or dependents on your employer coverage, or on COBRA.
			</p>
			<p>
				In other situations, your spouse or dependents may be able to receive
				affordable coverage from the individual market, also known as the
				Obamacare market.
			</p>
			<p>
				But in some situations, any savings you achieve by enrolling in Medicare
				may be offset by extra costs of your spouse or dependents needing to
				receive coverage elsewhere.
			</p>
			<p>
				If you have questions, you can contact one of licensed Advocates to
				discuss your situation at {CHAPTER_PHONE_NUMBER_FORMATTED}.
			</p>
		</div>
	);
}

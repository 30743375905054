import styled from "styled-components";
import {Dialog as BPDialog} from "@blueprintjs/core";
import Icon from "common/ui/Icon";

type DialogWrapperProps = {
	$height: number | string;
	$width: number;
	$bgColor: string;
};

export const DialogWrapper = styled(BPDialog)<DialogWrapperProps>`
	height: ${({$height}) => $height};
	width: ${({$width}) => $width}px;
	max-width: 95%;
	position: relative;

	&.bp3-dialog {
		background: ${({$bgColor}) => $bgColor};
		padding: 0;
	}
`;

export const CloseIcon = styled(Icon)`
	position: absolute;
	right: 25px;
	top: 25px;
`;

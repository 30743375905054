import {keyframes} from "styled-components";

const orderedKeyFrames = [];

const vennDiagramFrames = {};

vennDiagramFrames.left = keyframes`
	0% {
		left: -20px;
	}
	
	50% {
		left: -20px;
	}

	100% {
		left: 20px;
	}
`;
vennDiagramFrames.right = keyframes`
	0% {
		right: -20px;
	}
	
	50% {
		right: -20px;
	}

	100% {
		right: 20px;
	}
`;

// 0th (0-index)
orderedKeyFrames.push(vennDiagramFrames);

// 1st (0-index)
orderedKeyFrames.push(keyframes`
	0% {
		top: 20px;
		opacity: 0;
	}
	
	50% {
		top: 20px;
		opacity: 0;
	}

	100% {
		top: 0px;
		opacity: 1;
	}
`);

// 2nd (0-index)
orderedKeyFrames.push(keyframes`
	0% {
		top: 20px;
		opacity: 0;
	}
	66% {
		top: 20px;
		opacity: 0;
	}
	100% {
		top: 0px;
		opacity: 1;
	}
`);

const bottomOuterFrames = {};

bottomOuterFrames.bars = keyframes`
	0% {
		top: 20px;
		opacity: 0;
	}
	75% {
		top: 20px;
		opacity: 0;
	}
	100% {
		top: 0px;
		opacity: 1;
	}
`;

bottomOuterFrames.text = keyframes`
	0% {
		opacity: 0;
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

// 3rd (0-index)
orderedKeyFrames.push(bottomOuterFrames);

// 4th (0-index)
orderedKeyFrames.push(keyframes`
	0% {
		opacity: 0;
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`);

export {orderedKeyFrames};

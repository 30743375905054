import React from "react";
import clsx from "clsx";
import Icon from "../Icon";
import eventTracker from "common/util/eventTracker";

function FacebookIcon() {
	return (
		<>
			<span className="sr-only">Share with Facebook</span>
			<img
				className="h-5 w-auto object-contain"
				src="/images/social/facebook.png"
				alt="Share with Facebook"
			/>
			<div className="ml-1 text-accent font-bold">Share</div>
		</>
	);
}

function NextdoorIcon() {
	return (
		<>
			<span className="sr-only">Share with NextDoor</span>
			<img
				className="h-6 w-auto object-contain"
				src="/images/social/nextdoor.png"
				alt="Share with Nextdoor"
			/>
		</>
	);
}

function EmailIcon() {
	return (
		<>
			<span className="sr-only">Share with Email</span>
			<Icon className="w-5 h-5" src="email" />
			<div className="ml-1 text-accent font-bold">Email</div>
		</>
	);
}

export function Share({
	shareLink,
	title,
	description,
	channels,
	shareText,
	className,
	row,
}: {
	shareLink: string;
	title: string;
	description: string;
	shareText: string;
	channels: Array<"NEXTDOOR" | "FACEBOOK" | "EMAIL">;
	className?: string;
	row?: boolean;
}) {
	const CHANNEL_MAP: {
		[key: string]: {
			name: string;
			iconComponent: () => JSX.Element;
			url: string;
			className?: string;
		};
	} = {
		FACEBOOK: {
			name: "Facebook",
			iconComponent: FacebookIcon,
			url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
				shareLink
			)}&amp;src=sdkpreparse`,
			className: "fb-share-button",
		},
		NEXTDOOR: {
			name: "NextDoor",
			iconComponent: NextdoorIcon,
			url: "https://nextdoor.com/news_feed",
		},
		EMAIL: {
			name: "Email",
			iconComponent: EmailIcon,
			url: "",
		},
	};

	function share(url: string) {
		return window.open(url, "_blank", "noopener");
	}

	function shareEmail() {
		return window.open(
			`mailto:?subject=${title}&body=${description}`,
			"_blank"
		);
	}

	function onClickWrapper(channelData: any) {
		eventTracker.log({
			name: eventTracker.EVENTS.CLICK_SOCIAL,
			data: {
				channel: channelData.name,
			},
		});

		channelData.url ? share(channelData.url) : shareEmail();
	}

	return (
		<div className={className}>
			<div className="mb-3 text-center text-accent font-medium">
				{shareText || "Share"}
			</div>
			<div
				className={clsx("mt-1 grid gap-3", row ? "grid-cols-3" : "grid-cols-1")}
			>
				{channels.map((channel) => {
					const channelData = CHANNEL_MAP[channel];
					const Component = channelData.iconComponent;
					return (
						<div
							key={channelData.name}
							className={clsx(channelData.className)}
							data-href={shareLink}
							data-layout="button"
							data-size="large"
						>
							<button
								onClick={() => onClickWrapper(channelData)}
								className="w-full inline-flex justify-center items-center py-2 px-4 rounded-md border border-gray-300 shadow-sm text-sm font-medium text-gray-500 hover:bg-gray-50 fb-xfbml-parse-ignore"
							>
								<Component />
							</button>
						</div>
					);
				})}
			</div>
		</div>
	);
}

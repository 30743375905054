import React from "react";

export const ear = ({color, height, width}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 26 38"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.351 37.391a6.759 6.759 0 01-3.297-.885c-2.438-1.271-4.02-3.855-4.122-6.73 0-.688.528-1.246 1.178-1.246.65 0 1.178.558 1.178 1.246.112 1.944 1.206 3.673 2.861 4.524a4.03 4.03 0 004.404-.124 7.956 7.956 0 002.19-4.126c.19-.654.418-1.295.684-1.92a14.615 14.615 0 013.686-4.81 14.26 14.26 0 002.896-3.378 12.086 12.086 0 001.543-6.02c.04-6.247-4.697-11.354-10.598-11.43-5.902.076-10.638 5.183-10.599 11.43 0 .688-.527 1.246-1.177 1.246S0 14.61 0 13.922C-.04 6.298 5.751.075 12.954 0c7.202.075 12.992 6.298 12.953 13.922a14.621 14.621 0 01-1.884 7.266 16.724 16.724 0 01-3.332 3.989 12.447 12.447 0 00-3.145 4.026 17.36 17.36 0 00-.565 1.632c-.565 1.795-1.26 4.039-3.073 5.385a5.993 5.993 0 01-3.557 1.171z"
			fill={color}
		/>
		<path
			d="M7.065 22.434c-.506.001-.957-.34-1.119-.848A29.222 29.222 0 014.71 13.71c0-4.818 3.69-8.725 8.243-8.725 4.553 0 8.243 3.907 8.243 8.725 0 .688-.527 1.246-1.177 1.246s-1.178-.558-1.178-1.246c0-3.442-2.636-6.232-5.888-6.232s-5.888 2.79-5.888 6.232c.08 2.406.476 4.79 1.178 7.08.117.33.098.697-.055 1.01a1.194 1.194 0 01-.746.635 1.399 1.399 0 01-.377 0z"
			fill={color}
		/>
		<path
			d="M8.243 27.42c-.65 0-1.178-.558-1.178-1.247 0-.688.528-1.246 1.178-1.246 0 0 2.355-.075 2.355-1.246a12.06 12.06 0 00-1.06-4.425c-.289-.616-.052-1.364.53-1.67.58-.308 1.284-.063 1.578.548a14.65 14.65 0 011.307 5.547c0 2.954-3.085 3.739-4.71 3.739z"
			fill={color}
		/>
	</svg>
);

import React from "react";
import {Autocomplete} from "common/ui/Autocomplete";
import {useEffect, useState} from "react";
import {carrierService} from "./service";

export function CarrierAutocomplete(props) {
	const [options, setOptions] = useState<any[]>([]);

	useEffect(() => {
		async function getCarriers() {
			const newOptions = await carrierService.get().catch((err) => {
				console.log(err);
			});
			if (newOptions) {
				setOptions(newOptions as any);
			}
		}
		getCarriers();
	}, []);

	return (
		<>
			{options ? (
				<Autocomplete label="Carrier" {...props} options={options} />
			) : (
				<div>Loading...</div>
			)}
		</>
	);
}

import {Component} from "react";
import styled from "styled-components";
import Icon from "common/ui/Icon";
import IconList from "common/ui/IconList";
import CounterAnimated from "common/ui/CounterAnimated";
import eventTracker from "common/util/eventTracker";
import STYLES from "common/util/constants/styles";
import {Bold, ShadowBoxTop, PaddingDiv} from "./V3Helpers";
import {orderedKeyFrames} from "./AnimationKeyframes";

const TopBoxTitle = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	max-width: 660px;
	margin: auto;

	position: relative;
	animation: ${orderedKeyFrames[1]} 2s linear;

	@media (max-width: 639px) {
		font-size: 16px;
		margin-bottom: 20px;
	}

	@media (min-width: 640px) {
		font-size: 31px;
		margin-bottom: 40px;
	}
`;

const MidSection = styled.div`
	display: flex;
	justify-content: space-evenly;
`;

const VennWrapper = styled.div`
	display: flex;
	flex: 1;

	@media (max-width: 639px) {
		justify-content: center;
		margin-bottom: 10px;
	}
`;

const VennCommon = styled.div`
	border-radius: 50%;
	position: relative;
	opacity: 0.5;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 639px) {
		height: 150px;
		width: 150px;
	}

	@media (min-width: 640px) {
		height: 200px;
		width: 200px;
	}
`;

const Venn1 = styled(VennCommon)`
	background-color: #a3ffee;
	animation: ${orderedKeyFrames[0].left} 1s linear;
	left: 20px;
`;

const Venn2 = styled(VennCommon)`
	background-color: #b8eaff;
	animation: ${orderedKeyFrames[0].right} 1s linear;
	right: 20px;
`;

const VennDiagram = ({preferences}) => {
	return (
		<VennWrapper>
			<Venn1>
				<Icon src={preferences[0].icon} width={50} />
			</Venn1>
			<Venn2>
				<Icon src={preferences[1].icon} width={50} />
			</Venn2>
		</VennWrapper>
	);
};

const ListSectionWrapper = styled.div`
	position: relative;
	animation: ${orderedKeyFrames[2]} 3s linear;
	flex: 1;

	${Bold} {
		margin-bottom: 30px;
		font-size: 16px;
	}
`;

const IconListStyled = styled(IconList)`
	font-size: 14px;
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
`;

const ListSection = ({preference, color}) => {
	return (
		<ListSectionWrapper>
			<Bold>{preference.title}</Bold>
			<IconListStyled
				items={preference.listItems}
				color={color}
				icon="checkMark"
				iconWidth={15}
				iconHeight={15}
			/>
		</ListSectionWrapper>
	);
};

const Bar = styled.div`
	height: 22px;
	background-color: ${({$color}) => $color};

	position: relative;
	animation: ${orderedKeyFrames[3].bars} 4s linear;
`;

const BottomSection = styled.div`
	display: flex;
	justify-content: space-evenly;

	@media (min-width: 640px) {
		height: 140px;
	}
`;

const SinglePrioritySectionWrapper = styled.div`
	flex: 1;
	font-size: 14px;
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
`;

const PlansCovering = styled.div`
	animation: ${orderedKeyFrames[3].text} 4s linear;

	@media (max-width: 639px) {
		padding: 0 15px 10px 15px;
	}

	@media (min-width: 640px) {
		padding: 0 20px;
	}
`;

const BottomTitles = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
	font-size: 31px;
	margin: 20px 0 10px 0;
	color: ${({$color}) => $color || "inherit"};
`;

const SinglePrioritySection = ({preferences, index}) => {
	const barColor = index ? "#D5F0FF" : "#D0FFF6";
	const textColor = index ? "#97B7E7" : "#75DFD6";

	return (
		<SinglePrioritySectionWrapper>
			<Bar $color={barColor} />
			<PlansCovering>
				<BottomTitles $color={textColor}>
					<CounterAnimated
						endNumber={preferences[index].numPlans}
						delayMs={4000}
						durationMs={1500}
					/>
				</BottomTitles>
				Plans covering your {preferences[index].description}
			</PlansCovering>
		</SinglePrioritySectionWrapper>
	);
};

const IntersectionSection = styled.div`
	flex: 1;
	background-color: #23cac7;
	color: white;
	padding-top: 20px;
	animation: ${orderedKeyFrames[4]} 5s linear;
	font-size: 14px;
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
`;

const Triangle = styled.div`
	width: 0;
	height: 0;
	border-left: 30px solid transparent;
	border-right: 30px solid transparent;
	border-bottom: 30px solid #23cac7;
	animation: ${orderedKeyFrames[4]} 5s linear;

	@media (max-width: 639px) {
		margin: auto;
	}

	@media (min-width: 640px) {
		margin: 15px auto 0 auto;
	}
`;

export class VennDiagramSection extends Component {
	constructor(props) {
		super(props);

		eventTracker.log({
			name: eventTracker.EVENTS.SAW_VENN_DIAGRAM,
		});
	}

	render() {
		const {content, numPlans, isMobile} = this.props;

		const intersectionPlanPlural =
			numPlans.intersection === 1 ? "Plan" : "Plans";

		return (
			<ShadowBoxTop>
				<PaddingDiv>
					<TopBoxTitle>Plan options tailored to your needs</TopBoxTitle>
					{isMobile ? (
						<>
							<VennDiagram preferences={content} />
							<MidSection>
								<ListSection preference={content[0]} color="#7DDFD4" />
								<ListSection preference={content[1]} color="#86B8F6" />
							</MidSection>
						</>
					) : (
						<MidSection>
							<ListSection preference={content[0]} color="#7DDFD4" />
							<VennDiagram preferences={content} />
							<ListSection preference={content[1]} color="#86B8F6" />
						</MidSection>
					)}
				</PaddingDiv>
				<Triangle />
				<BottomSection>
					<SinglePrioritySection preferences={content} index={0} />
					<IntersectionSection>
						<BottomTitles>
							<CounterAnimated
								endNumber={numPlans.intersection}
								delayMs={5000}
								durationMs={1500}
							/>
						</BottomTitles>
						{intersectionPlanPlural} matching your needs
					</IntersectionSection>
					<SinglePrioritySection preferences={content} index={1} />
				</BottomSection>
			</ShadowBoxTop>
		);
	}
}

/**
 * Transforms formulary data from the server into a format usable by the client
 * For formulary API reference, view github.com/getChapter/medicare/wiki/API-Reference-for-MVP#prescription-search
 * @param {FormularyServer} formulary - The formulary to format
 * @returns {FormularyClient} The new properly formatted formulary, ready to use on client
 */

import {capitalize} from "common/util/format";

export default function formatFormulary(formulary) {
	formulary.key = formulary.brandName + formulary.compounds;
	formulary.compounds = capitalize(formulary.compounds);
	formulary.brandName = capitalize(formulary.brandName);
	formulary.genericOk = true;
	formulary.selectedDoseForm = "";
	formulary.selectedRxcuid = "";

	// Normalize formularyDosagesAndForms array and populate doseFormMapping
	const doseFormMapping = {};
	formulary.formularyDosagesAndForms = formulary.formularyDosagesAndForms.map(
		(dosageAndForm, index) => {
			dosageAndForm.doseForm = capitalize(dosageAndForm.doseForm);
			dosageAndForm.dosage = capitalize(dosageAndForm.dosage);

			const dosageRxcuid = {
				value: dosageAndForm.rxcuid,
				label: dosageAndForm.dosage,
			};

			if (doseFormMapping[dosageAndForm.doseForm])
				doseFormMapping[dosageAndForm.doseForm].push(dosageRxcuid);
			else doseFormMapping[dosageAndForm.doseForm] = [dosageRxcuid];

			return dosageAndForm;
		}
	);

	// Auto-select form and dosage if only 1
	const doseFormMappingKeys = Object.keys(doseFormMapping);
	if (doseFormMappingKeys.length === 1) {
		formulary.selectedDoseForm = doseFormMappingKeys[0];

		if (doseFormMapping[formulary.selectedDoseForm].length === 1)
			formulary.selectedRxcuid =
				doseFormMapping[formulary.selectedDoseForm][0].value;
	}

	formulary.doseFormMapping = doseFormMapping;

	return formulary;
}

import React, {FC, memo, useState} from "react";

import {Checklist} from "common/ui/Checkbox/Checklist";
import {Button} from "common/ui/Button/Button";
import {SOCIAL_SECURITY_CHECKLIST_PART_B_CONFIG} from "./socialSecurityChecklistPartBConfig";
import {ISocialSecurityChecklistProps} from "./SocialSecurityChecklist";
import {TimeLabel} from "../shared/TimeLabel";
import {Link} from "react-router-dom";
import {useUser} from "common/hooks/useUser";

export const SocialSecurityChecklistPartB: FC<ISocialSecurityChecklistProps> =
	memo(({onSubmit}) => {
		const [selected, setSelected] = useState([]);
		const {user} = useUser();

		return (
			<div>
				<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
					Here's your expert sign-up checklist
				</div>
				<div className="font-bold text-base text-gray-600 mb-10 mt-3 leading-relaxed px-4">
					{user && user.email && (
						<>
							We've also sent a copy to <b>{user.email}</b> for safe-keeping.
						</>
					)}
					<div className="font-normal text-sm mt-1">
						<TimeLabel className="justify-center" time="About 10 mins" />
					</div>
				</div>

				<Checklist
					value={selected}
					rows={SOCIAL_SECURITY_CHECKLIST_PART_B_CONFIG}
					name="social-security"
					numColumns={1}
					onChange={setSelected}
				/>

				<div className="flex flex-col items-center space-y-5 my-10">
					<Button
						type="submit"
						onClick={() => {
							onSubmit(selected);
						}}
					>
						I've applied
					</Button>
					<Link to="/flow/new/learn/sign-up/checklist/skip">
						or{" "}
						<span className="text-underline-accent">
							skip ahead to finding a plan {">"}
						</span>
					</Link>
				</div>
			</div>
		);
	});

import React from "react";
import ExpandableImage from "common/ui/ExpandableImage";
import applicationForm from "./images/application-form.png";
import applicationFormMedicareOnly from "./images/medicare-only.png";

export function SocialSecurityApplicationImage() {
	return (
		<div className="flex flex-col">
			<ExpandableImage
				src={applicationForm}
				alt=""
				className="object-contain ml-auto"
			/>
			<div className="italic text-sm">Application form for Medicare</div>
			<br />
			<ExpandableImage
				src={applicationFormMedicareOnly}
				alt=""
				className="object-contain ml-auto"
			/>
			<div className="italic text-sm">Enroll in Medicare only</div>
		</div>
	);
}

import React, {ReactElement} from "react";
import clsx from "clsx";
import {Field} from "formik";
import {Information} from "../../Information";

export interface IPage {
	autoAdvance?: boolean;
	fields: IField[];
}

export interface IField {
	name: string;
	label?: string;
	labelFcn?: (values: any) => string;
	labelClassName?: string;
	type: string;

	required?: boolean;
	tooltip?: string;
	options?: IOptionLabel[];
	fields?: IField[];
	placeholder?: string;
	className?: string;

	// Use this to render any supplemental area in a form row
	render?: () => ReactElement;
	// Additional allows dynamically generated sub-fields of a form row
	// based on values of the form itself. values: any here should be a
	// done in TS Generics
	additional?: (value: any, values: any) => IField[] | any;
}

export interface IOptionLabel {
	label: string;
	value: string;
}

/**
 * Form Field accepts a field type, alongside an active form object
 * represented by Formik
 *
 * ComponentMap lets you pass in a map of field type to a React component
 * to use for render. The component should accept the property types passed
 * in below
 *
 * isResults is an optional property that shows a "You've answered" for
 * form display purposes
 */
export default function FormRow({
	fieldData,
	isResults,
	componentMap,
	className,
	componentClassName,
	hideError,
	hasPressedSubmit,
}: {
	fieldData: IField;
	isResults?: boolean;
	componentMap: {[key: string]: any};
	className?: string;
	componentClassName?: string;
	hideError?: boolean;
	hasPressedSubmit?: boolean;
}) {
	const Component = componentMap[fieldData.type];
	const {render, ...remainingFieldData} = fieldData as any;

	return (
		<div className={clsx("flex flex-col", className)} id={fieldData.name}>
			<label
				htmlFor={fieldData.name}
				className="inline-block font-semibold leading-relaxed text-lg"
			>
				{fieldData.label}
				{fieldData.tooltip && !isResults && (
					<div className="inline-block ml-2 leading-none">
						<Information title="Why we're asking this" height={16} width={16}>
							<div className="text-base text-gray-600 font-light">
								{fieldData.tooltip}
							</div>
						</Information>
					</div>
				)}
			</label>

			{/** Memo here */}
			{render && render()}

			<div className="flex items-center py-1">
				{isResults &&
					(fieldData.type === "radio" || fieldData.type === "select") && (
						<div className="flex-shrink-0 font-semibold text-accent mr-5 pb-3 hidden sm:flex">
							You Answered
						</div>
					)}
				<Field name={fieldData.name}>
					{({field, meta}: {field: any; meta: any}) => (
						<div className="flex flex-col w-full">
							{Component && (
								<Component
									id={field.name}
									{...remainingFieldData}
									{...field}
									value={field.value || []}
									onChange={(e: any) => {
										// Formik expects onChange to be from the native el, but
										// some of our components don't pass up the native el
										const v = e.target
											? e
											: {target: {value: e, name: fieldData.name}};
										field.onChange(v);
									}}
									className={clsx("", componentClassName)}
									{...(fieldData.type === "zip" ||
									fieldData.type === "birthdate"
										? {
												error: !!meta.touched && meta.error ? meta.error : "",
										  }
										: {})}
								/>
							)}
							{!isResults && !hideError && (
								<div className="text-red-500 text-sm ml-1 h-5">
									{(!!meta.touched || hasPressedSubmit) &&
									meta.error &&
									fieldData.type !== "zip" &&
									fieldData.type !== "birthdate"
										? meta.error
										: ""}
								</div>
							)}
						</div>
					)}
				</Field>
			</div>
			{isResults && fieldData.tooltip && (
				<div className="flex-shrink-0 font-light leading-relaxed text-accent mr-5">
					Why it matters: {fieldData.tooltip}
				</div>
			)}
		</div>
	);
}

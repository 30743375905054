import React from "react";

export const pig = ({color, height, width}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 70 62"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M32.677 33.28h-.384c-1.462 0-2.646-1.092-2.646-2.439 0-1.346 1.184-2.439 2.646-2.439h.384v4.879zm3.03 2.44c1.462 0 2.646 1.092 2.646 2.438 0 1.347-1.184 2.44-2.646 2.44h-.384v-4.879h.384zm0-7.318c1.462 0 2.646 1.093 2.646 2.44H41c0-2.694-2.37-4.879-5.293-4.879h-.384V23h-2.646v2.963h-.384c-2.923 0-5.293 2.185-5.293 4.878 0 2.694 2.37 4.878 5.293 4.878h.384v4.879h-.384c-1.462 0-2.646-1.093-2.646-2.44H27c0 2.694 2.37 4.879 5.293 4.879h.384V46h2.646v-2.963h.384c2.923 0 5.293-2.185 5.293-4.879 0-2.693-2.37-4.877-5.293-4.877h-.384v-4.879h.384z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M66.336 43.543H59.9a1.251 1.251 0 00-1.05.572 23.25 23.25 0 01-5.337 5.78c-.447.34-.61.937-.4 1.456l1.587 3.861a3.123 3.123 0 01-1.75 4.063 3.15 3.15 0 01-2.362-.015 3.055 3.055 0 01-1.673-1.694l-1.25-3.126a1.25 1.25 0 00-1.512-.722c-1.996.61-4.063.956-6.149 1.033H27.507a22.906 22.906 0 01-8.21-1.755 1.252 1.252 0 00-1.638.684l-1.6 3.911a3.057 3.057 0 01-1.674 1.694 3.14 3.14 0 01-4.098-1.695 3.11 3.11 0 01-.014-2.353l2.237-5.467a1.242 1.242 0 00-.3-1.383c-9.272-8.708-9.706-23.258-.968-32.5A23.106 23.106 0 0127.507 8.67h13.385c.457.028.893-.196 1.137-.585a12.015 12.015 0 014.524-4.21c4.999-2.677 10.735-1.033 13.034-.174L55.75 14.325c-.143.434-.038.911.277 1.245a22.865 22.865 0 016.122 11.87 1.25 1.25 0 001.25 1.02h4.075l-1.137 15.083zm2.412-17.436H64.36a25.313 25.313 0 00-6.036-11.558L62.3 3.527a1.245 1.245 0 00-.624-1.532c-.352-.175-8.824-4.26-16.348-.212a14.48 14.48 0 00-4.999 4.434 1.18 1.18 0 00-.324-.038H27.507a1.311 1.311 0 00-.312 0A25.433 25.433 0 006.137 18.634a3.337 3.337 0 01-.5 0 3.118 3.118 0 01-3.124-3.114 3.119 3.119 0 013.124-3.113c.429-.005.855.078 1.25.249l.973-2.304c-2.85-1.23-6.16.073-7.394 2.913a5.597 5.597 0 002.923 7.372c.477.205.982.345 1.498.413a25.434 25.434 0 00-2.374 10.66A25.216 25.216 0 009.91 49.609l-1.912 4.67a5.61 5.61 0 003.087 7.299c.672.28 1.394.423 2.124.423a5.593 5.593 0 002.187-.448 5.617 5.617 0 003.025-3.064l1.124-2.777a25.683 25.683 0 007.649 1.531c.103.014.208.014.312 0h12.497c.104.014.209.014.313 0 1.83-.077 3.649-.342 5.423-.797l.85 2.043a5.622 5.622 0 003.025 3.051c1.378.58 2.933.58 4.31 0a5.61 5.61 0 003.088-7.298l-1.25-3.014a24.966 24.966 0 004.8-5.194h6.935a1.248 1.248 0 001.25-1.158l1.25-17.436c.02-.345-.1-.682-.338-.934a1.253 1.253 0 00-.912-.398z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M53.5 23a2.5 2.5 0 010 5 2.5 2.5 0 010-5zM24 13.666L24.87 16a28.042 28.042 0 0118.339 0L44 13.642a31.06 31.06 0 00-20 .024z"
			fill={color}
		/>
	</svg>
);

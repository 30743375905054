import React, {Component} from "react";
import sectionPageArray from "pages/QuestionFlow/pageStructure";
import {
	Wrapper,
	CircleGroup,
	Circle,
	Header,
} from "./PriorityExplanationHelpers";

const descriptions = [
	"We'll ask about your doctors to help us focus on options where your doctors are in-network. Some options also have cheaper monthly premiums because they work with a specific set of healthcare providers.",
	"Asking about your prescription drugs helps us to find options that cover your prescriptions at lower prices.",
	"We'll ask about your cost preferences to help find options that suit your budget. For example, some options cover almost all of your out-of-pocket costs in exchange for higher monthly premiums. And other options charge very low monthly premiums and make you responsible for more of your out-of-pocket costs if you get sick or injured.",
	"We'll ask about where you live and whether you mind needing referrals. These questions help us to identify options that give you access to care where you need it and how you prefer to receive it.",
	"We'll ask about whether you want other benefits, like dental, vision, and hearing care. We'll then try to short-list options that include these benefits.",
];

export class PriorityExplanation extends Component {
	sections = sectionPageArray.filter((s) => s.showTabs);
	state = {active: 0};

	render() {
		const {active} = this.state;

		return (
			<Wrapper>
				<Header>What we'll ask about:</Header>
				<CircleGroup>
					{this.sections.map((s, index) => (
						<Circle
							key={index}
							index={index}
							isActive={active === index}
							src={s.icon}
							onSelect={(active) => this.setState({active})}
							description={descriptions[index]}
							title={s.name}
						/>
					))}
				</CircleGroup>
			</Wrapper>
		);
	}
}

/**
 * Translate a URL string to a mapping of its query parameters
 * @param {string} url - The url source to generate the mapping
 * @returns {object} A mapping of {urlKey: urlValue}
 */

export const urlParse = (url) => {
	const {searchParams} = new URL(url);
	const searchParamsMap = {};

	for (const [key, value] of searchParams) {
		searchParamsMap[key] = value;
	}

	return searchParamsMap;
};

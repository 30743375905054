import React from "react";
import styled from "styled-components";
import STYLES from "common/util/constants/styles";

const Box = styled.div`
	height: 244px;
	background-color: white;
	border: 2px solid ${STYLES.COLORS.SKY_BLUE};
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	border-radius: 4px;

	h4 {
		border-bottom: 3px solid ${STYLES.COLORS.VIBRANT_MINT};
	}

	@media (min-width: 640px) {
		width: 384px;
	}

	@media (max-width: 639px) {
		width: 100%;
	}
`;

const Triangle = styled.div`
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid white;
	position: relative;
	top: -2px;
	margin: auto;
`;

const Chevron = styled.div`
	width: 0;
	height: 0;
	border-left: 2px solid ${STYLES.COLORS.SKY_BLUE};
	border-bottom: 2px solid ${STYLES.COLORS.SKY_BLUE};
	border-right: 1px solid transparent;
	border-top: 1px solid transparent;
	padding: 14px;
	transform: rotate(-45deg);
	position: relative;
	bottom: 37px;
	margin: auto;
`;

export default function BoxTriangle(props) {
	return (
		<div>
			<Box>
				<h4 children={props.children} />
			</Box>
			<Triangle />
			<Chevron />
		</div>
	);
}

import React from "react";
import styled, {css} from "styled-components";
import STYLES from "common/util/constants/styles";

const BoxWrapperCommon = styled.label`
	position: relative;
	display: flex;
	background: none;
	user-select: none;
	cursor: pointer;

	font-weight: ${({checked}) => (checked ? "bold" : "normal")};
`;

const BoxWrapperInline = styled(BoxWrapperCommon)`
	flex-direction: column;
	align-items: center;
	margin: 0 150px 50px 0;

	:last-child {
		margin-right: 0;
	}

	> :first-child {
		margin-bottom: 20px;
	}
`;

const BoxWrapperColumn = styled(BoxWrapperCommon)`
	> :first-child {
		margin-right: 15px;
	}

	@media (max-width: 639px) {
		padding: 20px 0;
	}
`;

const BoxStyled = styled.div`
	width: ${({large}) => (large ? 45 : 30)}px;
	height: ${({large}) => (large ? 45 : 30)}px;
	border: 2px solid ${STYLES.COLORS.SKY_BLUE};
	border-radius: ${({large}) => (large ? 12 : 6)}px;
	background-color: ${({checked}) =>
		checked ? STYLES.COLORS.MIDNIGHT_BLUE : "white"};
	background-repeat: no-repeat;
	background-image: url("/images/icons/checkmark.svg");
	background-size: ${({large}) => (large ? 29 : 20)}px;
	background-position: ${({large}) => (large ? "5px 10px" : "3px 5px")};
	content: " ";
	flex-shrink: 0;
`;

const typeComponentMap = {
	inline: BoxWrapperInline,
	column: BoxWrapperColumn,
};

export const Checkbox = (props) => {
	const onKeyDown = (e) => {
		if (e.keyCode !== 32) return;
		props.onChange();
		e.preventDefault();
	};

	const CheckboxComponent = typeComponentMap[props.type];

	return (
		<CheckboxComponent onClick={props.onChange} checked={props.checked}>
			<BoxStyled
				checked={props.checked}
				tabIndex={0}
				onKeyDown={onKeyDown}
				large={props.large}
			/>
			<div children={props.label} />
		</CheckboxComponent>
	);
};

export const CheckboxWrapper = styled.form`
	display: flex;

	${({type}) =>
		type === "column" &&
		css`
			flex-direction: column;
			align-items: flex-start;
		`}

	${({type}) =>
		type === "inline" &&
		css`
			justify-content: center;
		`}
`;

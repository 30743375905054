import {IChecklistItem} from "common/ui/Checkbox/Checklist";
import visitWebsite from "./images/visit-website.png";
import startApplication from "./images/start-application.png";
import signIn from "./images/sign-in.png";
import reviewApplication from "./images/review-application.png";
import reEntryNumber from "./images/re-entry-number.png";
import {SocialSecurityAccountCallout} from "./SocialSecurityAccountCallout";
import {SocialSecurityApplicationImage} from "./SocialSecurityApplicationImage";

export const SOCIAL_SECURITY_CHECKLIST_CONFIG: IChecklistItem[] = [
	{
		value: "visit-website",
		label: "1: Visit the Social Security Administration website (ssa.gov)",
		descriptionLines: [
			"You'll need to sign up for Medicare through the Social Security Administration website. <b>Don't worry - this does not enroll you in retirement benefits.</b>",
		],
		imageUrl: visitWebsite,
		imageExpandable: true,
		actions: [
			{title: "Open website", link: "https://secure.ssa.gov/iClaim/rib"},
		],
	},
	{
		value: "start-application",
		label: "2: Start the application",
		descriptionLines: [
			"Answer a few questions about yourself.",
			"If you don't have a \"my Social Security\" account, you'll need to create one.",
		],
		imageUrl: startApplication,
		imageExpandable: true,
	},
	{
		value: "sign-in",
		label: '3: Sign in or create your <b>"my Social Security"</b> account',
		descriptionLines: [
			'Sign in with your username and password, and then click <b>"Sign in"</b>.',
			'If you do not have an account, click on the button that reads <b>"Create New Account"</b>.',
		],
		imageUrl: signIn,
		imageExpandable: true,
		additionalComponent: SocialSecurityAccountCallout,
	},
	{
		value: "fill-out-application",
		label: "4: Fill out the Medicare application",
		descriptionLines: [
			'After logging in or creating your "my Social Security" account, you\'ll need to fill out a form of your basic info. Just follow the prompts. <br /><br /> <b>Questions include:</b>',
			"<b>If you want to Enroll in Medicare only:</b> Select “yes” unless you would like to start collecting social security benefits.",
			"<b>Choose whether to enroll in Parts A & B:</b> Select whether you’d like to enroll in just Part A, or Part A and Part B.",
			"<b>Your current health coverage:</b> Fill out your current health insurance and employment information (if applicable).",
		],
		imageExpandable: true,
		imageComponent: SocialSecurityApplicationImage,
	},
	{
		value: "review-application",
		label: "5: Review your application and confirm your e-signature",
		descriptionLines: [
			"Review your information, and submit your application for review. You'll receive a <b>re-entry number</b>, please record it in step 6 below.",
			'<b>IMPORTANT:</b> You will receive an email from "Echo Sign" to confirm your e-signature. <b>You need to click the confirmation in this email for your application to be complete.</b>',
		],
		imageUrl: reviewApplication,
		imageExpandable: true,
	},
	{
		value: "save-re-entry-number",
		label: "<b>Pro-tip:</b> save your re-entry number!",
		descriptionLines: [
			"Saving this number could potentially save you a lot of time and hassle if there’s a problem with your application. It is worth its weight in gold. Write it down and keep it in a safe place.",
		],
		imageUrl: reEntryNumber,
		imageExpandable: true,
	},
];

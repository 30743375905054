import React from "react";
import {Button} from "common/ui/Button/Button";
import {MdStar} from "react-icons/md";

const INTRO_HEADER_LINES = [
	"Welcome to Chapter!",
	"I'm Ari, I'll be your Medicare expert.",
];
const INTRO_EXPLAINER_LINES = [
	"Chapter is the first Medicare advisor that makes finding coverage simple and does the hard work for you.",
	"Our commitment is to make sure you have the information that is in your best interest. We always put your needs first by searching every single Medicare option available to you.",
];

function Reviews() {
	return (
		<div className="flex flex-col items-center space-y-3">
			<div className="flex text-xl">
				"
				<span className="flex text-3xl text-teal-800">
					<MdStar />
					<MdStar />
					<MdStar />
					<MdStar />
					<MdStar />
				</span>
				"
			</div>
			<div className="font-bold text-lg">100% 5-star reviews</div>
		</div>
	);
}

export function FlowIntro({onSubmit}: {onSubmit: () => void}) {
	return (
		<>
			<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
				{INTRO_HEADER_LINES.map((line, i) => (
					<div key={line}>
						{line}
						{(i === 1 && <br />) || ""}
					</div>
				))}{" "}
			</div>
			<div className="flex flex-col items-center space-y-10 mt-10">
				<img
					alt={INTRO_EXPLAINER_LINES[0]}
					className="h-40"
					src={"/images/illustrations/journey.svg"}
				/>
				<div className="text-left space-y-5 max-w-lg leading-relaxed">
					{INTRO_EXPLAINER_LINES.map((line) => (
						<p key={line} dangerouslySetInnerHTML={{__html: line}}></p>
					))}
				</div>
				<Button
					onClick={() => {
						onSubmit();
					}}
				>
					Get Started
				</Button>
				<div className="pb-8">
					<Reviews />
				</div>
			</div>
		</>
	);
}

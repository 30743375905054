import React from "react";
import {Link} from "react-router-dom";

import {Button} from "common/ui/Button/Button";
import {Video} from "common/ui/Video";

import {HOW_TO_SIGN_UP_FOR_MEDICARE_TIMELINE} from "./data";
import {GuideSignUp} from "pages/Flow/shared/GuideSignUp";
import {TimeLabel} from "pages/Flow/shared/TimeLabel";
import {useUser} from "common/hooks/useUser";

export function SignUpVideo({onSubmit}: {onSubmit: () => void}) {
	const {user} = useUser();

	return (
		<div className="flex flex-col">
			<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
				How to sign up for Medicare
			</div>
			<TimeLabel
				className="font-bold text-base text-gray-600 mb-10 mt-3 mx-auto leading-relaxed px-4"
				time="9 mins"
			/>
			<Video
				videoId={"T8Mocu9EB8k"}
				className="max-w-2xl mx-auto w-screen flex"
				timeline={HOW_TO_SIGN_UP_FOR_MEDICARE_TIMELINE}
			/>
			{user && user.email ? (
				<div className="my-10">
					<Button onClick={onSubmit}>Go to sign-up guide</Button>
				</div>
			) : (
				<div className="shadow-lg rounded border max-w-xl mx-auto my-5 py-5 px-10">
					<GuideSignUp onSubmit={onSubmit} />
				</div>
			)}
			<div className="my-5">
				<Link to="/flow/new/learn/sign-up/checklist/skip">
					or{" "}
					<span className="text-underline-accent">
						skip ahead to finding a plan {">"}
					</span>
				</Link>
			</div>
		</div>
	);
}

import React from "react";
import {Button} from "common/ui/Button/Button";
import {Video} from "common/ui/Video";

import {Link} from "react-router-dom";
import {EXPLAINER_VID_TIMELINE} from "./data/timeline";
import {TimeLabel} from "pages/Flow/shared/TimeLabel";

export function LearnExplainer({onSubmit}: {onSubmit: () => void}) {
	return (
		<div className="flex flex-col">
			<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
				{/** Page Header */}
				Medicare 101: Parts A & B Explained
			</div>
			<TimeLabel
				className="font-bold text-base text-gray-600 mb-10 mt-3 mx-auto leading-relaxed px-4"
				time="6 mins"
			/>

			<Video
				videoId={"QO8aXtH2l30"}
				className="max-w-2xl mx-auto w-screen flex"
				timeline={EXPLAINER_VID_TIMELINE}
			/>

			<div className="text-lg my-5">
				Next: Let's find out when you can enroll in Medicare.
			</div>

			<div className="my-10">
				<Link to="/flow/new/learn/enrollment-dates/1">
					<Button onSubmit={onSubmit}>Continue</Button>
				</Link>
			</div>
		</div>
	);
}

import {Component} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "common/ui/Button";
import Dialog from "common/ui/Dialog";
import axios from "common/util/api/axios";
import endpoints from "common/util/api/endpoints";
import eventTracker from "common/util/eventTracker";
import Scheduler from "modals/Scheduler";
import STYLES from "common/util/constants/styles";
import {legalExpertText} from "common/util/common";

const Wrapper = styled.div`
	margin: auto;
	width: fit-content;
	${({maxWidth}) => maxWidth && `max-width: ${maxWidth}px;`}
`;

const LegalText = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 12px;
	line-height: 23px;
	margin: 15px auto 0 auto;

	${({textColor}) => textColor && `color: ${textColor};`}
`;

export default class ExpertButton extends Component {
	clickButton = () => {
		const {name, email, customerId, onClick} = this.props;

		// Allow parent to overwrite the action
		if (onClick) onClick();
		else {
			Dialog.show({
				children: <Scheduler name={name} email={email} />,
				width: Scheduler.width,
				height: Scheduler.height,
				bgColor: "white",
			});
		}

		axios.post(endpoints.questionSave(), {
			acceptedScopeOfAppointment: true,
			customerId,
		});

		eventTracker.log({
			name: eventTracker.EVENTS.CLICK_BUTTON,
			data: {name: "OpenCalendly"},
		});
	};

	render() {
		const {
			buttonColorScheme,
			legalColor,
			maxWidth,
			className,
			text,
			type,
			showLegalText,
		} = this.props;

		return (
			<Wrapper maxWidth={maxWidth}>
				<Button
					onClick={this.clickButton}
					text={text}
					colorScheme={buttonColorScheme}
					type={type}
					className={className}
				/>
				{showLegalText && (
					<LegalText textColor={legalColor} children={legalExpertText} />
				)}
			</Wrapper>
		);
	}
}

ExpertButton.propTypes = {
	customerId: PropTypes.number,
};

ExpertButton.defaultProps = {
	text: "Speak with an expert",
	showLegalText: true,
};

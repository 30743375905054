import React, {useState} from "react";
import {Button} from "common/ui/Button/Button";
import {InputTextEmail} from "common/ui/InputText";
import {MdCheckCircle} from "react-icons/md";

const CONSENT_TEXT =
	"I grant consent for Chapter's licensed advisors or representatives to contact me about Medicare plans.";

export function EmailField({
	onSubmit,
	submitText,
}: {
	submitText: string;
	onSubmit: (email: string) => void;
}) {
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [email, setEmail] = useState("");

	const [hasClickedSubmit, setHasClickedSubmit] = useState(false);
	const [hasSubmitted, setHasSubmitted] = useState(false);

	return (
		<div className="flex flex-col items-center">
			{hasSubmitted ? (
				<div className="my-3 flex items-center justify-center bg-gray-100 px-10 py-5 rounded-lg">
					<MdCheckCircle className="text-teal-700 text-xl mr-2" />
					<div className="">Thanks, we'll send you an email!</div>
				</div>
			) : (
				<>
					<InputTextEmail
						onChange={(e) => {
							const updatedEmail = e.target.value;
							const emailMatchPattern = email.match(/.*@.*\..*/);
							setIsEmailValid(!!emailMatchPattern);
							setEmail(updatedEmail);
						}}
					/>
					<div className="h-3">
						{hasClickedSubmit && !isEmailValid && (
							<div className="text-red-600 text-xs">Required</div>
						)}
					</div>
					<div className="text-gray-500 text-xs max-w-sm mb-4 text-left">
						{CONSENT_TEXT}
					</div>
					<Button
						onClick={() => {
							setHasClickedSubmit(true);
							if (isEmailValid) {
								setHasSubmitted(true);
								onSubmit && onSubmit(email);
							}
						}}
					>
						{submitText}
					</Button>
				</>
			)}
		</div>
	);
}

import React, {Component} from "react";
import styled from "styled-components";
import BoxTriangle from "common/ui/BoxTriangle";
import STYLES from "common/util/constants/styles";

const Medicare101Styled = styled.div`
	text-align: center;
`;

const TextBottom = styled.div`
	line-height: 32px;

	div {
		margin-bottom: 25px;
	}
`;

export class Medicare101 extends Component {
	static containerColor = STYLES.COLORS.GLACIER;
	// constructor(props) {
	// 	super(props);
	// 	props.setBottomNextButtonText(null);
	// }

	render() {
		return (
			<Medicare101Styled>
				<h2>Medicare 101</h2>
				<BoxTriangle children="What is Medicare?" />
				<TextBottom>
					<div>
						Medicare is federally funded health insurance available to people
						who are aged 65+ or who are disabled and meet certain conditions.
						For most people, Medicare is something that you have earned, largely
						through years of paying income taxes.
					</div>
					<div>
						So congratulations! Medicare is something you've worked for.
					</div>
				</TextBottom>
			</Medicare101Styled>
		);
	}
}

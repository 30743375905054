import React from "react";
import Explainer from "pages/Flow/shared/Explainer";
import {Button} from "common/ui/Button/Button";

const COMPLETED_TITLE = "Congratulations! You did it!";
const COMPLETED_DESCRIPTION_LINES = [
	`You’ve <b>successfully finished your first step</b>: that’s a milestone!`,
	`Remember, <b>Parts A & B cover at most 80% </b> of your medical expenses. The next step will help you <b>cover that missing 20%</b>.`,
];

export function Completed({onSubmit}: {onSubmit: () => void}) {
	return (
		<>
			<Explainer
				title={COMPLETED_TITLE}
				descriptionLines={COMPLETED_DESCRIPTION_LINES}
				imgSrc="/images/illustrations/celebration.svg"
			/>
			<div className="mt-5 mb-10 text-lg">
				Let’s talk about additional coverage, and why most Americans have it.
			</div>
			<Button onClick={onSubmit}>Continue</Button>
		</>
	);
}

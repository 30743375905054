import React from "react";
import styled from "styled-components";
import {Popover} from "@blueprintjs/core";
import Tooltip from "./index";
import STYLES from "common/util/constants/styles";
import {capitalize} from "common/util/format";

const TextStyled = styled.span`
	border-bottom: 2px solid ${STYLES.COLORS.VIBRANT_MINT};
	background-color: ${STYLES.COLORS.GLACIER};
`;

export default function HoverTooltip({
	children,
	content,
	title,
	className,
	hideTitle,
	position,
}) {
	let tooltipContent;
	if (hideTitle) tooltipContent = content;
	else {
		tooltipContent = (
			<>
				<b>
					{typeof title === "undefined"
						? capitalize(children)
						: capitalize(title)}{" "}
					:
				</b>{" "}
				{content}
			</>
		);
	}

	return (
		<Popover className={className}>
			<Tooltip content={tooltipContent} position={position}>
				<TextStyled>{children}</TextStyled>
			</Tooltip>
		</Popover>
	);
}

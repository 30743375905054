import React from "react";
import styled from "styled-components";
import STYLES from "common/util/constants/styles";
import Button from "common/ui/Button";

export const ProviderAddDetailsBody = styled.div`
	padding: 65px 75px;
	text-align: center;
`;

const DotRow = styled.div`
	display: flex;
	justify-content: center;
`;

const Dot = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 50%;
	margin-right: 20px;

	${({$filled}) =>
		$filled
			? `background-color: ${STYLES.COLORS.SKY_BLUE}`
			: `border: 2px solid ${STYLES.COLORS.SKY_BLUE}`}
`;

export const FrequencyButtonRow = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 25px;

	@media (max-width: 639px) {
		justify-content: center;
	}
`;

const FrequencyButtonStyled = styled(Button)`
	width: ${({$fullWidth}) => ($fullWidth ? "100%" : "225px")};
	height: ${({$fullWidth}) => ($fullWidth ? "75px" : "140px")};
	margin-bottom: 30px;
`;

const LocationButtonStyled = styled(Button)`
	width: 100%;
	height: 120px;
	margin-top: 25px;
`;

export const LocationButton = ({
	location,
	onSelectAddress,
	selectedAddress,
}) => {
	const text = (
		<>
			{location.address_details.street}
			<br />
			{`${location.address_details.city}, ${location.address_details.state} ${location.address_details.zip}`}
		</>
	);

	return (
		<LocationButtonStyled
			type="large"
			text={text}
			onClick={() => onSelectAddress(location)}
			active={location.selected}
		/>
	);
};

export const FrequencyButton = ({frequency, onSelectFrequency, fullWidth}) => {
	return (
		<FrequencyButtonStyled
			type="large"
			text={frequency.label}
			onClick={() => onSelectFrequency(frequency.value)}
			$fullWidth={frequency.fullWidth || document.body.clientWidth < 640}
		/>
	);
};

export const ProviderAddDetailsDots = ({numTotal, numFilled}) => {
	const dots = [];

	for (let i = 0; i < numTotal; i++) {
		dots.push(<Dot $filled={i === numFilled - 1} key={i} />);
	}

	return <DotRow children={dots} />;
};

import React from "react";
import ExpandableImage from "../ExpandableImage";
import {Link} from "../Link/Link";
import {ICheckboxGroupProps, CheckboxGroup} from "./CheckboxGroup";

export interface IChecklistItem {
	/** Key for storing state */
	value: string;
	label: string;
	descriptionLines: string[];
	additionalDescription?: string;
	imageUrl?: string;
	imageExpandable: boolean;
	actions?: {title: string; link: string}[];
	additionalComponent?: React.FC<any>;
	imageComponent?: React.FC<any>;
}

export interface IChecklistProps extends Omit<ICheckboxGroupProps, "options"> {
	rows: IChecklistItem[];
}

export function Checklist({rows, ...props}: IChecklistProps) {
	return (
		<CheckboxGroup
			{...props}
			isVertical={true}
			rootClassName={"flex items-start"}
			labelClassName={"w-full"}
			numColumns={1}
			options={rows.map((row) => {
				const label = (
					<div
						key={row.value}
						className="flex flex-col text-left border-b border-gray-100"
					>
						<div className="flex px-5 py-2 pb-10 mb-1 space-x-20 ">
							<div className="space-y-5 w-3/5">
								<div
									className="text-xl font-medium text-gray-900"
									dangerouslySetInnerHTML={{__html: row.label}}
								/>
								<div className="prose ml-3">
									<ul className="prose space-y-4">
										{row.descriptionLines.map((line) => (
											<li
												key={line}
												className="font-medium text-gray-700"
												dangerouslySetInnerHTML={{__html: line}}
											></li>
										))}
									</ul>
									{row.additionalDescription && (
										<div className="text-gray-500 text-xs">
											{row.additionalDescription}
										</div>
									)}
								</div>
								{row.actions && row.actions.length && (
									<div className="flex items-center ml-5 space-x-3">
										{row.actions.map((action) => (
											<Link
												key={action.link}
												linkType={"buttonPrimary"}
												href={action.link}
												target="_blank"
											>
												{action.title}
											</Link>
										))}
									</div>
								)}
							</div>
							<div className="w-2/5">
								{row.imageComponent ? (
									<row.imageComponent />
								) : row.imageExpandable ? (
									<ExpandableImage
										src={row.imageUrl}
										alt=""
										className="object-contain ml-auto"
									/>
								) : (
									row.imageUrl && (
										<img
											src={row.imageUrl}
											alt=""
											className="object-contain ml-auto"
										/>
									)
								)}
							</div>
						</div>

						{row.additionalComponent && <row.additionalComponent />}
					</div>
				);
				return {label, value: row.value};
			})}
		/>
	);
}

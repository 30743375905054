import React from "react";

export const rightChevron = ({color, height, width, onClick}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 16 29"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.654 27.385l12.693-12.693L1.654 2"
			stroke={color}
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

import React, {Component} from "react";
import styled from "styled-components";
import {LocalStorage} from "common/util/storage/";
import STORAGE_KEYS from "common/util/storage/keys";
import STYLES from "common/util/constants/styles";
import Icon from "common/ui/Icon";
import IconBackdrop from "common/ui/Icon/IconBackdrop";

const Wrapper = styled.div`
	text-align: center;
	margin: auto;

	div {
		margin: 30px auto;
	}
`;

const Big = styled.div`
	font-size: 48px;
	font-weight: bold;
`;

const Smol = styled.span`
	font-size: 16px;
`;

export class EducationComplete extends Component {
	static bodyColor = "white";
	static hasWaves = true;

	constructor(props) {
		super(props);

		props.setBottomNextButtonText("Next step: Your Priorities");

		this.state = {name: LocalStorage.getItem(STORAGE_KEYS.FIRST_NAME)};
	}

	render() {
		const {name} = this.state;

		return (
			<Wrapper>
				<span>Medicare Basics: Complete</span>
				<IconBackdrop color={STYLES.COLORS.VIBRANT_MINT} width={100}>
					<Icon src="checkMark" width={45} />
				</IconBackdrop>
				<Big>Thank you{name && `, ${name}`}!</Big>
				<Smol>
					Knowing the basics of Medicare will help you make an <br />
					informed decision when looking at your options.
				</Smol>
			</Wrapper>
		);
	}
}

import React, {Component} from "react";
import styled from "styled-components";
import Card from "common/ui/Card";
import Icon from "common/ui/Icon";
import IconBackdrop from "common/ui/Icon/IconBackdrop";
import STYLES from "common/util/constants/styles";

const Wrapper = styled.div`
	text-align: center;
	line-height: 33px;
	margin: auto;
	max-width: ${STYLES.MAX_WIDTH.DEFAULT};

	@media (max-width: 639px) {
		padding: 0 ${STYLES.PADDING.BODY_HORIZONTAL};
	}
`;

const Header = styled.div`
	font-size: 32px;
	font-weight: bold;
	margin-bottom: 40px;
`;

const CardStyled = styled(Card)`
	margin-bottom: 30px;
`;

const IconRowContainer = styled.div`
	width: fit-content;
	margin: 30px auto 0 auto;
`;

const IconRow = styled.span`
	display: flex;
	padding: 15px 0;
`;

const IconBackDropStyledTop = styled(IconBackdrop)`
	flex-shrink: 0;

	@media (min-width: 640px) {
		margin-right: 28px;
	}

	@media (max-width: 639px) {
		margin-right: 15px;
	}
`;

const IconBackDropStyledBottom = styled(IconBackdrop)`
	border-radius: 6px;
`;

const SpacedText = styled.div`
	margin-top: 15px;
`;

const CoverageIcons = styled.div`
	display: flex;
	justify-content: space-evenly;
	margin-top: 35px;
`;

const BottomText = styled.div`
	font-size: 25px;
`;

const CoverageContainerStyled = styled.div`
	padding: 0 5px;
`;

const CoverageContainer = ({icon}) => {
	return (
		<CoverageContainerStyled>
			<IconBackDropStyledBottom color="#FFDCDA" width={75}>
				<Icon src={icon} width={40} />
			</IconBackDropStyledBottom>
			<Icon src="x" width={20} color={STYLES.COLORS.PEACH} />
		</CoverageContainerStyled>
	);
};

export class AdditionalCoverage extends Component {
	static containerColor = STYLES.COLORS.GLACIER;

	coverageIcons = ["formulary", "eye", "tooth", "ear"];

	render() {
		return (
			<Wrapper>
				<Header>Most people select additional coverage. Why?</Header>
				<CardStyled color={STYLES.COLORS.LIGHT_GREY} borderHeight={23}>
					<Icon src="dollarBill" />
					<SpacedText>
						Traditional Medicare (Parts A and B) leaves you{" "}
						<b>
							responsible
							<br />
							for paying at least 20% of medical costs.
						</b>
					</SpacedText>
					<IconRowContainer>
						<IconRow>
							<IconBackDropStyledTop
								color={STYLES.COLORS.CONFIRM_GREEN}
								width={33}
							>
								<Icon src="checkMark" width={17} color="white" />
							</IconBackDropStyledTop>
							<div>
								Medicare pays: at most <b>80%</b>
							</div>
						</IconRow>
						<IconRow>
							<IconBackDropStyledTop color={STYLES.COLORS.PEACH} width={33}>
								<Icon src="exclamation" width={6} color="white" />
							</IconBackDropStyledTop>
							<div>
								You still pay: at least <b>20%</b>
							</div>
						</IconRow>
					</IconRowContainer>
				</CardStyled>
				<CardStyled color={STYLES.COLORS.LIGHT_GREY} borderHeight={15}>
					<div>
						And Traditional Medicare <b>does not</b> include coverage for most
						prescription drugs or services like dental, vision, and hearing
						care.
					</div>
					<CoverageIcons>
						{this.coverageIcons.map((src, index) => (
							<CoverageContainer key={index} icon={src} />
						))}
					</CoverageIcons>
				</CardStyled>
				<BottomText>
					That's why people look for additional Medicare coverage.
					<br />
					<b>And that's exactly what we help with.</b>
				</BottomText>
			</Wrapper>
		);
	}
}

import React from "react";
import QuestionnaireWrapper from "./QuestionnaireTemplate";
import HoverTooltip from "common/ui/Tooltip/HoverTooltip";
import STORAGE_KEYS from "common/util/storage/keys";

const coverageDesc =
	"These are Part D prescription drug plans. We can help recommend one that fits your needs and covers your drugs.";

const partDDescription = (
	<>
		The Medicare Part D benefit provides coverage for prescription drugs and
		sometimes has its own premium required, depending on the plan
	</>
);

const partDTitle = "Medicare Part D";

const config = {
	options: [
		{value: "true", label: "Yes"},
		{value: "false", label: "No"},
	],
	localStorageKey: STORAGE_KEYS.PAGES.QUESTIONNAIRE_PRESCRIPTIONS,
	questionKey: "prescriptionCoverage",
	icon: "formulary",
	title: "Prescriptions",
	description: "Are you looking for prescription drug coverage?",
	drawer: {
		medigap: [
			"Does not include prescription drug coverage",
			<>
				Can be supplemented with inexpensive{" "}
				<HoverTooltip content={coverageDesc}>add-on coverage</HoverTooltip> for
				prescription drugs
			</>,
		],
		medAdv: [
			<>
				Sometimes includes{" "}
				<HoverTooltip content={partDDescription} title={partDTitle}>
					Part D
				</HoverTooltip>{" "}
				drug benefits within the plan
			</>,
			"Has lower premiums, sometimes as low as $0 per month",
		],
	},
};

export class QuestionnairePrescriptions extends QuestionnaireWrapper {
	componentDidMount() {
		this.setConfig(config);
	}
}

import React, {FormEvent} from "react";
import clsx from "clsx";

import {ToggleButton, IOption} from "./ToggleButton";

export interface IToggleButtonGroupProps {
	toggleClassName?: string;
	options: IOption[];
	name: string;
	isVertical?: boolean;
	additional?: any;
	onChange: (
		e: FormEvent<HTMLButtonElement>,
		newValue: IOption["value"] | null
	) => void;
	value: IOption["value"];
}

/**
 * Toggle Button Group controls the state of a set of
 * ToggleButtons. Currently only exclusive is supported,
 * but the onChange handler can mimic Material's version of this
 * https://material-ui.com/components/toggle-button/#toggle-buttons
 *
 * We diverged from Material's here because they assume all direct children
 * are are ToggleButtons, but we need to show additional information at times
 * and support multiple columns
 */
export function ToggleButtonGroup({
	options,
	isVertical = true,
	additional,
	value,
	toggleClassName,
	onChange,
	...props
}: IToggleButtonGroupProps) {
	const numColumns = isVertical && options.length > 5 ? 2 : 1;

	// Chunk our set of options to support multiple columns
	const chunks = options.reduce((acc, option: IOption, i: number) => {
		const ch = Math.floor(i / numColumns);
		acc[ch] = ([] as IOption[]).concat(acc[ch] || [], option);
		return acc;
	}, [] as IOption[][]);

	const handleExclusiveChange = (
		event: FormEvent<HTMLButtonElement>,
		buttonValue: IOption["value"]
	) => {
		if (!onChange) {
			return;
		}
		onChange(
			{
				...event,
				target: {...event.target, value: buttonValue, name: props.name} as any,
			},
			value === buttonValue ? null : buttonValue
		);
	};

	return (
		<div className={clsx(isVertical ? "grid" : "")}>
			{chunks.map((row, i) => (
				<div
					className={clsx(
						isVertical ? "grid gap-3" : "",
						numColumns === 2 ? "grid-cols-2" : "grid-cols-1"
					)}
					key={i}
				>
					{row.map((option) => (
						<ToggleButton
							key={option.value}
							className={toggleClassName}
							option={option}
							selected={value === option.value}
							{...props}
							onChange={(e: FormEvent<HTMLButtonElement>) =>
								handleExclusiveChange(e, option.value)
							}
						/>
					))}

					<div className="col-span-2">
						{row.find((o) => value === o.value) &&
							additional &&
							additional(value)}
					</div>
				</div>
			))}
		</div>
	);
}

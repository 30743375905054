import React from "react";
import {EmailField} from "../shared/EmailField";

export function SpouseEmailModal({
	onClose,
}: {
	onClose: (email: string) => void;
}) {
	return (
		<div className="flex flex-col py-4 h-full px-7 overflow-auto max-h-full font-normal text-base">
			<div className="flex flex-col h-full py-3 px-4 space-y-5">
				<div className="text-center font-bold text-xl">
					Enter your spouse's email and we'll send them a link
				</div>
			</div>
			<div className="max-w-sm mx-auto">
				<div className="text-center mb-2">Spouse's Email</div>
				<EmailField onSubmit={onClose} submitText="Send my spouse a link" />
			</div>
		</div>
	);
}

import {useUser} from "common/hooks/useUser";
import React, {useEffect, useRef} from "react";
import {EmailField} from "./EmailField";

interface IRemindersProps {
	headerText?: string;
	actionText: string;
	onSubmit: (email: string) => void;
}
export function Reminders({headerText, actionText, onSubmit}: IRemindersProps) {
	const divRef = useRef<HTMLDivElement>(null);
	const {updateUser} = useUser();

	useEffect(() => {
		divRef?.current?.scrollIntoView({behavior: "smooth"});
	}, []);

	return (
		<div
			className="space-y-2 pb-5 bg-gray-100 max-w-lg px-4 py-5 mx-auto rounded-lg"
			ref={divRef}
		>
			{headerText && <div className="h5">{headerText}</div>}

			<EmailField
				submitText={actionText}
				onSubmit={(email) => {
					updateUser({email});
					onSubmit && onSubmit(email);
				}}
			/>
		</div>
	);
}

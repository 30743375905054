import styled, {css} from "styled-components";
import Icon from "common/ui/Icon";

export const InlineTipContainer = styled.div`
	width: ${({$width}) => ($width ? `${$width}px` : "100%")};
	transition: 0.5s;

	${({$visible}) =>
		!$visible &&
		css`
			opacity: 0;
			height: 0;
			visibility: hidden;
		`}
`;

export const Triangle = styled.div`
	width: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid ${({$color}) => $color};
	margin: auto;
`;

export const InlineTipStyled = styled.div`
	background-color: ${({$color}) => $color};
	padding: 25px 20px;
	font-size: 16px;
	text-align: left;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ClosingX = styled(Icon)`
	flex-shrink: 0;
	margin-left: 10px;
`;

import React, {Component} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "common/ui/Icon";
import STYLES from "common/util/constants/styles";

const DotRowStyled = styled.div`
	display: flex;
	margin-bottom: 25px;
	text-align: ${({textAlign}) => textAlign};

	svg,
	img {
		flex-shrink: 0;
	}
`;

const Dot = styled.div`
	height: ${({$width}) => $width}px;
	width: ${({$width}) => $width}px;
	background-color: ${({$color}) => $color};
	border-radius: 50%;
	flex-shrink: 0;
`;

const Content = styled.div`
	margin-left: 10px;
`;

export default class IconList extends Component {
	render() {
		const {
			items,
			color,
			textAlign,
			className,
			iconWidth,
			iconHeight,
			icon,
		} = this.props;

		const IconComp =
			icon === "dot" ? (
				<Dot $color={color} $width={iconWidth} />
			) : (
				<Icon src={icon} color={color} width={iconWidth} height={iconHeight} />
			);

		return (
			<div className={className}>
				{items.map((item, index) => (
					<DotRowStyled key={index} textAlign={textAlign}>
						{IconComp}
						<Content>{item}</Content>
					</DotRowStyled>
				))}
			</div>
		);
	}
}

IconList.propTypes = {
	items: PropTypes.arrayOf(PropTypes.node).isRequired,
	color: PropTypes.string,
	textAlign: PropTypes.string,
	iconWidth: PropTypes.number,
	iconHeight: PropTypes.number,
	icon: PropTypes.string,
};

IconList.defaultProps = {
	color: STYLES.COLORS.SKY_BLUE,
	textAlign: "left",
	iconWidth: 10,
	iconHeight: 10,
	icon: "dot",
};

import {Component} from "react";
import Dialog from "common/ui/Dialog";
import {Information} from "common/ui/Information";
import endpoints from "common/util/api/endpoints";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import {SomethingElse} from "./modals";
import {
	Wrapper,
	Title,
	Subtext,
	ButtonWrapper,
	LargeButtonStyled,
} from "./HowCanWeHelpStyled";

const buttonOptions = [
	{
		value: "medicareFirstTime",
		label: "I don't have any type of Medicare",
	},
	{value: "onlyPartA", label: "I have only Medicare Part A"},
	{
		value: "bothAAndB",
		label: "I have Medicare Part A and Part B only",
	},
	{
		value: "hasSupplementalCoverage",
		label: "I have a Medicare Advantage plan or Medicare Supplement plan",
		information:
			"If you have one of these plans, you have also signed up for Medicare Part A and Part B.",
	},
	{value: "unsure", label: "I'm not sure"},
];

export class HowCanWeHelp extends Component {
	static showRecentSavings = true;

	constructor(props) {
		super(props);

		props.setBottomNextButtonText(null);
	}

	onButtonClick = (val) => {
		const {setPersistence, advancePage} = this.props;

		setPersistence({
			url: endpoints.questionSave(),
			data: {
				howCanWeHelpYou: val,
			},
		});

		LocalStorage.setItem(STORAGE_KEYS.PAGES.HOW_CAN_WE_HELP, val);

		// Adam Mar 2021 - "somethingElse" is no longer an option. I left this here just in case we add back something similar
		if (val === "somethingElse") {
			Dialog.show({
				children: <SomethingElse advancePage={advancePage} />,
				bgColor: "white",
				width: 800,
				height: 500,
			});
		} else {
			advancePage();
		}
	};

	render() {
		return (
			<Wrapper>
				<Title>Chapter members can save thousands of dollars per year.</Title>
				<Subtext>What is your Medicare status?</Subtext>
				<ButtonWrapper>
					{buttonOptions.map((btn) => (
						<div key={btn.value} className="flex">
							<LargeButtonStyled
								type="large"
								text={btn.label}
								onClick={() => this.onButtonClick(btn.value)}
								className="flex-shrink-0"
							/>
							{btn.information && (
								<Information className="ml-3 flex-shrink-0">
									<div className="text-sm" children={btn.information} />
								</Information>
							)}
						</div>
					))}
				</ButtonWrapper>
			</Wrapper>
		);
	}
}

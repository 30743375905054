import React, {Component} from "react";
import styled from "styled-components";
import Button from "common/ui/Button";
import Touchable from "common/ui/Touchable";
import HoverTooltip from "common/ui/Tooltip/HoverTooltip";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import STYLES from "common/util/constants/styles";
import {legalExpertText} from "common/util/common";
import ExpertButton from "pages/QuestionFlow/views/Summary/Results/Common/ExpertButton";

const Container = styled.div`
	max-width: 800px;
	margin: auto;
	text-align: center;
	padding-bottom: 15px;

	@media (max-width: 639px) {
		padding: 0 ${STYLES.PADDING.BODY_HORIZONTAL};
	}
`;

const Title = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
	margin-bottom: 20px;

	@media (max-width: 639px) {
		font-size: 25px;
	}

	@media (min-width: 640px) {
		font-size: 31px;
	}
`;

const SubTitle = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 25px;
	margin-bottom: 15px;
`;

const HoverTooltipStyled = styled(HoverTooltip)`
	font-size: 12px;
`;

const ButtonWrapper = styled.div`
	max-width: 500px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 639px) {
		flex-direction: column;
		height: 150px;
	}
`;

const DotRowContainer = styled.div`
	max-width: 450px;
	font-size: 16px;
	text-align: left;
	position: relative;

	@media (max-width: 639px) {
		margin: 15px auto;
	}

	@media (min-width: 640px) {
		margin: 30px auto;
	}
`;

const LegalText = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 12px;
	line-height: 23px;
	margin: 15px auto 0 auto;
`;

const DotRow = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 40px;
`;

const DotWrapper = styled.div`
	background-color: white;
	z-index: 1;
`;

const Dot = styled.div`
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background-color: ${STYLES.COLORS.DARK_MINT};
	margin: 10px 10px 10px 0;
	flex-shrink: 0;
`;

const Bold = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.BOLD};
`;

const SpaceAbove = styled.div`
	margin-top: 10px;
`;

const Line = styled.div`
	border-left: 2px solid ${STYLES.COLORS.DARK_MINT};
	height: 92%;
	margin: 5px 0 0 10px;
	position: absolute;
`;

const ExpertButtonStyled = styled(ExpertButton)`
	font-size: 16px;
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
`;

const hoverContent =
	"By searching all available options, we find plans and savings that others miss.";

export class BetterForLess extends Component {
	constructor(props) {
		super(props);

		props.setBottomNextButtonText(null);

		this.state = {customerId: LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID)};

		this.storeCustomerId();
	}

	storeCustomerId = async () => {
		const {activeRequestPromises} = this.props;

		await Promise.all(activeRequestPromises);

		this.setState({customerId: LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID)});
	};

	render() {
		const {advancePage} = this.props;
		const {customerId} = this.state;

		return (
			<Container>
				<Title>Most people can find better coverage, for less.</Title>
				<SubTitle>Many people save thousands of dollars per year.</SubTitle>
				<HoverTooltipStyled children="HOW?" content={hoverContent} hideTitle />
				<DotRowContainer>
					<Line />
					<DotRow>
						<DotWrapper>
							<Dot />
						</DotWrapper>
						<Bold>
							<Touchable onClick={advancePage}>
								You take our short, 3-minute quiz
							</Touchable>
						</Bold>
					</DotRow>
					<DotRow>
						<DotWrapper>
							<Dot />
						</DotWrapper>
						<div>
							<Bold>
								Our algorithms search thousands of plans from all insurance
								companies.
							</Bold>
							<SpaceAbove>
								Most others search only a limited set of plans.
							</SpaceAbove>
						</div>
					</DotRow>
					<DotRow>
						<DotWrapper>
							<Dot />
						</DotWrapper>
						<Bold>
							We match you to the best-fit plans and review your options over
							the phone at your earliest convenience.
						</Bold>
					</DotRow>
				</DotRowContainer>
				<ButtonWrapper>
					<Button onClick={advancePage} text="Continue to quiz" />
					<ExpertButtonStyled
						text={
							<>
								Skip the quiz.
								<br />
								Call us directly instead.
							</>
						}
						customerId={customerId}
						buttonColorScheme="white"
						showLegalText={false}
					/>
				</ButtonWrapper>
				<LegalText>{legalExpertText}</LegalText>
			</Container>
		);
	}
}

import React, {Component} from "react";
import Drawer from "common/ui/Drawer";
import {SearchWrapper, SearchTop, ResultsRow} from "templates/search";
import {LocalStorage} from "common/util/storage/";
import STORAGE_KEYS from "common/util/storage/keys";
import endpoints from "common/util/api/endpoints";
import {ProviderComponent} from "./Provider";
import {ProviderAddDetailsDrawer} from "./ProviderAddDetails";
import ProviderDropDownRow from "./ProviderDropDownRow";
import {
	serverProviderToClient,
	cleanUserInput,
	generateStateFromChange,
	clientProviderToServer,
} from "./ProviderSearchHelpers";

export class ProviderSearch extends Component {
	static maxWidth = 1000;

	constructor(props) {
		super(props);

		this.zip = "00000";
		const basicInfo = LocalStorage.getItem(STORAGE_KEYS.PAGES.BASIC_INFO);
		if (basicInfo) this.zip = basicInfo.zipcode;

		const selectedProviders =
			LocalStorage.getItem(STORAGE_KEYS.PAGES.PROVIDER_SEARCH) || [];

		this.state = {
			selectedProviders,
			showTip: false,
		};

		this.setBottomText();
	}

	componentDidUpdate(newProps, newState) {
		const {setPersistence} = this.props;
		const {selectedProviders} = this.state;

		if (selectedProviders.length !== newState.selectedProviders.length)
			this.setBottomText();

		// Map doctors to server format
		const doctors = selectedProviders.map(clientProviderToServer);

		setPersistence({
			url: endpoints.providerSave(),
			data: {doctors},
		});

		LocalStorage.setItem(STORAGE_KEYS.PAGES.PROVIDER_SEARCH, selectedProviders);
	}

	setBottomText = () => {
		const {
			setBottomNextButtonText,
			setBottomNextButtonColorScheme,
		} = this.props;
		const {selectedProviders} = this.state;

		const hasContent = selectedProviders.length > 0;

		setBottomNextButtonText(hasContent ? "Next" : "Skip");
		setBottomNextButtonColorScheme(hasContent ? "blue" : "white");
	};

	openProviderDrawer = (provider) => {
		Drawer.show({
			content: ProviderAddDetailsDrawer,
			childProps: {
				provider,
				setProviderInfo: this.setProviderInfo,
			},
			canOutsideClickClose: false,
		});
	};

	// Add or edit provider
	setProviderInfo = ({provider, selectedAddressUuid, timesVisited}) => {
		const {selectedProviders} = this.state;

		const newState = generateStateFromChange({
			provider,
			selectedAddressUuid,
			timesVisited,
			selectedProviders,
		});

		this.setState(newState);
	};

	removeProvider = (providerNpi) => {
		const {selectedProviders} = this.state;

		const newProviders = [...selectedProviders].filter(
			(p) => providerNpi !== p.npi
		);

		this.setState({selectedProviders: newProviders});
	};

	mapProviderDropDownRow = (provider) => {
		return <ProviderDropDownRow provider={provider} key={provider.npi} />;
	};

	mapProvider = (provider) => {
		return (
			<ProviderComponent
				provider={provider}
				key={provider.npi}
				removeProvider={this.removeProvider}
				editProvider={this.openProviderDrawer}
			/>
		);
	};

	render() {
		const {selectedProviders, showTip} = this.state;

		return (
			<SearchWrapper>
				<SearchTop
					iconSrc="provider"
					iconWidth={50}
					header="We can search for options that already have your doctors in-network."
					dropDownUrl={endpoints.providerSearch({
						zip: this.zip,
					})}
					dropDownOnSelect={this.openProviderDrawer}
					dropDownPlaceholder="Search for your doctor"
					cleanUserInput={cleanUserInput}
					formatData={serverProviderToClient}
					renderRow={this.mapProviderDropDownRow}
					tipIsVisible={showTip}
					tipOnClose={() => this.setState({showTip: false})}
					tipText="You can also search for another doctor or specialist"
				/>
				<ResultsRow children={selectedProviders.map(this.mapProvider)} />
			</SearchWrapper>
		);
	}
}

import React, {Component} from "react";
import PropTypes from "prop-types";
import InlineTip from "common/ui/InlineTip";
import STYLES from "common/util/constants/styles";
import {
	InputWrapper,
	TextInputLabel,
	TextAreaWrapper,
} from "./TextInputHelpers";

const elementComponentMap = {
	input: InputWrapper,
	textarea: TextAreaWrapper,
};

export default class TextInput extends Component {
	state = {};

	onChangeWrapper = (e) => {
		const {onChange} = this.props;
		const {errorText} = this.state;

		onChange(e.target.value, e);

		const errorTextNew = this.generateErrorText(e.target.value);
		if (errorText && !errorTextNew) this.setState({errorText: null});
	};

	onKeyDownWrapper = (e) => {
		const {onKeyDown} = this.props;

		if (onKeyDown) onKeyDown(e.key);
	};

	generateErrorText = (val) => {
		const {validator} = this.props;

		let errorText = null;
		if (validator) {
			try {
				validator(val);
			} catch (err) {
				errorText = err.message;
			}
		}

		return errorText;
	};

	onBlurWrapper = (e) => {
		const {onBlur} = this.props;
		this.setState({errorText: this.generateErrorText(e.target.value)});

		if (onBlur) onBlur(e.target.value);
	};

	render() {
		const {
			value,
			placeholder,
			className,
			type,
			title,
			minLength,
			childRef,
			icon,
			min,
			max,
			autoFocus,
			name,
			htmlEl,
			rows,
			maxLength,
			iconPaddingLeft,
			iconPositionLeft,
			autoComplete,
		} = this.props;
		const {errorText} = this.state;

		const TextInputComponent = elementComponentMap[htmlEl];

		return (
			<div className={className}>
				{title && <TextInputLabel children={title} />}
				<TextInputComponent
					value={value}
					placeholder={placeholder}
					type={type}
					minLength={minLength}
					maxLength={maxLength}
					ref={childRef}
					onKeyDown={this.onKeyDownWrapper}
					onChange={this.onChangeWrapper}
					onBlur={this.onBlurWrapper}
					$icon={icon}
					iconPaddingLeft={iconPaddingLeft}
					iconPositionLeft={iconPositionLeft}
					min={min}
					max={max}
					autoFocus={autoFocus}
					autoComplete={autoComplete}
					className={className}
					hasError={!!errorText}
					name={name}
					rows={rows}
				/>
				<InlineTip
					visible={!!errorText}
					color={STYLES.COLORS.WARNING_RED}
					children={errorText}
				/>
			</div>
		);
	}
}

TextInput.propTypes = {
	autoFocus: PropTypes.bool,
	autoComplete: PropTypes.string,
	childRef: PropTypes.object,
	className: PropTypes.string,
	icon: PropTypes.string,
	iconPaddingLeft: PropTypes.number,
	iconPositionLeft: PropTypes.number,
	max: PropTypes.number,
	min: PropTypes.number,
	minlength: PropTypes.string,
	maxLength: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onKeyDown: PropTypes.func,
	onBlur: PropTypes.func,
	placeholder: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.string,
	htmlEl: PropTypes.oneOf(["input", "textarea"]),
	validator: PropTypes.func,
	value: PropTypes.string.isRequired,
};

TextInput.defaultProps = {
	placeholder: "",
	className: "",
	htmlEl: "input",
	type: "text",
	autoFocus: false,
	autoComplete: "on",
};

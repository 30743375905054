import React, {Component} from "react";
import Dialog from "common/ui/Dialog";
import Button from "common/ui/Button";
import endpoints from "common/util/api/endpoints";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import STYLES from "common/util/constants/styles";
import {primativeArraysAreEqual} from "common/util/common";
import {
	Wrapper,
	Title,
	Subtext,
	FactorInputCheckboxStyled,
	HelperText,
	TipStyled,
	checkboxOptions,
	ButtonContainer,
	SomethingElseContent,
	ButtonContinue,
} from "./FactorsHelpers";
import {SomethingElse} from "./modals";

export class Factors extends Component {
	static showRecentSavings = true;

	constructor(props) {
		super(props);

		props.setOnAttemptAdvance(this.onAttemptAdvance);

		let selected = LocalStorage.getItem(STORAGE_KEYS.PAGES.FACTORS) || [];

		// Handle transition from old schema
		if (typeof selected === "string") selected = [selected];
		this.state = {selected};

		props.setBottomNextButtonText(null);
	}

	componentDidMount() {
		const {selected} = this.state;

		if (selected.length) this.setParentPersistence();
	}

	componentDidUpdate(prevProps, prevState) {
		const {selected} = this.state;

		if (primativeArraysAreEqual(selected, prevState.selected)) return;

		LocalStorage.setItem(STORAGE_KEYS.PAGES.FACTORS, selected);

		this.setParentPersistence();
	}

	setParentPersistence = () => {
		const {selected} = this.state;
		const {setPersistence} = this.props;

		setPersistence({
			url: endpoints.questionSave(),
			data: {
				mostImportantToYou: selected.join(","),
			},
		});
	};

	updateUserPath = () => {
		const {insertSection, userPath} = this.props;
		const {selected} = this.state;

		const newPages = selected.map((s) => {
			const {template} = checkboxOptions.find((co) => co.value === s);
			return {name: template};
		});

		const newIndex = userPath.findIndex((s) => s.name === "Factors") + 1;

		insertSection({pages: newPages, type: "questionnaire"}, newIndex);
	};

	onAttemptAdvance = () => {
		const {selected} = this.state;

		let canAdvance = true;

		if (selected.length < 2) canAdvance = false;
		else this.updateUserPath();

		this.setState({showError: !canAdvance});
		return canAdvance;
	};

	onChangeCheckbox = (value) => {
		const {selected} = this.state;

		const newState = {};

		if (selected.includes(value)) {
			newState.selected = selected.filter((s) => s !== value);
		} else {
			newState.selected = [...selected, value];

			if (newState.selected.length > 2) newState.selected.shift();
		}

		this.setState(newState);
	};

	onClickSomethingElse = () => {
		Dialog.show({
			children: <SomethingElse />,
			bgColor: "white",
			width: 800,
		});
	};

	render() {
		const {selected, showError} = this.state;
		const {advancePage} = this.props;

		return (
			<Wrapper>
				<Title>What factors are most important to you?</Title>
				<Subtext>Choose 2:</Subtext>
				<FactorInputCheckboxStyled
					selected={selected}
					options={checkboxOptions}
					onChange={this.onChangeCheckbox}
					type="column"
				/>
				<TipStyled
					visible={showError}
					color={STYLES.COLORS.WARNING_RED}
					children="Please select 2 priorities above."
					width={300}
				/>
				<HelperText>
					(This helps us initially search. We'll ask one of our licensed
					advisors to further refine options for you.)
				</HelperText>
				<ButtonContainer>
					<ButtonContinue text="Continue" onClick={advancePage} />
					<Button
						colorScheme="white"
						text={<SomethingElseContent />}
						onClick={this.onClickSomethingElse}
					/>
				</ButtonContainer>
			</Wrapper>
		);
	}
}

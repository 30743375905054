import {IVideoTimeline} from "common/ui/Video";

export const EXPLAINER_VID_TIMELINE: IVideoTimeline[] = [
	{
		title: "Introduction",
		startTime: 0,
		durationText: "30s",
		descriptionLines: [
			`Hello again! In this video, I’m going to give an overview of what Medicare Parts A & B cover, and what they don’t cover.`,
			`Medicare is health insurance that the federal government helps to cover for Americans over the age of 65 and for many people with disabilities under the age of 65.`,
			`What is Medicare? Original Medicare has two parts, Part A and Part B.`,
		],
	},
	{
		title: "Part A",
		startTime: 30,
		durationText: "27s",
		descriptionLines: [
			`Part A is hospital coverage, and it helps to pay for services if you’re hospitalized.`,
			`The good news is that for most people, the cost of Medicare Part A coverage has already been paid for.`,
			`If you or your spouse have worked for ten years and had Medicare payroll taxes withheld, you have pre-paid for your Medicare Part A coverage.`,
			`This means you’ll never have to pay a premium for Part A when you enroll in Medicare.`,
		],
	},
	{
		title: "Part B",
		startTime: 60,
		durationText: "31s",
		descriptionLines: [
			`Then, there’s Part B. Part B helps cover medical services, for example when you go to the doctor’s office.`,
			`Unlike Part A, Part B premiums are not pre-paid.`,
			`When you choose to enroll in Part B, you must start paying premiums to Uncle Sam. `,
			`You should know that you can start Part A and Part B at different times.`,
			`When you turn 65, whether you should sign up for Part A and Part B will depend on a number of factors which I'll help you walk through in another video.`,
			`Together, Part A and Part B cover about 80% of hospital and medical services, but not the full costs.`,
		],
	},
	{
		title: "What they cover, and what they don’t",
		startTime: 92,
		durationText: "42s",
		descriptionLines: [
			`Together, Part A and Part B cover about 80% of hospital and medical services, but not the full costs.`,
			`This means you’re still responsible for the remaining 20-plus percent, with no cap on out-of-pocket expenses. So if you need an MRI that costs $1000 you would owe $200, and if the MRI reveals that you need a $10,000 shoulder procedure then you would owe an additional $2,000.`,
			`What’s more, Parts A and B don’t provide *any* coverage for prescriptions or for most dental care, vision care, or hearing aids.`,
		],
	},
	{
		title: "Supplemental coverage",
		startTime: 130,
		durationText: "40s",
		descriptionLines: [
			`This is why most people on Medicare consider supplemental coverage, which helps to cover the gaps that Medicare doesn’t cover.`,
			`Some of these plans are called Medigap plans -- or Medicare Supplement plans. Others are called Medicare Advantage plans, which are a different type of coverage.`,
			`And then there are prescription drug plans too -- it’s a lot to think about. And I’m here to help you through it.`,
			`First things first: When most people turn 65, they should sign up for Part A, because most people don’t need to pay a premium for Part A.`,
		],
	},
	{
		title: "If you have an HSA",
		startTime: 175,
		durationText: "55s",
		descriptionLines: [
			`If you have a Health Savings Account and want to continue contributing to it, you should not enroll in Part A.`,
			`This is because Uncle Sam will not let you contribute to an HSA once you are enrolled in any part of Medicare, whether Part A or Part B.`,
			`In this circumstance, you will want to defer Part A until you are ready to stop contributing to your HSA or you lose your employer-provided coverage. `,
		],
	},
	{
		title: "Summary",
		startTime: 230,
		durationText: "30s",
		descriptionLines: [
			`To summarize, almost everyone eligible for Medicare should enroll in Part A because (a) it’s pre-paid from your payroll taxes and (b) it gives you extra coverage when going to the hospital. The one potential exception is if you have an HSA.`,
			`In the next steps we’ll talk about whether you should sign up for Part B and how to actually sign up for Medicare.`,
		],
	},
];

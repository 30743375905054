/*
  * The Storage interace serves as a wrapper for window.localStorage and window.sessionStorage
  
  * It makes it easy to store both strings and objects in Web Storage
*/

class Storage {
	static storageMethod: any;

	static setItem(keyName: string, keyValue: any): void {
		if (typeof keyValue === "object") keyValue = JSON.stringify(keyValue);

		this.storageMethod.setItem(keyName, keyValue);
	}

	static getItem(keyName: string): any {
		let keyValue = this.storageMethod.getItem(keyName);

		if (keyValue !== null) {
			try {
				keyValue = JSON.parse(keyValue);
			} catch (e) {}
		}

		return keyValue;
	}

	static removeItem(keyName: string): void {
		this.storageMethod.removeItem(keyName);
	}
}

export class LocalStorage extends Storage {
	static storageMethod: any = window.localStorage;
}

// The v2 app uses SessionStorage acrros the board so that brokers can clear the data each time they open the app
export class SessionStorage extends Storage {
	static storageMethod: any = window.sessionStorage;
}

import React, {Component} from "react";
import styled from "styled-components";
import TextInput from "common/ui/TextInput";
import Button from "common/ui/Button";
import Dialog from "common/ui/Dialog";
import STYLES from "common/util/constants/styles";
import endpoints from "common/util/api/endpoints";
import axios from "common/util/api/axios";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import Scheduler from "modals/Scheduler";

export const Wrapper = styled.div`
	text-align: center;

	@media (min-width: 640px) {
		padding: 50px 0;
	}

	@media (max-width: 639px) {
		padding: 50px 10px 10px 10px;
	}
`;

export const Title = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.REGULAR};
	font-size: 25px;
`;

export const SubTitle = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.REGULAR};
	font-size: 16px;
	margin-top: 25px;
`;

export const XBold = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
	font-size: 16px;
	margin: 25px 0 15px 0;
`;

const ButtonWrapper = styled(Button)`
	margin: 35px auto 0 auto;
`;

const WhiteButton = styled(Button)`
	box-shadow: 0px 0px 5px rgba(0, 31, 103, 0.25);
	border-radius: 100px;
	border: none;
	font-size: 16px;
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	color: ${STYLES.COLORS.MIDNIGHT_BLUE};
	margin: 15px auto 0 auto;
`;

export const TextInputWrapper = styled(TextInput)`
	max-width: 400px;
	margin: auto;
`;

export class SomethingElse extends Component {
	state = {text: ""};

	submitText = () => {
		const {text} = this.state;

		const customerId = LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID);

		axios
			.post(endpoints.questionSave(), {
				customerId,
				howCanWeHelpYouSomethingElse: text,
			})
			.catch(console.error);

		this.setState({submitted: true});
	};

	showScheduler = () => {
		// Send data to server here because data is typically sent on page change, but user doesn't change page in this "off ramp"
		const customerId = LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID);
		axios
			.post(endpoints.questionSave(), {
				customerId,
				howCanWeHelpYou: "somethingElse",
			})
			.catch(console.error);

		Dialog.show({
			children: <Scheduler />,
			width: Scheduler.width,
			height: Scheduler.height,
			bgColor: "white",
		});
	};

	declineSchedule = () => {
		const {advancePage} = this.props;

		Dialog.hide();
		advancePage();
	};

	render() {
		const {text, submitted} = this.state;

		return (
			<Wrapper>
				{submitted ? (
					<>
						<Title>We can help with that.</Title>
						{text && <SubTitle>"{text}"</SubTitle>}
						<XBold>Would you like to speak with a licensed advisor now?</XBold>
						<ButtonWrapper text="Yes" onClick={this.showScheduler} />
						<WhiteButton
							text="Not yet"
							onClick={this.declineSchedule}
							type="outlineRound"
						/>
					</>
				) : (
					<>
						<Title>Is there something else we can help with?</Title>
						<SubTitle>
							One of our licensed advisors can help answer any questions you
							might have.
						</SubTitle>
						<XBold>I need help with...</XBold>
						<TextInputWrapper
							htmlEl="textarea"
							onChange={(text) => this.setState({text})}
							value={text}
							placeholder="Write something here..."
							rows={4}
						/>
						<ButtonWrapper text="Save" onClick={this.submitText} />
					</>
				)}
			</Wrapper>
		);
	}
}

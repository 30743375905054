import React from "react";
import QuestionnaireWrapper from "./QuestionnaireTemplate";
import STORAGE_KEYS from "common/util/storage/keys";
import HoverTooltip from "common/ui/Tooltip/HoverTooltip";

const description = (
	<>
		Do you spend parts of the year in different locations
		<br />
		where you want to be covered?
	</>
);

const networkDesc = (
	<>
		A plan's network refers to the set of healthcare providers or facilities
		that you can use, or that you can use at pre-set costs.
		<br />
		Most Medicare Advantage plans have "networks" of providers, while Medigap
		plans will pay for care provided by any doctor who accepts Traditional
		Medicare.
	</>
);

const networkTitle = "Network";

const config = {
	options: [
		{value: "true", label: "Yes"},
		{value: "false", label: "No"},
	],
	localStorageKey: STORAGE_KEYS.PAGES.QUESTIONNAIRE_LOCATION,
	questionKey: "coverageInMultipleLocations",
	icon: "house",
	title: "Location",
	description,
	drawer: {
		medigap: [
			"Allow you to see any doctor that accepts Traditional Medicare anywhere in the US",
			<>
				Does not have <HoverTooltip content={networkDesc}>network</HoverTooltip>{" "}
				restrictions, so may be a good fit if you spend substantial time in
				multiple parts of the country
			</>,
		],
		medAdv: [
			"Typically has defined networks of healthcare providers in a particular location or region",
			<>
				Is required to cover emergency coverage outside of their{" "}
				<HoverTooltip content={networkDesc} title={networkTitle}>
					networks
				</HoverTooltip>
				, but not non-emergency coverage
			</>,
		],
	},
};

export class QuestionnaireLocation extends QuestionnaireWrapper {
	componentDidMount() {
		this.setConfig(config);
	}
}

import styled from "styled-components";
import IconBackdrop from "common/ui/Icon/IconBackdrop";
import STYLES from "common/util/constants/styles";

export const Wrapper = styled.div`
	margin: auto;
	text-align: center;
	max-width: 600px;
	padding: 0px ${STYLES.PADDING.BODY_HORIZONTAL};

	button {
		margin: auto;
	}

	h2 {
		margin: 0;
		font-size: 32px;
	}
`;

export const IconBackdropStyled = styled(IconBackdrop)`
	margin: auto;
`;

export const AnimationParent = styled.div`
	max-width: 200px;
	min-height: 200px;
	margin: auto;
`;

import React from "react";

export const plus = ({color, height, width}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 19 19"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			stroke={color}
			className="colorable"
			strokeWidth="2"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
		>
			<path d="M9.5 18V1M1 9.5h17" />
		</g>
	</svg>
);

import React, {Component, Fragment, ReactElement} from "react";
import STYLES from "common/util/constants/styles";
import eventTracker from "common/util/eventTracker";
import {DialogWrapper, CloseIcon} from "./DialogHelpers";

type DialogProps = {
	children: ReactElement;
	height?: number;
	width?: number;
	bgColor?: string;
	canOutsideClickClose?: boolean;
};

type DialogState = DialogProps & {
	isOpen: boolean;
};

let instance: Dialog;

export default class Dialog extends Component<DialogProps, DialogState> {
	constructor(props: DialogProps) {
		super(props);

		this.state = {
			isOpen: false,
			children: <Fragment />,
		};

		instance = this;
	}

	static show = ({
		children,
		height,
		width,
		bgColor,
		canOutsideClickClose,
	}: DialogProps) => {
		instance.setState({
			isOpen: true,
			children,
			height,
			width,
			canOutsideClickClose,
			bgColor,
		});

		if (children) {
			eventTracker.log({
				name: eventTracker.EVENTS.MODAL_VIEW,
				// data: {modalName: children?.type?.name},
			});
		}
	};

	static hide = () => {
		instance.setState({isOpen: false});
	};

	render() {
		const {
			isOpen,
			children,
			height,
			width = 400,
			bgColor = STYLES.COLORS.GLACIER,
			canOutsideClickClose = true,
		} = this.state;

		const heightParsed = height ? `${height}px` : "auto";

		return (
			<DialogWrapper
				$height={heightParsed}
				$width={width}
				$bgColor={bgColor}
				isOpen={isOpen}
				onClose={() => this.setState({isOpen: false})}
				canOutsideClickClose={canOutsideClickClose}
			>
				{canOutsideClickClose && (
					<CloseIcon src="x" width={20} onClick={Dialog.hide} />
				)}
				{children}
			</DialogWrapper>
		);
	}
}

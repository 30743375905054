import React, {Component} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "common/ui/Button";
import STYLES from "common/util/constants/styles";

const SectionIntroStyled = styled.div`
	text-align: center;
	background-color: white;
	box-shadow: 0px 0px 4px 2px rgba(11, 33, 117, 0.15);
	border-radius: 4px;
	padding: 50px 0;

	button {
		margin: auto;
	}
`;

const SectionIntroTitle = styled.div`
	margin-top: 10px;
	font-size: 48px;
`;

export class SectionIntroPage extends Component {
	static containerColor = STYLES.COLORS.GLACIER;
	static hasWaves = true;

	constructor(props) {
		super(props);

		props.setBottomNextButtonText(null);
	}

	render() {
		const {title, description, advancePage, skipSection} = this.props;

		return (
			<SectionIntroStyled>
				NEXT SECTION:
				<br />
				<SectionIntroTitle>{title}</SectionIntroTitle>
				<br />
				<div>{description}</div>
				<br />
				<br />
				<Button onClick={advancePage} text="Start" />
				<br />
				<Button
					onClick={skipSection}
					text="Skip Section"
					type="minimal"
					icon="double-triangle"
					iconPosition="right"
				/>
			</SectionIntroStyled>
		);
	}
}

SectionIntroPage.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	advancePage: PropTypes.func.isRequired,
	skipSection: PropTypes.func.isRequired,
};

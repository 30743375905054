import React, {Component} from "react";
import styled from "styled-components";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import {legalExpertText} from "common/util/common";

// Map UTM source data to a FreshWorks source ID
const utmSourceFreshMapping = {
	twitter: 15000339201,
	nextdoor: 15000339200,
	google: 15000339199,
	facebook: 15000339051,
	"hear.com": 15000005207,
	hank: 15000339487,
	direct_mail_sep: 15000339438,
};

const Wrapper = styled.div`
	height: 100%;
	padding: 10px;

	#CalendlyDiv {
		height: calc(100% - 30px);
	}
`;

const LegalText = styled.div`
	font-size: 10px;
`;

export default class Scheduler extends Component {
	static height = 515;
	static width = 500;

	componentDidMount() {
		const {email, name} = this.props;
		const {REACT_APP_CALENDLY_URL} = process.env;

		const url = `https://calendly.com${REACT_APP_CALENDLY_URL}`;
		const queryParams = [
			"hide_landing_page_details=1",
			"hide_event_type_details=1",
		];

		const utmSource =
			utmSourceFreshMapping[LocalStorage.getItem(STORAGE_KEYS.UTM.SOURCE)];
		const utmMedium = LocalStorage.getItem(STORAGE_KEYS.UTM.MEDIUM);
		const utmCampaign = LocalStorage.getItem(STORAGE_KEYS.UTM.CAMPAIGN);
		const utmTerm = LocalStorage.getItem(STORAGE_KEYS.UTM.TERM);
		const utmContent = LocalStorage.getItem(STORAGE_KEYS.UTM.CONTENT);
		const utmAudience = LocalStorage.getItem(STORAGE_KEYS.UTM.AUDIENCE);
		const utmDrip = LocalStorage.getItem(STORAGE_KEYS.UTM.DRIP);

		window.Calendly.initInlineWidget({
			url: `${url}?${queryParams.join("&")}`,
			parentElement: document.getElementById("CalendlyDiv"),
			prefill: {name, email},
			utm: {
				utmSource,
				utmMedium,
				utmCampaign,
				utmTerm,
				utmContent,
				utmAudience,
				utmDrip,
			},
		});
	}
	render() {
		const {showLegalText} = this.props;

		return (
			<Wrapper>
				<div id="CalendlyDiv" />
				{showLegalText && <LegalText>{legalExpertText}</LegalText>}
			</Wrapper>
		);
	}
}

import QuestionnaireWrapper from "./QuestionnaireTemplate";
import STORAGE_KEYS from "common/util/storage/keys";
import {strToCamel} from "common/util/common";

const recomendationBenefits = ["Dental", "Vision", "Hearing"].map((b) => {
	const camel = strToCamel(b);
	return {value: camel, label: b, field: camel};
});

const otherBenefits = [
	"Chiropractor",
	"Acupuncture",
	"OTC",
	"Meal delivery",
	"Nutrition",
	"Physical Therapy",
	"Gym benefits",
	"Transportation",
	"Another benefit",
].map((b) => {
	return {value: strToCamel(b), label: b, field: "additionalBenefits"};
});

const config = {
	localStorageKey: STORAGE_KEYS.PAGES.QUESTIONNAIRE_BENEFITS,
	questionKey: "ADDITIONAL_BENEFITS",
	icon: "diamond",
	title: "Additional Benefits",
	type: "chips",
	options: recomendationBenefits.concat(otherBenefits),
	description:
		"Specific benefits: which ones are most important? (Choose up to 3)",
	drawer: {
		medigap: [
			"Typically excludes additional types of coverage",
			"Can be bundled with reasonable add-ons that cover additional benefits like dental, vision, or hearing",
		],
		medAdv: [
			"May include a combination of additional benefits, including dental, vision, and / or hearing care",
			"May come with other perks like fitness benefits or over-the-counter drug allowances",
		],
	},
};

export class QuestionnaireAdditionalCoverage extends QuestionnaireWrapper {
	componentDidMount() {
		this.setConfig(config);
	}
}

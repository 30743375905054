import axios from "axios";
import {LocalStorage} from "../storage";
import STORAGE_KEYS from "../storage/keys";

const instance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	headers: {
		common: {
			"X-API-TOKEN": process.env.REACT_APP_BACKEND_KEY,
		},
	},
});

// Override API token if one is stored in LS
instance.interceptors.request.use(function (config) {
	const token = LocalStorage.getItem(STORAGE_KEYS.LOGIN_TOKEN);
	if (token) config.headers["X-API-TOKEN"] = token;
	return config;
});

export default instance;

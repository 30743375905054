import React, {useState, useContext, createContext} from "react";

/**
 * The header context returned from the {@link useHeader} hook.
 * @category Client
 */
export interface IHeaderContext {
	coBrandImageUrl: string;
	setCoBrandImageUrl: (imageUrl: string) => void;
	altPhoneNumber: string;
	setAltPhoneNumber: (imageUrl: string) => void;
	homeUrl: string;
	setHomeUrl: (url: string) => void;
}

const missingHeaderProvider = "You forgot to wrap the app in <HeaderProvider>";

const HeaderContext = createContext<IHeaderContext>({
	get coBrandImageUrl(): never {
		throw new Error(missingHeaderProvider);
	},

	get setCoBrandImageUrl(): never {
		throw new Error(missingHeaderProvider);
	},

	get altPhoneNumber(): never {
		throw new Error(missingHeaderProvider);
	},

	get setAltPhoneNumber(): never {
		throw new Error(missingHeaderProvider);
	},

	get homeUrl(): never {
		throw new Error(missingHeaderProvider);
	},

	get setHomeUrl(): never {
		throw new Error(missingHeaderProvider);
	},
});

export type UseHeader = () => IHeaderContext;

export const useHeader: UseHeader = () =>
	useContext<IHeaderContext>(HeaderContext);

export function ProvideHeader({children}) {
	const headerProps = useProvideHeader();
	return (
		<HeaderContext.Provider value={headerProps as any}>
			{children}
		</HeaderContext.Provider>
	);
}

// Provider hook that creates auth object and handles state
export function useProvideHeader() {
	const [coBrandImageUrl, setCoBrandImageUrl] = useState<string>("");
	const [altPhoneNumber, setAltPhoneNumber] = useState<string>("");
	const [homeUrl, setHomeUrl] = useState<string>("");

	return {
		coBrandImageUrl,
		setCoBrandImageUrl,
		altPhoneNumber,
		setAltPhoneNumber,
		homeUrl,
		setHomeUrl,
	};
}

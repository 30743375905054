import {getMedsupCarriers, getMedsupPlanTypes} from "common/util/api/medsup";

class CarrierService {
	carriers: any[] | undefined = undefined;
	planTypes: any[] | undefined = undefined;

	async get() {
		if (this.carriers) {
			return this.carriers;
		}
		return await getMedsupCarriers().then((res) => {
			if (res) {
				this.carriers = res.data.map((name) => ({label: name}));
			}
			return this.carriers;
		});
	}

	async getPlanTypes() {
		if (this.planTypes) {
			return this.planTypes;
		}
		return await getMedsupPlanTypes().then((res) => {
			if (res) {
				this.planTypes = Object.entries(res.data).map(([value, label]) => ({
					label,
					value,
				}));
			}
			return this.planTypes;
		});
	}
}

export const carrierService = new CarrierService();

import {MEDICARE_MINIMUM_AGE} from "../common";

const medsupLowestPremium = ({
	zip,
	age = MEDICARE_MINIMUM_AGE,
	gender = "Female",
	tobacco,
	partner = false,
	planType = "G",
}) =>
	`/medsup/lowest_premium?zipcode=${zip}&age=${age}&gender=${gender}&partner=${partner}&tobacco=${!!tobacco}&planType=${planType}`;

const providerSearch = ({q = "", zip = "", distance = ""}) =>
	`/provider/search?zip=${zip}&distance=${distance}&q=${q}`;

const maReduce = ({maZipReferenceId, field, percentile}) =>
	`/medicare_advantage/plans/reduce?maZipReferenceId=${maZipReferenceId}&field=${field}&percentile=${percentile}`;

const partDReduce = ({maZipReferenceId, field, percentile}) =>
	`/drug_plans/plans/reduce?maZipReferenceId=${maZipReferenceId}&field=${field}&percentile=${percentile}`;

const endpoints = {
	providerSearch,
	medsupPlanDetails: () => "/medsup/plan_details",
	medsupLowestPremium,
	maReduce,
	partDReduce,
	formularySearch: ({q = ""}) => `/formulary/search?q=${q}`,
	zipcodePremiumSearch: ({zip = ""}) => `/customer/premium?zip=${zip}`,
	medsupCarriersList: () => "/medsup/carriers",
	medsupPlanTypesList: () => "/medsup/plan_types",
	customerSave: () => "/customer/save",
	customerGet: ({token}) => `/customer/${token}`,
	customerEvent: () => "/customer/event",
	formularySave: () => "/customer/prescriptions/save",
	providerSave: () => "/customer/doctors/save",
	questionSave: () => "/customer/questions/save",
	recommendations: () => "/recommendations",
	summaryV3: ({customerId}) => `/summary?customerId=${customerId}`,
	brokerSearchPlans: () => "/broker/doctor/plans",
	zipToCounty: ({zip = ""}) => `/zip?zip=${zip}`,
	createUser: () => "/user/create",
	login: () => "/user/login",
};

export default endpoints;

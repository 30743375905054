import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import * as FullStory from "@fullstory/browser";
import "./tailwind.css";
import "./global.css";
import "./constants.css";
import "./globalStyles/blueprintOverride.css";

const devMode = process.env.REACT_APP_FULLSTORY_DEV_MODE === "true";

FullStory.init({
	orgId: "Y6B5G",
	devMode,
});

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById("root")
);

import React from "react";

import {Button} from "common/ui/Button/Button";
import Explainer from "../shared/Explainer";
import {INTRO_DATA} from "./data/explainer";
import {useUser} from "common/hooks/useUser";

export function FirstTimerIntro({onSubmit}: {onSubmit: () => void}) {
	const {user} = useUser();

	const introData =
		INTRO_DATA[(user && user.medicareSignUpStatus) || "neither"];

	return (
		<>
			<Explainer
				{...introData}
				imgSrc="/images/illustrations/confirmed-with-woman.svg"
			/>
			<Button
				onClick={() => {
					onSubmit();
				}}
			>
				See my steps
			</Button>
		</>
	);
}

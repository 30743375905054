import React, {Component} from "react";
import styled from "styled-components";
import IconList from "common/ui/IconList";
import STYLES from "common/util/constants/styles";

const PageStructure = styled.div`
	text-align: center;
`;

const BoxRow = styled.div`
	@media (min-width: 640px) {
		display: flex;
		justify-content: space-between;
		max-width: 640px;
		margin: 50px auto;
	}

	@media (max-width: 639px) {
		margin-top: 50px;
	}
`;

const BoxStyled = styled.div`
	border-top: 27px solid ${STYLES.COLORS.SKY_BLUE};
	border-radius: 4px;
	background-color: white;
	box-shadow: 0px 0px 3px rgba(0, 31, 103, 0.25);
	padding: 14px;

	@media (min-width: 640px) {
		width: 288px;
	}

	@media (max-width: 639px) {
		margin-bottom: 50px;
	}
`;

const Text = styled.div`
	position: relative;
	top: -30px;
`;

const Circle = styled.div`
	width: 68px;
	height: 68px;
	border-radius: 50%;
	background-color: ${STYLES.COLORS.NICE_BLUE};
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	position: relative;
	top: -55px;
	margin: 0 auto;
`;

const BoxHeader = styled.div`
	font-weight: bold;
	margin-bottom: 15px;
	span {
		border-bottom: 2px solid ${STYLES.COLORS.DARK_MINT};
	}
	div {
		margin-bottom: 10px;
	}
`;

const IconListWrapper = styled(IconList)`
	font-size: 16px;
`;

const Box = (props) => {
	return (
		<BoxStyled>
			<Circle children={props.letter} />
			<Text>
				<BoxHeader>
					<div>
						<span>Part {props.letter}:</span>
					</div>
					{props.title}
				</BoxHeader>
				<IconListWrapper items={props.items} color={STYLES.COLORS.SALMON} />
			</Text>
		</BoxStyled>
	);
};

const PageHeader = styled.div`
	> * {
		margin-bottom: 30px;
	}
`;

export class BasicMedicare extends Component {
	static maxWidth = 1000;
	static containerColor = STYLES.COLORS.GLACIER;

	content = [
		{
			letter: "A",
			title: "Hospital Insurance",
			items: [
				"Inpatient care in a hospital",
				"Care in a skilled nursing facility",
				"Home health care",
			],
		},
		{
			letter: "B",
			title: "Medical Insurance",
			items: [
				"Medically necessary services, like those in a doctors' office",
				"Preventative services, like flu shots and certain disease screenings",
			],
		},
	];
	// constructor(props) {
	// 	super(props);
	// 	props.setBottomNextButtonText(null);
	// }

	render() {
		return (
			<PageStructure>
				<PageHeader>
					<h2>Traditional Medicare</h2>
					<div>
						There are 2 parts of traditional Medicare: Part A and Part B
					</div>
				</PageHeader>
				<BoxRow>
					{this.content.map((b) => (
						<Box key={b.letter} {...b} />
					))}
				</BoxRow>
			</PageStructure>
		);
	}
}

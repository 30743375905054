import React from "react";
import {Bar} from "react-chartjs-2";

export function BarChart({
	data,
	labels,
	className,
	...props
}: {
	data: number[];
	labels: string[];
	className?: string;
}) {
	return (
		<Bar
			className={className}
			type="bar"
			{...props}
			options={{
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						intersect: false,
					},
				},
				scaleShowLabels: false,
				tickMarkLength: 0,
				scales: {
					y: {
						ticks: {
							maxTicksLimit: 3,
						},
					},
					x: {
						ticks: {
							maxTicksLimit: 2,
						},
					},
				},
			}}
			data={{
				labels,
				datasets: [
					{
						label: "",
						data,
						backgroundColor: ["#14B8A6", "#115E59"],
					},
				],
			}}
		/>
	);
}

import React, {Component} from "react";
import Form from "common/ui/Form";
import TextInput from "common/ui/TextInput";
import Select from "common/ui/Select";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import {stringToMDY} from "common/util/format";
import endpoints from "common/util/api/endpoints";
import STYLES from "common/util/constants/styles";
import {Wrapper} from "./BasicInfoHelpers";

export class BasicInfo extends Component {
	static containerColor = STYLES.COLORS.GLACIER;

	nextComponent = "MedicareRetire";
	genderOptions = [
		{value: "Male", label: "Male"},
		{value: "Female", label: "Female"},
	];
	tobaccoOptions = [
		{value: "true", label: "Yes"},
		{value: "false", label: "No"},
	];

	constructor(props) {
		super(props);

		const savedState = LocalStorage.getItem(STORAGE_KEYS.PAGES.BASIC_INFO) || {};
		this.state = this.generateState(savedState);

		this.setParentPersistence();
		props.setOnAttemptAdvance(this.onAttemptAdvance);
	}

	componentDidUpdate(prevProps, prevState) {
		const {birthdate, zipcode, email, gender, tobacco} = this.state;

		this.setParentPersistence();

		LocalStorage.setItem(STORAGE_KEYS.PAGES.BASIC_INFO, {
			birthdate,
			zipcode,
			email,
			gender,
			tobacco,
		});
	}

	generateState = ({
		birthdate = "",
		zipcode = "",
		email = "",
		gender = "",
		tobacco = "",
	}) => ({
		birthdate,
		zipcode,
		email,
		gender,
		tobacco,
	});

	setParentPersistence = () => {
		const {setPersistence} = this.props;
		const {birthdate, zipcode, email, gender, tobacco} = this.state;

		const employment = LocalStorage.getItem(STORAGE_KEYS.HEAR_DATA.EMPLOYMENT) || "";
		const credit = LocalStorage.getItem(STORAGE_KEYS.HEAR_DATA.CREDIT_SCORE) || "";
		const firstName = LocalStorage.getItem(STORAGE_KEYS.FIRST_NAME) || "";
		const partner = LocalStorage.getItem(STORAGE_KEYS.PARTNER) || "";

		const birthmonth = birthdate.substring(0, 2);
		const birthyear = birthdate.substring(4, 8);
		const dob =
			birthdate && `${birthyear}-${birthmonth}-${birthdate.substring(2, 4)}`;

		setPersistence({
			url: endpoints.customerSave(),
			data: {
				birthmonth,
				birthyear,
				zip: zipcode,
				dob,
				partner,
				employment,
				credit,
				email,
				firstName,
				gender,
				tobacco,
			},
			onResponse: this.onResponse,
		});
	};

	isValidBirthdate = (birthdate) => {
		const parsedVal = birthdate.replace(/\D/g, "").substring(0, 8);

		if (parsedVal) {
			if (parsedVal.length !== 8)
				throw new Error("Please enter a full birthdate");

			const month = parseInt(parsedVal.substring(0, 2));
			const day = parseInt(parsedVal.substring(2, 4));
			const year = parseInt(parsedVal.substring(4, 8));

			if (isNaN(month) || month < 1 || month > 12)
				throw new Error("Please enter a valid birth month");
			if (isNaN(day) || day < 1 || day > 31)
				throw new Error("Please enter a valid birth day");
			if (isNaN(year) || year < 1900 || year > 2020)
				throw new Error("Please enter a valid birth year");
		}

		return true;
	};

	isValidEmail = (email) => {
		const emailMatchPattern = email.match(/.*@.*\..*/);

		if (!emailMatchPattern)
			throw new Error("Please enter a valid email address");

		return true;
	};

	onChangeBirthdate = (val, e) => {
		const parsedVal = val.replace(/\D/g, "").substring(0, 8);

		let loc = e.target.selectionStart;
		// Add 3 to account for the ' / '
		if ([3, 5].includes(parsedVal.length)) loc += 3;

		this.setState(
			{
				birthdate: parsedVal,
			},
			() => e.target.setSelectionRange(loc, loc)
		);
	};

	render() {
		const {advancePage} = this.props;
		const {birthdate, zipcode, email, gender, tobacco} = this.state;

		return (
			<Wrapper>
				<h4 className="page-header">
					To give you personalized advice, we need to <br />
					know a few more things about you.
				</h4>
				<Form onSubmit={advancePage}>
					<div className="form-row">
						<TextInput
							value={stringToMDY(birthdate)}
							onChange={this.onChangeBirthdate}
							placeholder="MM/DD/YYYY"
							title="Your Birthdate"
							name="birthdate"
							validator={this.isValidBirthdate}
							autoFocus
						/>
						<TextInput
							value={zipcode}
							onChange={this.onChangeZipcode}
							title="Zipcode"
							name="zipcode"
							validator={this.isValidZipcode}
							className="fs-exclude"
						/>
					</div>
					<div className="form-row">
						<Select
							title="Gender"
							placeholder="Select a gender"
							value={gender}
							options={this.genderOptions}
							onChange={(gender) => this.setState({gender})}
						/>
						<Select
							title="Do you use tobacco?"
							placeholder="Select"
							value={tobacco}
							options={this.tobaccoOptions}
							onChange={(tobacco) => this.setState({tobacco})}
						/>
					</div>
					<div className="form-row">
						<TextInput
							value={email}
							onChange={(email) => this.setState({email})}
							placeholder="example@getchapter.com"
							title="Email address"
							type="email"
							className="fs-exclude"
						/>
					</div>
				</Form>
			</Wrapper>
		);
	}
}

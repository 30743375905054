import React from "react";
import {Button} from "common/ui/Button/Button";
import Explainer from "pages/Flow/shared/Explainer";

export function SocialSecurityTip({onSubmit}: {onSubmit: () => void}) {
	return (
		<>
			<Explainer
				title="Before we finish this step, one more tip: save your re-entry number!"
				descriptionLines={[
					`When you’ve finished signing up through the Social Security website, you’ll be given a reference number. Keep it somewhere safe – it saves you time if you have questions about your application.`,
					`Remember, <b>Parts A & B cover at most 80%</b> of your medical expenses. The next step will help you <b>cover that missing 20%.</b>`,
				]}
				imgSrc="/images/illustrations/computer-code.svg"
			/>
			<div className="mt-5 mb-10 text-lg">
				Let’s talk about additional coverage, and why most Americans have it.
			</div>
			<Button onClick={onSubmit}>Continue</Button>
		</>
	);
}

import React from "react";
import {Formik, Form} from "formik";
import * as Yup from "yup";
import {FormField} from "../shared/FormField";
import {useUser} from "common/hooks/useUser";

import firstTimer from "./images/first-timer.svg";
import firstTimerSelected from "./images/first-timer-selected.svg";
import firstTimerHover from "./images/first-timer-hover.svg";

import switcher from "./images/switcher.svg";
import switcherSelected from "./images/switcher-selected.svg";
import switcherHover from "./images/switcher-hover.svg";

type IHowMayWeHelp = "new" | "switcher" | "unsure";

const howMayWeHelp = {
	name: "howMayWeHelp",
	label: "",
	type: "iconButton",
	options: [
		{
			label: "Choose Medicare coverage for the first time",
			value: "new",
			icon: firstTimer,
			iconSelected: firstTimerSelected,
			iconHover: firstTimerHover,
		},
		{
			label: "Improve my current Medicare coverage",
			value: "switcher",
			icon: switcher,
			iconSelected: switcherSelected,
			iconHover: switcherHover,
		},
		/*{
			label: "Help me decide if I should switch to Medicare",
			value: "unsure",
			icon: unsure,
			iconSelected: unsureSelected,
			iconHover: unsureHover,
		},*/
	],
};

const seekingCoverageForField = {
	name: "seekingCoverageFor",
	label: "Who are you looking for coverage for?",
	type: "toggleButton",
	options: [
		{
			label: "Just me",
			value: "me",
		},
		{
			label: "Me and my spouse/partner",
			value: "meAndSpouse",
		},
		{
			label: "A relative or a loved one",
			value: "other",
		},
	],
};

export function FlowTriage({
	onSubmit,
}: {
	onSubmit: (path: IHowMayWeHelp) => void;
}) {
	const {updateUser} = useUser();

	return (
		<Formik
			initialValues={{
				howMayWeHelp: "",
				seekingCoverageFor: "",
			}}
			validationSchema={Yup.object().shape({
				howMayWeHelp: Yup.string().required(),
			})}
			onSubmit={(values) => {
				if (!values.seekingCoverageFor) {
					return;
				}
				onSubmit(values.howMayWeHelp as any);
			}}
		>
			{({values, submitForm}) => (
				<Form className="w-full">
					<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
						{/** Page Header */}
						How may we help you today?
					</div>
					<div className="flex max-w-lg mx-auto my-10 mb-0 text-sm">
						<FormField
							fieldData={howMayWeHelp}
							nextStep={() => {
								setTimeout(() => {
									submitForm();
								}, 100);
							}}
							autoAdvance={false}
						/>
					</div>

					{values.howMayWeHelp && (
						<div className="bg-gray-100 my-7 rounded-lg px-5 py-4">
							{values.howMayWeHelp === "new" && (
								<>
									We’ll guide you through your options and help you enroll in
									your first plan{" "}
								</>
							)}
							{values.howMayWeHelp === "switcher" && (
								<>
									We’ll help you compare other plan options to find you savings.
								</>
							)}
							{values.howMayWeHelp === "unsure" && (
								<>
									We’ll help you decide whether to switch to Medicare from
									employer insurance, Obamacare, or COBRA.
								</>
							)}
						</div>
					)}

					<div className="mt-3" />
					{values.howMayWeHelp && (
						<div className="mx-20 relative flex flex-col text-center  px-5 ">
							<FormField
								fieldData={seekingCoverageForField}
								nextStep={(seekingCoverageFor) => {
									updateUser({seekingCoverageFor});
									setTimeout(() => {
										submitForm();
									}, 300);
								}}
								autoAdvance={true}
							/>
						</div>
					)}
				</Form>
			)}
		</Formik>
	);
}

import React from "react";
import {MdError} from "react-icons/md";
import {Collapse} from "common/ui/Collapse";

export function Alert({
	title,
	descriptionLines,
	collapse,
}: {
	title: string;
	descriptionLines: string[];
	collapse?: boolean;
}) {
	return (
		<div className="flex items-start py-2 px-8 text-left mb-5">
			<MdError className="text-teal-700 flex-shrink-0 w-14 text-2xl" />
			<div>
				<div className="font-bold mb-1">
					IMPORTANT: &nbsp;
					{title}
				</div>
				<div className="font-light leading-relaxed">
					{collapse && descriptionLines?.length > 2 ? (
						<div className="flex flex-col w-full">
							{descriptionLines.slice(0, 1)?.map((line) => (
								<p key={line}>{line}</p>
							))}
							<Collapse
								triggerClassName="ml-auto mr-10"
								triggerContent="Read More"
							>
								<>
									{descriptionLines.slice(1)?.map((line) => (
										<p key={line}>{line}</p>
									))}
								</>
							</Collapse>
						</div>
					) : (
						descriptionLines?.map((line) => <p key={line}>{line}</p>)
					)}
				</div>
			</div>
		</div>
	);
}

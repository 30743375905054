import React, {useState} from "react";
import InputText, {InputTextPhone, InputTextEmail} from "common/ui/InputText";
import Icon from "common/ui/Icon";
import {CALCULATOR_LEGAL_DISCLAIMER} from "./data/header-constants";

interface IData {
	phoneNumber?: string;
	email?: string;
	name?: string;
}

export function EmailAndPhone({
	onComplete,
	onClose,
}: {
	onComplete: (data: IData) => void;
	onClose: () => void;
}) {
	const [isPhoneValid, setIsPhoneValid] = useState(false);
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [isNameValid, setIsNameValid] = useState(false);

	const [hasClickedSubmit, setHasClickedSubmit] = useState(false);

	const [data, setData] = useState<IData>();
	const [showName] = useState(false);

	return (
		<div className="flex flex-col space-y-3 pb-5">
			<div className="flex items-center mt-3">
				<button className="flex items-center mr-4 absolute" onClick={onClose}>
					<Icon
						src="leftChevron"
						height={20}
						width={20}
						alt={"Go Back"}
						color="#BDBDBD"
					/>
				</button>
				<div className="w-full text-2xl font-semibold text-accent text-center">
					We're calculating your results...
				</div>
			</div>

			<div className="font-light pb-5 text-center w-full">
				Please enter your information to see your results.
			</div>
			<div className="mb-4 w-full">
				<div className="flex flex-col md:space-x-4 md:flex-row items-center text-sm mx-auto max-w-sm md:max-w-xl md:px-4">
					<div className="md:w-1/2 md:px-0 w-full">
						{showName ? (
							<>
								<InputText
									onChange={(e) => {
										const name = e.target.value;
										setIsNameValid(name.length > 0);
										setData({...data, name});
									}}
									placeholder="Name"
								/>
								<div className="h-5">
									{hasClickedSubmit && !isNameValid && (
										<div className="text-red-400">Required</div>
									)}
								</div>
							</>
						) : (
							<>
								<InputTextPhone
									onChange={(e) => {
										const phoneNumber = e.target.value;
										const phoneMatchPattern = phoneNumber
											.replace(/\D/g, "")
											.match(/^\d{10}$/);
										setIsPhoneValid(!!phoneMatchPattern);
										setData({...data, phoneNumber});
									}}
								/>
								<div className="h-5">
									{hasClickedSubmit && !isPhoneValid && (
										<div className="text-red-400">Required</div>
									)}
								</div>
							</>
						)}
					</div>

					<div className="md:w-1/2 md:px-0 w-full">
						{showName ? (
							<>
								<InputTextPhone
									onChange={(e) => {
										const phoneNumber = e.target.value;
										const phoneMatchPattern = phoneNumber
											.replace(/\D/g, "")
											.match(/^\d{10}$/);
										setIsPhoneValid(!!phoneMatchPattern);
										setData({...data, phoneNumber});
									}}
								/>
								<div className="h-5">
									{hasClickedSubmit && !isPhoneValid && (
										<div className="text-red-400">Required</div>
									)}
								</div>
							</>
						) : (
							<>
								<InputTextEmail
									onChange={(e) => {
										const email = e.target.value;
										const emailMatchPattern = email.match(/.*@.*\..*/);
										setIsEmailValid(!!emailMatchPattern);
										setData({...data, email});
									}}
								/>
								<div className="h-5">
									{hasClickedSubmit && !isEmailValid && (
										<div className="text-red-400">Required</div>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<div className="text-sm text-gray-400 pb-5">
				{CALCULATOR_LEGAL_DISCLAIMER}
			</div>
			<div className="text-center">
				<button
					className="bg-orange font-bold hover:bg-red-500 transition-colors rounded-full text-white px-20 py-4 text-lg disabled:bg-gray-300"
					onClick={() => {
						setHasClickedSubmit(true);
						if (isPhoneValid && (isEmailValid || isNameValid)) {
							onComplete(data!);
						}
					}}
				>
					See my Medicare Premiums
				</button>
			</div>
		</div>
	);
}

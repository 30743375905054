import {IVideoTimeline} from "common/ui/Video";

export const HOW_TO_SIGN_UP_FOR_MEDICARE_TIMELINE: IVideoTimeline[] = [
	{
		title: "Signing up online",
		startTime: 0,
		durationText: "65s",
		descriptionLines: [
			`Now that you know about Medicare Parts A and B, let’s talk about how to sign up for Medicare.`,
			`If you’re not yet receiving Social Security benefits, signing up is not automatic.`,
			`You can sign up for Medicare benefits online and avoid waiting on hold to speak to Social Security.`,
			`If you are signing up for Part A... or Part A and Part B at the same time, you can click on the link below this video to sign up through the Social Security website`,
			`If you have already signed up for Part A, you can sign up for only Part B.`,
			`There is a separate Social Security website to sign up for Part B if you already have Part A. You can find it here https://secure.ssa.gov/mpboa/medicare-part-b-online-application/. `,
			`If you choose to sign up for part B online, here are a few helpful tips:`,
			`Make sure to use Google Chrome or Microsoft Edge as your browser, as the government only supports these browsers.`,
		],
	},
	{
		title: "For those retiring (SEP)",
		startTime: 66,
		durationText: "27s",
		descriptionLines: [
			`If you are retiring and leaving employer-based health coverage, you will be applying for Part B as part of a Special Enrollment Period.`,
			`You will need to choose your target start date, which must be the first day of a month. Ideally, you will want to choose a date that does not leave you with any coverage gaps following the end of your employer-provided insurance coverage.`,
			`If you have questions on when your employer-provided coverage ends, you can usually get answers from your employer’s Human Resources department.`,
		],
	},
	{
		title: "Confirm your signature",
		startTime: 138,
		durationText: "1m",
		descriptionLines: [
			`Then, there’s Part B. Part B helps cover medical services, for example when you go to the doctor’s office.`,
			`Unlike Part A, Part B premiums are not pre-paid.`,
			`When you choose to enroll in Part B, you must start paying premiums to Uncle Sam. `,
			`You should know that you can start Part A and Part B at different times.`,
			`When you turn 65, whether you should sign up for Part A and Part B will depend on a number of factors which I'll help you walk through in another video.`,
			`Together, Part A and Part B cover about 80% of hospital and medical services, but not the full costs.`,
		],
	},
];

import React from "react";

export const magGlass = ({color, width, height}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 36 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="16" cy="16" r="13.5" stroke={color} strokeWidth="5" />
		<path
			d="M22 29l7.333 16.292"
			stroke={color}
			strokeWidth="5"
			strokeLinecap="round"
		/>
	</svg>
);

import React from "react";
import styled from "styled-components";

const IconBackdropStyled = styled.div`
	display: flex;
	justify-content: center;
	width: ${({$width}) => $width}px;
	height: ${({$width}) => $width}px;
	border-radius: 50%;
	overflow: hidden;
	background-color: ${({$color}) => $color};
	cursor: ${({onClick}) => (onClick ? "pointer" : "inherit")};
	transition: 0.5s;
`;

export default function IconBackdrop({
	width,
	color,
	children,
	className,
	onClick,
}) {
	return (
		<IconBackdropStyled
			$width={width}
			$color={color}
			children={children}
			className={className}
			onClick={onClick}
		/>
	);
}

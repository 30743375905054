import React from "react";
import {
	CALCULATOR_DESCRIPTION,
	CALCULATOR_FACTORS,
	CALCULATOR_TITLE,
} from "./data/header-constants";
import {Collapse} from "common/ui/Collapse";

export function Header() {
	return (
		<div className="flex flex-col max-w-4xl mx-auto space-y-7">
			<h1 className="text-center font-extrabold text-gray-900 text-3xl md:text-4xl mt-10">
				{CALCULATOR_TITLE}
			</h1>

			<div className="max-w-3xl mx-auto text-lg text-center leading-relaxed font-light text-accent">
				{CALCULATOR_DESCRIPTION}
			</div>

			<div className="font-semibold border-b border-green-300 mx-auto my-5">
				Here are the factors to consider:
			</div>

			<div className="grid md:grid-cols-2 gap-3">
				{CALCULATOR_FACTORS.map((factor) => (
					<div
						className="flex flex-col items-center space-y-3 px-3 md:px-0"
						key={factor.title}
					>
						<img
							src={`/images/icons/${factor.icon}.svg`}
							alt={factor.title}
							height={64}
							width={64}
						/>
						<div className="font-semibold">{factor.title}</div>
						{factor.descriptionLines.map((line) => (
							<div key={line} className="text-sm text-gray-600">
								{line}
							</div>
						))}
						{factor.extendedDescriptionLines && (
							<>
								<Collapse
									triggerClassName="text-blue-500 text-sm"
									triggerContent="Read More"
								>
									<div className="space-y-3">
										{factor.extendedDescriptionLines.map((line) => (
											<div key={line} className="text-sm text-gray-600">
												{line}
											</div>
										))}
									</div>
								</Collapse>
							</>
						)}
					</div>
				))}
			</div>
		</div>
	);
}

import React, {FormEvent} from "react";
import {IconButton, IIcon} from "./IconButton";
import {IOption} from "./ToggleButton";

interface IIconButtonGroupProps {
	options: IIcon[];
	value: IOption["value"];
	onChange: (
		e: FormEvent<HTMLButtonElement>,
		newValue: IOption["value"] | null
	) => void;
	name: string;
}

export function IconButtonGroup({
	options,
	value,
	onChange,
	...props
}: IIconButtonGroupProps) {
	const handleExclusiveChange = (
		event: FormEvent<HTMLButtonElement>,
		buttonValue: IOption["value"] | null
	) => {
		if (!onChange) {
			return;
		}
		onChange(
			{
				...event,
				target: {...event.target, value: buttonValue, name: props.name},
			} as any,
			value === buttonValue ? null : buttonValue
		);
	};

	return options.map((o) => (
		<IconButton
			key={o.value}
			option={o}
			selected={value === o.value}
			onChange={(e: FormEvent<HTMLButtonElement>) =>
				handleExclusiveChange(e, o.value)
			}
			{...props}
		/>
	));
}

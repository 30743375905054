import {IField} from "common/ui/Form/FormRow";
import {FormRow} from "../PartBCalculator/FormRow";
import {IFormData} from "pages/PartBCalculator/data/form-data";
import React, {useEffect, useState} from "react";
import {SOCIAL_SECURITY_QUESTIONS} from "./data/form";
import {FormMedsupAlert} from "./alerts/Medsup";

export function SocialSecurityQuestions({
	initialData,
	values,
	isResults,
}: {
	values: IFormData;
	initialData?: IFormData;
	isResults?: boolean;
}) {
	const field = SOCIAL_SECURITY_QUESTIONS;
	const [additionalFields, setAdditionalFields] = useState<IField[]>([]);
	useEffect(() => {
		const additional = field.additionalFields(
			values.isReceivingSocialSecurityBenefits!,
			values.hasExistingMedicareFromDisability
		);
		setAdditionalFields(additional);
	}, [field, values]);

	const render = () => {
		return (
			<div>
				<div className="items-center py-2 grid grid-cols-3 md:flex">
					{field.fields.map((f) => (
						<FormRow fieldData={{...f}} key={f.name} />
					))}
				</div>
				<div className="ml-20 border-l px-5 border-green-300 space-y-4">
					{additionalFields.map((f) => (
						<FormRow fieldData={{...f}} key={f.name} hideError={true} />
					))}
				</div>
				{!initialData && <FormMedsupAlert values={values} />}
			</div>
		);
	};

	return <FormRow fieldData={{...field, render}} isResults={isResults} />;
}

import React from "react";

export const x = ({color, height, width, onClick, className}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 17 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		onClick={onClick}
		className={className}
	>
		<path
			d="M2.13 14.774L15.78 1.226M1.712 1.61L15.202 15"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

import {PureComponent} from "react";
import styled from "styled-components";
import Icon from "common/ui/Icon";
import Dialog from "common/ui/Dialog";
import Scheduler from "modals/Scheduler";
import STYLES from "common/util/constants/styles";

const Wrapper = styled.div`
	background-color: #fff0ef;
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	svg {
		margin-left: 10px;
	}

	@media (max-width: 639px) {
		font-size: 12px;
	}

	@media (min-width: 640px) {
		font-size: 15px;
	}
`;

const XBold = styled.span`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
`;

export default class HeaderSkip extends PureComponent {
	clickBanner = () => {
		Dialog.show({
			children: <Scheduler showLegalText />,
			width: Scheduler.width,
			height: Scheduler.height,
			bgColor: "white",
		});
	};

	render() {
		const {isMobile} = this.props;

		return (
			<Wrapper onClick={this.clickBanner}>
				{isMobile ? (
					<>
						<XBold>Schedule time</XBold> - speak with an expert
					</>
				) : (
					<>
						<XBold>Skip the questions</XBold> - schedule time with a licensed
						expert
						<Icon src="doubleArrow" width={18} color="#FFA59F" />
					</>
				)}
			</Wrapper>
		);
	}
}

import React from "react";
import Tooltip from "common/ui/Tooltip";
import {IResultPlan} from "./results";

/**
 *
 * Darsh Mar 2021 - TODO: Some of this text content should be moved to a constants file!
 */
export function SinglePlanSummary({plan}: {plan: IResultPlan}) {
	const {price, name, secondaryText} = plan;
	return (
		<div className="space-y-2">
			<div className="mt-4 flex items-center justify-center">
				<span className="px-1 flex items-start text-3xl tracking-tight text-gray-900 sm:text-3xl">
					<span className="font-extrabold">{price || "--"}</span>
				</span>
				<span className="font-medium text-gray-500">/mo</span>
			</div>
			<div className="text-center text-gray-800 text-sm font-semibold sm:-mx-6">
				<div>{name}</div>
				<div className="text-gray-500">{secondaryText}</div>
			</div>
		</div>
	);
}

export function BasicMedicare({plan}: {plan: IResultPlan}) {
	return (
		<div>
			<div className="font-bold text-sm text-accent mb-5">
				Original Medicare
			</div>
			<div className="text-xl font-light my-2">
				<span className="text-underline-accent">
					<span className="font-bold">{plan.price}</span>/mo
				</span>
				&nbsp;Part B Premiums
			</div>
			<div className="font-light text-accent text-sm">{plan.secondaryText}</div>
		</div>
	);
}

export function SupplementalPlan({plan}: {plan: IResultPlan}) {
	return (
		<>
			<div className="text-xl font-light my-2 px-3 py-2">
				<span className="text-underline-accent">
					<span className="font-bold">{plan.price}</span>/mo
				</span>
				&nbsp;
			</div>
			<div className="font-light text-accent text-lg">{plan.name}</div>
			{plan.recommended && (
				<Tooltip
					content={
						<div className="text-base">
							Based on your answers, you may prefer a {plan.name} plan. You can
							always contact us to discuss the details.
						</div>
					}
				>
					<span className="inline-flex items-center px-2 mt-3 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 cursor-pointer">
						Recommended
					</span>
				</Tooltip>
			)}
		</>
	);
}

export function SupplementalPlans({plans}: {plans: IResultPlan[]}) {
	return (
		<div>
			<div className="font-bold text-sm text-accent mb-5">
				Additional Coverage
			</div>
			<div className="relative">
				<div className="absolute right-1/2 -mr-3 -mt-5 top-1/2 bg-white p-1">
					or
				</div>
				<div className="flex divide-x divide-gray-200">
					{plans.map((p) => (
						<div className="w-1/2 px-2" key={p.name}>
							<SupplementalPlan plan={p} key={p.name} />
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

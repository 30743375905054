import {RefObject} from "react";

/**
 * Returns whether the component is currently being server side rendered or hydrated on the client.
 */
export const isSSR: boolean = !(
	typeof window !== "undefined" && window.document?.createElement
);

/**
 * Will return element from ref. If it isn't wrapped in a ref it will return itself.
 */
export const getRefElement = <T>(
	element?: RefObject<Element> | T
): Element | T | undefined | null => {
	if (element && "current" in element) {
		return element.current;
	}
	return element;
};

import React from "react";
import clsx from "clsx";

import MuiToggleButton, {
	ToggleButtonProps,
} from "@material-ui/lab/ToggleButton";
import {MdCheckCircle} from "react-icons/md";

export interface IOption {
	value: string;
	label: string;
}

interface IToggleButtonProps extends ToggleButtonProps {
	name: string;
	className?: string;

	selected?: boolean;
	option: IOption;
}

export function ToggleButton({
	className,
	option,
	selected,
	...props
}: IToggleButtonProps) {
	return (
		<MuiToggleButton
			color={"secondary"}
			className={clsx(className)}
			key={option.value}
			classes={{
				label: "font-unset text-sm font-unset normal-case w-80 ",
				root:
					"mx-auto rounded-full inline-flex items-center px-8 py-4 shadow-sm text-black " +
					className,
				selected: "bg-teal-800 text-white",
			}}
			{...props}
			type="button"
			value={option.value}
			selected={selected}
		>
			<div className="flex items-center justify-center w-full">
				<div className="mx-auto">{option.label}</div>

				{selected && (
					<div className="right-0 w-0 text-xl">
						<MdCheckCircle className="mr-2" />
					</div>
				)}
			</div>
		</MuiToggleButton>
	);
}

import React from "react";

import {Header} from "./Header";
import {Form} from "./Form";
import {LegalFooter} from "core/Footer/LegalFooter";

import {useHistory} from "react-router";
import {partBCalculatorService} from "./service";
import {Helmet} from "react-helmet";
import {
	SITE_DESCRIPTION,
	SITE_IMAGE_URL,
	SITE_TITLE,
	SITE_URL,
} from "./data/header-constants";

const RESULTS_ROUTE = "/medicare-premium-calculator/results";

export function PartBCalculatorNoPhone() {
	const history = useHistory();

	function onSubmitPressed() {
		partBCalculatorService.saveCustomerData();
		history.push(RESULTS_ROUTE);
	}

	return (
		<div className="container mx-auto px-7 lg:px-20 font-normal	text-base">
			<Helmet>
				<title>Medicare Premium Calculator</title>
				<meta property="og:url" content={SITE_URL} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={SITE_TITLE} />
				<meta property="og:description" content={SITE_DESCRIPTION} />
				<meta property="og:image" content={SITE_IMAGE_URL} />
			</Helmet>
			<Header />
			<div className="flex pt-16 pb-0 max-w-5xl mx-auto">
				<Form onSubmit={onSubmitPressed} />
			</div>
			<div className="flex py-6 max-w-5xl mx-auto">
				<LegalFooter />
			</div>
		</div>
	);
}

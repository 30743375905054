import React, {ReactChild, ReactChildren} from "react";
import clsx from "clsx";
import Popper, {PopperPlacementType} from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";

export function Popup({
	children,
	isOpen,
	anchorEl,
	placement = "top",
}: {
	children: ReactChild | ReactChildren;
	isOpen: boolean;
	anchorEl: null | HTMLElement;
	placement?: PopperPlacementType;
}) {
	return (
		<Popper
			open={isOpen}
			anchorEl={anchorEl}
			transition
			placement={placement}
			className="z-50 max-w-sm"
		>
			{({TransitionProps}) => (
				<Fade {...TransitionProps} timeout={350}>
					<div className={clsx(placement === "top" && "mb-2")}>{children}</div>
				</Fade>
			)}
		</Popper>
	);
}

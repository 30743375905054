import React, {useEffect} from "react";
import {IFormData} from "../data/form-data";
import Alert from "common/ui/Alert";
import Dialog from "common/ui/Dialog";

import ExpertButton from "../../QuestionFlow/views/Summary/Results/Common/ExpertButton";
import {
	CHAPTER_PHONE_NUMBER_FORMATTED,
	MEDICARE_ENROLLMENT_PENALTY_SITE,
} from "common/util/common";

export function FormAlertEmployeesCount({values}: {values: IFormData}) {
	const {hasMoreThanTwentyEmployees} = values;

	useEffect(() => {
		if (hasMoreThanTwentyEmployees === "no") {
			Dialog.show({
				children: <AlertModal />,
				width: 550,
				bgColor: "white",
			} as any);
		}
	}, [hasMoreThanTwentyEmployees]);
	return <></>;
}

function AlertModal() {
	return (
		<div className="flex h-full px-7 overflow-auto max-h-full font-normal">
			<Alert type={"info"}>
				<div className="flex flex-col h-full py-6 px-4">
					<div className="text-xl mb-5 font-medium">
						Medicare Small Employer Exception
					</div>
					<div className="flex flex-col h-full">
						<div className="text-base text-black space-y-3">
							<p>
								Many people are not aware that Medicare is considered primary
								coverage for employees at companies with &lt; 20 employees.
							</p>
							<p>
								As such, you may be subject to{" "}
								<a
									className="text-underline-accent"
									href={MEDICARE_ENROLLMENT_PENALTY_SITE}
									target="_blank"
									rel="noreferrer"
								>
									life-time penalties
								</a>{" "}
								if you sign up for Medicare later than your Initial Enrollment
								Period.
							</p>
							<p>
								Our licensed advocates can help answer questions about your
								particular situation, if you would like. Call us at{" "}
								{CHAPTER_PHONE_NUMBER_FORMATTED} to make an appointment for a
								free, no obligation consult.
							</p>
						</div>
						<div className="mt-auto pt-5 text-center">
							<ExpertButton className="mx-auto" />
						</div>
					</div>
				</div>
			</Alert>
		</div>
	);
}

import {Component} from "react";
import styled from "styled-components";
import Button from "common/ui/Button";
import Dialog from "common/ui/Dialog";
import {CHAPTER_PHONE_NUMBER_RAW, legalExpertText} from "common/util/common";
import {formatPhone} from "common/util/format";
import FreshChat from "common/util/FreshChat";
import STYLES from "common/util/constants/styles";
import {LargeButtonContent, LegalText} from "../Results/v3/V3Helpers";
import Scheduler from "modals/Scheduler";

const GovernmentRulesStyled = styled.div`
	text-align: center;

	h3 {
		margin: 0 0 20px 0;
		line-height: 29px;
	}
`;

const TopSection = styled.div`
	font-size: 16px;
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};

	@media (min-width: 640px) {
		padding: 50px 80px 0 80px;
	}

	@media (max-width: 639px) {
		padding: 50px 20px 0 20px;
	}
`;

const ButtonGroup = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 auto 20px auto;

	@media (min-width: 640px) {
		width: 400px;
	}

	@media (max-width: 639px) {
		flex-direction: column;
		height: 140px;
	}
`;

const ButtonWrapper = styled(Button)`
	display: block;
	border: none;
	box-shadow: 0px 0px 5px rgba(0, 31, 103, 0.25);
	font-size: 12px;

	@media (min-width: 640px) {
		width: 200px;
		height: 125px;
	}
`;

const Footer = styled.div`
	background-color: #eefdff;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;

	@media (min-width: 640px) {
		height: 150px;
	}

	@media (max-width: 639px) {
		height: 400px;
		flex-direction: column;
		align-items: center;
	}
`;

const WhiteButton = styled(Button)`
	font-size: 16px;
`;

const FooterText = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};

	@media (max-width: 639px) {
		max-width: 266px;
	}

	@media (min-width: 640px) {
		width: 275px;
	}
`;

const LoneText = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.BOLD};
	font-size: 20px;
	margin: 100px auto;
	max-width: 430px;
`;

export default class GovernmentRules extends Component {
	state = {clickedButton: false};

	openFresh() {
		FreshChat.open({
			name: "SummaryPage",
			replyText: "I'd like to learn more about my plan options",
		});
	}

	openCalendly() {
		Dialog.show({
			children: <Scheduler />,
			width: Scheduler.width,
			height: Scheduler.height,
			bgColor: "white",
		});
	}

	render() {
		const {clickedButton} = this.state;
		const {onClickSchedulerButton} = this.props;

		const onClick = onClickSchedulerButton || this.openCalendly;

		return (
			<GovernmentRulesStyled>
				<TopSection>
					{!clickedButton ? (
						<>
							<h3>
								As an independent, licensed advisor, we are required to speak
								with each person to share the details of their options.
							</h3>
							<div>
								This is to ensure that you can ask questions and fully
								understand your Medicare options.
								<br />
								<br />
								You have no obligation to choose coverage or enroll with us.
							</div>
							<br />
							<br />
							<h3>Would you like to speak with us?</h3>
							<ButtonGroup>
								<Button onClick={onClick} text="Yes" colorScheme="red" />
								<WhiteButton
									onClick={() => this.setState({clickedButton: true})}
									text="Not yet"
									colorScheme="white"
								/>
							</ButtonGroup>
						</>
					) : (
						<>
							<LoneText>
								We understand – we’ll save your progress for you if you change
								your mind.
							</LoneText>
							<ButtonGroup>
								<Button
									onClick={() => (document.location = "https://getchapter.com")}
									text="Back to Home"
									colorScheme="red"
								/>
								<WhiteButton
									onClick={onClick}
									text={
										<>
											I'll speak with
											<br />
											an advisor
										</>
									}
									colorScheme="white"
								/>
							</ButtonGroup>
						</>
					)}
					<LegalText>{legalExpertText}</LegalText>
				</TopSection>
				<Footer>
					<FooterText>
						You can also reach an expert over the phone or online.
					</FooterText>
					<ButtonWrapper
						text=<LargeButtonContent
							text={`Call or text: ${formatPhone(CHAPTER_PHONE_NUMBER_RAW)}`}
							icon="phone"
						/>
						onClick={() => (document.location = `tel:+1${CHAPTER_PHONE_NUMBER_RAW}`)}
						type="large"
					/>
					<ButtonWrapper
						text=<LargeButtonContent
							text="Chat with us online"
							icon="text-blurb"
						/>
						onClick={this.openFresh}
						type="large"
					/>
				</Footer>
			</GovernmentRulesStyled>
		);
	}
}

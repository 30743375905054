import React, {useState} from "react";
import * as yup from "yup";
import {Checkbox} from "common/ui/Checkbox/Checkbox";
import Dialog from "common/ui/Dialog";
import {InputTextMonthYear} from "common/ui/InputText";
import {apiService} from "common/util/api/service";
import {parse, format} from "date-fns";

export function DeadlineReminders() {
	const [isChecked, setIsChecked] = useState(false);

	function showModal() {
		Dialog.show({
			children: (
				<AlertModal
					onClose={() => {
						Dialog.hide();
					}}
				/>
			),
			width: 550,
			bgColor: "white",
		} as any);
	}

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setIsChecked(e.target.checked);
		if (e.target.checked) {
			showModal();
		}
	}

	return (
		<div className="text-center">
			<Checkbox
				checked={isChecked}
				onChange={handleChange}
				label="Send me reminders about my Part B sign-up deadline"
			/>
		</div>
	);
}

const schema = yup.object().shape({
	date: yup.date().min(new Date()).required(),
});

function AlertModal({onClose}: {onClose: () => void}) {
	const [monthAndYear, setMonthAndYear] = useState<string>();
	const [error, setError] = useState<string>();
	const [touched, setTouched] = useState<boolean>(false);

	function saveReminder() {
		if (!monthAndYear) {
			return;
		}

		apiService.updateCustomer({
			expectedRetirementDate: monthAndYear,
		});
		onClose();
	}

	async function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
		const dateString = e.target.value;
		// Validate date by assuming the first of the month
		// TODO Darsh: move this to a more re-usable place
		if (dateString.length === 7) {
			setTouched(true);
		}
		if (dateString.length !== 7) {
			return;
		}
		const dateParts = dateString.split("/");
		const date = parse(
			`${dateParts[1]}-${dateParts[0]}-01`,
			"yyyy-MM-dd",
			new Date()
		);
		const isValid = await schema.isValid({date});
		setError(isValid ? "" : "Please enter a future date");
		if (isValid) {
			setMonthAndYear(format(date, "yyyy-MM-dd"));
		} else {
			setMonthAndYear("");
		}
	}

	return (
		<div className="flex h-full px-7 overflow-auto max-h-full font-normal">
			<div className="flex flex-col h-full py-6 px-4 w-full text-center">
				<div className="text-center font-bold text-xl mb-5">
					When are you planning to retire?
				</div>
				<div className="flex flex-col h-full items-center">
					<div className="text-center text-base text-black space-y-3">
						<p>We'll send you reminders for your Medicare sign-up deadline.</p>
					</div>

					<div className="flex flex-col md:space-x-4 md:flex-row items-center text-sm my-7">
						<div className="">
							<InputTextMonthYear
								className="bg-white border-0 focus:border-blue-300 text-base"
								placeholder="MM/YYYY"
								onChange={handleOnChange}
								onBlur={() => {
									setTouched(true);
								}}
								error={touched && !!error}
								helperText={touched && error}
								hidePopup={true}
							/>
						</div>
					</div>
					<button
						className="bg-orange font-bold hover:bg-red-500 transition-colors rounded-full text-white px-16 py-4 text-lg disabled:bg-gray-300"
						onClick={() => {
							saveReminder();
						}}
					>
						Send me reminders
					</button>
				</div>
			</div>
		</div>
	);
}

import {IField} from "common/ui/Form/FormRow";
import {dependentCode, IEmployerData} from "../data/models";
import {IFormData} from "../service";

export const DEPENDENT_CODE_MAP: {[key: string]: dependentCode} = {
	"0": "EO",
	"1": "ES",
	"2": "EC",
	"3": "EF",
};

export const zipCountyField = {
	type: "zipCounty",
	name: "zip",
	label: "Zip Code",
	placeholder: "32601",
	required: true,
};

export const nameField = {
	name: "name",
	label: "Your Name",
	type: "input",
	placeholder: "Name",
	required: true,
};

export const emailField = {
	name: "email",
	label: "Email",
	type: "input",
	placeholder: "email@mail.com",
};

export const optMinimizeCosts = {
	name: "optMinimizeCosts",
	label:
		"If you joined the Medicare Made Simple with Cathy, do you know which of the following plan options you would prefer?",
	tooltip:
		"Medicare Supplement plans minimize future out-of-pocket costs but have more expensive premiums. These plans also give you maximum flexibility to see any doctor who accepts Medicare, which is the vast majority of doctors.",
	type: "radio",
	options: [
		{label: "I don't know", value: "maybe"},
		{label: "Medicare Supplement", value: "no"},
		{label: "Medicare Advantage", value: "yes"},
	],
};

const currentInsuranceDeductibleField = {
	name: "currentInsuranceDeductible",
	label: "What is the annual deductible (per person) for your current plan?",
	tooltip:
		"Most Medicare plans have much lower deductibles than employer-provided plans. So even if the Medicare premiums are higher, it might be worth considering Medicare to secure a much lower deductible. If you have dependents on your plan, divide your total annual deductible by the number of people (including yourself) to approximate the per-person deductible.",
	type: "inputNumber",
};

const numDependentsField = {
	name: "numDependents",
	type: "select",
	className: "flex-grow",
	label: "Who does your plan cover?",
	options: [
		{
			label: "Just me",
			value: "0",
		},
		{
			label: "My spouse and me",
			value: "1",
		},
		{
			label: "My child(ren) and me",
			value: "2",
		},
		{
			label: "My family and me",
			value: "3",
		},
	],
};

const maxOopInField = {
	name: "maxOopIn",
	label: "What is your annual max-out-of-pocket?",
	tooltip: "",
	type: "inputNumber",
};

const currentInsurancePremium = {
	type: "inputNumber",
	label: "What is your current monthly premium?",
	name: "currentInsurancePremium",
};

export function calculateAdditionalQuestions(
	employerData: IEmployerData,
	{currentEmployerPlan}: IFormData
) {
	const additionalQuestions: IField[] = [numDependentsField];

	additionalQuestions.push(
		currentInsurancePremium,

		currentInsuranceDeductibleField,
		maxOopInField
	);

	if (!currentEmployerPlan) {
		return additionalQuestions;
	}

	if (currentEmployerPlan === "hsa-plan") {
		additionalQuestions.push({
			name: "hsaDiscount",
			label: "What is your HSA contribution per month?",
			tooltip:
				"LifeSouth matches your HSA contribution dollar-for-dollar, up to $30 per pay period (up to $780 per year). Additional employer contributions are available by meeting outcomes-based goals or by completing activities associated with LifeWise. ",
			type: "inputNumber",
		});
	} else if (currentEmployerPlan === "co-pay-plan") {
		additionalQuestions.push({
			name: "premiumDiscount",
			label: "Do you receive any discounts to your premium?",
			tooltip:
				"Earn up to $10 off the premium by meeting outcomes-based goals or STARS points for completing activities associated with LifeWise.",
			type: "select",
			options: employerData?.premiumDiscountOptions,
		});
	}
	return additionalQuestions;
}

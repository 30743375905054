import React from "react";
import styled from "styled-components";
import Drawer from "common/ui/Drawer";
import IconList from "common/ui/IconList";
import {DrawerHeader} from "templates/drawer";
import STYLES from "common/util/constants/styles";

export const QuestionnaireStyled = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 1000px;
	margin: auto;

	@media (max-width: 639px) {
		padding: 0 ${STYLES.PADDING.BODY_HORIZONTAL};
	}

	form {
		margin-top: 60px;
	}
`;

export const PageTitle = styled.h4`
	font-weight: bold;
`;

export const QuestionWrapper = styled.div`
	line-height: 32px;
	max-width: 800px;
	margin: 20px 0 30px 0;
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
`;

export const ButtonRow = styled.div`
	@media (min-width: 640px) {
		display: flex;
		justify-content: center;

		button {
			margin-right: 50px;
		}

		button:last-of-type {
			margin: 0;
		}
	}

	@media (max-width: 639px) {
		button {
			margin: 20px auto;
		}
	}
`;

const DrawerTitle = styled.div`
	font-size: 31px;
	font-weight: bold;
	text-align: center;
	margin-top: 60px;
`;

const DrawerContent = styled.div`
	padding: 0 50px;
`;

const DrawerTopText = styled.div`
	line-height: 32px;
	margin: 40px 0;
`;

const BulletColumns = styled.div`
	margin: 60px 0;

	@media (min-width: 640px) {
		display: flex;
		justify-content: space-between;

		> div {
			max-width: 260px;
			flex: 1;
		}
	}

	@media (max-width: 639px) {
		> div {
			padding: 0 30px;
		}
	}
`;

const BulletColumnsTitle = styled.div`
	font-weight: bold;
	font-size: 25px;
	text-align: center;
	margin-bottom: 45px;
`;

const IconListWrapper = styled(IconList)`
	font-size: 16px;
`;

export const QuestionnaireDrawer = ({data}) => {
	return (
		<div>
			<DrawerHeader
				height={125}
				iconBackdropWidth={100}
				iconWidth={60}
				onClickBack={Drawer.hide}
				iconSrc={data.icon}
				iconColor={STYLES.COLORS.MIDNIGHT_BLUE}
			/>
			<DrawerContent>
				<DrawerTitle>{data.title}</DrawerTitle>
				{data.drawer.topText && (
					<DrawerTopText>{data.drawer.topText}</DrawerTopText>
				)}
				{data.drawer.medigap && (
					<BulletColumns>
						<div>
							<BulletColumnsTitle>Medigap</BulletColumnsTitle>
							<IconListWrapper items={data.drawer.medigap} />
						</div>
						<div>
							<BulletColumnsTitle>Medicare Advantage</BulletColumnsTitle>
							<IconListWrapper items={data.drawer.medAdv} />
						</div>
					</BulletColumns>
				)}
			</DrawerContent>
		</div>
	);
};

import React, {useState} from "react";
import {Form as FormikForm} from "formik";

import {FORM_FIELDS, IFormData} from "./data/form-data";
import clsx from "clsx";

import {
	FormRow,
	IncomeRange,
	OptionalCurrentPay,
	OptionalHigherPremiums,
} from "./FormRow";
import {FormAlertEmployeesCount} from "./alerts/Employer";
import {FormAlertAge} from "./alerts/Age";
import {FormAlertTaxes} from "./alerts/Taxes";

const SPACING_BETWEEN_ROWS = "mb-2";

export interface IFormCalculatorProps {
	values: IFormData;
	isValid: boolean;
	initialData?: IFormData;
	AdditionalQuestionsComponent?: React.FunctionComponent<any>;
}

export function FormCalculator({
	values,
	isValid,
	initialData,
	AdditionalQuestionsComponent,
}: IFormCalculatorProps) {
	const [hasPressedSubmit, setHasPressedSubmit] = useState(false);

	const [formFields] = useState([
		[
			{
				name: "name",
				label: "Your Name",
				type: "input",
				placeholder: "Name",
				required: true,
			},
		],
		...FORM_FIELDS,
	]);

	return (
		<FormikForm>
			<div className="flex flex-col">
				<div className="flex flex-col">
					<div className="flex w-full">
						<div className="w-full md:w-2/3">
							{formFields.slice(0, 2).map((row, i) => (
								<div
									className={clsx("flex space-x-3", SPACING_BETWEEN_ROWS)}
									key={i}
								>
									{row.map((field) => (
										<div className="flex-grow pr-2" key={field.name}>
											<FormRow
												isResults={!!initialData}
												fieldData={field}
												hasPressedSubmit={hasPressedSubmit}
											/>
										</div>
									))}
								</div>
							))}
						</div>
						<div className="ml-auto hidden md:block mt-6">
							<img
								src="/images/part-b/calculator.svg"
								alt="Calculate my Medicare Part B Savings!"
								height={400}
								width={250}
							/>
						</div>
					</div>
					<div className="w-full">
						{formFields.slice(2).map((row, i) => (
							<div
								className={clsx("flex space-x-3", SPACING_BETWEEN_ROWS)}
								key={i}
							>
								{row.map((field) => (
									<div className="flex-grow pr-2" key={field.name}>
										<FormRow
											isResults={!!initialData}
											fieldData={field}
											hasPressedSubmit={hasPressedSubmit}
										/>
									</div>
								))}
							</div>
						))}
						<IncomeRange
							optFileTaxes={values?.optFileTaxes}
							isResults={!!initialData}
							hasPressedSubmit={hasPressedSubmit}
						/>
						{AdditionalQuestionsComponent && (
							<AdditionalQuestionsComponent
								values={values}
								initialData={initialData}
							/>
						)}
					</div>
				</div>

				<div className="mt-8 mb-4 w-full h-px bg-gray-100"></div>
				<div className="font-light text-sm text-gray-600 text-underline-accent mr-auto my-5 pb-2 flex mb-8">
					Optional questions for more accurate results
				</div>

				<div className={clsx("flex space-x-3", SPACING_BETWEEN_ROWS)}>
					<OptionalHigherPremiums values={values} isResults={!!initialData} />
				</div>

				<div className={clsx("flex space-x-3", SPACING_BETWEEN_ROWS)}>
					<OptionalCurrentPay values={values} isResults={!!initialData} />
				</div>

				{hasPressedSubmit && !isValid && (
					<div className={clsx("text-red-400", SPACING_BETWEEN_ROWS)}>
						Please fill in all the values above!
					</div>
				)}
				<div className="text-center mt-5">
					<button
						className="bg-orange font-bold hover:bg-red-500 transition-colors rounded-full text-white px-20 py-4 text-lg disabled:bg-gray-300"
						type="submit"
						onClick={() => {
							setHasPressedSubmit(true);
						}}
					>
						{!!initialData ? "Resubmit" : "See Results"}
					</button>
				</div>
			</div>
			{!initialData && <FormAlertEmployeesCount values={values} />}
			{!initialData && <FormAlertAge values={values} />}
			{!initialData && <FormAlertTaxes values={values} />}
		</FormikForm>
	);
}

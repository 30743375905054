import styled, {css} from "styled-components";
import STYLES from "common/util/constants/styles";

export const TextAreaWrapper = styled.textarea`
	border: 1px solid #e0e0e0;
	box-shadow: 0px 0px 5px rgba(0, 31, 103, 0.25);
	border-radius: 6px;
	padding: 20px 25px;
	width: 100%;
	resize: none;
	font-size: 18px;

	&:focus {
		outline: none;
	}
`;

export const InputWrapper = styled.input`
	border: 2px solid
		${({hasError}) =>
			hasError ? STYLES.COLORS.WARNING_RED : STYLES.COLORS.SKY_BLUE};
	border-radius: 6px;
	padding: 13px 20px;
	width: 100%;

	${({$icon, iconPaddingLeft, iconPositionLeft}) =>
		$icon &&
		css`
			background-image: url('/images/icons/${$icon}.svg');
			background-repeat: no-repeat;
			background-position: ${iconPositionLeft}px center;
			padding-left: ${iconPaddingLeft}px;
		`}
`;

export const TextInputLabel = styled.div`
	font-size: 16px;
	margin-bottom: 11px;
`;

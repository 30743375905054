import React from "react";

export function List({rows}: {rows: any}) {
	return (
		<>
			{rows.map((row, i) => (
				<div
					key={row.value}
					className="px-5 py-2 pb-10 mb-1 flex space-x-20 text-left border-b border-gray-100"
				>
					<div className="flex-grow">
						<div className="text-xl font-medium text-gray-900">
							{i + 1}: {row.label}
						</div>
						<div className="prose ml-3">
							<ul>
								{row.descriptionLines.map((line) => (
									<li key={line} className="text-sm font-medium text-gray-600">
										{line}
									</li>
								))}
							</ul>
							{row.additionalDescription && (
								<div className="text-gray-500 text-xs">
									{row.additionalDescription}
								</div>
							)}
						</div>
					</div>

					<img
						src={row.imageUrl}
						alt=""
						className="w-48 max-h-32 object-contain h-full ml-auto"
					/>
				</div>
			))}
		</>
	);
}

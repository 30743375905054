import React from "react";

export const leftChevron = ({color, height, width, onClick}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 17 29"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		onClick={onClick}
	>
		<path
			d="M14.692 2L2 14.692l12.692 12.693"
			className="colorable"
			stroke={color}
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

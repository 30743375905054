import React from "react";
import {LEGAL_FOOTER_DISCLAIMER, LEGAL_FOOTER_FINAL_LINE} from "./constants";
const year = new Date().getFullYear();

export const LegalFooter = () => {
	return (
		<div className="flex flex-row flex-wrap max-w-screen-lg px-5">
			<div className="text-sm text-gray-900 font-medium md:mt-0 md:order-1 md:w-1/3">
				© {year} Chapter Medicare LLC
			</div>
			<div className="flex flex-col space-y-4 mt-4 md:mt-0 md:order-2 md:w-2/3">
				<p className="text-xs text-gray-900 font-normal leading-relaxed">
					{LEGAL_FOOTER_DISCLAIMER}
				</p>
				<p className="text-xs text-gray-900 font-normal leading-relaxed">
					{LEGAL_FOOTER_FINAL_LINE}
				</p>
			</div>
		</div>
	);
};

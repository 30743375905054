import {Button} from "common/ui/Button/Button";
import React, {useState} from "react";
import {List} from "../shared/List";
import {Reminders} from "../shared/Reminders";
import {useUser} from "common/hooks/useUser";
import {getStepsForMedicareStatus} from "./utils";

export default function Checklist({
	onSubmit,
}: {
	onSubmit: (values: any) => void;
}) {
	const {user} = useUser();

	const items = getStepsForMedicareStatus(user?.medicareSignUpStatus);

	return (
		<div>
			<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
				Here are the steps I recommend for your first plan:
			</div>
			<div className="font-bold text-base text-gray-600 mb-10 mt-3 leading-relaxed px-4"></div>

			<List rows={items || []} />

			<div className="my-10">
				<Button
					type="submit"
					onClick={() => {
						onSubmit(items);
					}}
				>
					Looks Good
				</Button>
				<div className="my-4 max-w-lg mx-auto">
					<SaveSteps />
				</div>
			</div>
		</div>
	);
}

function SaveSteps() {
	const [showEmail, setShowEmail] = useState(false);

	return (
		<>
			<div className="flex justify-center items-center space-x-4 my-4">
				<Button
					buttonType={"secondary"}
					type="button"
					onClick={(e) => {
						setShowEmail(true);
					}}
				>
					Save these steps
				</Button>
			</div>

			{showEmail && (
				<div className="mt-5 mb-10">
					<Reminders
						headerText={"We'll send you an email with your printable list:"}
						actionText="Send"
						onSubmit={() => {}}
					/>
				</div>
			)}
		</>
	);
}

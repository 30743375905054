import {formatPhone} from "common/util/format";
import React from "react";
import {IEmployerData} from "./data/models";

function BrokerCallout({employerData}: {employerData?: IEmployerData}) {
	return (
		<div className="flex items-center space-x-5">
			<div className="flex-shrink-0 hidden lg:block">
				<img
					className="w-56"
					src="/images/brokers/cathy-bg.png"
					alt="Cathy O'Brien, Director of Partnerships and Education, Chapter"
				/>
				<div className="mt-1 text-center">
					Cathy O' Brien
					<div className="text-sm text-gray-600">Director of Education</div>
				</div>
			</div>

			<div className="text-base flex-grow">
				We're honored to assist you in comparing your current{" "}
				{employerData && employerData.name} health insurance with your options
				that are available through Medicare.
				<br />
				<br />
				You may complete the information below or call us at{" "}
				<a
					className="text-underline-accent"
					href={`tel:${employerData?.phoneNumberRaw}`}
				>
					{formatPhone(employerData?.phoneNumberRaw || "")}
				</a>
				. We look forward to working to you!
				<br />
				<br />
				Our commitment to you: You can’t find a better resource for Medicare
				guidance anywhere else. Period.
			</div>
			<div className="flex-shrink-0 hidden lg:block">
				<img
					className="w-56"
					src="/images/brokers/ari-bg.png"
					alt="Ari Parker, Medicare Advisor, Chapter"
				/>
				<div className="mt-2 text-center">
					Ari Parker
					<div className="text-sm text-gray-600">
						Senior Licensed Medicare Advisor
					</div>
				</div>
			</div>
		</div>
	);
}

export function Header({employerData}: {employerData?: IEmployerData}) {
	return (
		<>
			<div className="my-5 space-y-5">
				<div className="text-4xl font-bold text-center leading-tight">
					{employerData?.nameLong || employerData?.name}
					<br />
					<span className="text-2xl">Medicare Comparison Calculator</span>
				</div>
				<div className="">
					<BrokerCallout employerData={employerData} />
				</div>
			</div>
			{/**<div className="text-xl max-w-2xl mx-auto font-bold">
				Find out if switching to Medicare from employer coverage is the right
				choice for you.
			</div>**/}
		</>
	);
}

import {QuestionFlow} from "./QuestionFlow";
import {PageList} from "./PageList";
import {BookingConfirmation} from "./BookingConfirmation";
import {RecomenndationsArtifact} from "./RecomenndationsArtifact";
import {PartBCalculator} from "./PartBCalculator";
import {PartBCalculatorNoPhone} from "./PartBCalculator/PartBCalculatorNoPhone";
import PartBResults from "./PartBResults";
import {SocialSecurityCalculator} from "./SocialSecurityCalculator";
import SocialSecurityCalculatorResults from "./SocialSecurityCalculatorResults";
import {MapdComparison} from "./MapdComparison";
import {EmployeeCalculatorBase} from "./Calculators/EmployeeCalculator";
import {Flow} from "./Flow";

const urlMappings = [
	{
		path: "/page-list",
		name: "PageList",
		component: PageList,
	},
	{
		path: "/confirmation",
		name: "BookingConfirmation",
		component: BookingConfirmation,
	},
	{
		path: "/recommendations/:token",
		name: "RecomenndationsArtifact",
		component: RecomenndationsArtifact,
	},
	{
		path: "/",
		name: "QuestionFlow",
		component: QuestionFlow,
	},
	{
		path: "/calculators/employees",
		name: "EmployeeCalculatorBase",
		isExact: false,
		component: EmployeeCalculatorBase,
	},
	{
		path: "/medicare-premium-calculator",
		name: "PartBCalculator",
		component: PartBCalculator,
	},
	// Version of Part B Calculator that doesn't show modal
	{
		path: "/premium-calculator",
		name: "PartBCalculatorNoPhone",
		component: PartBCalculatorNoPhone,
	},
	{
		path: "/medicare-premium-calculator/results",
		name: "PartBResults",
		component: PartBResults,
	},
	{
		path: "/social-security-and-medicare-calculator",
		name: "SocialSecurityCalculator",
		component: SocialSecurityCalculator,
	},
	{
		path: "/social-security-and-medicare-calculator/results",
		name: "SocialSecurityResults",
		component: SocialSecurityCalculatorResults,
	},
	{
		path: "/mapd/compare",
		name: "MapdComparison",
		component: MapdComparison,
	},
	{
		path: "/flow",
		name: "Flow",
		component: Flow,
		isExact: false,
	},
	{
		path: "*",
		name: "QuestionFlow",
		component: QuestionFlow,
	},
];

export {urlMappings};

import React, {Component} from "react";
import {throttle} from "throttle-debounce";
import HeaderSkip from "core/Headers/HeaderSkip";
import TabBar from "core/TabBar";
import Footer from "core/Footer";
import {LegalFooter} from "core/Footer/LegalFooter";
import Button from "common/ui/Button";
import Waves from "common/ui/Waves";
import Dialog from "common/ui/Dialog";
import Timeline from "common/ui/Timeline";
import RecentSavings from "common/custom/RecentSavings";
import NoPlans from "modals/NoPlans";
import ABTester from "common/util/abTester";
import STYLES from "common/util/constants/styles";
import axios from "common/util/api/axios";
import {urlParse} from "common/util/urlParse";
import sectionPageArray from "pages/QuestionFlow/pageStructure";
import eventTracker from "common/util/eventTracker";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import * as QuestionViews from "./views";
import {
	NavigationButtonsTop,
	QuestionFlowWrapper,
	NavigationButtonsBottom,
	QuestionFlowAndNavigation,
	TabBarContentWrapper,
} from "./QuestionFlowHelpers";

export class QuestionFlow extends Component {
	constructor(props) {
		super(props);

		let sectionPageIndeces = [0, 0];
		const queryParams = urlParse(document.location);
		if (queryParams.skip) {
			sectionPageIndeces = queryParams.skip.split(",").map((a) => parseInt(a));
		}

		this.state = {
			sectionPageIndeces,
			bottomNextButtonText: "Continue",
			bottomNextButtonColorScheme: null,
			width: document.body.clientWidth,
		};

		this.userPath = [...sectionPageArray];

		// Store the promises of any active network requests
		// Great for requests that need to happen syncronously
		this.activeRequestPromises = [];

		this.clearHelpers();
		this.sectionPageHistory = [];
	}

	componentDidMount() {
		window.addEventListener("resize", this.onResize);

		this.addEmailAndPhoneSection();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}

	componentDidUpdate(prevProps, prevState) {
		const {hasError} = this.state;

		// First time error was discovered
		if (!prevState.hasError && hasError) {
			eventTracker.log({
				name: eventTracker.EVENTS.ERROR,
				data: {name: "RenderError"},
			});
		}
	}

	addEmailAndPhoneSection = async () => {
		const expId = process.env.REACT_APP_EXPERIMENT_CONTACT_PAGE_ORDER;
		const abTesterInstance = new ABTester(expId);
		const variants = await abTesterInstance.getVariants();

		// Variant 0 ––> PhoneAndEmail appears near beginning
		// Variant 1 ––> PhoneAndEmail appears near end

		const insertIndex = variants[0] === 0 ? 1 : this.userPath.length - 1;

		this.insertSection({pages: [{name: "EmailAndPhone"}]}, insertIndex);
	};

	onResize = throttle(500, () => {
		this.setState({width: document.body.clientWidth});
	});

	clearHelpers = () => {
		this.onAttemptAdvance = null;
		this.persistence = {
			url: "",
			data: null,
			onResponse: null,
		};
	};

	getPage = ([sectionIndex, pageIndex]) => {
		return this.userPath[sectionIndex].pages[pageIndex];
	};

	setPersistence = ({url, data, onResponse}) => {
		const customerId = LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID);
		if (customerId) data.customerId = customerId;

		this.persistence = {url, data, onResponse};
	};

	setBottomNextButtonText = (bottomNextButtonText) => {
		this.setState({bottomNextButtonText});
	};

	setBottomNextButtonColorScheme = (bottomNextButtonColorScheme) => {
		this.setState({bottomNextButtonColorScheme});
	};

	setOnAttemptAdvance = (onAttemptAdvance) => {
		this.onAttemptAdvance = onAttemptAdvance;
	};

	insertSection = (section, index) => {
		this.userPath.splice(index, 0, section);
	};

	sendDataToServer = () => {
		const {onResponse, url, data} = this.persistence;

		if (url) {
			const postPromise = axios.post(url, data);
			this.activeRequestPromises.push(postPromise);
			postPromise
				.then((response) => {
					if (onResponse) onResponse(response.data);
				})
				.catch(console.error);
		}
	};

	advancePage = () => {
		const {sectionPageIndeces} = this.state;

		let nextSectionPage = [sectionPageIndeces[0], sectionPageIndeces[1] + 1];

		// Jump to next section if at the end of a section
		if (!this.getPage(nextSectionPage)) {
			nextSectionPage[0]++;
			nextSectionPage[1] = 0;
		}

		this.skipToSectionPage(nextSectionPage);
	};

	skipSection = () => {
		const {sectionPageIndeces} = this.state;

		this.skipToSectionPage([sectionPageIndeces[0] + 1, 0]);
	};

	skipToSectionPage = (nextSectionPage) => {
		const {sectionPageIndeces} = this.state;

		if (this.onAttemptAdvance && !this.onAttemptAdvance()) return;

		this.sectionPageHistory.push(sectionPageIndeces);

		this.sendDataToServer();
		this.clearHelpers();

		this.setState({
			sectionPageIndeces: nextSectionPage,
			bottomNextButtonText: "Next",
			bottomNextButtonColorScheme: null,
		});
		window.scrollTo(0, 0);

		const page = this.userPath[nextSectionPage[0]].pages[nextSectionPage[1]];
		eventTracker.log({
			name: eventTracker.EVENTS.PAGE_VIEW,
			data: {pageName: page.name},
		});
	};

	regressNavigation = () => {
		const newState = {
			sectionPageIndeces: this.sectionPageHistory.pop(),
			bottomNextButtonText: "Next",
		};
		this.setState(newState);

		this.clearHelpers();

		// Remove added questionnaire sections if user regressed to factors page
		if (this.getPage(newState.sectionPageIndeces).name === "Factors")
			this.userPath = this.userPath.filter((s) => s.type !== "questionnaire");

		window.scrollTo(0, 0);
	};

	static getDerivedStateFromError(error) {
		return {hasError: true};
	}

	render() {
		const {
			sectionPageIndeces,
			bottomNextButtonText,
			bottomNextButtonColorScheme,
			hasError,
			width,
		} = this.state;

		if (hasError) {
			Dialog.show({
				children: <NoPlans />,
				width: 800,
				canOutsideClickClose: false,
			});

			return null;
		}

		const CurrentComponentClass =
			QuestionViews[this.getPage(sectionPageIndeces).name];

		const {containerColor, hasWaves, config, hideFooter, showRecentSavings} =
			CurrentComponentClass;

		const section = this.userPath[sectionPageIndeces[0]];
		const page = section.pages[sectionPageIndeces[1]];

		const backButtonProps = {
			text: "Back",
			onClick: this.regressNavigation,
			type: "minimal",
			icon: "leftChevron",
			iconHeight: 30,
			iconWidth: 30,
			iconColor: STYLES.COLORS.SKY_BLUE,
			visible: !!this.sectionPageHistory.length,
		};

		const lastSectionIndex = this.userPath.length - 1;
		let timelineSection = 1;
		if (sectionPageIndeces[0] === 0) timelineSection = 0;
		else if (sectionPageIndeces[0] === lastSectionIndex) timelineSection = 2;

		const isMobile = width < 640;

		return (
			<>
				{!page.hideHeader && <HeaderSkip isMobile={isMobile} />}
				<TabBarContentWrapper>
					{section.showTabs && (
						<TabBar
							skipToComponent={this.skipToSectionPage}
							sectionPageIndeces={sectionPageIndeces}
						/>
					)}
					<QuestionFlowAndNavigation
						containerColor={containerColor || "white"}
						hasWaves={hasWaves}
					>
						<NavigationButtonsTop>
							{!isMobile && <Button {...backButtonProps} />}
							<Timeline section={timelineSection} />
							{isMobile && <Button {...backButtonProps} />}
							{!isMobile && (
								<Button
									text="Next"
									onClick={this.advancePage}
									type="minimal"
									icon="rightChevron"
									iconHeight={30}
									iconWidth={30}
									iconColor={STYLES.COLORS.SKY_BLUE}
									iconPosition="right"
									visible={false}
								/>
							)}
						</NavigationButtonsTop>

						<QuestionFlowWrapper>
							<CurrentComponentClass
								setOnAttemptAdvance={this.setOnAttemptAdvance}
								setPersistence={this.setPersistence}
								advancePage={this.advancePage}
								setBottomNextButtonText={this.setBottomNextButtonText}
								setBottomNextButtonColorScheme={
									this.setBottomNextButtonColorScheme
								}
								skipSection={this.skipSection}
								insertSection={this.insertSection}
								userPath={this.userPath}
								sectionPageIndeces={sectionPageIndeces}
								isMobile={isMobile}
								activeRequestPromises={this.activeRequestPromises}
								{...config}
							/>
						</QuestionFlowWrapper>
						{bottomNextButtonText && (
							<NavigationButtonsBottom>
								<Button
									text={bottomNextButtonText}
									onClick={this.advancePage}
									colorScheme={bottomNextButtonColorScheme}
								/>
							</NavigationButtonsBottom>
						)}
						{showRecentSavings && <RecentSavings isMobile={isMobile} />}
					</QuestionFlowAndNavigation>
				</TabBarContentWrapper>
				{hasWaves && <Waves />}
				{!hasWaves && !hideFooter && <Footer />}
				<div className="flex py-8 max-w-5xl mx-auto">
					<LegalFooter />
				</div>
			</>
		);
	}
}

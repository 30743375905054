import React, {Component} from "react";
import lottie from "lottie-web";
import Button from "common/ui/Button";
import Dialog from "common/ui/Dialog";
import NoPlans from "modals/NoPlans";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import axios from "common/util/api/axios";
import endpoints from "common/util/api/endpoints";
import {cycle} from "common/util/common";
import eventTracker from "common/util/eventTracker";
import {Wrapper, AnimationParent} from "./SummaryLoadingHelpers";

export class SummaryLoading extends Component {
	static bodyColor = "white";
	static showRecentSavings = true;

	constructor(props) {
		super(props);

		props.setBottomNextButtonText(null);

		this.icons = ["diamond", "provider", "formulary", "pig", "house"];
		this.state = {iconCounter: 0, doneLoading: false};

		this.name = "";
		this.numPlans = 0;
		this.animationParent = React.createRef();
	}

	componentDidMount() {
		const {activeRequestPromises} = this.props;

		this.animationId = window.setInterval(() => {
			const {iconCounter} = this.state;

			const newCounter = cycle(iconCounter, this.icons.length * 3, 1);

			if (newCounter === 0) {
				window.clearInterval(this.animationId);
				this.setState({doneLoading: true});
			}

			this.setState({
				iconCounter: newCounter,
			});
		}, 500);

		this.name = LocalStorage.getItem(STORAGE_KEYS.FIRST_NAME);
		this.customerId = LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID);

		lottie.loadAnimation({
			container: this.animationParent.current,
			renderer: "svg",
			loop: true,
			autoplay: true,
			path: "/animations/summary-loading/loading_animation.json",
		});

		// Make sure we make this request after the last save request
		if (activeRequestPromises.length) {
			activeRequestPromises[activeRequestPromises.length - 1].then(
				this.executeNetworkRequest
			);
		}
		// This actually should never be hit (because there will always be earlier network requets)
		else {
			this.executeNetworkRequest();
		}
	}

	componentWillUnmount() {
		window.clearInterval(this.animationId);
	}

	executeNetworkRequest = () => {
		axios
			.get(endpoints.summaryV3({customerId: this.customerId}))
			.then((response) => {
				LocalStorage.setItem(STORAGE_KEYS.PAGES.SUMMARY_V3.PLAN_COUNTS, response.data);

				if (response.data.error) {
					return this.catchError();
				}
			})
			.catch(this.catchError);
	};

	catchError = () => {
		window.clearInterval(this.animationId);

		eventTracker.log({
			name: eventTracker.EVENTS.ERROR,
			data: {name: "NoPlans"},
		});

		Dialog.show({
			children: <NoPlans />,
			width: 800,
			canOutsideClickClose: false,
		});
	};

	render() {
		const {doneLoading} = this.state;
		const {advancePage} = this.props;

		return (
			<Wrapper>
				{doneLoading ? (
					<>
						<h2>
							{this.name ? `${this.name}: w` : "W"}e’ve asked our experts to
							short-list a set of options just for you.
						</h2>
						<br />
						<Button onClick={advancePage} text="Next" />
						<br />
					</>
				) : (
					<>
						<h2>Finding plans in your area</h2>
						<AnimationParent ref={this.animationParent} />
					</>
				)}
			</Wrapper>
		);
	}
}

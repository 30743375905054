import React, {useEffect} from "react";
import {IFormData} from "../data/form-data";
import Alert from "common/ui/Alert";
import Dialog from "common/ui/Dialog";

import ExpertButton from "../../QuestionFlow/views/Summary/Results/Common/ExpertButton";
import {MEDICARE_PART_A_COSTS_SITE} from "common/util/common";

export function FormAlertTaxes({values}: {values: IFormData}) {
	const {hasWorkedTenYears} = values;

	useEffect(() => {
		if (hasWorkedTenYears === "no") {
			Dialog.show({
				children: <AlertModal />,
				width: 550,
				bgColor: "white",
			} as any);
		}
	}, [hasWorkedTenYears]);
	return <></>;
}

function AlertModal() {
	return (
		<div className="flex h-full px-7 overflow-auto max-h-full font-normal">
			<Alert type={"info"}>
				<div className="flex flex-col h-full py-6 px-4">
					<div className="text-xl mb-5 font-medium">Medicare Payroll Taxes</div>
					<div className="flex flex-col h-full">
						<div className="text-base text-black space-y-3">
							<p>
								If you haven’t paid Medicare payroll taxes for at least 10
								years, you may need to pay a premium for Part A, which is
								hospital coverage, if you choose to enroll.
							</p>
							<p>
								People who buy Part A will pay a premium of either{" "}
								<a
									className="underline"
									href={MEDICARE_PART_A_COSTS_SITE}
									target="_blank"
									rel="noreferrer"
								>
									$259 or $471 each month in 2021{" "}
								</a>
								 depending on how long they or their spouse worked and paid
								Medicare taxes.
							</p>
						</div>
						<div className="mt-auto pt-5 text-center">
							<ExpertButton className="mx-auto" />
						</div>
					</div>
				</div>
			</Alert>
		</div>
	);
}

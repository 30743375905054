import React, {Component} from "react";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import {
	Wrapper,
	Title,
	Subtext,
	FactorsConfirmationCheckboxStyled,
	BottomText,
	checkboxOptions,
} from "./FactorsHelpers";

export class FactorsConfirmation extends Component {
	static showRecentSavings = true;

	constructor(props) {
		super(props);

		const selected = LocalStorage.getItem(STORAGE_KEYS.PAGES.FACTORS) || [];
		this.state = {selected};
		this.options = checkboxOptions.filter((o) => selected.includes(o.value));
	}

	render() {
		const {selected} = this.state;

		return (
			<Wrapper>
				<Title>Fantastic, thank you. </Title>
				<Subtext>Let’s use these priorities to find options for you.</Subtext>
				<FactorsConfirmationCheckboxStyled
					selected={selected}
					options={this.options}
					onChange={() => {}}
					type="inline"
					large
				/>
				<BottomText>Ready to answer a couple of questions?</BottomText>
			</Wrapper>
		);
	}
}

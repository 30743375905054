import React from "react";

export const provider = ({color, height, width, className}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 62 72"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path
			d="M43.304 45.525c-2.139 0-2.139 2.673 0 2.673v10.158c-3.208.535-5.881 3.742-5.881 6.95v1.604c0 1.604 2.673 1.604 2.673 0v-1.604c0-2.139 1.604-4.277 4.277-4.277 2.138 0 4.277 1.604 4.277 4.277v1.604c0 2.138 2.673 2.138 2.673 0v-1.604c0-3.208-2.673-6.415-5.88-6.95v-9.623c7.484 1.07 12.83 7.484 12.83 15.504v5.88c0 .535.535 1.604 1.604 1.604.534 0 1.604-.534 1.604-1.604v-5.88c.534-10.693-8.02-18.712-18.177-18.712zM13.9 42.318V19.864c0-9.623 7.485-17.108 17.108-17.108 9.623 0 17.108 7.485 17.108 17.108v21.919c0 2.138 2.673 2.138 2.673 0v-21.92C50.789 9.172 41.7.084 31.008.084c-10.692 0-19.78 9.088-19.78 19.78v21.92c0 2.138 2.672 2.138 2.672.535zM18.177 59.96V48.198c2.138 0 2.138-2.673 0-2.673C8.019 45.525 0 53.545 0 63.702v5.88c0 .535.535 1.605 1.604 1.605.534 0 1.604-.535 1.604-1.604v-5.881c0-7.485 5.346-13.9 12.83-15.504v11.227c-2.673 1.07-3.742 2.673-3.742 4.277 0 2.139 2.139 4.277 4.277 4.277 2.139 0 4.277-2.138 4.277-4.277 0-1.604-1.07-3.208-2.673-3.742zm-1.604 5.346c-.535 0-1.604-.535-1.604-1.604 0-.535.535-1.604 1.604-1.604.535 0 1.604.535 1.604 1.604 0 1.07-.535 1.604-1.604 1.604z"
			fill={color}
		/>
		<path
			d="M39.562 47.129v-7.485c2.673-2.138 4.277-5.88 4.277-9.623v-8.553c0-.535-.535-1.604-1.604-1.604h-2.673c-3.208 0-4.277-3.208-4.277-3.208 0-.535-.535-1.07-1.604-1.07-.535 0-1.07.535-1.07 1.07 0 0-1.068 3.208-4.276 3.208h-9.089c-.534 0-1.603.534-1.603 1.604v8.553c0 3.743 1.603 6.95 4.276 9.623v7.485c0 .535 0 .535.535 1.07l6.95 10.157c.535.535 1.604.535 2.139 0l7.484-10.692.535-.535zm-2.673-.535l-6.416 8.02-5.346-8.02v-5.346c1.604 1.07 3.742 1.604 5.88 1.604 2.14 0 3.743-.534 5.882-1.604v5.346zm-5.881-6.95c-5.346 0-10.158-4.276-10.158-10.157V23.07h6.95c2.673 0 4.812-1.604 5.881-2.673 1.07 1.604 3.208 2.673 5.88 2.673h1.605v6.95c-.535 5.346-4.812 9.623-10.158 9.623z"
			fill={color}
		/>
	</svg>
);

import React from "react";
import styled from "styled-components";
import {Position} from "@blueprintjs/core";
import Icon from "common/ui/Icon";
import Tooltip from "common/ui/Tooltip";
import IconBackdrop from "common/ui/Icon/IconBackdrop";
import STYLES from "common/util/constants/styles";
import {isMobile} from "common/util/common";

export const Wrapper = styled.div`
	text-align: center;
`;

export const Header = styled.div`
	font-size: 42px;
	font-weight: bold;
	margin-bottom: 100px;
`;

export const CircleGroup = styled.div`
	display: flex;
	justify-content: space-between;

	@media (min-width: 640px) {
		align-items: flex-start;
		flex-direction: column;
		margin-top: 50px;
		height: 500px;
	}

	@media (max-width: 639px) {
		height: 400px;
	}
`;

const TooltipTitle = styled.div`
	font-size: 25px;
	font-weight: bold;
	border-bottom: 3px solid ${STYLES.COLORS.DARK_MINT};
	margin-bottom: 15px;
	width: fit-content;
`;

const Smol = styled.span`
	font-size: 16px;
`;

export const Circle = ({
	isActive,
	index,
	onSelect,
	src,
	description,
	title,
}) => {
	const content = (
		<>
			<TooltipTitle children={title} />
			<Smol>{description}</Smol>
		</>
	);
	return (
		<Tooltip
			content={content}
			position={isMobile ? Position.BOTTOM : Position.RIGHT}
			isOpen={isActive}
			type="large"
		>
			<IconBackdrop
				color={isActive ? STYLES.COLORS.MIDNIGHT_BLUE : STYLES.COLORS.DARK_MINT}
				width={62}
				onClick={() => onSelect(index)}
			>
				<Icon
					src={src}
					width={40}
					color={isActive ? "white" : STYLES.COLORS.MIDNIGHT_BLUE}
				/>
			</IconBackdrop>
		</Tooltip>
	);
};

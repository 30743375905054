/*
QuestionnaireWrapper
	This component, extended by the various questionnaire classes, houses all the brains and styling of the
	 questionnaire pages. This somewhat unique solution was chosen due to the structure of QuestionFlow, the parent
	 component to the entire app.
*/

/**
 * config, passed via constructor has the following form.
 *
 * @param {Array} options The key/value pairs of radio buttons. Of form {value: 'SERVER_KEY', label: 'Readable Label'}.
 * @param {String} nextComponent The component to be rendered when user hits "next" button
 * @param {String} localStorageKey The localStorage key to save the selected button value
 * @param {String} questionKey The key that uniquely identifies this question on server
 * @param {String} icon The name of the SVG file located in `/public/images/icons`. E.g. `scales.svg`
 * @param {Number} index The question number to be displayed in the page header
 * @param {String} title The page and drawer header
 * @param {Node} description Helpful text giving context to the user
 * @see Location.js, Provider.js, etc.
 */

import React, {Component} from "react";
import styled from "styled-components";
import Chips from "common/ui/Chips";
import Button from "common/ui/Button";
import Icon from "common/ui/Icon";
import InlineTip from "common/ui/InlineTip";
import IconBackdrop from "common/ui/Icon/IconBackdrop";
import endpoints from "common/util/api/endpoints";
import STYLES from "common/util/constants/styles";
import {LocalStorage} from "common/util/storage";
import {
	QuestionnaireStyled,
	QuestionWrapper,
	ButtonRow,
} from "./QuestionnaireHelpers";

const ChipsStyled = styled(Chips)`
	max-width: 600px;
`;

export default class QuestionnaireWrapper extends Component {
	state = {options: []};
	config = {};

	setConfig(config) {
		const {setOnAttemptAdvance} = this.props;

		const newState = {
			icon: config.icon,
			title: config.title,
			type: config.type,
			options: config.options,
			description: config.description,
		};

		const existingData = LocalStorage.getItem(config.localStorageKey);
		this.config = config;

		if (config.type === "chips") {
			newState.selected = existingData || [];
		} else {
			newState.selected = existingData === null ? "" : existingData.toString();
		}

		this.setState(newState);

		setOnAttemptAdvance(this.onAttemptAdvance);
	}

	setPersistence = () => {
		const {setPersistence} = this.props;
		const {selected, options} = this.state;

		// Use Array.isArray because the state could be either a string or an array
		// The state is an array if the input element is a multi-select (e.g. chips)
		// The state is a string if the input element in a single-select (e.g. radio buttons)
		const data = {};
		if (Array.isArray(selected)) {
			data.additionalBenefits = [];

			selected.forEach((s) => {
				const option = options.find((o) => o.value === s);
				if (option.field === "additionalBenefits") {
					data.additionalBenefits.push(option.value);
				} else {
					data[s] = true;
				}
			});

			if (data.additionalBenefits.length)
				data.additionalBenefits = data.additionalBenefits.join(",");
			else {
				delete data.additionalBenefits;
			}
		} else {
			data[this.config.questionKey] = selected;
		}

		if (selected !== undefined) {
			setPersistence({
				url: endpoints.questionSave(),
				data,
			});
		}
	};

	componentDidUpdate(prevProps, prevState) {
		const {selected} = this.state;
		this.setPersistence();
		LocalStorage.setItem(this.config.localStorageKey, selected);
	}

	onSelectButton = (selected) => {
		const {advancePage} = this.props;

		this.setState({selected}, () => setTimeout(advancePage, 200));
	};

	onAttemptAdvance = () => {
		const {selected} = this.state;

		const canAdvance = !(this.config.isRequired && !selected);
		if (!canAdvance) this.setState({hasError: true});
		return canAdvance;
	};

	onToggleValue = (value) => {
		const {selected} = this.state;

		let newSelected;

		if (selected.includes(value)) {
			newSelected = selected.filter((s) => s !== value);
		} else {
			newSelected = selected.concat(value);
			if (newSelected.length > 3) newSelected.shift();
		}

		this.setState({selected: newSelected});
	};

	render() {
		const {selected, icon, type, options, description, hasError} = this.state;

		return (
			<QuestionnaireStyled>
				<IconBackdrop color={STYLES.COLORS.SALMON} width={70}>
					{icon && (
						<Icon src={icon} color={STYLES.COLORS.MIDNIGHT_BLUE} width={50} />
					)}
				</IconBackdrop>
				<QuestionWrapper children={description} />
				{type === "chips" ? (
					<ChipsStyled
						selected={selected}
						options={options}
						onChange={this.onToggleValue}
					/>
				) : (
					<ButtonRow
						children={options.map((o) => (
							<Button
								text={o.label}
								active={selected === o.value}
								onClick={() => this.onSelectButton(o.value)}
								type="large"
								key={o.value}
							/>
						))}
					/>
				)}
				<InlineTip
					visible={hasError}
					children={"Please select an option"}
					color={STYLES.COLORS.WARNING_RED}
					width={225}
				/>
			</QuestionnaireStyled>
		);
	}
}

import React, {useState} from "react";
import YouTube from "react-youtube";

import {Button} from "../Button/Button";
import {Collapse} from "../Collapse";
import {MdPlayArrow} from "react-icons/md";

export interface IVideoTimeline {
	title: string;
	startTime: number; // time in seconds
	durationText: string;
	descriptionLines: string[];
}

export function Video({
	videoId,
	className,
	timeline,
}: {
	videoId: string;
	className?: string;
	timeline?: IVideoTimeline[];
}) {
	const opts = {
		width: "100%",
		playerVars: {
			modestbranding: 1,
			rel: 0,
		},
	} as any;

	const [videoEl, setVideoEl] = useState<any>(undefined);

	function onReady(event) {
		// access to player in all event handlers via event.target
		setVideoEl(event.target);
	}

	function goToTime(time?) {
		videoEl?.seekTo(time);
	}

	return (
		<>
			<YouTube
				videoId={videoId}
				className={""}
				containerClassName={className}
				opts={opts}
				onReady={onReady}
			/>
			{timeline &&
				timeline.map((section) => (
					<Collapse
						key={section.title}
						triggerClassName="w-full border-gray-200 border-b py-4 px-4 mt-4"
						triggerContent={
							<div className="w-full flex items-center font-bold">
								{section.title}{" "}
								<div className="ml-auto">{section.durationText}</div>
								<Button
									className={"text-xl text-gray-600"}
									onClick={(e) => {
										// Prevent collapse from triggering
										e.stopPropagation();
										goToTime(section.startTime);
									}}
								>
									<MdPlayArrow />
								</Button>
							</div>
						}
					>
						<div className="space-y-3 text-left text-gray-600 px-4 leading-relaxed prose mt-5">
							{section.descriptionLines.map((line) => (
								<p className="my-2" key={line}>
									{line}
								</p>
							))}
						</div>
					</Collapse>
				))}
		</>
	);
}

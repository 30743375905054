import {IChecklistItem} from "common/ui/Checkbox/Checklist";

export const MEDICARE_INTRO: IChecklistItem = {
	value: "medicareIntro",
	label: "Medicare 101",
	descriptionLines: [
		"Watch short video about Parts A & B",
		"Get your enrollment window dates",
		"Learn how to sign up for Medicare",
	],
	additionalDescription: "About 4 mins",
	imageUrl: "/images/illustrations/video-call.svg",
	imageExpandable: false,
};

export const MEDICARE_INTRO_PART_B: IChecklistItem = {
	value: "medicareIntro",
	label: "Medicare 101",
	descriptionLines: [
		"Answer any questions about Parts A & B",
		"Find your enrollment window dates",
		"Learn how to sign up for Part B",
	],
	additionalDescription: "About 15 mins",
	imageUrl: "/images/illustrations/video-call.svg",
	imageExpandable: false,
};

export const PLAN_RECOMMENDATIONS: IChecklistItem = {
	value: "planRecommendations",
	label: "Plan recommendations",
	descriptionLines: [
		"Learn about additional coverage",
		"Search all plans for the right fit",
		"Compare plans that fit your needs",
	],
	additionalDescription: "About 6 mins",
	imageUrl: "/images/illustrations/filling-out-form.svg",
	imageExpandable: false,
};

export const CONSULT: IChecklistItem = {
	value: "consult",
	label: "Peace-of-mind consult with me",
	descriptionLines: [
		"Confirm the plan choice that’s right for you",
		"Receive expert support to complete and track your application",
	],
	additionalDescription: "About 6 mins",
	imageUrl: "/images/illustrations/device-add.svg",
	imageExpandable: false,
};

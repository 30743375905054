interface LocationFromServer {
	uuid: string;
	address: string;
	latitude: number;
	longitude: number;
	confidence: number;
	selected?: boolean;
	address_details: {
		zip: string;
		city: string;
		state: string;
		street: string;
	};
}

interface ProviderCommon {
	npi: string;
	locations: LocationFromServer[];
}

interface ProviderFromServer extends ProviderCommon {
	first_name: string;
	middle_name: string;
	last_name: string;
	specialtyList: string[];
}

export interface Provider extends ProviderCommon {
	key: string;
	firstName: string;
	middleName: string;
	lastName: string;
	specialties: string[];
	timesVisited?: string;
}

interface ProviderToSaveEndpoint extends Provider {
	locationUuid: string;
	address: string;
	latitude: number;
	longitude: number;
}

export interface SelectedProviderState {
	showTip?: boolean;
	selectedProviders: Provider[];
}

export const serverProviderToClient = (inputProvider: ProviderFromServer) => {
	const outputProvider: Provider = {
		npi: inputProvider.npi,
		key: inputProvider.npi,
		firstName: inputProvider.first_name,
		middleName: inputProvider.middle_name,
		lastName: inputProvider.last_name,
		specialties: inputProvider.specialtyList,
		locations: inputProvider.locations,
	};

	return outputProvider;
};

export const clientProviderToServer = (inputProvider: Provider) => {
	const selectedLocation = inputProvider.locations.find((l) => l.selected);

	if (selectedLocation) {
		const outputProvider: ProviderToSaveEndpoint = {
			...inputProvider,
			locationUuid: selectedLocation.uuid,
			address: selectedLocation.address,
			latitude: selectedLocation.latitude,
			longitude: selectedLocation.longitude,
		};

		return outputProvider;
	}
};

export const generateStateFromChange = ({
	provider,
	selectedAddressUuid,
	timesVisited,
	selectedProviders,
}: {
	provider: Provider;
	selectedAddressUuid: string;
	timesVisited: string;
	selectedProviders: Provider[];
}): SelectedProviderState => {
	let providerToUpdate = selectedProviders.find((p) => p.npi === provider.npi);
	const newState = {} as SelectedProviderState;

	const isNewProvider = !providerToUpdate;
	if (isNewProvider) providerToUpdate = provider;

	if (providerToUpdate) {
		// Set selected=true for appropriate location
		providerToUpdate.locations.forEach((l) => {
			l.selected = l.uuid === selectedAddressUuid;
		});

		providerToUpdate.timesVisited = timesVisited;

		if (isNewProvider) {
			newState.selectedProviders = [providerToUpdate].concat(selectedProviders);
			if (newState.selectedProviders.length === 1) newState.showTip = true;
		} else {
			newState.selectedProviders = selectedProviders;
		}
	}

	return newState;
};

// Remove "Dr." (and all variants) from search
export const cleanUserInput = (s: string) =>
	s.toLowerCase().replace(/^dr /, "").replace(/^dr\./, "").trim();

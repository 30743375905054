import React from "react";
import Icon from "common/ui/Icon";
import STYLES from "common/util/constants/styles";
import {
	IconBackdropCentered,
	ConfirmationWrapper,
	BoldedText,
} from "./BookingConfirmationHelpers";

export default function ThankYou({firstName, brokerName}) {
	return (
		<ConfirmationWrapper>
			<h4>Thank you{firstName && ` ${firstName}`}!</h4>
			<IconBackdropCentered color={STYLES.COLORS.VIBRANT_MINT} width={75}>
				<Icon src="checkMark" width={40} />
			</IconBackdropCentered>
			<BoldedText>
				Your time with {brokerName} is confirmed.
				<br />
				We'll send you an email shortly.
			</BoldedText>
		</ConfirmationWrapper>
	);
}

import styled from "styled-components";
import TextInput from "common/ui/TextInput";
import STYLES from "common/util/constants/styles";

export const Container = styled.div`
	max-width: 600px;
	margin: auto;
	text-align: center;
	padding-bottom: 15px;

	@media (max-width: 639px) {
		padding: 0 ${STYLES.PADDING.BODY_HORIZONTAL};
	}

	input::placeholder {
		color: #b9d8f8;
	}
`;

export const Title = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
	font-size: 31px;
	margin-bottom: 20px;
`;

export const SubTitle = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 25px;
	margin-bottom: 41px;
`;

export const StyledInput = styled(TextInput)`
	font-weight: ${STYLES.FONT_WEIGHT.REGULAR};
	border-radius: 6px;
	font-size: 16px;

	input::placeholder {
		color: #828282;
	}

	@media (max-width: 639px) {
		width: 100%;
		:first-child {
			margin-bottom: 5px;
		}
	}
	@media (min-width: 640px) {
		width: 255px;
	}
`;

export const InputGroup = styled.div`
	display: flex;
	justify-content: space-evenly;

	@media (max-width: 639px) {
		flex-direction: column;
	}
`;

export const SmolLegalText = styled.div`
	margin-top: 20px;
	font-size: 12px;
`;

import React, {Component} from "react";
import * as FullStory from "@fullstory/browser";
import endpoints from "common/util/api/endpoints";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import eventTracker from "common/util/eventTracker";
import {formatPhone} from "common/util/format";
import {CHAPTER_PHONE_NUMBER_FORMATTED} from "common/util/common";
import {QuestionFlowChildProps} from "pages/QuestionFlow/QuestionFlowTypes";
import {
	InputGroup,
	StyledInput,
	Container,
	Title,
	SubTitle,
	SmolLegalText,
} from "./EmailAndPhoneStyled";

type EmailAndPhoneState = {
	email: string;
	phone: string;
	zipcode: string;
	customerId: string;
	isRequired: boolean;
	variant: number;
};

type DataToServer = {
	id: string;
	email?: string;
	phoneNumber?: string;
};

export class EmailAndPhone extends Component<
	QuestionFlowChildProps,
	EmailAndPhoneState
> {
	static showRecentSavings = true;

	constructor(props) {
		super(props);

		const basicInfo: any = LocalStorage.getItem(STORAGE_KEYS.PAGES.BASIC_INFO);
		const customerId = LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID);
		let phone = LocalStorage.getItem(STORAGE_KEYS.PHONE_NUMBER) || "";
		if (phone) phone = phone.toString();

		// Require input if this page is re-shown to user in a later section
		// This isn't best practice because it's implicit rather than explicit
		this.state = {
			email: basicInfo?.email || "",
			zipcode: basicInfo.zipcode,
			isRequired: props.sectionPageIndeces[0] !== 1,
			variant: 0,
			phone,
			customerId,
		};

		this.setParentPersistence();
		props.setOnAttemptAdvance(this.onAttemptAdvance);
		props.setBottomNextButtonText(
			this.generateBottomNextButtonText(
				this.state.email,
				this.state.phone,
				this.state.isRequired
			)
		);
	}

	componentDidUpdate(prevProps, prevState) {
		const {email, zipcode, isRequired, phone} = this.state;
		const {setBottomNextButtonText} = this.props;

		this.setParentPersistence();

		LocalStorage.setItem(STORAGE_KEYS.PAGES.BASIC_INFO, {
			email,
			zipcode,
		});

		LocalStorage.setItem(STORAGE_KEYS.PHONE_NUMBER, phone);

		if (email !== prevState.email || phone !== prevState.phone) {
			setBottomNextButtonText(
				this.generateBottomNextButtonText(email, phone, isRequired)
			);
		}
	}

	generateBottomNextButtonText(
		email: string,
		phone: string,
		isRequired: boolean
	): string {
		return isRequired || email || phone ? "Save & Continue" : "Skip";
	}

	onAttemptAdvance = () => {
		const {isRequired, email, phone} = this.state;

		return !(isRequired && !email && !phone);
	};

	setParentPersistence = () => {
		const {setPersistence} = this.props;
		const {email, customerId, phone} = this.state;

		const data: DataToServer = {id: customerId};
		if (email) data.email = email;
		if (phone) data.phoneNumber = phone;

		setPersistence({
			url: endpoints.customerSave(),
			data,
			onResponse: this.onResponse,
		});
	};

	onResponse = () => {
		const {email, phone, isRequired} = this.state;
		const {insertSection, userPath} = this.props;

		if (window.dataLayer && email) {
			// Send email to Google Tag Manager
			window.dataLayer.push({email});

			eventTracker.log({
				name: eventTracker.EVENTS.RECORD_EMAIL,
			});
		}

		// HIPAA compliance - email domain and area code aren't PII
		if (email) {
			FullStory.setUserVars({
				email: email.split("@")[1],
				phoneAreaCode: phone.substring(0, 3),
			});
		}

		// Append this page to user path if user didn't input email/phone
		// notion.so/chapter/Phone-Email-input-experiment-12-23-20-f2823afcbeb146d3849d5f619a38ec1b
		if (!isRequired && !phone) {
			insertSection({pages: [{name: "EmailAndPhone"}]}, userPath.length - 1);
		}
	};

	onKeyDownWrapper = (key) => {
		const {advancePage} = this.props;

		if (key === "Enter") advancePage();
	};

	onChangeEmail = (email) => this.setState({email: email.replaceAll(" ", "")});

	onChangePhone = (val, e) => {
		const parsedVal = val.replace(/\D/g, "").substring(0, 10);

		let loc = e.target.selectionStart;
		// Move cursor to account for the formatting
		if (parsedVal.length === 4) loc += 4;
		if (parsedVal.length === 7) loc += 1;

		this.setState({phone: parsedVal}, () =>
			e.target.setSelectionRange(loc, loc)
		);
	};

	isValidEmail = (email) => {
		const emailMatchPattern = email.match(/.*@.*\..*/);

		if (!emailMatchPattern)
			throw new Error("Please enter a valid email address to continue");

		return true;
	};

	isValidPhone = (phone) => {
		const phoneMatchPattern = phone.replace(/\D/g, "").match(/^\d{10}$/);

		if (!phoneMatchPattern)
			throw new Error("Please enter a valid phone number to continue");

		return true;
	};

	render() {
		const {email, phone, isRequired, variant} = this.state;

		const titleText = isRequired
			? "A preview of your short-list is ready for review with your dedicated expert on the next page."
			: "We can definitely help with that.";

		const variantHasEmail = variant === 0;

		return (
			<Container>
				<Title>{titleText}</Title>
				{!isRequired && <SubTitle>Save your information for later.</SubTitle>}
				<InputGroup>
					<StyledInput
						value={formatPhone(phone)}
						onChange={this.onChangePhone}
						type="tel"
						icon="cell-phone"
						iconPaddingLeft={55}
						iconPositionLeft={15}
						validator={isRequired ? this.isValidPhone : null}
						className="fs-exclude"
						placeholder={CHAPTER_PHONE_NUMBER_FORMATTED}
						onKeyDown={this.onKeyDownWrapper}
						maxLength="16"
					/>
					{variantHasEmail && (
						<StyledInput
							value={email}
							onChange={this.onChangeEmail}
							name="email"
							type="email"
							icon="email"
							iconPaddingLeft={65}
							iconPositionLeft={15}
							className="fs-exclude"
							placeholder="email@mail.com"
							onKeyDown={this.onKeyDownWrapper}
							autoFocus
						/>
					)}
				</InputGroup>
				<SmolLegalText>
					By giving your contact information, you grant permission for a
					licensed agent to contact you.
				</SmolLegalText>
			</Container>
		);
	}
}

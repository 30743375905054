import React, {Component} from "react";
import {urlParse} from "common/util/urlParse";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import STYLES from "common/util/constants/styles";
import {JourneyStartStandard, JourneyStartHear} from "./JourneyStartHelpers";

export class JourneyStart extends Component {
	static bodyColor = STYLES.COLORS.MIDNIGHT_BLUE;
	static containerColor = STYLES.COLORS.MIDNIGHT_BLUE;

	constructor(props) {
		super(props);

		const queryParams = urlParse(document.location);

		// Show different content for users from Hear.com
		this.BodyComponent = queryParams.hcom_z
			? JourneyStartHear
			: JourneyStartStandard;

		this.firstName = LocalStorage.getItem(STORAGE_KEYS.FIRST_NAME);

		props.setBottomNextButtonColorScheme("red");
		props.setBottomNextButtonText("Start");
	}
	render() {
		const {BodyComponent, firstName} = this;

		return <BodyComponent firstName={firstName} />;
	}
}

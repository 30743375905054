import React from "react";
import styled from "styled-components";
import Icon from "common/ui/Icon";
import STYLES from "common/util/constants/styles";

export const TabBarWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	margin-top: 15px;

	@media (min-width: 640px) {
		flex-direction: column;
		width: 100px;
		flex-shrink: 0;
		margin-left: 10px;
	}

	@media (max-width: 639px) {
		height: 80px;
	}
`;

const TabStyled = styled.div`
	background-color: ${STYLES.COLORS.DARK_MINT};
	transition-duration: 0.1s;
	cursor: pointer;
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover:not(.active) {
		box-shadow: 0px 0px 3px rgba(0, 31, 103, 0.25);
		background-color: #9bdbde;
	}

	&.active {
		background-color: ${STYLES.COLORS.MIDNIGHT_BLUE};
		color: white;
	}

	@media (min-width: 640px) {
		width: 85px;
		height: 100px;
		border-radius: 6px 0px 0px 6px;
		margin-bottom: 5px;

		&.active {
			width: 100px;
			border-right: 8px solid ${STYLES.COLORS.SALMON};
		}

		&:hover:not(.active) {
			width: 93px;
		}

		&.active:hover {
			background-color: #000f47;
		}
	}

	@media (max-width: 639px) {
		height: 70px;
		flex-grow: 1;
		border-radius: 6px 6px 0px 0px;
		margin-right: 5px;

		&.active {
			height: 80px;
			border-bottom: 6px solid ${STYLES.COLORS.SALMON};
		}

		:last-child {
			margin-right: 0;
		}
	}
`;

export const Tab = ({tabInfo, isActive, onSelect}) => {
	if (!tabInfo.showTabs) return null;

	return (
		<TabStyled
			tabIndex="0"
			onClick={() => onSelect(tabInfo)}
			className={isActive ? "active" : ""}
		>
			<Icon
				src={tabInfo.icon}
				color={isActive ? "white" : STYLES.COLORS.MIDNIGHT_BLUE}
				width={40}
			/>
		</TabStyled>
	);
};

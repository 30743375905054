/**
 * Determine the next index of a cycled array. As if you taped two ends of an array together
 * @param {number} currentIndex - The current index
 * @param {number} count - The total number of elements in the array
 * @param {number} increment - The direction to step by (-1 or 1)
 * @returns {number} The new index
 */

export const cycle = (currentIndex, count, increment) =>
	!currentIndex && increment === -1
		? count - 1
		: (currentIndex + increment) % count;

/**
 * Pluralize an english word
 * @param {number} num - The number of things to pluralize
 * @param {string} string - The English word you'd like to pluralize
 * @returns {string} The pluralized phrase with the number
 */

export const plural = (num, string) =>
	`${num} ${string}${num !== 1 ? "s" : ""}`;

export const isMobile = document.body.clientWidth < 640;

export const isApple = navigator.vendor === "Apple Computer, Inc.";

export const CHAPTER_PHONE_NUMBER_RAW = "6052058869";
export const CHAPTER_PHONE_NUMBER_FORMATTED = "(605) 205-8869";
export const TTY_PHONE_NUMBER_RAW = "711";
export const TTY_PHONE_NUMBER_FORMATTED = "711";
export const SSA_WEBSITE = "https://secure.ssa.gov/iClaim/rib";

export const MEDICARE_MINIMUM_AGE = 65;
export const MEDICARE_CONSIDERATION_AGE = 64;
export const LANDING_URL = "https://getchapter.com";
export const LUSTRE_ARTICLE =
	"https://www.lustre.net/home/2020/11/5/why-i-created-a-company-to-serve-older-people-starting-with-medicare";

export const MEDICARE_ENROLLMENT_PENALTY_SITE =
	"https://www.medicare.gov/your-medicare-costs/part-b-costs/part-b-late-enrollment-penalty";

export const MEDICARE_PART_A_COSTS_SITE =
	"https://www.medicare.gov/your-medicare-costs/part-a-costs";
/**
 * Scroll the viewport vertically and smoothly. This function exists to polyfill for Safari
 * beacuse it doesn't support behavior: smooth
 * @param {number} yPos - The y position you'd like to scroll to
 * @param {number} duration - The duration of the animation
 * @param {number} frames - How many times the animation should update. Higher values = smoother = worse performance
 */

export const polyfillScroll = (yPos, duration = 275, frames = 100) => {
	if (!isApple)
		return window.scrollBy({
			top: yPos,
			behavior: "smooth",
		});

	let eAmt = yPos / frames;
	let curTime = 0;

	while (curTime <= duration) {
		window.setTimeout(window.scrollBy, curTime, 0, eAmt);
		curTime += duration / frames;
	}
};

export const legalExpertText =
	"By scheduling an appointment, you grant consent for Chapter's licensed advisors or representatives to contact you about Medicare plans.";

/**
 * Compare two arrays of primitives
 * @param {primitive[]} arr1 - The first array of primitives to compare
 * @param {primitive[]} arr2 - The second array of primitives to compare
 * @returns {boolean} Wether or not the arrays are equivalent
 */
export const primativeArraysAreEqual = (arr1, arr2) => {
	if (arr1.length !== arr2.length) return false;

	return !arr1.some((el, index) => el !== arr2[index]);
};

export const strToCamel = (str) =>
	str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

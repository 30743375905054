import React, {Component} from "react";
import {LocalStorage} from "common/util/storage";
import STORAGE_KEYS from "common/util/storage/keys";
import {SummaryV3} from "./v3";

export class SummaryParent extends Component {
	static hideFooter = true;
	static bodyColor = "white";
	static maxWidth = 1200;
	static navbarButtonTextColor = "white";

	constructor(props) {
		super(props);

		props.setBottomNextButtonText(null);

		this.state = {
			name: LocalStorage.getItem(STORAGE_KEYS.FIRST_NAME),
			customerId: LocalStorage.getItem(STORAGE_KEYS.CUSTOMER_ID),
			email: LocalStorage.getItem(STORAGE_KEYS.PAGES.BASIC_INFO).email,
		};
	}

	render() {
		const {name, customerId, email} = this.state;
		const {isMobile} = this.props;

		return (
			<SummaryV3
				name={name}
				customerId={customerId}
				email={email}
				variant={1}
				isMobile={isMobile}
			/>
		);
	}
}

import {useUser} from "common/hooks/useUser";
import {Button} from "common/ui/Button/Button";
import Dialog from "common/ui/Dialog";
import Scheduler from "modals/Scheduler";
import {Reminders} from "pages/Flow/shared/Reminders";
import React, {useState} from "react";
import {useHistory} from "react-router";
import {IAction} from "./constants";

export function Actions({actions}: {actions: IAction[]}) {
	const history = useHistory();
	function showScheduler() {
		Dialog.show({
			children: <Scheduler />,
			width: Scheduler.width,
			height: Scheduler.height,
			bgColor: "white",
		});
	}
	const {updateUser} = useUser();

	const [showSaveDates, setShowSaveDates] = useState(false);
	const [showReminders, setShowReminders] = useState(false);

	function handleClick(action: IAction) {
		switch (action.type) {
			case "SIGN_UP":
				console.log("here", history);
				history.push("/flow/new/learn/sign-up");
				break;
			case "CALCULATOR":
				// TODO @Darsh: swap once unsure flow is pushed
				history.push("/flow/new/learn/sign-up");
				// history.push("/flow/unsure/calculator");
				break;
			case "QUESTIONS":
				showScheduler();
				// Show Scheduler
				break;
			case "REMINDERS_GEP":
				setShowReminders(true);
				break;
			case "REMINDERS_IEP":
				setShowReminders(true);
				break;
			case "SAVE_DATES":
				setShowSaveDates(true);
				break;
			default:
				console.warn("Case Not Caught");
		}
	}

	function handleEmailInput(email: string) {
		updateUser({email});
		// need to submit dates here too
		//onSubmit && onSubmit(email);
	}

	return (
		<>
			<div className="flex justify-center items-center space-x-4 my-10">
				{actions.map((action, i) => (
					<Button
						key={action.type}
						buttonType={i === 0 ? "primary" : "secondary"}
						type="button"
						onClick={(e) => {
							handleClick(action);
						}}
					>
						{action.title}
					</Button>
				))}
			</div>

			{showSaveDates && (
				<div className="mt-5 mb-10">
					<Reminders
						headerText={"We'll send you an email with your sign-up dates:"}
						actionText={"Send my dates"}
						onSubmit={handleEmailInput}
					/>
				</div>
			)}
			{showReminders && (
				<div className="mt-5 mb-10">
					<Reminders
						headerText={"We'll send you a reminder when it's time to sign-up."}
						actionText={"Send me reminders"}
						onSubmit={handleEmailInput}
					/>
				</div>
			)}
		</>
	);
}

/**
 * Calculator Form Field Data
 */

import {IField} from "common/ui/Form/FormRow";
import {CHAPTER_PHONE_NUMBER_FORMATTED} from "common/util/common";
import {
	marriedButSeparateIncomeBrackets,
	marriedIncomeBrackets,
	singleIncomeBrackets,
} from "./income-brackets";

export interface IFormData {
	name?: string;
	email?: string;
	zip: string;
	dob: string;
	gender: "Male" | "Female" | "preferNotToAnswer";
	hasEmployerHealthCoverage: "yes" | "no";
	hasWorkedTenYears: "yes" | "no";
	hasMoreThanTwentyEmployees: "yes" | "no";
	tobacco?: "true" | "false" | "preferNotToAnswer";

	wantsSpouseCoverage: "true" | "false";
	optFileTaxes: "single" | "joint" | "separately";
	optIncomeRange: "1" | "2" | "3" | "4" | "5" | "6";
	optMinimizeCosts: "yes" | "no" | "maybe";

	currentInsurancePremium?: string; // (number);
	currentInsuranceDeductible?: string; // (number);

	numDependents: "0" | "1" | "2" | "3";
	spouseOver65?: "yes" | "no";
	disabilityCoverage?: "yes" | "no";

	medsupForZipCost?: number;

	isReceivingSocialSecurityBenefits?: "yes" | "no";
	hasExistingMedicareFromDisability?: "yes" | "no";
	isEnrolledInMedsup?: "yes" | "no";
}

export const nameField = {
	name: "name",
	label: "Your Name",
	type: "input",
	placeholder: "Name",
	required: true,
};

export const zipField = {
	name: "zip",
	label: "Zip Code",
	type: "zip",
	placeholder: "12345",
	required: true,
};

export const birthdayField = {
	name: "dob",
	label: "Birthday",
	type: "birthdate",
	placeholder: "MM/DD/YYYY",
	required: true,
};

export const genderField = {
	name: "gender",
	label: "Gender",
	type: "radio",
	options: [
		{label: "Male", value: "Male"},
		{label: "Female", value: "Female"},
		{label: "Prefer not to answer", value: "preferNotToAnswer"},
	],
	required: true,
};

export const tobaccoField = {
	name: "tobacco",
	label: "Do you use tobacco?",
	tooltip:
		"In certain circumstances, some Medicare Supplement plans will ask for this information.",
	type: "radio",
	options: [
		{label: "Yes", value: "true"},
		{label: "No", value: "false"},
		{label: "Prefer not to answer", value: "preferNotToAnswer"},
	],
	required: true,
};

export const employerHealthCoverageField = {
	name: "hasEmployerHealthCoverage",
	label:
		"Do you have health coverage through your employer (or the employer of a spouse or partner)?",
	tooltip:
		" The type and cost of your current health coverage can influence your Medicare choices.",
	type: "radio",
	options: [
		{label: "Yes", value: "yes"},
		{label: "No", value: "no"},
	],
	required: true,
};

export const employerHasMoreThanTwentyEmployeesField = {
	name: "hasMoreThanTwentyEmployees",
	label: "Does that employer have 20 or more employees?",
	tooltip: `When an organization providing health coverage has fewer than 20 employees, Medicare will typically be required as the "primary" insurance for someone turning 65.`,
	type: "radio",
	options: [
		{label: "Yes", value: "yes"},
		{label: "No", value: "no"},
	],
	required: true,
	// show alert if no is pressed
	/**
	 * Many people are not aware that Medicare is considered primary coverage for employees at companies with <20 employees. As such, you may be subject to penalties if you do not sign up for Medicare at the correct time. We can check your personal situation if you call us." */
};

export const hasWorkedTenYearsField = {
	name: "hasWorkedTenYears",
	label:
		"Have you worked and paid Medicare payroll taxes for at least 10 years?",
	tooltip:
		"Most people have Medicare payroll taxes automatically deducted from each paycheck.",
	type: "radio",
	options: [
		{label: "Yes", value: "yes"},
		{label: "No", value: "no"},
	],
	required: true,
};

export const wantsSpouseCoverageField = {
	name: "wantsSpouseCoverage",
	label: "Who is covered under your current insurance?",
	tooltip: `Because Medicare does not cover people under the age of 65, you may want to consider how your dependents will be covered if you switch to Medicare. If you have questions on this, you can contact us at ${CHAPTER_PHONE_NUMBER_FORMATTED}`,
	type: "select",
	options: [
		{label: "Just me", value: "false"},
		{label: "My spouse and me", value: "true"},
	],
	required: true,
};

export const optFileTaxes = {
	name: "optFileTaxes",
	label: "How do you file your taxes?",
	tooltip: "Your Part B premium depends on your income and filing status.",
	type: "radio",
	options: [
		{label: "Single", value: "single"},
		{label: "Joint", value: "joint"},
		{label: "Married filing separately", value: "separately"},
	],
	required: true,
};

export const FORM_FIELDS: Array<IField[]> = [
	[zipField, birthdayField],
	[genderField],
	[tobaccoField],
	[employerHealthCoverageField],
	[employerHasMoreThanTwentyEmployeesField],
	[hasWorkedTenYearsField],
	[wantsSpouseCoverageField],
	[optFileTaxes],
];

export const IncomeBracketData = {
	name: "optIncomeRange",
	label: "What was your annual income range in 2019?",
	tooltip:
		"Part B premiums vary by income. 2021 premiums are based on your income tax returns from 2019. If your income has decreased since 2019, you can file an appeal with Medicare. Most people who file are successful. Reach out to us, and we can send you the forms.",
	type: "select",
	options: (marriageStatus: IFormData["optFileTaxes"]) =>
		marriageStatus === "single"
			? singleIncomeBrackets
			: marriageStatus === "joint"
			? marriedIncomeBrackets
			: marriedButSeparateIncomeBrackets,
	required: true,
};

export const OptMinimizeCosts = {
	name: "optMinimizeCosts",
	label:
		"Some plans minimize out-of-pocket costs for higher premiums. These plans give you maximum flexibilty to see any doctor who takes Medicare.",
	tooltip:
		"Medigap plans minimize future out-of-pocket costs but have more expensive premiums. These plans also give you maximum flexibility to see any doctor who accepts Medicare, which is the vast majority of doctors.",
	type: "radio",
	options: [
		{label: "Yes", value: "yes"},
		{label: "No", value: "no"},
		{label: "Maybe", value: "maybe"},
	],
};

export const currentInsuranceDeductibleField = {
	name: "currentInsuranceDeductible",
	label:
		"What is the annual deductible (per person) for your employer health plan?",
	tooltip:
		"Most Medicare plans have much lower deductibles than employer-provided plans. So even if the Medicare premiums are higher, it might be worth considering Medicare to secure a much lower deductible. If you have dependents on your plan, divide your total annual deductible by the number of people (including yourself) to approximate the per-person deductible.",
	type: "inputNumber",
};

export const spouseOver65Field = {
	name: "spouseOver65",
	label: "Is your spouse 65 or older?",
	type: "radio",
	options: [
		{label: "Yes", value: "yes"},
		{label: "No", value: "no"},
	],
};

export const disabilityCoverageField = {
	name: "disabilityCoverage",
	label: "Do your dependents qualify for disability coverage?",
	type: "radio",
	options: [
		{label: "Yes", value: "yes"},
		{label: "No", value: "no"},
	],
};

export const CurrentCost = {
	name: "currentCostOuter",
	label: "How much do you pay for your employer coverage each month?",
	tooltip:
		"This amount helps us calculate the cost of remaining on your current coverage, compared to signing up for Medicare.",
	type: "inlineMulti",
	fields: [
		{type: "label", name: "about-text", text: "About"},
		{
			type: "inputNumber",
			name: "currentInsurancePremium",
		},
		{
			type: "label",
			name: "per-month-for-text",
			text: "for",
		},
		{
			name: "numDependents",
			type: "select",
			className: "flex-grow",
			options: [
				{
					label: "Just me",
					value: "0",
				},
				{
					label: "My spouse and me",
					value: "1",
				},
				{
					label: "My spouse/dependents and me",
					value: "2",
				},
			],
		},
	],
	additionalFields: (
		numDependents: IFormData["numDependents"],
		currentInsurancePremium?: string
	) => {
		const optionalQuestions: IField[] = [];
		if (currentInsurancePremium && parseInt(currentInsurancePremium, 10) >= 0) {
			optionalQuestions.push(currentInsuranceDeductibleField);
		}

		if (numDependents === "1" || numDependents === "2") {
			optionalQuestions.push(spouseOver65Field);
		}

		if (numDependents === "2") {
			optionalQuestions.push(disabilityCoverageField);
		}

		return optionalQuestions;
	},
};

export const CURRENT_PREMIUM_TOOLTIP_LINES = [
	"This value is specific to your location and is among the lowest-cost Medigap Plan G options available in your area.",
	"While Medigap Plan G is the most popular Medicare Supplement plan in your area, we can help you evaluate other plans too.",
];

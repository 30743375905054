import React from "react";
import {IEnrollmentResultWindowExtended} from "../service";

export function EnrollmentDateDetails({
	data,
}: {
	data: Partial<IEnrollmentResultWindowExtended>;
}) {
	return (
		<>
			<div className="font-bold text-left">What you need to know:</div>
			<div className="divide-y divide-gray-100">
				{data.descriptionAreas?.map(({title, lines}) => (
					<div key={title} className="space-y-3 py-4">
						<div className="font-bold text-left">{title}</div>
						<div className="text-left space-y-5 leading-relaxed">
							{lines.map((line) => (
								<p dangerouslySetInnerHTML={{__html: line}} key={line}></p>
							))}
						</div>
					</div>
				))}
			</div>

			<div className="text-lg mt-4">
				{data.nextStepLines &&
					data.nextStepLines.map((line) => <p key={line}>{line}</p>)}
			</div>
		</>
	);
}

import React, {ReactChild, ReactChildren} from "react";
import clsx from "clsx";
import Icon from "common/ui/Icon";

const COLORS = {
	info: {
		text: "text-accent",
	},
};

export default function Alert({
	type,
	className,
	children,
}: {
	type: "info";
	size?: string;
	className?: string;
	children: ReactChild | ReactChildren;
}) {
	return (
		<div
			className={clsx(
				"flex items-center text-left rounded-md",
				className,
				`${COLORS[type]?.text}`
			)}
		>
			<div className="flex-shrink-0 mr-3 hidden md:flex">
				<Icon src={type} alt="alert" height={30} width={30} />
			</div>
			{children}
		</div>
	);
}

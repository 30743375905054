import React from "react";
import clsx from "clsx";
import MuiToggleButton, {
	ToggleButtonProps,
} from "@material-ui/lab/ToggleButton";
import {useHover} from "../../hooks";

export interface IIcon {
	label: string;
	value: string;
	icon: string;
	iconSelected: string;
	iconHover?: string;
}

interface IIconButtonProps extends ToggleButtonProps {
	option: IIcon;
	selected: boolean;
}

export function IconButton({option, selected, ...props}: IIconButtonProps) {
	const [hoverRef, isHovered] = useHover();

	return (
		<MuiToggleButton
			ref={hoverRef}
			color={"secondary"}
			key={option.value}
			classes={{
				label: "font-unset text-base font-bold font-unset normal-case",
				root: "mx-auto inline-flex items-center border-0 text-black hover:bg-transparent rounded-full",
				selected: "bg-teal-50",
			}}
			type="button"
			value={option.value}
			selected={selected}
			{...props}
		>
			<div className={clsx("flex flex-col items-center cursor-pointer mx-3")}>
				<img
					alt={option.label}
					src={
						selected
							? option.iconSelected
							: isHovered && option.iconHover
							? option.iconHover
							: option.icon
					}
				/>
				<p className={clsx("flex text-center mt-4", selected && "font-bold")}>
					{option.label}
				</p>
			</div>
		</MuiToggleButton>
	);
}

import axios from "./axios";
import endpoints from "./endpoints";
const ROUND_ZIP_PREMIUM_NUMBER = 5;

export function getMedsupCarriers() {
	return axios.get(endpoints.medsupCarriersList()).catch(console.error);
}

export function getMedsupPlanTypes() {
	return axios.get(endpoints.medsupPlanTypesList()).catch(console.error);
}

export function getMedsupLowestPremium(url: string) {
	return axios
		.get(url)
		.then((response) => {
			const rawPrice = response.data;
			// Round up premium to nearest 5
			const roundedPrice =
				Math.ceil(rawPrice / ROUND_ZIP_PREMIUM_NUMBER) *
				ROUND_ZIP_PREMIUM_NUMBER;
			return {data: roundedPrice};
		})
		.catch((err) => {
			console.warn("Retrieving Zip Premium Error", err);
		});
}

import * as React from "react";

export const useBoolean = (initialState: boolean) => {
    const [state, setState] = React.useState(initialState);

    return {
        state,
        toggle: () => setState(s => !s),
        setTrue: () => setState(true),
        setFalse: () => setState(false),
    }
};

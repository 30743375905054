import {Component} from "react";
import axios from "common/util/api/axios";
import endpoints from "common/util/api/endpoints";

type RecomenndationsArtifactProps = {
	match: any;
	location: any;
	history: any;
};

export class RecomenndationsArtifact extends Component<
	RecomenndationsArtifactProps
> {
	componentDidMount() {
		this.fetchData();
	}
	fetchData = async () => {
		const {token} = this.props.match.params;

		const customerResponse = await axios.get(endpoints.customerGet({token}));

		const recommendationsResponse = await axios.post(
			endpoints.recommendations(),
			{
				customerId: customerResponse.data.id,
				zipCode: customerResponse.data.zip,
			}
		);

		console.log(recommendationsResponse);
	};
	render() {
		return ":)";
	}
}

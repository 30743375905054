import React from "react";
import styled from "styled-components";
import STORAGE_KEYS from "common/util/storage/keys";
import STYLES from "common/util/constants/styles";
import QuestionnaireWrapper from "./QuestionnaireTemplate";

const SmallText = styled.div`
	font-size: 12px;
	font-weight: ${STYLES.FONT_WEIGHT.REGULAR};
`;

const description = (
	<>
		Which type of cost is most important to minimize?
		<SmallText>
			We search for both, and this helps us know how to prioritize your options.
		</SmallText>
	</>
);

const topText = (
	<>
		With some plans you’ll pay more if you are hospitalized, but these plans
		charge lower monthly premiums.
		<br />
		<br />
		With other plans you’ll pay less if you are hospitalized, but those plans
		charge higher monthly premiums.
	</>
);

const config = {
	options: [
		{value: "true", label: "Cost when you see a doctor"},
		{value: "false", label: "Cost for a hospital stay"},
	],
	localStorageKey: STORAGE_KEYS.PAGES.QUESTIONNAIRE_HOSPITAL_COSTS,
	questionKey: "zeroPremiumPreference",
	icon: "pig",
	isRequired: true,
	title: "Hospital Costs",
	description,
	drawer: {topText},
};

export class QuestionnaireHospitalCosts extends QuestionnaireWrapper {
	componentDidMount() {
		this.setConfig(config);
	}
}

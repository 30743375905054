import {format} from "date-fns";
import React, {useEffect, useState} from "react";
import Alert from "common/ui/Alert";
import Dialog from "common/ui/Dialog";
import eventTracker from "common/util/eventTracker";
import Scheduler from "modals/Scheduler";
import {IResultData} from "./results";
import clsx from "clsx";
import {BasicMedicare, SinglePlanSummary, SupplementalPlans} from "./Plans";
import {
	CHAPTER_PHONE_NUMBER_FORMATTED,
	LUSTRE_ARTICLE,
} from "common/util/common";
import {DeadlineReminders} from "./alerts/Reminder";
import {SpouseAndDependents} from "./SpouseAndDependents";
import ABTester from "common/util/abTester";
import {useHistory} from "react-router";
const {REACT_APP_EXPERIMENT_PART_B_CALC_APP_FLOW} = process.env;

export function ResultPlans({plans}: {plans: IResultData["plans"]}) {
	return (
		<>
			<div className={clsx("sm:grid")}>
				{plans?.length === 1 ? (
					<SinglePlanSummary plan={plans[0]} />
				) : (
					plans && (
						<div className="flex flex-col space-y-3">
							<div className="shadow rounded py-5 w-full md:w-3/4 mx-auto">
								<BasicMedicare plan={plans[0]} />
							</div>
							<div className="text-accent text-2xl font-bold leading-none">
								+
							</div>
							<div className="shadow rounded py-5 w-full md:w-3/4 mx-auto">
								<SupplementalPlans plans={plans.slice(1)} />
							</div>
						</div>
					)
				)}
			</div>
		</>
	);
}

export function ResultExplainer() {
	return (
		<div className="prose text-lg max-w-prose text-accent">
			The option that's right for you depends on a few factors:
			<ul>
				<li>Your preferred doctors and their insurance networks</li>
				<li>
					Your desire to minimize premiums or reduce potential out-of-pocket
					costs
				</li>
				<li>
					Whether you want additional benefits like hearing, dental, vision, and
					fitness benefits
				</li>
			</ul>
			<div className="pt-2">
				Most people become eligible as they approach their 65th birthday. If you
				think you may be eligible due to disability, we’d be happy to help.
			</div>
			<div>
				We created Chapter as the Medicare Advisor we{" "}
				<a
					target="_blank"
					rel="noreferrer"
					href={LUSTRE_ARTICLE}
					className="text-decoration-underline"
				>
					wanted for our own families
				</a>{" "}
				but couldn't find. Our licensed Advocates specialize in helping people
				decide whether to sign up, how and when to enroll, and what coverage to
				choose.
			</div>
			<div className="pt-4">
				You can call us at {CHAPTER_PHONE_NUMBER_FORMATTED} or chat with us in
				the bottom right of the screen.
			</div>
		</div>
	);
}

export function Result({result}: {result: IResultData}) {
	const history = useHistory();
	const {
		header,
		headerNotEligible,
		descriptionLines,
		experimentDescriptionLines,
		image,
		ctas,
		alertMessage,
	} = result;

	const isNotEligible = !!result.eligibilityDate;

	function showScheduler() {
		Dialog.show({
			children: <Scheduler />,
			width: Scheduler.width,
			height: Scheduler.height,
			bgColor: "white",
		});
	}

	function ctaClickWrapper(cta) {
		if (cta.link === "SCHEDULE") {
			return showScheduler();
		}
		if (cta.link === "APP") {
			// Redirect to app
			history.push("/");
			return;
		}
		// Track event
		eventTracker.log({
			name: eventTracker.EVENTS.CLICK_BUTTON,
			data: {name: "SSAMedicareSignup"},
		});
		window.open(cta.link);
	}

	/** Google Optimize Experiment */
	const [showExperiment, setShowExperiment] = useState(true);

	useEffect(() => {
		getNameExperiment();
	}, []);

	async function getNameExperiment() {
		const abTesterInstance = new ABTester(
			REACT_APP_EXPERIMENT_PART_B_CALC_APP_FLOW
		);
		const variants = await abTesterInstance.getVariants();

		// Variant 0 ––> Show Scheduler
		// Variant 1 ––> Redirect to App
		if (variants[0] === 1) {
			setShowExperiment(true);
		}
	}

	return (
		<div className="text-center space-y-5">
			{/** Header */}
			<div
				className="text-3xl py-3 leading-normal font-light max-w-3xl mx-auto"
				dangerouslySetInnerHTML={{
					__html: isNotEligible ? headerNotEligible : header,
				}}
			/>
			{/** Image */}
			<div className="flex justify-center py-4">
				<img
					src={`/images/part-b/${image}.svg`}
					alt="We've calculated your results!"
					height={260}
					width={image === "trophy" ? 200 : 175}
				/>
			</div>

			<div
				className={clsx(
					"font-light text-lg max-w-2xl text-left mx-auto text-accent leading-relaxed",
					(isNotEligible || result.secondaryTextLines?.length) && "pb-4"
				)}
			>
				{result.isReceivingSocialSecurityBenefits === "no" && (
					<div className="pb-2">
						You are not yet taking Social Security benefits. Here is what we
						recommend for your Medicare enrollment decision:
					</div>
				)}
				{/** Adjust content based on whether or not the user is medicare-eligible */}
				{isNotEligible && result.eligibilityDate && (
					<div className="pb-2">
						You can first sign up for Medicare starting in{" "}
						<span className="text-underline-accent font-bold">
							{format(result.eligibilityDate, "MMMM yyyy")}
						</span>{" "}
						, and your coverage can go into effect on{" "}
						<span className="text-underline-accent font-bold">
							{format(result.effectiveDate!, "MMMM d, yyyy")}
						</span>
						.{" "}
					</div>
				)}
				{/** Summary */}
				{result.secondaryTextLines?.map((line) => (
					<div
						key={line}
						className="pb-2"
						dangerouslySetInnerHTML={{__html: line}}
					></div>
				))}
			</div>
			{/** CTAs */}
			<div className="mb-2 pb-1 md:space-x-5 text-center">
				{ctas.map((cta, i) => (
					<button
						key={cta.link}
						className={clsx(
							"py-4 px-6 font-bold transition-colors rounded-full w-full md:w-auto text-sm sm:text-base",
							i === 0
								? "bg-orange text-white hover:bg-red-500"
								: "border border-gray-300  text-gray-700 hover:bg-gray-50"
						)}
						onClick={() => ctaClickWrapper(cta)}
					>
						{cta.text}
					</button>
				))}
				<div className="my-3 text-gray-600 text-sm">
					Working with Chapter is entirely free
				</div>
			</div>
			<div className="max-w-2xl mx-auto">
				{/** Plans Overview */}
				<ResultPlans plans={result.plans} />

				<div className="flex flex-col md:flex-row space-x-3"></div>

				<div className="space-y-5 mt-5 text-lg text-left leading-relaxed text-accent">
					<div className="space-y-4">
						{/** Details */}
						{(
							(showExperiment && experimentDescriptionLines) ||
							descriptionLines
						)
							.slice(0, -1)
							.map((description, i) => (
								<div
									key={i}
									className="text-accent"
									dangerouslySetInnerHTML={{__html: description}}
								/>
							))}
					</div>

					{/** Dependents Alert */}
					{alertMessage && (
						<div className="space-y-5">
							<SpouseAndDependents />
							<Alert
								className="text-sm px-4 py-3 shadow border border-gray-100"
								type="info"
							>
								<div className="w-full">
									<b>Please note:</b> {alertMessage} Call or text{" "}
									<b>{CHAPTER_PHONE_NUMBER_FORMATTED}</b> for help.
								</div>
							</Alert>
						</div>
					)}
					{showExperiment && (
						<div>
							Our{" "}
							<a href="/" className="text-underline-accent">
								plan finder tool{" "}
							</a>
							will ask you a series of questions that will allow us to match you
							to the best plan.
						</div>
					)}

					{/** Reminders Checkbox */}
					{<DeadlineReminders />}
					{/** Additional Text */}
					<div className="space-y-4">
						{descriptionLines
							.slice(-1)
							.map(
								(description, i) =>
									i !== descriptionLines.length - 1 && (
										<div
											key={i}
											className="text-accent"
											dangerouslySetInnerHTML={{__html: description}}
										/>
									)
							)}
					</div>
					{/** Shared Explainer Text */}
					<ResultExplainer />
				</div>
			</div>
		</div>
	);
}

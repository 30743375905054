import React, {useEffect, useState} from "react";
import useSWR from "swr";
import {ScheduleCard} from "../shared/ScheduleCard";
import axios from "common/util/api/axios";
import {MedSup, MA} from "./AdditionalCoverage/data/result";
import {FooterCta} from "../shared/FooterCta";
import clsx from "clsx";
import {useUser} from "common/hooks/useUser";

const fetcher = (url) => axios.get(url).then((res) => res.data);

export function NumberOfPlans({
	zip,
	maZipRefId,
}: {
	zip?: string;
	maZipRefId?: string;
}) {
	const {data: numberOfPlans, error} = useSWR(
		`/medsup/plan_count?zipcode=${zip}&planTypes=${"G"}`,
		fetcher
	);

	// pass in zip or maZipRefId
	return <span className="text-underline-accent">{numberOfPlans}</span>;
}

export function Result() {
	const {user} = useUser();

	const [data, setData] = useState<typeof MA | typeof MedSup>();

	useEffect(() => {
		if (user) {
			setData(
				user.premiumAffordability === false && user.zeroPremiumPreference
					? MA
					: MedSup
			);
		}
	}, [user]);

	const hasFilledInForm = user && user.premiumAffordability !== undefined;

	return (
		<>
			{data && (
				<div className="flex flex-col">
					{hasFilledInForm && (
						<>
							<div className="font-bold text-2xl mt-6 leading-relaxed px-4">
								{data.titleLines &&
									data.titleLines.map((line) => (
										<p key={line} dangerouslySetInnerHTML={{__html: line}}></p>
									))}
							</div>

							<div className="flex flex-col items-center my-10">
								<div className="text-left space-y-5 text-lg leading-relaxed">
									{user && data !== MA && (
										<>
											We found <NumberOfPlans zip={user.zip} /> of these plans
											in your area.
										</>
									)}
								</div>
								<div className="max-w-3xl">
									<AriCard />
								</div>
							</div>
						</>
					)}

					<div
						className={clsx(
							"flex flex-col items-center",
							!hasFilledInForm && "mt-12"
						)}
					>
						<div className="max-w-3xl mx-auto">
							<div className="font-bold text-2xl leading-relaxed px-4">
								{hasFilledInForm && "Your next step: "}Schedule your
								peace-of-mind consult
							</div>
							<ScheduleCard />

							<ExcitedCard />
						</div>

						{/** Button */}
						<div className="mt-auto w-full">
							<FooterCta />
						</div>
					</div>
				</div>
			)}
			{/** Catch Error State */}
		</>
	);
}

export function AriCard() {
	return (
		<div className="shadow-sm border rounded flex my-8">
			<div className="w-2/3 text-left space-y-4 px-8 py-3">
				<div className="text-lg">Ari sent you a message</div>
				<div className="italic text-sm leading-normal">
					“I’m looking forward to speaking with you about your Medicare options.
					Take a look at our agenda below and let me know of you have any
					questions. Talk soon!”
				</div>
				<div className="text-sm leading-normal">
					<b> About Ari: </b>
					Ari is our Lead Medicare advisor and has a JD from Stanford. He’s
					dedicated to helping people choose the right coverage for their needs.
				</div>
			</div>
			<img
				className="w-1/3 ml-auto h-auto object-contain"
				src="/images/brokers/ari-fam.png"
			/>
		</div>
	);
}

const FEATURE_TEXT_LOWEST_OOP =
	"Medicare Supplement plans offer the lowest out-of-pocket costs.";

const FEATURE_TEXT_FLEXIBILITY =
	"You’ll have maximum flexibility to see any doctor that takes Medicare.";

const FEATURE_TEXT_PART_D =
	"We’ll help you navigate Part D Drug coverage and other benefits.";

function ExcitedCard() {
	return (
		<div className="my-10">
			<div className="text-xl mt-6 leading-relaxed px-4 mb-10">
				We’re excited to help you narrow down your options.
			</div>
			<div className="grid grid-gap-3 grid-cols-3 text-sm space-x-5">
				<div className="text-center justify-center w-full">
					<img
						className="mx-auto text-center mb-3 h-28"
						src="/images/illustrations/search-agent.svg"
						alt={FEATURE_TEXT_LOWEST_OOP}
					/>
					{FEATURE_TEXT_LOWEST_OOP}
				</div>
				<div>
					<img
						className="mx-auto text-center mb-3 h-28"
						src="/images/illustrations/doctor-woman.svg"
						alt={FEATURE_TEXT_FLEXIBILITY}
					/>
					{FEATURE_TEXT_FLEXIBILITY}
				</div>
				<div>
					<img
						className="mx-auto text-center mb-3 h-28"
						src="/images/illustrations/device-add.svg"
						alt={FEATURE_TEXT_PART_D}
					/>
					{FEATURE_TEXT_PART_D}
				</div>
			</div>
		</div>
	);
}

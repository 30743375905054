/*
	The NativeSelect component is a wrapper for a simple HTML <select> element
	It looks great on mobile as iOS and Android handle them in a special way
	Avoid using on desktop since most desktop browsers don't style it
 */

import React, {Component} from "react";
import {NativeSelectStyled} from "./SelectHelpers";

export class NativeSelect extends Component {
	onChangeWrapper = (e) => {
		const {onChange} = this.props;

		onChange(e.target.value);
	};

	renderOption = (option) => {
		const value = option.value || option;
		const label = option.label || option;

		return <option key={value} children={label} value={value} />;
	};
	render() {
		const {options, value, className, placeholder, disabled} = this.props;

		return (
			<NativeSelectStyled
				onChange={this.onChangeWrapper}
				value={value}
				disabled={disabled}
				className={className}
			>
				{placeholder && (
					<option disabled value="">
						{placeholder}
					</option>
				)}
				{options.map(this.renderOption)}
			</NativeSelectStyled>
		);
	}
}

/**
 * Calculator Header Language
 */

export const CALCULATOR_TITLE = "Medicare Sign Up Calculator";
export const CALCULATOR_DESCRIPTION =
	"Most people aged 65 and older have the option to use Medicare as primary health insurance, instead of an employer plan. Use this calculator to see whether signing up for Medicare would offer more affordable coverage.";

export const CALCULATOR_FACTORS = [
	{
		title: "Cost",
		descriptionLines: [
			"We help you compare your monthly costs for employer coverage with what you would pay for Medicare.",
		],
		extendedDescriptionLines: [
			"For Medicare, most people pay two monthly costs: a Part B premium and a separate premium for additional coverage, like a Medicare Supplement or a Medicare Advantage plan.",
			"For employer coverage, most people pay a monthly premium that is often deducted from your paycheck.",
		],
		icon: "pig-with-backdrop",
	},
	{
		title: "Quality",
		descriptionLines: [
			"In addition to cost, you will want to compare the quality of Medicare coverage with the quality of your employer coverage.",
		],
		extendedDescriptionLines: [
			"In many circumstances, Medicare options can provide higher quality coverage, with lower deductibles and broad networks of healthcare providers.",
			"While our calculator focuses on cost, many people choose to have a free peace-of-mind consultation with a Chapter Advocate to compare the quality of their employer plan with Medicare options. If you'd like, a Chapter Advocate will walk explain the tradeoffs in a free, 1:1 phone consultation. ",
		],
		icon: "medal",
	},
];

export const CALCULATOR_LEGAL_DISCLAIMER =
	"By entering your contact information, you consent to having a licensed Medicare agent or representative from Chapter contact you.";

export const SITE_URL =
	"https://medicare.getchapter.com/medicare-premium-calculator";
export const SITE_TITLE = "Medicare Premium Calculator";
export const SITE_DESCRIPTION =
	"This free calculator helps you decide whether you should switch to Medicare";
export const SITE_IMAGE_URL =
	"https://medicare.getchapter.com/part-b/social.jpg";

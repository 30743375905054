import styled from "styled-components";
import Button from "common/ui/Button";
import STYLES from "common/util/constants/styles";

export const Wrapper = styled.div`
	padding: 10px ${STYLES.PADDING.BODY_HORIZONTAL};
	text-align: center;
`;

export const Title = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.XBOLD};
	font-size: 31px;
	margin-bottom: 30px;
`;

export const Subtext = styled.div`
	font-weight: ${STYLES.FONT_WEIGHT.NORMAL};
	font-size: 25px;
	margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
	max-width: 320px;
	margin: auto;

	@media (min-width: 640px) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		flex-direction: column;
	}
`;

export const LargeButtonStyled = styled(Button)`
	height: 70px;
	font-size: 16px;
	margin-bottom: 20px;

	@media (min-width: 640px) {
		width: 320px;
	}

	@media (max-width: 639px) {
		width: 100%;
	}
`;

export const MA_HEADER =
	"Understood. We can help you switch your plan during the allowable period, depending on your situation.";

export const MS_HEADER =
	"Understood. We’ll find you the same coverage with lower monthly premiums.";

export const MA_LINES = [
	"Most people on a Medicare Advantage plan may switch during the Annual Enrollment period, which runs from October 15 - December 7.",
	"However, if any of the following apply to you, you may be eligible to switch your Medicare Advantage plan outside of the this Annual Enrollment Period:",
];

export const MS_LINES = [
	"All supplemental plans of the same letter have <b>the same coverage</b> by law.",
	"We’ll search through all plans in your area to find you the lowest premiums, and help you compare carriers.",
];

export const MA_BULLETS_LINES = [
	"Moved to a new county or state within the past 2 months",
	"Are interested in switching to a 5-star Medicare Advantage plan",
	"Are eligible for Medicaid and Medicare",
	"Are enrolled in a State Pharmaceutical Assistance Program (SPAP)",
	"Are diagnosed with a chronic condition and there's a Medicare Chronic Care Special Needs Plan available that servies people with your condition",
];

interface PLAN_DATA {
	title: string;
	description: string[];
	bullets?: string[];
}
export const MS_DATA: PLAN_DATA = {
	title: MS_HEADER,
	description: MS_LINES,
};

export const MA_DATA: PLAN_DATA = {
	title: MA_HEADER,
	description: MA_LINES,
	bullets: MA_BULLETS_LINES,
};
